import React from 'react';

const Smiley6SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <defs>
        <style></style>
      </defs>
      <g transform="translate(0.783 0.783)">
        <path
          className="a"
          style={{ fill: '#feca2e' }}
          d="M25.217,8A17.217,17.217,0,1,1,8,25.217,17.218,17.218,0,0,1,25.217,8Zm7.826,14.087a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,33.043,22.087Zm-12.522,0a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,20.522,22.087Z"
          transform="translate(-8 -8)"
        />
        <circle
          className="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(22.217 12.217)"
        />
        <circle
          className="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(9.217 12.217)"
        />
      </g>
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M18,0A18,18,0,1,0,36,18,18.021,18.021,0,0,0,18,0Zm0,34.435A16.435,16.435,0,1,1,34.435,18,16.453,16.453,0,0,1,18,34.435Z"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M98.348,132.7A2.348,2.348,0,1,0,96,130.348,2.351,2.351,0,0,0,98.348,132.7Zm0-3.13a.783.783,0,1,1-.783.783A.784.784,0,0,1,98.348,129.565Z"
        transform="translate(-86.609 -115.478)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M226.348,128a2.348,2.348,0,1,0,2.348,2.348A2.351,2.351,0,0,0,226.348,128Zm0,3.13a.783.783,0,1,1,.783-.783A.784.784,0,0,1,226.348,131.13Z"
        transform="translate(-202.087 -115.478)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M112.435,224H96.783a.783.783,0,0,0,0,1.565h15.652a.783.783,0,0,0,0-1.565Z"
        transform="translate(-86.609 -202.087)"
      />
    </svg>
  </>
);
export default Smiley6SVG;
