import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getValue, setValue } from "../../core/lodash";
import {
  add360SurveyEmails,
  add360SurveyEmailsEmployees,
  add360SurveyEmailsEmployeesOnPopup,
  enableAddParticipants,
  get360SurveyEmails,
  get360SurveyParticipantEmails,
  getAudianceEmails,
  getOrganization,
  getOrganizationParticipant,
  getStandardSurveyEmails,
  getSurvey,
  import360SurveyEmails,
  importStandardsurveyEmails,
  pickAudianceEmails,
  updateOrganizationParticipant,
} from "../../core/services/surveyService";
import PickAudienceComponent from "./PickAudienceComponent";
import FormHeader from "./common/FormHeader";
import { NavLink } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import Cookies from "js-cookie";
import BackSVG from "../../components/svg/back-svg";
import SimpleReactValidator from "simple-react-validator";
import DownloadSvg from "../../components/svg/download-svg";
import { saveAs } from "file-saver";
import InviteParticipantSurveyModal from "../modal/inviteParticipantsModal";
import AlreadyThereParticipants from "pages/modal/alreadyThereParticipantsModal";

interface ISurveyPickAudianceProps {}

const SurveyPickAudiance: React.FunctionComponent<ISurveyPickAudianceProps> = (
  props
) => {
  const [emails, setEmails] = useState([]);
  const [surveyDetails, updateSurvey] = useState({});
  const surveyId = getValue(props, "match.params.id", "");
  const surveyQuestionType = getValue(props, "match.params.type", "");
  const surveyTemplate = getValue(props, "match.params.templates", "");

  const [isLeftMenu, manageMenu] = useState(false);
  const [standardSurveyPermission, setStandardSurveyPermission] = useState("");
  const [standardSurveyPermission1, setStandardSurveyPermission1] = useState(
    ""
  );

  const [organization, setOrganization] = useState([]);
  const [organizationLeftHeaders, setOrganizationLeftHeaders] = useState<any>(
    []
  );
  const [organizationValues, setOrganizationValues] = useState([]);
  const [organizationParticipants, setOrganizatioParticipants] = useState({});
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const toggleModal = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const [errorEmails, setErrorEmails] = useState([]);
  const [invalidEmailsDownloadUrl, setInvalidEmailsDownloadUrl] = useState("");

  const [participants, setParticipant] = useState([
    {
      name: "",
      email: "",
      managerLimit: 0,
      peerLimit: 0,
      subordinateLimit: 0,
      publicLimit: 0,
      feedbackParticipants: [
        {
          type: "private",
          emails: [],
          group: "manager",
        },
        {
          type: "private",
          emails: [],
          group: "peer",
        },
        {
          type: "private",
          emails: [],
          group: "subordinate",
        },
        {
          type: "public",
          emails: [],
          group: "public",
        },
      ],
    },
  ]);
  const [participantsStandard, setParticipantStandard] = useState<any>([
    {
      feedbackParticipants: [
        {
          type: "private",
          emails: [],
        },
        {
          type: "public",
          emails: [],
        },
      ],
    },
  ]);

  const removeParticipants = (index) => {
    let participantArray = participants.filter((_item, i) => i !== index);
    setParticipant([...participantArray]);
  };
  const [pickAudianceLoading, setPickAudianceLoading] = useState(false);
  const handlePickAudiance = async () => {
    try {
      var myNewArray;
      let wholeArray: any = [];
      for (let i = 0; i < participantsStandard.length; i++) {
        myNewArray = [].concat.apply(
          [],
          participantsStandard[i].feedbackParticipants.map((item) => {
            let emails: any = item.emails.map((email) => ({
              type: item.type,
              email: email,
            }));
            return emails;
          })
        );
        let req = {
          participants: myNewArray,
        };

        wholeArray.push(req);
      }
      let req = {
        participants: wholeArray[0].participants,
      };
      setPickAudianceLoading(true);
      let resp = await pickAudianceEmails(surveyId, req);
      if (resp) {
        let mainReq: any = [];
        if (getValue(resp, "data.participants.length", 0) !== 0) {
          for (
            let i = 0;
            i < getValue(resp, "data.participants.length", []);
            i++
          ) {
            let Internal = getValue(resp, `data.participants`, []).filter(
              (item) => item.type === "private"
            );
            let Extrenal = getValue(resp, `data.participants`, []).filter(
              (item) => item.type === "public"
            );
            let InternalEmails = Internal.map((item) => item.email);
            let ExtrenalEmails = Extrenal.map((item) => item.email);
            let req: any = {};
            req = {
              feedbackParticipants: [
                {
                  type: "private",
                  emails: InternalEmails,
                },
                {
                  type: "public",
                  emails: ExtrenalEmails,
                },
              ],
            };

            mainReq.push(req);
          }
          setParticipantStandard(mainReq);
        }
        setPickAudianceLoading(false);
        toast.success("Participants updated successfully", {
          className: "green-circle",
        });
      } else {
        setPickAudianceLoading(false);
      }
    } catch (error) {
      setPickAudianceLoading(false);
    }
  };

  useEffect(() => {
    getSurveyList();
    get360Emails();
    getAudianceEmail();
    getStandardEmails();
  }, []);

  const getOrganizationParticipants = async () => {
    let resp = await getOrganizationParticipant(surveyId);
    if (resp) {
      if (Object.keys(resp.data.participants).length !== 0) {
        setOrganizatioParticipants(getValue(resp, "data.participants", {}));
      } else {
        let response = await getOrganization(surveyId);
        if (response) {
          let obj = getValue(response, "data", {});
          let initialParticipants = {};
          Object.keys(obj).forEach((key) => {
            initialParticipants[key] = [];
          });
          setOrganizatioParticipants(initialParticipants);
        }
      }
    }
  };
  const getOrganizations = async () => {
    let resp = await getOrganization(surveyId);
    if (resp) {
      setOrganization(getValue(resp, "data", {}));
      setOrganizationLeftHeaders(Object.keys(getValue(resp, "data", {})));
      setOrganizationValues(Object.values(getValue(resp, "data", [])));
    }
  };

  const changeOrganizationParticipants = (
    headerIndex,
    sideHeaderIndex,
    id,
    value
  ) => {
    if (!organizationParticipants[value].includes(id)) {
      organizationParticipants[value].push(id);
      setOrganizatioParticipants({ ...organizationParticipants });
    } else {
      let item = organizationParticipants[value].filter((item) => item !== id);
      organizationParticipants[value] = item;
      setOrganizatioParticipants({ ...organizationParticipants });
    }
  };
  const [orgLoading, setOrgLoading] = useState(false);
  const addOrganisation = async () => {
    try {
      setOrgLoading(true);
      let payload = {
        participants: organizationParticipants,
      };
      let resp = await updateOrganizationParticipant(surveyId, payload);
      if (resp) {
        setOrgLoading(false);
        toast.success("Update successfully", { className: "green-circle" });
        getOrganizations();
      } else {
        setOrgLoading(false);
      }
    } catch (error) {
      setOrgLoading(false);
    }
  };

  const addParticipants = () => {
    participants.push({
      name: "",
      email: "",
      managerLimit: 0,
      peerLimit: 0,
      subordinateLimit: 0,
      publicLimit: 0,
      feedbackParticipants: [
        {
          type: "private",
          emails: [],
          group: "manager",
        },
        {
          type: "private",
          emails: [],
          group: "peer",
        },
        {
          type: "private",
          emails: [],
          group: "subordinate",
        },
        {
          type: "public",
          emails: [],
          group: "public",
        },
      ],
    });
    setParticipant([...participants]);
  };

  const getSurveyList = async () => {
    try {
      if (surveyId) {
        let resp = await getSurvey(surveyId);
        if (resp) {
          updateSurvey(getValue(resp, "data", {}));
          if (surveyQuestionType !== "SURVEY360") {
            setStandardSurveyPermission(
              getValue(resp, "data.settings.publicEnabled", false) ||
                getValue(resp, "data.settings.publicUniqueEnabled", false)
            );
            setStandardSurveyPermission1(
              getValue(resp, "data.settings.privateEnabled", false)
            );
            getOrganizations();
            getOrganizationParticipants();
          } else {
            setEnableAddParticipant(
              getValue(resp, "data.settings.enableAddParticipant", false)
            );
          }
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getAudianceEmail = async () => {
    try {
      let resp = await getAudianceEmails(surveyId);
      if (resp) {
        let mainReq: any = [];
        if (getValue(resp, "data.participants.length", 0) !== 0) {
          for (
            let i = 0;
            i < getValue(resp, "data.participants.length", []);
            i++
          ) {
            let Internal = getValue(resp, `data.participants`, []).filter(
              (item) => item.type === "private"
            );
            let Extrenal = getValue(resp, `data.participants`, []).filter(
              (item) => item.type === "public"
            );
            let InternalEmails = Internal.map((item) => item.email);
            let ExtrenalEmails = Extrenal.map((item) => item.email);
            let req: any = {};
            req = {
              feedbackParticipants: [
                {
                  type: "private",
                  emails: InternalEmails,
                },
                {
                  type: "public",
                  emails: ExtrenalEmails,
                },
              ],
            };
            mainReq.push(req);
          }
          setParticipantStandard(mainReq);
        }
      }
    } catch (error) {}
  };

  const [loading360, setLoading360] = useState(false);
  const add360EmailTemplate = async () => {
    if (getValue(surveyDetails, "status", "") === "published") {
      toast.error(
        "Participants shouldn't be updated, Survey Already submitted",
        { className: "red-circle" }
      );
    } else {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
      } else {
        try {
          var myNewArray;
          let wholeArray: any = [];
          for (let i = 0; i < participants.length; i++) {
            myNewArray = [].concat.apply(
              [],
              participants[i].feedbackParticipants.map((item) => {
                let emails: any = item.emails.map((email) => ({
                  type: item.type,
                  email: email,
                  group: item.group,
                }));
                return emails;
              })
            );
            let req = {
              // name: participants[i].name,
              managerLimit: participants[i].managerLimit
                ? participants[i].managerLimit
                : 0,
              peerLimit: participants[i].peerLimit
                ? participants[i].peerLimit
                : 0,
              subordinateLimit: participants[i].subordinateLimit
                ? participants[i].subordinateLimit
                : 0,
              publicLimit: participants[i].publicLimit
                ? participants[i].publicLimit
                : 0,
              email: participants[i].email,
              feedbackParticipants: myNewArray,
            };
            wholeArray.push(req);
          }
          let req = {
            participants: wholeArray,
          };
          setLoading360(true);
          let resp = await add360SurveyEmails(surveyId, req);
          if (resp) {
            setLoading360(false);
            toast.success(
              "Participants added successfully"
              //  {className: 'green-circle'}
            );
            if (getValue(resp, "data.invalidEmails.length", 0) !== 0) {
              setErrorEmails(getValue(resp, "data.invalidEmails", []));
              setInvalidEmailsDownloadUrl(
                getValue(resp, "data.invalidEmailsDownloadUrl", [])
              );
              toggleModal();
            }
            get360Emails();
          } else {
            setLoading360(false);
            get360Emails();
          }
        } catch (error) {
          setLoading360(false);
          get360Emails();
        }
      }
    }
  };

  const [all360SurveyEmails, setAll360SurveyEmails] = useState([]);
  const get360Emails = async () => {
    try {
      let resp = await get360SurveyEmails(surveyId);
      if (resp) {
        setAll360SurveyEmails(getValue(resp, "data.participants", []));
        if (getValue(resp, "data.participants.length", 0) !== 0) {
          let mainReq: any = [];
          for (
            let i = 0;
            i < getValue(resp, "data.participants.length", []);
            i++
          ) {
            let managers = getValue(
              resp,
              `data.participants[${i}].feedbackParticipants`,
              []
            ).filter((item) => item.group === "manager");

            let peers = getValue(
              resp,
              `data.participants[${i}].feedbackParticipants`,
              []
            ).filter((item) => item.group === "peer");

            let subordinates = getValue(
              resp,
              `data.participants[${i}].feedbackParticipants`,
              []
            ).filter((item) => item.group === "subordinate");

            let team = getValue(
              resp,
              `data.participants[${i}].feedbackParticipants`,
              []
            ).filter((item) => item.group === "public");

            let managerEmails = managers.map((item) => item.email);
            let peersEmails = peers.map((item) => item.email);
            let subordinatesEmails = subordinates.map((item) => item.email);
            let teamEmail = team.map((item) => item.email);

            let req: any = {};

            req = {
              name: getValue(resp, `data.participants[${i}].name`, ""),
              id: getValue(resp, `data.participants[${i}].id`, ""),
              email: getValue(resp, `data.participants[${i}].email`, ""),
              managerLimit: getValue(
                resp,
                `data.participants[${i}].managerLimit`,
                ""
              ),
              peerLimit: getValue(
                resp,
                `data.participants[${i}].peerLimit`,
                ""
              ),
              subordinateLimit: getValue(
                resp,
                `data.participants[${i}].subordinateLimit`,
                ""
              ),
              publicLimit: getValue(
                resp,
                `data.participants[${i}].publicLimit`,
                ""
              ),
              // empId: getValue(resp, `data.participants[${i}].empId`, ''),
              // enabled360: getValue(resp, `data.participants[${i}].enabled360`, ''),
              feedbackParticipants: [
                {
                  type: "private",
                  emails: managerEmails,
                  group: "manager",
                },
                {
                  type: "private",
                  emails: peersEmails,
                  group: "peer",
                },
                {
                  type: "private",
                  emails: subordinatesEmails,
                  group: "subordinate",
                },
                {
                  type: "public",
                  emails: teamEmail,
                  group: "public",
                },
              ],
            };

            mainReq.push(req);
          }
          setParticipant(mainReq);
        } else {
          setValue(participants[0], `email`, "");
          setValue(participants[0], `feedbackParticipants[${0}].emails`, []);
          setValue(participants[1], `feedbackParticipants[${0}].emails`, []);
          setValue(participants[2], `feedbackParticipants[${0}].emails`, []);
          setValue(participants[3], `feedbackParticipants[${0}].emails`, []);
          setParticipant([...participants]);
        }
      }
    } catch (error) {}
  };

  const [participantLoading, setParticipantLoading] = useState(false);
  const getStandardEmails = async () => {
    try {
      setParticipantLoading(true);
      let resp = await getStandardSurveyEmails(surveyId);
      if (resp) {
        if (getValue(resp, "data.participants.length", 0) !== 0) {
          let publicEmails = getValue(resp, "data.participants", []).filter(
            (item: object) => getValue(item, `type`, "") === "public"
          );

          let privateEmails = getValue(resp, "data.participants", []).filter(
            (item: object) => getValue(item, `type`, "") === "private"
          );
          setParticipantStandard((prevState: any) => [
            {
              ...prevState[0],
              feedbackParticipants: [
                {
                  ...prevState[0].feedbackParticipants[0],
                  emails: privateEmails.map((item: any) =>
                    getValue(item, "email", "")
                  ),
                },
                {
                  ...prevState[0].feedbackParticipants[1],
                  emails: publicEmails.map((item: any) =>
                    getValue(item, "email", "")
                  ),
                },
              ],
            },
          ]);
          setParticipantLoading(false);
        } else {
          setParticipantLoading(false);
        }
      }
    } catch (error) {
      setParticipantLoading(false);
    }
  };
  // **** Survey List/Update **** //
  const handleChangeEmail = (email, emailIndex, mainIndex) => {
    // setEmails(email);
    setParticipantStandard((pages) => {
      return pages.map((x, index) => {
        if (index !== mainIndex) return x;
        return {
          ...x,
          feedbackParticipants: getValue(x, "feedbackParticipants", []).map(
            (y, index2) => {
              if (index2 !== emailIndex) return y;
              return {
                ...y,
                emails: email,
              };
            }
          ),
        };
      });
    });
  };

  const emailTemplateOnchange = (e, i) => {
    const { name, value } = e.target;
    setParticipant((pages) => {
      return pages.map((x, index) => {
        if (index !== i) return x;
        return {
          ...x,
          [name]: value,
        };
      });
    });
  };
  const emailTemplateOnchangeNumber = (e, i) => {
    const { name, value } = e.target;
    setParticipant((pages) => {
      return pages.map((x, index) => {
        if (index !== i) return x;
        return {
          ...x,
          [name]: parseInt(value),
        };
      });
    });
  };

  const handleEmailTemplateEmail = (email, emailIndex, mainIndex) => {
    setParticipant((pages) => {
      return pages.map((x, index) => {
        if (index !== mainIndex) return x;
        return {
          ...x,
          feedbackParticipants: getValue(x, "feedbackParticipants", []).map(
            (y, index2) => {
              if (index2 !== emailIndex) return y;
              return {
                ...y,
                emails: email,
              };
            }
          ),
        };
      });
    });
  };

  //----------- for 360 ----------------//

  const [toggleUpload, setToggleUpload] = useState(false);
  const [uploadData, setUploadData] = useState("");

  const import360Emails = async (e) => {
    setUploadData(e.target.files[0]);
  };

  const [uploadLoading, setUploadLoading] = useState(false);
  const upload360Emails = async () => {
    try {
      setUploadLoading(true);
      let formData = new FormData();
      formData.append("file", uploadData);
      let resp = await import360SurveyEmails(surveyId, formData);
      if (resp) {
        //console.log(resp);
        get360Emails();
        handleToggleUpload();
        setUploadLoading(false);
      } else {
        handleToggleUpload();
        get360Emails();
        setUploadLoading(false);
      }
    } catch (error) {
      setUploadLoading(false);
    }
  };

  const handleToggleUpload = () => {
    setToggleUpload(!toggleUpload);
  };

  const handleDownloadEmails = () => {
    if (invalidEmailsDownloadUrl) {
      saveAs(invalidEmailsDownloadUrl, "Excel");
    } else {
      toast.error("No Emails Found", { className: "red-circle" });
    }
  };

  //----------- for standard ----------------//

  const [toggleUpload1, setToggleUpload1] = useState(false);
  const [uploadData1, setUploadData1] = useState("");

  const import360Emails1 = async (e) => {
    setUploadData1(e.target.files[0]);
  };

  const upload360Emails1 = async () => {
    try {
      setUploadLoading(true);
      let formData = new FormData();
      formData.append("file", uploadData1);
      let resp = await importStandardsurveyEmails(surveyId, formData);
      if (resp) {
        if (getValue(resp, "data.invalidEmails.length", 0) !== 0) {
          setErrorEmails(getValue(resp, "data.invalidEmails", []));
          setInvalidEmailsDownloadUrl(
            getValue(resp, "data.invalidEmailsDownloadUrl", [])
          );
          toggleModal();
        }
        getStandardEmails();
        handleToggleUpload1();
        setUploadLoading(false);
      } else {
        handleToggleUpload1();
        getStandardEmails();
        setUploadLoading(false);
      }
    } catch (error) {
      setUploadLoading(false);
    }
  };

  const handleToggleUpload1 = () => {
    setToggleUpload1(!toggleUpload1);
  };

  const handleDownloadEmails1 = () => {
    if (invalidEmailsDownloadUrl) {
      saveAs(invalidEmailsDownloadUrl, "Excel");
    } else {
      toast.error("No Emails Found", { className: "red-circle" });
    }
  };

  const [enableAddParticipant, setEnableAddParticipant] = useState(false);
  const handleEnableAddParticipant = async (status) => {
    setEnableAddParticipant(status);
    let payload = {
      enableAddParticipant: status,
    };
    let resp = await enableAddParticipants(surveyId, payload);
    if (resp) {
      toast.success(getValue(resp, "message", "Updated successfully"), {
        className: "green-circle",
      });
    }
  };

  // ===========================================================================
  // Participants popup section
  // ===========================================================================
  const [participantsPopup, setParticipantPopup] = useState([
    {
      name: "",
      email: "",
      feedbackParticipants: [
        {
          type: "private",
          emails: [],
          group: "manager",
        },
        {
          type: "private",
          emails: [],
          group: "peer",
        },
        {
          type: "private",
          emails: [],
          group: "subordinate",
        },
        {
          type: "public",
          emails: [],
          group: "public",
        },
      ],
    },
  ]);
  const removeParticipantsPopup = (index) => {
    let participantArray = participantsPopup.filter((_item, i) => i !== index);
    setParticipantPopup([...participantArray]);
  };
  const addParticipantsPopup = () => {
    participantsPopup.push({
      name: "",
      email: "",
      feedbackParticipants: [
        {
          type: "private",
          emails: [],
          group: "manager",
        },
        {
          type: "private",
          emails: [],
          group: "peer",
        },
        {
          type: "private",
          emails: [],
          group: "subordinate",
        },
        {
          type: "public",
          emails: [],
          group: "public",
        },
      ],
    });
    setParticipantPopup([...participantsPopup]);
  };
  const handleEmailTemplateEmailPopup = (email, emailIndex, mainIndex) => {
    setParticipantPopup((pages) => {
      return pages.map((x, index) => {
        if (index !== mainIndex) return x;
        return {
          ...x,
          feedbackParticipants: getValue(x, "feedbackParticipants", []).map(
            (y, index2) => {
              if (index2 !== emailIndex) return y;
              return {
                ...y,
                emails: email,
              };
            }
          ),
        };
      });
    });
  };
  const emailTemplateOnchangePopup = (e, i) => {
    const { name, value } = e.target;
    setParticipantPopup((pages) => {
      return pages.map((x, index) => {
        if (index !== i) return x;
        return {
          ...x,
          [name]: value,
        };
      });
    });
  };
  const add360EmailTemplatePopup = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        var myNewArray;
        let wholeArray: any = [];
        for (let i = 0; i < participantsPopup.length; i++) {
          myNewArray = [].concat.apply(
            [],
            participantsPopup[i].feedbackParticipants.map((item) => {
              let emails: any = item.emails.map((email) => ({
                type: item.type,
                email: email,
                group: item.group,
              }));
              return emails;
            })
          );
          let req = {
            // name: participants[i].name,
            email: participantsPopup[i].email,
            feedbackParticipants: myNewArray,
          };
          wholeArray.push(req);
        }
        let req = {
          participants: wholeArray[0],
        };
        setLoading360(true);
        let resp = await add360SurveyEmailsEmployeesOnPopup(
          surveyId,
          participantId,
          req
        );
        if (resp) {
          toast.success(
            "Participants added successfully"
            //  {className: 'green-circle'}
          );
          setValue(
            participantsPopup[0],
            `feedbackParticipants[${0}].emails`,
            []
          );
          setValue(
            participantsPopup[0],
            `feedbackParticipants[${1}].emails`,
            []
          );
          setValue(
            participantsPopup[0],
            `feedbackParticipants[${2}].emails`,
            []
          );
          setValue(
            participantsPopup[0],
            `feedbackParticipants[${3}].emails`,
            []
          );
          setParticipantPopup([...participantsPopup]);
          setLoading360(false);
          get360Emails();
          handleModal();
        } else {
          setLoading360(false);
        }
      } catch (error) {
        setLoading360(false);
      }
    }
  };
  const handleDownloadEmailsPopup = () => {
    if (invalidEmailsDownloadUrl) {
      saveAs(invalidEmailsDownloadUrl, "Excel");
    } else {
      toast.error("No Emails Found", { className: "red-circle" });
    }
  };

  const handleToggleUploadPopup = () => {
    setToggleUpload(!toggleUpload);
  };
  const [toggleUploadPopup, setToggleUploadPopup] = useState(false);
  const [uploadDataPopup, setUploadDataPopup] = useState("");

  const import360EmailsPopup = async (e) => {
    setUploadData(e.target.files[0]);
  };
  const upload360EmailsPopup = async () => {
    let formData = new FormData();
    formData.append("file", uploadData);
    let resp = await import360SurveyEmails(surveyId, formData);
    if (resp) {
      handleToggleUpload();
    } else {
      handleToggleUpload();
    }
  };
  const toggleModal1 = () => {
    getParticipantsEmails(surveyId, empId);
    setModal1(!modal1);
  };

  const [alreadyThereParticipants, setAlreadyThereParticipants] = useState({});
  const getParticipantsEmails = async (surveyId, empId) => {
    let resp = await get360SurveyParticipantEmails(surveyId, empId);
    if (resp) {
      setAlreadyThereParticipants(getValue(resp, "data.participants", {}));
    }
  };

  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleModalWithParams = (participantId,index) => {
    setEmpId(getValue(all360SurveyEmails,`[${index}].empId`,''))
    selectedParticipantId(participantId);
    handleModal();
  };
  const [empId, setEmpId] = useState("");
  const [participantId, selectedParticipantId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [userName, setUserName] = useState("");


  // ===========================================================================
  // End
  // ===========================================================================

  return (
    <div className="page-layout page-layout-full">
      <div className="create-survey-tabs">
        <FormHeader
          surveyDetails={surveyDetails}
          surveyId={surveyId}
          surveyQuestionType={surveyQuestionType}
          surveyTemplate={surveyTemplate}
        />
        <div className="back-btn">
          {!Cookies.get("template") ? (
            <NavLink to="/home">
              <Button color="nothing">
                <BackSVG />
              </Button>
            </NavLink>
          ) : (
            <NavLink to="/templates">
              <Button color="nothing">
                <BackSVG />
              </Button>
            </NavLink>
          )}
        </div>
      </div>
      <PickAudienceComponent
        uploadLoading={uploadLoading}
        upload360Emails={upload360Emails}
        handleToggleUpload={handleToggleUpload}
        toggleUpload={toggleUpload}
        upload360Emails1={upload360Emails1}
        handleToggleUpload1={handleToggleUpload1}
        toggleUpload1={toggleUpload1}
        import360Emails1={import360Emails1}
        removeParticipants={removeParticipants}
        handlePickAudiance={handlePickAudiance}
        pickAudianceLoading={pickAudianceLoading}
        handleChangeEmail={handleChangeEmail}
        emails={emails}
        participantsStandard={participantsStandard}
        surveyDetails={surveyDetails}
        setParticipant={setParticipant}
        participant={participants}
        emailTemplateOnchange={emailTemplateOnchange}
        emailTemplateOnchangeNumber={emailTemplateOnchangeNumber}
        handleEmailTemplateEmail={handleEmailTemplateEmail}
        add360EmailTemplate={add360EmailTemplate}
        addParticipants={addParticipants}
        standardSurveyPermission={standardSurveyPermission}
        standardSurveyPermission1={standardSurveyPermission1}
        organizationLeftHeaders={organizationLeftHeaders}
        organizationValues={organizationValues}
        organizationParticipants={organizationParticipants}
        addOrganisation={addOrganisation}
        orgLoading={orgLoading}
        changeOrganizationParticipants={changeOrganizationParticipants}
        loading360={loading360}
        import360Emails={import360Emails}
        surveyQuestionType={surveyQuestionType}
        simpleValidator={simpleValidator}
        handleEnableAddParticipant={handleEnableAddParticipant}
        enableAddParticipant={enableAddParticipant}
        handleModal={handleModal}
        handleModalWithParams={handleModalWithParams}
        surveyId={surveyId}
        participantLoading={participantLoading}
      />
      <InviteParticipantSurveyModal
        handleModal={handleModal}
        isOpen={isOpen}
        addParticipants={addParticipantsPopup}
        removeParticipants={removeParticipantsPopup}
        handleEmailTemplateEmail={handleEmailTemplateEmailPopup}
        add360EmailTemplate={add360EmailTemplatePopup}
        loading360={loading360}
        simpleValidator={simpleValidator}
        emailTemplateOnchange={emailTemplateOnchangePopup}
        participant={participantsPopup}
        handleDownloadEmails={handleDownloadEmailsPopup}
        modal={modal}
        toggleModal={toggleModal}
        errorEmails={errorEmails}
        handleToggleUpload={handleToggleUploadPopup}
        toggleUpload={toggleUploadPopup}
        import360Emails={import360EmailsPopup}
        upload360Emails={upload360EmailsPopup}
        uploadData={uploadDataPopup}
        userName={userName}
        getParticipantsEmails={upload360EmailsPopup}
        empId={empId}
        surveyId={surveyId}
        toggleModal1={toggleModal1}
        showClient
      />

      <AlreadyThereParticipants
        modal={modal1}
        toggleModal={toggleModal1}
        alreadyThereParticipants={alreadyThereParticipants}
      />

      <Modal isOpen={modal} toggle={toggleModal} centered className="modal-lg">
        <ModalBody>
          <div
            className="text-right"
            onClick={handleDownloadEmails}
            style={{ cursor: "pointer" }}
          >
            <DownloadSvg />
          </div>
          <h5 className="mb-1">Invalid Emails :</h5>

          <hr className="my-2" />
          <div className="ol-overflow-scroll mb-3">
            <ol className="mt-3 answer-ol pl-5">
              {errorEmails &&
                errorEmails.map((item) => {
                  return <li>{item}</li>;
                })}
            </ol>
          </div>
          <div className="text-right">
            <Button onClick={toggleModal}>Close</Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SurveyPickAudiance;
