import * as React from 'react';
import { Label, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface IShortAnswerProps {
  questions: any;
}

const ShortAnswer: React.FunctionComponent<IShortAnswerProps> = (props) => {
  return (
    <div className="form-group">
      <Input
        type="text"
        placeholder="Enter Text"
        value={getValue(props, 'questions.response[0]', '')}
        disabled
      ></Input>
    </div>
  );
};

export default ShortAnswer;
