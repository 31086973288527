import React, { useState } from 'react';
import { getSpecificSurveyTemplate } from '../../core/services/surveyTemplateService';
import ChooseTemplateLayout from './chooseTemplateLayout';
import { getValue } from '../../core/lodash';

interface ITemplateFormComponantProps {}

const TemplateFormComponant: React.FunctionComponent<ITemplateFormComponantProps> = (
  props
) => {
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [templateInfo, setTemplateInfo] = React.useState({});
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };

  React.useEffect(() => {
    getTemplateInfo(getValue(props, 'match.params.id', ''));
  }, []);
  // =============================================================================
  // Api section
  // =============================================================================
  const getTemplateInfo = async (id) => {
    try {
      let resp = await getSpecificSurveyTemplate(id);
      if (resp) {
        setTemplateInfo(getValue(resp, 'data', ''));
      }
    } catch (error) {
      //console.log(error);
    }
  };
  return (
    <>
      <ChooseTemplateLayout
        templateInfo={templateInfo}
        getTemplateInfo={getTemplateInfo}
      />
    </>
  );
};

export default TemplateFormComponant;
