import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  NavLink as NavLink1,
  TabContent,
  Label,
  Modal,
  ModalBody,
  Input,
} from "reactstrap";
import BackSVG from "../../components/svg/back-svg";
import { getValue, setValue } from "core/lodash";
import {
  getSurvey,
  addSurveyQuestion,
  updateSurveyName,
  updateSurveyPageD,
  getSurveyQuestions,
  createSurveyPage,
  deleteSurveyPageQuestion,
  updateSurveyPageQuestion,
  getSurveyCategories,
  assesmentTypes,
  surveysTypes,
  survey360Types,
  uploadExcelFiles,
  uploadExcelFilesStandard,
  uploadExcelFiles360,
} from "core/services/surveyService";
import { deleteSurveyPage } from "core/services/surveyPageService";
import { reqTypes } from "../../components/answerTypeRequest";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
// import ChangeQuestionType from '../../components/common/changesQuestionType';
import CloseSVG from "components/svg/close-svg";
import { useHistory } from "react-router-dom";
import {
  createSurveyTemplate,
  updateSurveyTemplate,
} from "core/services/surveyTemplateService";
// import DeleteSurveyModal from '../../components/common/modal/deleteModal';
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import RenderSurveyQuestion from "./RenderSurveyQuestion";
import FormHeader from "./common/FormHeader";

interface Props {
  handleQuestionType: (value: string) => void;
  handleQuestionTypeValues: (
    e: any,
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  addMoreOption: (value: any, pageIndex: number, questionIndex: number) => void;
  removeMoreOption: (
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleScore: (
    index: number,
    name: string,
    value: number,
    updateState: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  addPredefinedOption: (
    value: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleAddQuestion: (
    pageIndex: number,
    questionIndex: number,
    pageId: string,
    surveyId: string
  ) => void;
  handleEditorData: (
    evt: any,
    path: string,
    state: string,
    value: string
  ) => void;
  copyQuestion: (pageIndex: number, questionIndex: number) => void;
  deleteQuestion: (
    pageIndex: number,
    questionIndex: number,
    pageId: string,
    surveyId: string,
    questionId: string
  ) => void;
  handleChangeQuestion: (
    evt: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  handleChangeBooleanFourParams: (
    name: string,
    value: boolean,
    path: any,
    state: any
  ) => void;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  handleEditQuestion: (
    evt: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleDateQuestionType: (
    _name: any,
    value: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  handleQuestionTypeInputBox: (
    e: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleFullName: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number
  ) => void;
  handleGridData: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleGridDataColumn: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleAddGridDataRow: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleAddGridDataColumn: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleDeleteGridRow: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number
  ) => void;
  handleDeleteGridColumn: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    colIndex: number
  ) => void;

  handleRatingScaleDropdownString: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    param: any
  ) => void;
  handleChangeVisibility: (status: boolean, path: string) => void;
  emails: string[];
}

const CreateNewSurveyForm: FunctionComponent<Props> = (props) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const surveyId = getValue(props, "match.params.id", "");
  const surveyQuestionType = getValue(props, "match.params.type", "");
  const surveyTemplate = getValue(props, "match.params.templates", "");

  const [surveyDetails, updateSurvey] = useState({});
  const [isLeftMenu, manageMenu] = useState(false);
  const [isSideMenu, manageSideMenu] = useState(false);
  const [isBulkMenu, manageSideMenuBulkanswer] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabPage, setActiveTabPage] = useState("1");
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [questionRequestDummy, setquestionRequestDummy] = useState<any>(
    reqTypes.MATRIX_RADIO_BOX
  );
  const [questionRequest, setQuestionReq] = useState<any>(
    reqTypes.SINGLE_CHOICE
  );
  const [bulkAnswer, setBulkAnswer] = useState("");
  const [bulkIndex, setBulkIndex] = useState({
    pageIndex: 0,
    questionIndex: 0,
  });
  const [categoryOption, setCategoryOption] = useState([]);
  const [typeOption, setTypeOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [handleDelete, setHandleDelete] = useState({
    pageIndex: "",
    pageId: "",
    value: "",
  });
  const [isOpen, setIsOPen] = useState(false);
  const [isOpen1, setIsOPen1] = useState(false);
  const [isOpen2, setIsOPen2] = useState(false);
  const [surveyCategories, setSurveyCategories] = useState([]);
  const [surveyType, setSurveyType] = useState([
    { value: "Standard Survey", label: "Standard Survey" },
    { value: "360 Survey", label: "360 Survey" },
    { value: "Assessment", label: "Assessment" },
  ]);
  const [request, setRequest] = useState({
    name: "",
    category: "",
  });
  const [condStatus, setCondStatus] = useState(false);

  const setBulkAnswerFun = (e) => {
    const { name, value } = e.target;
    setBulkAnswer(value);
  };
  const [questionTypes, setQuestionTypes] = useState<any[]>([]);
  const [questionsInfo, setQuestionsInfo] = useState<any[]>([]);

  useEffect(() => {
    getSurveyList();
    getSurveyQuestion("");
    getSurveyCategoriesInfo();
    if (Cookies.get("progressSurvey")) {
      setActiveTab("2");
    }
  }, []);
  useEffect(() => {
    if (getValue(surveyDetails, "status", "") === "published") {
      setActiveTab("2");
    }
    if (surveyQuestionType) {
      if (
        surveyQuestionType !== "ASSESSMENT" &&
        surveyQuestionType !== "SURVEY360" &&
        surveyQuestionType !== "TEMPLATE360"
      ) {
        getTypeList();
      } else if (
        surveyQuestionType === "SURVEY360" ||
        surveyQuestionType === "TEMPLATE360"
      ) {
        getTypeListSurvey360();
      } else {
        getTypeLists();
      }
    }
  }, [surveyDetails]);

  // =============================================================================
  // API section
  // =============================================================================
  const getTypeList = async () => {
    let resp1 = await surveysTypes();
    if (resp1) {
      setQuestionTypes(getValue(resp1, "data", ""));
    }
  };

  const getTypeLists = async () => {
    let resp = await assesmentTypes();
    if (resp) {
      setQuestionTypes(getValue(resp, "data", ""));
    }
  };

  //console.log(questionsInfo)

  const getTypeListSurvey360 = async () => {
    let resp = await survey360Types();
    if (resp) {
      setQuestionTypes(getValue(resp, "data", ""));
    }
  };

  const getSurveyCategoriesInfo = async () => {
    try {
      let resp = await getSurveyCategories();
      if (resp) {
        setSurveyCategories(
          getValue(resp, "data", []).map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // =============================================================================
  // On change section
  // =============================================================================

  const history = useHistory();

  const toggleModal = () => {
    setModal(!modal);
    getSurveyList();
  };
  const toggleModal2 = () => setModal2(!modal2);

  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  const enableSideMenu = () => {
    manageSideMenu(!isSideMenu);
  };

  const enableSideMenuBulkAnswer = (pageIndex, quesionIndex) => {
    setBulkIndex({
      ...bulkIndex,
      pageIndex: pageIndex,
      questionIndex: quesionIndex,
    });

    manageSideMenuBulkanswer(!isBulkMenu);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // **** Common functions **** //

  const handleChange = (evt, path, state) => {
    const { name, value } = evt.target;
    path({
      ...state,
      [name]: value,
    });
  };

  const handleChangeCategory = (value) => {
    setCategoryOption(value);
    updateSurvey({
      ...surveyDetails,
      category: value.value,
    });
  };

  const handleChangeCategoryType = (value) => {
    setTypeOption(value);
    let surveyType = "";
    if (value.value === "Standard Survey") {
      surveyType = "SURVEY";
    } else if (value.value === "360 Survey") {
      surveyType = "SURVEY360";
    } else {
      surveyType = "ASSESSMENT";
    }
    updateSurvey({
      ...surveyDetails,
      type: surveyType,
    });
  };

  // **** End **** //

  // =============================================================================
  // Survey Page/list/update
  // =============================================================================

  const getSurveyQuestion = async (newPage) => {
    try {
      let resp = await getSurveyQuestions(surveyId);
      if (resp) {
        setQuestionsInfo(
          getValue(resp, "data.pages", []).map((item) => {
            if (!item.questions) {
              if (
                surveyQuestionType === "SURVEY360" ||
                surveyQuestionType === "TEMPLATE360"
              ) {
                return { ...item, questions: [questionRequestDummy] };
              } else {
                return { ...item, questions: [questionRequest] };
              }
              // return item;
            } else {
              return item;
            }
          })
        );
        if (typeof newPage === "string") {
          setActiveTabPage(getValue(resp, "data.pages.length", 1));
        } else {
          setActiveTabPage(newPage);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const [loading1, setLoading1] = useState(false);
  const handleSurveyPageSubmit = async () => {
    let surveyPageDetails = {
      uniqueOrder: questionsInfo[parseFloat(activeTabPage) - 1].uniqueOrder,
      title: questionsInfo[parseFloat(activeTabPage) - 1].title,
      description: questionsInfo[parseFloat(activeTabPage) - 1].description,
    };
    try {
      setLoading1(true);
      let resp = await updateSurveyPageD(
        surveyId,
        questionsInfo[parseFloat(activeTabPage) - 1].id,
        surveyPageDetails
      );
      if (resp) {
        setLoading1(false);
        toast.success("Updated successfully", { className: "green-circle" });
        toggleModal2();
      } else {
        setLoading1(false);
        toggleModal2();
      }
    } catch (error) {
      setLoading1(false);
      toggleModal2();
    }
  };

  // *********  End  *********//

  // =============================================================================
  // Email template $&& 360
  // =============================================================================

  const getSurveyList = async () => {
    try {
      if (surveyId) {
        let resp = await getSurvey(surveyId);
        if (resp) {
          updateSurvey(getValue(resp, "data", {}));
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // handle survey submit
  const [loading, setLoading] = useState(false);
  const handleSurveySubmit = async () => {
    let req = {
      name: getValue(surveyDetails, "name", ""),
      category: getValue(surveyDetails, "category", ""),
      // type: surveyQuestionType
    };
    try {
      setLoading(true);
      let resp = await updateSurveyName(surveyId, req);
      if (resp) {
        toggleModal();
        setLoading(false);
        getSurveyQuestion("");
        toast.success("Updated successfully", { className: "green-circle" });
      } else {
        toggleModal();
        setLoading(false);
      }
    } catch (error) {
      toggleModal();
      setLoading(false);
    }
  };

  // *****  End  *****//

  // =============================================================================
  // Survey Question
  // =============================================================================
  const handleChangeQuestion = (evt, pageIndex, questionIndex) => {
    const { name, value } = evt.target;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((subItem, subIndex) => {
            if (subIndex !== questionIndex) return subItem;
            return {
              ...subItem,
              [name]: value,
            };
          }),
        };
      });
    });
  };

  const handleAddNewQuestion = (pageIndex) => {
    if (
      surveyQuestionType === "SURVEY360" ||
      surveyQuestionType === "TEMPLATE360"
    ) {
      if (!questionsInfo[pageIndex].questions) {
        setValue(questionsInfo[pageIndex], `questions`, [questionRequestDummy]);
        setquestionRequestDummy({ ...questionRequestDummy });
      } else {
        getValue(questionsInfo[pageIndex], `questions`, "").push(
          questionRequestDummy
        );
        setquestionRequestDummy({ ...questionRequestDummy });
      }
    } else {
      if (!questionsInfo[pageIndex].questions) {
        setValue(questionsInfo[pageIndex], `questions`, [questionRequest]);
        setQuestionReq({ ...questionRequest });
      } else {
        getValue(questionsInfo[pageIndex], `questions`, "").push(
          questionRequest
        );
        setQuestionReq({ ...questionRequest });
      }
    }
  };

  const handleEditQuestion = (evt, pageIndex, questionIndex) => {
    // var newContent = evt.editor.getData();
    // setQuestionsInfo((pages) => {
    //   return pages.map((x, index) => {
    //     if (index !== pageIndex) return x;
    //     return {
    //       ...x,
    //       questions: x.questions.map((subItem, subIndex) => {
    //         if (subIndex !== questionIndex) return subItem;
    //         return {
    //           ...subItem,
    //           text: newContent,
    //         };
    //       }),
    //     };
    //   });
    // });
    const { value } = evt.target;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((subItem, subIndex) => {
            if (subIndex !== questionIndex) return subItem;
            return {
              ...subItem,
              text: value,
            };
          }),
        };
      });
    });
  };

  const addMoreOption = (value, pageIndex, questionIndex) => {
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              options: [...y.options, value],
            };
          }),
        };
      });
    });

    setQuestionReq((prevState) => ({ ...prevState, questionRequest }));
  };

  const addPredefinedOption = (value, pageIndex, questionIndex) => {
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              options: value,
            };
          }),
        };
      });
    });
    setBulkAnswer("");
  };

  const handleBulkAnswer = () => {
    let value = bulkAnswer;
    let item = value.split("\n");
    let answerArray =
      item &&
      item.map((bulk) => {
        return {
          text: bulk,
          score: 0,
        };
      });

    addPredefinedOption(
      answerArray,
      bulkIndex.pageIndex,
      bulkIndex.questionIndex
    );
    enableSideMenuBulkAnswer(bulkIndex.pageIndex, bulkIndex.questionIndex);
  };

  const removeMoreOption = (optionIndex, pageIndex, questionIndex) => {
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              options: questionsInfo[pageIndex].questions[
                questionIndex
              ].options.filter((a, i) => {
                let b = a;
                return i !== optionIndex;
              }),
            };
          }),
        };
      });
    });
  };

  const handleQuestionType = (value, pageIndex, questionIndex, type) => {
    {
      /* <ChangeQuestionType
 type={type}
 pageIndex={pageIndex}
 questionIndex={questionIndex}
 value={value}
 /> */
    }
    if (value === "DATE") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.DATE
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "TIME") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.TIME
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "EMAIL_TEXTBOX") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.EMAIL_TEXTBOX
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "SHORT_ANSWER") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.SHORT_ANSWER
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "LONG_ANSWER") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.LONG_ANSWER
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "FULL_NAME") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.FULL_NAME
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "NUMERIC_TEXTBOX") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.NUMERIC_TEXTBOX
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "EMAIL_TEXTBOX") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.EMAIL_TEXTBOX
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "IMAGE_SINGLE_CHOICE") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.IMAGE_SINGLE_CHOICE
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "RATING_SCALE") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.RATING_SCALE
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "STAR_RATING") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.STAR_RATING
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "FILE_UPLOAD") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.FILE_UPLOAD
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "MATRIX_RADIO_BOX") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.MATRIX_RADIO_BOX
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "MATRIX_CHECK_BOX") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.MATRIX_CHECK_BOX
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "SINGLE_CHOICE") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.SINGLE_CHOICE
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "CHECK_BOX") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.CHECK_BOX
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "DROPDOWN") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.DROPDOWN
      );
      setQuestionsInfo([...questionsInfo]);
    } else if (value === "MULTIPLE_CHOICE") {
      setValue(
        questionsInfo[pageIndex],
        `questions[${questionIndex}]`,
        reqTypes.MULTIPLE_CHOICE
      );
      setQuestionsInfo([...questionsInfo]);
    } else {
      setQuestionsInfo((pages) => {
        return pages.map((x, index) => {
          if (index !== pageIndex) return x;
          return {
            ...x,
            questions: x.questions.map((subItem, subIndex) => {
              if (subIndex !== questionIndex) return subItem;
              return {
                ...subItem,
                [type]: value,
              };
            }),
          };
        });
      });
    }
  };

  // handle date question type
  const handleDateQuestionType = (value, pageIndex, questionIndex) => {
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              validations: {
                ...y.validations,
                format: value.value,
              },
            };
          }),
        };
      });
    });
  };

  const handleChangeQuestionAdvancedOptions = (
    name,
    status,
    _updateState,
    pageIndex,
    questionIndex
  ) => {
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((subItem, subIndex) => {
            if (subIndex !== questionIndex) return subItem;
            return {
              ...subItem,
              [name]: status,
            };
          }),
        };
      });
    });
  };

  const handleQuestionTypeValues = (
    e,
    optionIndex,
    pageIndex,
    questionIndex
  ) => {
    const { name, value } = e.target;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              options: y.options.map((z, index3) => {
                if (index3 !== optionIndex) return z;
                return {
                  ...z,
                  [name]: value,
                };
              }),
            };
          }),
        };
      });
    });
  };

  const handleScore = (
    optionIndex,
    name,
    value,
    updateState,
    pageIndex,
    questionIndex
  ) => {
    if (value > -1) {
      updateState((pages) => {
        return pages.map((x, index) => {
          if (index !== pageIndex) return x;
          return {
            ...x,
            questions: x.questions.map((y, index2) => {
              if (index2 !== questionIndex) return y;
              return {
                ...y,
                options: y.options.map((z, index3) => {
                  if (index3 !== optionIndex) return z;
                  return {
                    ...z,
                    [name]: value,
                  };
                }),
              };
            }),
          };
        });
      });
    }
  };

  const handleScoreInputBoxEdit = (
    optionIndex,
    e,
    updateState,
    pageIndex,
    questionIndex
  ) => {
    const { name, value } = e.target;
    if (value > -1) {
      updateState((pages) => {
        return pages.map((x, index) => {
          if (index !== pageIndex) return x;
          return {
            ...x,
            questions: x.questions.map((y, index2) => {
              if (index2 !== questionIndex) return y;
              return {
                ...y,
                options: y.options.map((z, index3) => {
                  if (index3 !== optionIndex) return z;
                  return {
                    ...z,
                    [name]: parseFloat(value),
                  };
                }),
              };
            }),
          };
        });
      });
    }
  };

  const deleteQuestion = async (
    pageIndex,
    questionIndex,
    pageId,
    surveyId,
    questionId
  ) => {
    setDeletQuestionIndex({
      ...deleteQuestionIndex,
      pageIndex: pageIndex,
      questionIndex: questionIndex,
      pageId: pageId,
      surveyId: surveyId,
      questionId: questionId,
    });

    handleModal2();
  };

  const deletePage = async (pageIndex, pageId, value) => {
    handleModal();
    setHandleDelete({
      ...handleDelete,
      pageIndex: pageIndex,
      pageId: pageId,
      value: value,
    });

    // if (pageId) {
    //   let resp = await deleteSurveyPage(surveyId, pageId);
    //   if (resp) {
    //     let value = questionsInfo.filter((item, i) => {
    //       if (i !== pageIndex) {
    //         return item;
    //       }
    //     });
    //     getSurveyQuestion(parseFloat(activeTabPage) - 1);
    //   }
    // }
  };

  const handleDeleteQuestion = async (
    pageIndex,
    questionIndex,
    pageId,
    surveyId,
    questionId
  ) => {
    if (questionId) {
      let resp = await deleteSurveyPageQuestion(surveyId, pageId, questionId);
      if (resp) {
        toast.success("Removed successfully", { className: "green-circle" });
        let value = questionsInfo[pageIndex].questions.filter((item, i) => {
          if (i !== questionIndex) {
            return item;
          }
        });
        setQuestionsInfo((pages) => {
          return pages.map((x, index) => {
            if (index !== pageIndex) return x;
            return {
              ...x,
              questions: value,
            };
          });
        });
        handleModal2();
      }
    } else {
      setQuestionsInfo((pages) => {
        return pages.map((x, index) => {
          if (index !== pageIndex) return x;
          return {
            ...x,
            questions: questionsInfo[pageIndex].questions.filter((_a, i) => {
              return i !== questionIndex;
            }),
          };
        });
      });
      toast.success("Removed successfully", { className: "green-circle" });
      handleModal2();
    }
  };

  const handleDeleteFun = async (pageIndex, pageId) => {
    try {
      if (pageId) {
        let resp = await deleteSurveyPage(surveyId, pageId);
        if (resp) {
          let value = questionsInfo.filter((item, i) => {
            if (i !== pageIndex) {
              return item;
            }
          });
          getSurveyQuestion(parseFloat(activeTabPage) - 1);
          handleModal();
        }
      }
    } catch (error) {
      handleModal();
    }
  };

  const handleModal = () => {
    setIsOPen(!isOpen);
  };

  const handleModal1 = () => {
    setIsOPen1(!isOpen1);
  };

  const handleModal2 = () => {
    setIsOPen2(!isOpen2);
  };

  // ==========================================================================
  //   copy / delete question
  // ==========================================================================

  const [deleteQuestionIndex, setDeletQuestionIndex] = useState({
    pageIndex: "",
    questionIndex: "",
    pageId: "",
    surveyId: "",
    questionId: "",
  });

  const [copyIndex, setCopyIndex] = useState({
    pageIndex: "",
    questionIndex: "",
  });

  const handleCopy = (pageIndex, questionIndex) => {
    setCopyIndex({
      ...copyIndex,
      pageIndex: pageIndex,
      questionIndex: questionIndex,
    });
    let value = questionsInfo[pageIndex].questions[questionIndex].text;
    if (value) {
      handleModal1();
    } else {
      toast.error("Please add question to copy", { className: "red-circle" });
    }
  };

  const [copy, setCopy] = useState(false);
  const handleCopyFun = (pageIndex, questionIndex) => {
    copyQuestion(pageIndex, questionIndex);
    setCopy(true);
  };

  const copyQuestion = (pageIndex, questionIndex) => {
    let value = questionsInfo[pageIndex].questions[questionIndex];
    questionsInfo[pageIndex].questions.push(value);
    setQuestionsInfo([...questionsInfo]);
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((subItem, subIndex) => {
            if (subIndex !== questionIndex + 1) return subItem;
            return {
              ...subItem,
              id: "",
            };
          }),
        };
      });
    });

    toast.success("Question copied successfully", {
      className: "green-circle",
    });
    handleModal1();
  };

  // ===========================================================================
  //  submit-add question
  // ===========================================================================

  const handleAddQuestion = async (
    pageIndex,
    questionIndex,
    pageId,
    surveyId
  ) => {
    let type = getValue(props, `match.params.type`, "");
    if (type === "ASSESSMENT") {
      let req = questionsInfo[pageIndex].questions[questionIndex];
      if (
        req.type === "SINGLE_CHOICE" ||
        req.type === "DROPDOWN" ||
        req.type === "CHECK_BOX"
      ) {
        let lessThanFive = false;
        for (let i = 0; i < req.options.length; i++) {
          if (req.options[i].correct === true) {
            lessThanFive = true;
            break;
          }
        }
        if (lessThanFive) {
          try {
            setIsLoading(true);
            let req = questionsInfo[pageIndex].questions[questionIndex];
            if (req.text) {
              let resp = await addSurveyQuestion(surveyId, pageId, req);
              if (resp) {
                toast.success("Added successfully", {
                  className: "green-circle",
                });
                getSurveyQuestion(parseFloat(activeTabPage));
                setIsLoading(false);
              }
            } else {
              setIsLoading(false);
              toast.error("Please input question", { className: "red-circle" });
            }
          } catch (error) {
            setIsLoading(false);
          }
        } else {
          toast.error(
            "Atleast 1 answer should be updated for all question type under assessment",
            { className: "red-circle" }
          );
        }
      } else {
        try {
          setIsLoading(true);
          let req = questionsInfo[pageIndex].questions[questionIndex];
          if (req.text) {
            let resp = await addSurveyQuestion(surveyId, pageId, req);
            if (resp) {
              toast.success("Added successfully", {
                className: "green-circle",
              });
              getSurveyQuestion(parseFloat(activeTabPage));
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            toast.error("Please input question", { className: "red-circle" });
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    } else {
      try {
        setIsLoading(true);
        let req = questionsInfo[pageIndex].questions[questionIndex];
        if (req.text) {
          let resp = await addSurveyQuestion(surveyId, pageId, req);
          if (resp) {
            toast.success("Added successfully", { className: "green-circle" });
            getSurveyQuestion(parseFloat(activeTabPage));
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          toast.error("Please input question", { className: "red-circle" });
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleUpdateQuestion = async (
    pageIndex,
    questionIndex,
    pageId,
    surveyId,
    questionId
  ) => {
    let type = getValue(props, `match.params.type`, "");
    if (type === "ASSESSMENT") {
      let req = questionsInfo[pageIndex].questions[questionIndex];
      if (
        req.type === "SINGLE_CHOICE" ||
        req.type === "DROPDOWN" ||
        req.type === "CHECK_BOX"
      ) {
        let lessThanFive = false;
        for (let i = 0; i < req.options.length; i++) {
          if (req.options[i].correct === true) {
            lessThanFive = true;
            break;
          }
        }
        if (lessThanFive) {
          try {
            setIsLoading(true);
            let req = questionsInfo[pageIndex].questions[questionIndex];
            if (req.text) {
              let resp = await updateSurveyPageQuestion(
                surveyId,
                pageId,
                questionId,
                req
              );
              if (resp) {
                toast.success("Updated successfully", {
                  className: "green-circle",
                });
                // getSurveyPageInfo();
                setIsLoading(false);
              }
            } else {
              setIsLoading(false);
              toast.error("Please input question", { className: "red-circle" });
            }
          } catch (error) {
            setIsLoading(false);
          }
        } else {
          toast.error(
            "Atleast 1 answer should be updated for all question type under assessment",
            { className: "red-circle" }
          );
        }
      } else {
        try {
          setIsLoading(true);
          let req = questionsInfo[pageIndex].questions[questionIndex];
          if (req.text) {
            let resp = await updateSurveyPageQuestion(
              surveyId,
              pageId,
              questionId,
              req
            );
            if (resp) {
              toast.success("Updated successfully", {
                className: "green-circle",
              });
              // getSurveyPageInfo();
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            toast.error("Please input question", { className: "red-circle" });
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    } else {
      try {
        setIsLoading(true);
        let req = questionsInfo[pageIndex].questions[questionIndex];
        if (req.text) {
          let resp = await updateSurveyPageQuestion(
            surveyId,
            pageId,
            questionId,
            req
          );
          if (resp) {
            toast.success("Updated successfully", {
              className: "green-circle",
            });
            // getSurveyPageInfo();
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          toast.error("Please input question", { className: "red-circle" });
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  // *****  End  *****//

  // ===========================================================================
  // page
  // ===========================================================================

  const handleChangePage = (index) => {
    togglePage(index.toString());
    // slickGoTo(e.target.value);
  };

  const handleChangePageInfo = (evt) => {
    const { name, value } = evt.target;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== parseFloat(activeTabPage) - 1) return x;
        return {
          ...x,
          [name]: value,
        };
      });
    });
  };

  const togglePage = (index) => {
    setActiveTabPage(index);
  };
  const [loading2, setLoading2] = useState(false);
  const addNewPage = async () => {
    try {
      let item = questionsInfo[parseFloat(activeTabPage) - 1].questions.filter(
        (item) => {
          if (!item.text) {
            return item;
          }
        }
      );
      if (item.length === 0) {
        let req = {
          title: "Untitled",
          description: "Untitled",
          uniqueOrder: 0,
        };
        setLoading2(true);
        let response = await createSurveyPage(surveyId, req);
        if (response) {
          getSurveyQuestion("newPage");
          setLoading2(false);
        }
      } else {
        toast.error("Please input all questions", { className: "red-circle" });
        setLoading2(false);
      }
    } catch (error) {
      setLoading2(false);
    }
  };

  //Question type editors

  // ===========================================================================
  // short answer module
  // ===========================================================================

  const handleQuestionTypeDropdown = (e, pageIndex, questionIndex, param) => {
    if (param !== "scaleFrom" && param !== "scaleTo") {
      let value = e.value.split(" ")[0];
      setQuestionsInfo((pages) => {
        return pages.map((x, index) => {
          if (index !== pageIndex) return x;
          return {
            ...x,
            questions: x.questions.map((y, index2) => {
              if (index2 !== questionIndex) return y;
              return {
                ...y,
                validations: {
                  ...y.validations,
                  [param]: parseFloat(value),
                },
              };
            }),
          };
        });
      });
    } else {
      let value = e.value;
      setQuestionsInfo((pages) => {
        return pages.map((x, index) => {
          if (index !== pageIndex) return x;
          return {
            ...x,
            questions: x.questions.map((y, index2) => {
              if (index2 !== questionIndex) return y;
              return {
                ...y,
                validations: {
                  ...y.validations,
                  [param]: parseFloat(value),
                },
              };
            }),
          };
        });
      });
    }
  };

  const handleQuestionTypeInputBox = (e, pageIndex, questionIndex) => {
    const { name, value } = e.target;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              validations: {
                ...y.validations,
                [name]: value,
              },
            };
          }),
        };
      });
    });
  };

  const handleFullName = (e, pageIndex, questionIndex, optionIndex) => {
    const { name, value } = e.target;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              row: y.row.map((z, index3) => {
                if (index3 !== 0) return z;
                return {
                  ...z,
                  columns: z.columns.map((a, index4) => {
                    if (index4 !== optionIndex) return a;
                    return {
                      ...a,
                      question: {
                        ...a.question,
                        text: value,
                      },
                    };
                  }),
                };
              }),
            };
          }),
        };
      });
    });
  };

  const handleFullNameCheckbox = (
    status,
    pageIndex,
    questionIndex,
    optionIndex
  ) => {
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              row: y.row.map((z, index3) => {
                if (index3 !== 0) return z;
                return {
                  ...z,
                  columns: z.columns.map((a, index4) => {
                    if (index4 !== optionIndex) return a;
                    return {
                      ...a,
                      question: {
                        ...a.question,
                        mandatoryEnabled: status,
                      },
                    };
                  }),
                };
              }),
            };
          }),
        };
      });
    });
  };

  // ===========================================================================
  // Grid module
  // ===========================================================================

  const handleGridData = (
    e,
    pageIndex,
    questionIndex,
    rowIndex,
    _columnIndex
  ) => {
    const { name, value } = e.target;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              row: y.row.map((z, index3) => {
                if (index3 !== rowIndex) return z;
                return {
                  ...z,
                  text: value,
                };
              }),
            };
          }),
        };
      });
    });
  };
  const [filler, setFiller] = useState<any>([]);

  const handleAddGridDataRow = (
    pageIndex,
    questionIndex,
    _rowIndex,
    _columnIndex
  ) => {
    let scoreLength =
      questionsInfo[pageIndex].questions[questionIndex].row[0].score.length;
    for (var i = 0; i < scoreLength; i++) {
      filler.push(0); //Example, pushing 5 integers in an array
    }

    let row = {
      text: "",
      score: filler,
    };

    questionsInfo[pageIndex].questions[questionIndex].row.push(row);
    let item = questionsInfo[pageIndex].questions[questionIndex].row;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              row: item,
            };
          }),
        };
      });
    });
    setFiller([]);
  };

  const handleAddGridDataColumn = (
    pageIndex,
    questionIndex,
    _rowIndex,
    _columnIndex
  ) => {
    let column = {
      text: "",
    };
    questionsInfo[pageIndex].questions[questionIndex].columns.push(column);
    let item = questionsInfo[pageIndex].questions[questionIndex].columns;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              columns: item,
            };
          }),
        };
      });
    });

    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              row: y.row.map((z, _index3) => {
                // if (index3 === rowIndex) return z;
                return {
                  ...z,
                  score: z.score.concat(0),
                };
              }),
            };
          }),
        };
      });
    });
  };

  const handleGridDataColumn = (
    e,
    pageIndex,
    questionIndex,
    colIndex,
    _columnIndex
  ) => {
    const { name, value } = e.target;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              columns: y.columns.map((z, index3) => {
                if (index3 !== colIndex) return z;
                return {
                  ...z,
                  text: value,
                };
              }),
            };
          }),
        };
      });
    });
  };

  const handleGridDataColumnScore = (
    e,
    pageIndex,
    questionIndex,
    colIndex,
    rowIndex
  ) => {
    const { name, value } = e.target;
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              row: y.row.map((z, index3) => {
                if (index3 !== rowIndex) return z;
                z.score[colIndex] = parseFloat(value);
                return z;
              }),
            };
          }),
        };
      });
    });
  };

  const handleDeleteGridRow = (pageIndex, questionIndex, rowIndex) => {
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              row: y.row.filter((_a, i) => {
                return i !== rowIndex;
              }),
            };
          }),
        };
      });
    });
  };

  const handleDeleteGridColumn = (pageIndex, questionIndex, colIndex) => {
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              columns: y.columns.filter((_a, i) => {
                return i !== colIndex;
              }),
            };
          }),
        };
      });
    });
  };

  // ===========================================================================
  // rating module
  // ===========================================================================

  const handleRatingScaleDropdownString = (
    e,
    pageIndex,
    questionIndex,
    param
  ) => {
    let value = e.value.split(" ")[0];
    setQuestionsInfo((pages) => {
      return pages.map((x, index) => {
        if (index !== pageIndex) return x;
        return {
          ...x,
          questions: x.questions.map((y, index2) => {
            if (index2 !== questionIndex) return y;
            return {
              ...y,
              validations: {
                ...y.validations,
                [param]: value,
              },
            };
          }),
        };
      });
    });
  };

  // const handleSubmitSurveyTemplate = async () => {
  //   if (!Cookies.get("templateEdit")) {
  //     try {
  //       let req = {
  //         surveyId: surveyId,
  //         name: getValue(surveyDetails, 'name', ''),
  //       };
  //       let resp = await createSurveyTemplate(req);
  //       if (resp) {
  //         Cookies.remove('template');
  //         toast.success('Submitted successfully');
  //         history.push('/templates');
  //       }
  //     } catch (error) {
  //       //console.log(error);
  //     }
  //   } else {
  //     try {
  //       if (Cookies.get('templateId')) {
  //         let req = {
  //           surveyId: surveyId,
  //           name: getValue(surveyDetails, 'name', ''),
  //         };
  //         let resp = await updateSurveyTemplate(Cookies.get('templateId'), req);
  //         if (resp) {
  //           Cookies.remove('template');
  //           toast.success('Submitted successfully');
  //           Cookies.remove('templateId')
  //           Cookies.remove('templateEdit')
  //           history.push('/templates');
  //         }
  //       } else {
  //         toast.error("Template id not found");
  //         history.push('/templates')
  //       }
  //     } catch (error) {
  //       //console.log(error);
  //     }
  //   }
  // };

  const handleNavigate = (index) => {
    handleChangePage(index);
  };

  const handleCheckCond = () => {
    let item2 = questionsInfo.map((qus) => {
      return qus.questions.filter((item) => {
        if (!item.text) {
          return item;
        }
      });
    });
    var item = [].concat.apply([], item2);
    if (item.length === 0) {
      setCondStatus(true);
    } else {
      toast.error("Questions should not be empty", { className: "red-circle" });
    }
  };

  // ===========================================================================
  // Assessment module
  // ===========================================================================

  const handleAssessmentCorrectAnswer = (
    optionIndex,
    status,
    updateState,
    pageIndex,
    questionIndex,
    type
  ) => {
    if (type === "SINGLE_CHOICE") {
      updateState((pages) => {
        return pages.map((x, index) => {
          if (index !== pageIndex) return x;
          return {
            ...x,
            questions: x.questions.map((y, index2) => {
              if (index2 !== questionIndex) return y;
              return {
                ...y,
                options: y.options.map((z, index3) => {
                  if (index3 !== optionIndex) return z;
                  return {
                    ...z,
                    correct: status,
                  };
                }),
              };
            }),
          };
        });
      });

      updateState((pages) => {
        return pages.map((x, index) => {
          if (index !== pageIndex) return x;
          return {
            ...x,
            questions: x.questions.map((y, index2) => {
              if (index2 !== questionIndex) return y;
              return {
                ...y,
                options: y.options.map((z, index3) => {
                  if (index3 === optionIndex) return z;
                  return {
                    ...z,
                    correct: false,
                  };
                }),
              };
            }),
          };
        });
      });
    } else {
      updateState((pages) => {
        return pages.map((x, index) => {
          if (index !== pageIndex) return x;
          return {
            ...x,
            questions: x.questions.map((y, index2) => {
              if (index2 !== questionIndex) return y;
              return {
                ...y,
                options: y.options.map((z, index3) => {
                  if (index3 !== optionIndex) return z;
                  return {
                    ...z,
                    correct: status,
                  };
                }),
              };
            }),
          };
        });
      });
    }
  };

  // ===========================================================================
  // upload excel file
  // ===========================================================================
  const [toggleUpload, setToggleUpload] = useState(false);
  const [uploadData, setUploadData] = useState("");
  const [uploadData1, setUploadData1] = useState("");
  const [toggleUpload1, setToggleUpload1] = useState(false);

  const uploadExcelFile = async (e) => {
    setUploadData(e.target.files[0]);
  };
  const uploadExcelFile1 = async (e) => {
    setUploadData1(e.target.files[0]);
  };
  const handleUploadExcelFile = async () => {
    let formData = new FormData();
    formData.append("file", uploadData);
    // let resp = await uploadExcelFiles(surveyId, formData);
    let resp = await uploadExcelFiles360(surveyId, formData);
    if (resp) {
      getSurveyQuestion("");
      setUploadData("");
      toast.success("Questions Imported successfully", {
        className: "green-circle",
      });
      handleToggleUpload();
    } else {
      setUploadData("");
      handleToggleUpload();
    }
  };
  const handleUploadExcelFile1 = async () => {
    let formData = new FormData();
    formData.append("file", uploadData1);
    let resp = await uploadExcelFilesStandard(surveyId, formData);
    if (resp) {
      getSurveyQuestion("");
      setUploadData1("");
      toast.success("Questions Imported successfully", {
        className: "green-circle",
      });
      handleToggleUpload1();
    } else {
      setUploadData1("");
      handleToggleUpload1();
    }
  };
  const handleToggleUpload = () => {
    setToggleUpload(!toggleUpload);
  };
  const handleToggleUpload1 = () => {
    setToggleUpload1(!toggleUpload1);
  };
  return (
    <>
      <div className="page-layout page-layout-full">
        <div className="create-survey-tabs">
          <FormHeader
            surveyDetails={surveyDetails}
            surveyId={surveyId}
            surveyQuestionType={surveyQuestionType}
            surveyTemplate={surveyTemplate}
            //  handleCheckCond={handleCheckCond}
          />
          <div className="back-btn">
            {!surveyTemplate ? (
              <NavLink to="/home">
                <Button color="nothing">
                  <BackSVG />
                </Button>
              </NavLink>
            ) : (
              <NavLink to="/templates">
                <Button color="nothing">
                  <BackSVG />
                </Button>
              </NavLink>
            )}
          </div>
        </div>
        <TabContent activeTab={activeTab}>
          {!Cookies.get("progressSurvey") &&
          getValue(surveyDetails, "status", "") !== "published" ? (
            <RenderSurveyQuestion
              handleUploadExcelFile={handleUploadExcelFile}
              toggleUpload={toggleUpload}
              handleToggleUpload={handleToggleUpload}
              surveyDetails={surveyDetails}
              toggleModal={toggleModal}
              surveyId={surveyId}
              // handleSubmitSurveyTemplate={handleSubmitSurveyTemplate}
              activeTabPage={activeTabPage}
              questionsInfo={questionsInfo}
              handleChangePage={handleChangePage}
              handleNavigate={handleNavigate}
              addNewPage={addNewPage}
              toggleModal2={toggleModal2}
              deletePage={deletePage}
              handleChangeQuestionAdvancedOptions={
                handleChangeQuestionAdvancedOptions
              }
              setQuestionsInfo={setQuestionsInfo}
              handleCopy={handleCopy}
              deleteQuestion={deleteQuestion}
              handleQuestionType={handleQuestionType}
              questionTypes={questionTypes}
              handleEditQuestion={handleEditQuestion}
              handleChangeQuestion={handleChangeQuestion}
              enableSideMenuBulkAnswer={enableSideMenuBulkAnswer}
              handleQuestionTypeValues={handleQuestionTypeValues}
              addMoreOption={addMoreOption}
              removeMoreOption={removeMoreOption}
              handleScore={handleScore}
              addPredefinedOption={addPredefinedOption}
              handleDateQuestionType={handleDateQuestionType}
              handleQuestionTypeDropdown={handleQuestionTypeDropdown}
              handleQuestionTypeInputBox={handleQuestionTypeInputBox}
              handleFullName={handleFullName}
              handleGridData={handleGridData}
              handleAddGridDataRow={handleAddGridDataRow}
              handleAddGridDataColumn={handleAddGridDataColumn}
              handleGridDataColumn={handleGridDataColumn}
              handleGridDataColumnScore={handleGridDataColumnScore}
              handleDeleteGridRow={handleDeleteGridRow}
              handleDeleteGridColumn={handleDeleteGridColumn}
              handleRatingScaleDropdownString={handleRatingScaleDropdownString}
              handleScoreInputBoxEdit={handleScoreInputBoxEdit}
              handleFullNameCheckbox={handleFullNameCheckbox}
              handleAssessmentCorrectAnswer={handleAssessmentCorrectAnswer}
              handleAddNewQuestion={handleAddNewQuestion}
              isLoading={isLoading}
              handleAddQuestion={handleAddQuestion}
              handleUpdateQuestion={handleUpdateQuestion}
              uploadExcelFile={uploadExcelFile}
              uploadExcelFile1={uploadExcelFile1}
              handleToggleUpload1={handleToggleUpload1}
              handleUploadExcelFile1={handleUploadExcelFile1}
              loading2={loading2}
              surveyTemplate={surveyTemplate}
              toggleUpload1={toggleUpload1}
            />
          ) : (
            ""
          )}
        </TabContent>
      </div>

      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalBody>
          <h3 className="text-center">Name your Survey</h3>
          <div className="form-group">
            <Label>
              Survey Name<span className="imp">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter Survey Number"
              value={
                getValue(surveyDetails, "name", "")
                  ? getValue(surveyDetails, "name", "")
                  : ""
              }
              name="name"
              onChange={(evt) => handleChange(evt, updateSurvey, surveyDetails)}
            />
            {/* <div className="form-error">Survey Name required</div> */}
          </div>
          <div className="form-group">
            <Label>Category Name</Label>
            <Select
              value={
                surveyCategories.filter(
                  (item) =>
                    getValue(item, "label", "") ===
                    getValue(surveyDetails, "category", "")
                )[0]
              }
              onChange={handleChangeCategory}
              options={surveyCategories}
              name="category"
            />
            <div className="form-error">
              {simpleValidator.current.message(
                "category",
                getValue(surveyDetails, "category", ""),
                "required"
              )}
            </div>
          </div>
          {/* <div className="form-group">
            <Input type="text" placeholder="Human Resource Internal" />
          </div> */}
          <div className="form-group">
            <Label>
              Survey Type<span className="imp">*</span>
            </Label>
            <Select
              value={
                surveyType.filter(
                  (item) =>
                    getValue(item, "label", "") ===
                    (surveyQuestionType === "SURVEY"
                      ? "Standard Survey"
                      : surveyQuestionType === "SURVEY360"
                      ? "360 Survey"
                      : surveyQuestionType === "ASSESSMENT"
                      ? "Assessment"
                      : "")
                )[0]
              }
              // SURVEY360
              onChange={handleChangeCategoryType}
              options={surveyType}
              name="category"
              isDisabled
            />
            {/* <div className="form-error">Survey Name required</div> */}
          </div>
          <div className="btn-group-margin text-md-right">
            <Button color="border" onClick={toggleModal}>
              Cancel
            </Button>
            {!loading ? (
              <Button color="blue" onClick={handleSurveySubmit}>
                Save
              </Button>
            ) : (
              <Button color="blue">Please wait...</Button>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal2}
        toggle={toggleModal2}
        centered
        className="modal-lg"
      >
        <ModalBody>
          <h4>Page Information</h4>
          <div className="form-group">
            <Label>Page Title</Label>
            <Input
              type="textarea"
              rows="2"
              placeholder="Enter Page Title"
              name="title"
              value={
                getValue(
                  questionsInfo[parseFloat(activeTabPage) - 1],
                  "title",
                  ""
                )
                  ? getValue(
                      questionsInfo[parseFloat(activeTabPage) - 1],
                      "title",
                      ""
                    )
                  : ""
              }
              onChange={(evt) => handleChangePageInfo(evt)}
            />
            <p className="mb-0">Max 100 characters</p>
          </div>
          <div className="form-group">
            <Label>Page Description</Label>
            <Input
              type="textarea"
              rows="3"
              name="description"
              placeholder="Enter Page Description"
              value={
                getValue(
                  questionsInfo[parseFloat(activeTabPage) - 1],
                  "description",
                  ""
                )
                  ? getValue(
                      questionsInfo[parseFloat(activeTabPage) - 1],
                      "description",
                      ""
                    )
                  : ""
              }
              onChange={(evt) => handleChangePageInfo(evt)}
            />
            <p className="mb-0">Max 5000 characters</p>
          </div>
          <div className="btn-group-margin text-md-right">
            <Button color="border" onClick={toggleModal2}>
              Cancel
            </Button>
            {!loading1 ? (
              <Button color="blue" onClick={handleSurveyPageSubmit}>
                Save
              </Button>
            ) : (
              <Button color="blue">Please wait...</Button>
            )}
          </div>
        </ModalBody>
      </Modal>
      {/* <Footer /> */}

      {/* question bank */}
      <div
        className={"slide-menu-overlay " + (isSideMenu ? "show" : "")}
        onClick={() => enableSideMenu()}
      ></div>
      <div className={"slide-menu " + (isSideMenu ? "show" : "")}>
        <Button
          color="nothing"
          onClick={() => enableSideMenu()}
          className="slide-menu-close"
        >
          <CloseSVG />
        </Button>
        <h4>Question Bank</h4>
        <Input type="textarea" rows="3" placeholder="Enter 1 choice per line" />
        <div className="btn-group-margin text-md-right mt-4">
          <Button color="blue">Save</Button>
        </div>
      </div>

      {/* Bulk answer */}
      <div
        className={"slide-menu-overlay " + (isBulkMenu ? "show" : "")}
        onClick={() =>
          enableSideMenuBulkAnswer(bulkIndex.pageIndex, bulkIndex.questionIndex)
        }
      ></div>
      <div className={"slide-menu " + (isBulkMenu ? "show" : "")}>
        <Button
          color="nothing"
          onClick={() =>
            enableSideMenuBulkAnswer(
              bulkIndex.pageIndex,
              bulkIndex.questionIndex
            )
          }
          className="slide-menu-close"
        >
          <CloseSVG />
        </Button>
        <h4>Bulk Answer</h4>
        <Input
          type="textarea"
          rows="5"
          name="bulkAnswer"
          placeholder="Enter 1 choice per line"
          value={bulkAnswer ? bulkAnswer : ""}
          onChange={(e) => setBulkAnswerFun(e)}
        />
        <div className="btn-group-margin text-md-right mt-4">
          <Button color="blue" onClick={handleBulkAnswer}>
            Save
          </Button>
        </div>

        {/* delete modal */}
        <Modal isOpen={isOpen} centered>
          <ModalBody className="text-center">
            <h4>Delete</h4>
            <p>Are you sure you want to delete {handleDelete.value}?</p>
            <div className="btn-group-margin text-center mt-4">
              <Button color="border" onClick={handleModal}>
                Cancel
              </Button>
              <Button
                color="blue"
                onClick={() =>
                  handleDeleteFun(handleDelete.pageIndex, handleDelete.pageId)
                }
              >
                Delete
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {/* copy modal */}
        <Modal isOpen={isOpen1} centered>
          <ModalBody className="text-center">
            <h4>Copy</h4>
            <p>Are you sure you want to Copy?</p>
            <div className="btn-group-margin text-center mt-4">
              <Button color="border" onClick={handleModal1}>
                Cancel
              </Button>
              <Button
                color="blue"
                onClick={() =>
                  handleCopyFun(copyIndex.pageIndex, copyIndex.questionIndex)
                }
              >
                Copy
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {/* copy modal */}
        <Modal isOpen={isOpen2} centered>
          <ModalBody className="text-center">
            <h4>Delete</h4>
            <p>Are you sure you want to delete?</p>
            <div className="btn-group-margin text-center mt-4">
              <Button color="border" onClick={handleModal2}>
                Cancel
              </Button>
              <Button
                color="blue"
                onClick={() =>
                  handleDeleteQuestion(
                    deleteQuestionIndex.pageIndex,
                    deleteQuestionIndex.questionIndex,
                    deleteQuestionIndex.pageId,
                    deleteQuestionIndex.surveyId,
                    deleteQuestionIndex.questionId
                  )
                }
              >
                Delete
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};
export default CreateNewSurveyForm;
