import * as React from 'react';
import { Input, Label } from 'reactstrap';
import AdvancedOptions from './AdvancedOptions';

interface IFileUploadProps {
  pageIndex: number;
  questionIndex: number;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  surveyType: string;
}

const FileUpload: React.FunctionComponent<IFileUploadProps> = (props) => {
  return (
    <div>
      <h5 className="mb-2">Advance Options:</h5>
      <AdvancedOptions
            surveyType={props.surveyType}

        handleChangeQuestionAdvancedOptions={
          props.handleChangeQuestionAdvancedOptions
        }
        questionIndex={props.questionIndex}
        pageIndex={props.pageIndex}
        setQuestionsInfo={props.setQuestionsInfo}
        handleChange={props.handleChange}
        questionInfo={props.questionInfo}
      />
    </div>
  );
};

export default FileUpload;
