import React from 'react';

const Smiley7SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.002"
      height="36.002"
      viewBox="0 0 36.002 36.002"
    >
      <defs>
        <style></style>
      </defs>
      <g transform="translate(0.783 0.783)">
        <path
          className="a"
          style={{ fill: '#feca2e' }}
          d="M25.219,8A17.219,17.219,0,1,1,8,25.219,17.219,17.219,0,0,1,25.219,8Zm7.827,14.088a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,33.045,22.088Zm-12.523,0a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,20.523,22.088Z"
          transform="translate(-8 -8)"
        />
        <ellipse
          className="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="2"
          rx="1.5"
          ry="2"
          transform="translate(22.219 12.218)"
        />
        <ellipse
          className="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="2"
          rx="1.5"
          ry="2"
          transform="translate(9.219 12.218)"
        />
      </g>
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M18,0A18,18,0,1,0,36,18,18.022,18.022,0,0,0,18,0Zm0,34.437A16.436,16.436,0,1,1,34.437,18,16.454,16.454,0,0,1,18,34.437Z"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M100.7,130.348a2.348,2.348,0,1,0-2.348,2.348A2.351,2.351,0,0,0,100.7,130.348Zm-3.131,0a.783.783,0,1,1,.783.783A.784.784,0,0,1,97.565,130.348Z"
        transform="translate(-86.608 -115.477)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M226.348,128a2.348,2.348,0,1,0,2.348,2.348A2.351,2.351,0,0,0,226.348,128Zm0,3.131a.783.783,0,1,1,.783-.783A.784.784,0,0,1,226.348,131.131Z"
        transform="translate(-202.085 -115.477)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M118.287,224.256a8.706,8.706,0,0,1-12.922,0A.783.783,0,1,0,104.2,225.3a10.273,10.273,0,0,0,15.254,0,.783.783,0,0,0-1.168-1.044Z"
        transform="translate(-93.826 -202.08)"
      />
    </svg>
  </>
);
export default Smiley7SVG;
