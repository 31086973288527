import { Container, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import ProfileDropdown from './profileDropdown';
import MenuSVG from '../svg/menu-svg';
import * as React from 'react';
import { getValue } from '../../core/lodash';
import { getPermission } from '../../core/services/surveyService';

interface ITakeSurveyComponentProps {
  enableLeftMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  hideMenu: boolean;
}

const Header: React.FunctionComponent<ITakeSurveyComponentProps> = (props) => {
  const [userInfo, setUserInfo] = React.useState([]);
  React.useEffect(() => {
    if (!getValue(userInfo, `id`, '')) {
      getPermissions();
    }
  }, []);
  const getPermissions = async () => {
    if (getValue(localStorage, 'access_token', '')) {
      let resp = await getPermission();
      if (resp) {
        setUserInfo(getValue(resp, 'data', {}));
      }
    }
  };
  const { enableLeftMenu, hideMenu } = props;
  let type = window.location.pathname.split('/')[2];
  //console.log(userInfo)
  return (
    <div>
      <header>
        <Container fluid={true}>
          <div className="header-left">
            {type !== 'public' && !hideMenu && (
              <div className="menu-btn">
                <Button color="nothing" onClick={enableLeftMenu}>
                  <MenuSVG />
                </Button>
              </div>
            )}
            {type !== 'public' ? (
              <div className="logo">
                <NavLink to="/home">
                  <img src="/images/logo.png" alt="" />
                </NavLink>
              </div>
            ) : (
              <div className="logo">
                {/* <a href={`${window.location.origin}/take-survey/${window.location.pathname.split('/')[2]}/${window.location.pathname.split('/')[3]}`}> */}
                <img src="/images/logo.png" alt="" />
                {/* </a> */}
              </div>
            )}
          </div>
          {type !== 'public' && getValue(localStorage, `access_token`, '') && (
            <div className="header-right">
              <ul className="header-right-links">
                <ProfileDropdown userInfo={userInfo} />
              </ul>
            </div>
          )}
          <div className="clearfix"></div>
        </Container>
      </header>
      <div className="header-margin"></div>
    </div>
  );
};
export default Header;
