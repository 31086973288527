import * as React from 'react';
import { Label, Input, Row, Col } from 'reactstrap';
import { getValue } from '../../core/lodash';
import PreviewAdvancedOptions from './previewAdvancedOptions';

interface IShortAnswerProps {
  options: any;
  questions: any;
  answer: any[];
  handleChangeTakeSurveyInput: (e: any, questionId: string) => void;
  // validation
  required: boolean;
  requiredErrors: any;
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string
  ) => void;
}

const ShortAnswer: React.FunctionComponent<IShortAnswerProps> = (props) => {
  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === props.questions.id
  );
  return (
    <div className="form-group">
      <Input
        type="textarea"
        rows={3}
        placeholder="Enter Text"
        value={getValue(currentValue[0], 'response', '')}
        onChange={(e) => {
          // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
          props.handleChangeTakeSurveyInput(e, props.questions.id);
        }}
      ></Input>
      <div className="form-error" style={{ color: 'red' }}>
        {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
          ?getValue(currentValue[0], 'response.length', '') === 0? props.questions.mandatoryMsg:`characters range should be minimum ${getValue(props.questions,'validations.minLength','')} and maximum ${getValue(props.questions,'validations.maxLength','')}`
          : ''}
      </div>
      <PreviewAdvancedOptions
        questions={props.questions}
        answer={props.answer}
        handleChangeTakeSurveyAdvancedOptionInput={
          props.handleChangeTakeSurveyAdvancedOptionInput
        }
      />
    </div>
  );
};

export default ShortAnswer;
