const SingleChoiceReq = {
  text: '',
  type: 'SINGLE_CHOICE',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  options: [
    {
      text: '',
      score: 0,
      correct:false
    },
    {
      text: '',
      score: 0,
      correct:false
    },
  ],
};
const multiple_choice = {
  text: '',
  type: 'MULTIPLE_CHOICE',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  options: [
    {
      text: '',
      score: 0,
      correct:false
    },
    {
      text: '',
      score: 0,
      correct:false
    },
  ],
};

const dropdown = {
  text: '',
  type: 'DROPDOWN',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  options: [
    {
      text: '',
      score: 0,
      correct:false
    },
    {
      text: '',
      score: 0,
      correct:false
    },
  ],
};
const checkbox = {
  text: '',
  type: 'CHECK_BOX',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  options: [
    {
      text: '',
      score: 0,
      correct:false
    },
    {
      text: '',
      score: 0,
      correct:false
    },
  ],
};

const date = {
  text: '',
  type: 'DATE',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  validations: {
    format: '31 Jan 1970',
    minValue: '',
    maxValue: '',
    type: 'range',
  },
};

const time = {
  text: '',
  type: 'TIME',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
};

const email = {
  text: '',
  type: 'EMAIL_TEXTBOX',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
};

const short_answer = {
  text: '',
  type: 'SHORT_ANSWER',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  validations: {
    minLength: 25,
    maxLength: 50,
  },
  review: false,
  flag: false,
};
const long_answer = {
  text: '',
  type: 'LONG_ANSWER',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  validations: {
    minLength: 25,
    maxLength: 500,
  },
};
const full_name = {
  text: '',
  type: 'FULL_NAME',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  row: [
    {
      text: 'Full Name',
      columns: [
        {
          question: {
            text: '',
            type: 'SHORT_ANSWER',
            mandatoryEnabled: false,
          },
        },
        {
          question: {
            text: '',
            type: 'SHORT_ANSWER',
            mandatoryEnabled: false,
          },
        },
      ],
    },
  ],
};

const numeric_text = {
  text: '',
  type: 'NUMERIC_TEXTBOX',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  validations: {
    maxValue: '5',
    minValue: '1',
  },
};

const image_single_choice = {
  text: '',
  type: 'IMAGE_SINGLE_CHOICE',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  options: [
    {
      text: 'image 1 option',
      imageUrl: '',
    },
  ],
};

const rating_scale = {
  text: '',
  type: 'RATING_SCALE',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  validations: {
    scaleFrom: '1',
    scaleTo: '5',
    startLabel: 'bad',
    endLabel: 'good',
    type: 'number',
  },
};

const star_rating = {
  text: '',
  type: 'STAR_RATING',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  validations: {
    scaleFrom: 1,
    scaleTo: 5,
  },
};

const file_upload = {
  text: '',
  type: 'FILE_UPLOAD',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
};
const check_choice_grid = {
  text: '',
  type: 'MATRIX_CHECK_BOX',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  columns: [
    {
      text: '',
    },
    {
      text: '',
    },
  ],
  row: [
    {
      text: '',
      score: [0, 0],
    },
    {
      text: '',
      score: [0, 0],
    },
  ],
};
const MATRIX_RADIO_BOX = {
  text: '',
  type: 'MATRIX_RADIO_BOX',
  validationEnabled: true,
  mandatoryEnabled: false,
  mandatoryMsg: 'This question is mandatory',
  hintEnabled: false,
  hintMsg: '',
  randomEnabled: false,
  randomizationType: '',
  noneOptionEnabled: false,
  otherOptionEnabled: false,
  otherOptionMsg: 'Other (Please specify)',
  commentEnabled: false,
  commentMsg: 'Any additional comments?',
  notApplicableEnabled: false,
  notApplicableMsg: '',
  // uniqueOrder: 'A',
  answerWidth: '',
  initialMsg: '',
  scoreEnabled: false,
  review: false,
  flag: false,
  columns: [
    {
      text: '',
    },
    {
      text: '',
    },
  ],
  row: [
    {
      text: '',
      score: [0, 0],
    },
    {
      text: '',
      score: [0, 0],
    },
  ],
};

export const reqTypes = {
  SINGLE_CHOICE: SingleChoiceReq,
  DATE: date,
  TIME: time,
  EMAIL_TEXTBOX: email,
  SHORT_ANSWER: short_answer,
  LONG_ANSWER: long_answer,
  FULL_NAME: full_name,
  NUMERIC_TEXTBOX: numeric_text,
  IMAGE_SINGLE_CHOICE: image_single_choice,
  RATING_SCALE: rating_scale,
  STAR_RATING: star_rating,
  FILE_UPLOAD: file_upload,
  MATRIX_RADIO_BOX: MATRIX_RADIO_BOX,
  MATRIX_CHECK_BOX: check_choice_grid,
  CHECK_BOX: checkbox,
  DROPDOWN: dropdown,
  MULTIPLE_CHOICE: multiple_choice,
};
