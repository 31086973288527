import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { getValue } from '../core/lodash';

const InputField = (props) => {
  // time picker for train
  const [startDate, setStartDate] = useState(new Date());
  switch (props.inputType) {
    case 'TEXT':
      return (
        <div className="form-group">
          <Input
            type="text"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            // autoComplete="off"
            autoComplete="new-password"
            disabled={props.disabled}
            className={props.className ? props.className : 'form-control'}
          />
          <label className="on-hover" htmlFor={props.id}>
            {/* {props.label} */}
            {props.required ? <span className="form-imp">*</span> : ''}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required'
              )}
            </span>
          )}
        </div>
      );
    case 'TEXTAREA':
      return (
        <div className="form-group">
          <Input
            type="textarea"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            // autoComplete="off"
            rows={props.rows ? props.rows : 3}
            autoComplete="new-password"
            disabled={props.disabled}
          />
          <label className="on-hover" htmlFor={props.id}>
            {/* {props.label} */}
            {props.required ? <span className="form-imp">*</span> : ''}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required'
              )}
            </span>
          )}
        </div>
      );
    case 'TEXTALPHA':
      return (
        <div className="form-group">
          <Input
            type="text"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            autoComplete="off"
            disabled={props.disabled}
          />
          <label className="on-hover" htmlFor={props.id}>
            {/* {props.label}{' '} */}
            {props.required ? <span className="form-imp">*</span> : ''}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required|alpha'
              )}
            </span>
          )}
        </div>
      );
    case 'EMAIL':
      return (
        <div className="form-group">
          <Input
            type="email"
            // autoComplete="on"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onKeyDown={props.onKeyDown}
            // autoComplete={props.autoComplete?props.autoComplete:"new-password"}
          />
          <label className="on-hover" htmlFor={props.id}>
            {/* {props.label}{' '} */}
            {props.required ? <span className="form-imp">*</span> : ''}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required|email'
              )}
            </span>
          )}
        </div>
      );
    case 'NUMBER':
      return (
        <div className="form-group">
          <Input
            autoComplete="off"
            type="number"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
          <label className="on-hover" htmlFor={props.id}>
            {/* {props.label}{' '} */}
            {props.required ? <span className="form-imp">*</span> : ''}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required|integer'
              )}
            </span>
          )}
        </div>
      );
    case 'PHONE':
      return (
        <div className="form-group">
          <Input
            autoComplete="off"
            type="number"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
          <label className="on-hover" htmlFor={props.id}>
            {/* {props.label}{' '} */}
            {props.required ? <span className="form-imp">*</span> : ''}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required|integer|size:10'
              )}
            </span>
          )}
        </div>
      );
    case 'PASSWORD':
      return (
        <div className="form-group">
          <Input
            type="password"
            // autoComplete="new-password"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
            autoComplete={props.autoComplete?props.autoComplete:"new-password"}
          />
          <label className="on-hover" htmlFor={props.id}>
            {/* {props.label} */}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required'
              )}
            </span>
          )}
        </div>
      );

    case 'INPUT_SELECT':
      return (
        <div className="form-group">
          <Input
            type="select"
            multiple={props.isMulti}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            disabled={props.disabled}
          >
            <option>Select {props.label}</option>
            {props.options.map((items) => (
              <option
                value={items[props.valueInput]}
                selected={
                  items[props.valueInput] === props.selectedItem ? true : false
                }
              >
                {items[props.textInput]}
              </option>
            ))}
          </Input>
          <Label className="on-hover">{props.label}</Label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required'
              )}
            </span>
          )}
        </div>
      );

    case 'INPUT_REACT_SELECT':
      return (
        <div className="form-group">
          <Select
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            options={props.options}
            value={props.value}
            placeholder={getValue(props, `placeholder`, 'Select')}
            isClearable={props.isClear ? props.isClear : false}
            isMulti={props.isMulti}
            isDisabled={props.disabled}
            styles={props.styles}
            onInputChange={props.onInputChange}
            isAsync={props.isAsync}
          ></Select>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required'
              )}
            </span>
          )}
        </div>
      );

    case 'INPUT_REACT_SELECT_MODAL':
      return (
        <div className={`form-group m-0 ${props?.customClass}`}>
          <Select
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            options={props.options}
            value={props.value}
            placeholder={getValue(props, `placeholder`, 'Select')}
            isClearable={props.isClear ? props.isClear : false}
            isMulti={props.isMulti}
            className={
              props.class
                ? props.class
                : `configure-wrapper__form-control_modal`
            }
            isDisabled={props.disabled}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          ></Select>
          {/* <Label className="on-hover on-top">
						{props.label}
						{props.required ? <span className="form-imp">*</span> : ""}
					</Label> */}
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required'
              )}
            </span>
          )}
        </div>
      );

    case 'INPUT_REACT_SELECT_DOB':
      return (
        <div className="form-group">
          <Select
            name={props.name}
            onChange={(val, e) =>
              props.path
                ? props.onChange(
                    props.type,
                    { target: { ...e, ...val } },
                    props.path,
                    props.keyItem
                  )
                : props.onChange(
                    props.type,
                    { target: { ...e, ...val } },
                    props.keyItem
                  )
            }
            options={props.options}
            // value={getValue(props,`value.value`,'')?props.value:''}
            value={props.options.filter(
              (obj) => parseInt(obj.value) === parseInt(props.selectedOption)
            )}
            placeholder={props.selectName}
            selectedOption={props.selectedOption}
            classNamePrefix="fct"
            className="fct-select"
          ></Select>
          <Label className="on-hover on-top">
            {props.label}
            {props.required ? <span className="form-imp">*</span> : ''}
          </Label>
        </div>
      );
    case 'INPUT_REACT_SELECT_CSC':
      return (
        <div className="form-group">
          <Select
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            options={props.options}
            value={getValue(props, `value.value`, '') ? props.value : ''}
            placeholder="Select"
          ></Select>
          <Label className="on-hover on-top">
            {props.label}
            {props.required ? <span className="form-imp">*</span> : ''}
          </Label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required'
              )}
            </span>
          )}
        </div>
      );

    case 'CHECKBOX':
      return (
        <label className="checkbox-container">
          {props.label}
          <input
            type="checkbox"
            onChange={() => props.onChange(props.list)}
            value={props.value}
            checked={props.checked(props.list)}
          />
          <span className="checkmark"></span>
        </label>
      );
    case 'TIMETYPE-1':
      return (
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          inline
          dateFormat="h:mm aa"
        />
      );

    case 'DATETYPE-DOB':
      return (
        <div className="form-group">
          <div
            style={{
              border: '1px solid #dadce0',
              borderRadius: '5px',
              overflow: 'hidden',
            }}
          >
            <DatePicker
              className="form-control"
              selected={props.selected}
              onChange={(date) => props.onChange(date)}
              peekNextMonth
              startDate={props.startDate}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={props.maxDate}
              minDate={props.minDate}
              placeholderText={props.placeholder}
              dateFormat="d MMMM yyyy"
              placeholder="Select Date"
            />
          </div>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                'required'
              )}
            </span>
          )}
        </div>
      );

    default:
      return <h1>Input not valid</h1>;
  }
};
export default InputField;
