import * as React from 'react';
import { Label, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface IEmailProps {
  questions: any;
}

const Email: React.FunctionComponent<IEmailProps> = (props) => {
  return (
    <Input
      type="email"
      placeholder="example@example.com"
      value={getValue(props, 'questions.response[0]', '')}
      disabled
    ></Input>
  );
};

export default Email;
