import axios from 'axios';
import { getValue } from 'core/lodash';
import { toast } from 'react-toastify';
import { config } from '../../config';
import { get, patch, post, Delete, put } from './helpers/http-handler';

interface surveyResponse {
  survey: object;
}

// *********** survey *********** //



export const deleteSurveyPage = (
  surveyId: string,
  pageId: string
) => Delete(
  `${config.API_URL}survey-builder/surveys/${surveyId}/pages/${pageId}`
)

