import * as React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import App from './App';
import configureStore from './core/store/configureStore';
import reportWebVitals from './reportWebVitals';
import { GlobalStateProvider } from './context/global-context';
const store = configureStore();
const rootElement = document.getElementById('root');

render(
  <Provider store={store}>
    <GlobalStateProvider>
      <App />
    </GlobalStateProvider>
  </Provider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
