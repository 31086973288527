import React from 'react';

const AddGradientSVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.176"
      height="22.937"
      viewBox="0 0 22.176 22.937"
    >
      <defs></defs>
      <g transform="translate(-6239.914 147.249)">
        <path
          className="a"
          style={{ fill: '#d7242e' }}
          d="M21,21v9.688H17V7.751h4V21Z"
          transform="translate(6232 -155)"
        />
        <path
          className="b"
          style={{ fill: '#276ef1' }}
          d="M30.09,21H21V17h9.09Z"
          transform="translate(6232 -155)"
        />
        <path
          className="c"
          style={{ fill: '#ff9932' }}
          d="M17,21H7.914V17H17Z"
          transform="translate(6232 -155)"
        />
      </g>
    </svg>
  </>
);
export default AddGradientSVG;
