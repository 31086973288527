import React from 'react';

function NoData() {
  return (
    <div>
      <div className="text-center mt-5">
        <div className="mt-4 mb-2">
          <img src="/images/empty.PNG" alt="" />
        </div>
        <h4 className="mb-2">No Data Found</h4>
      </div>
    </div>
  );
}

export default NoData;
