import * as React from 'react';
import { Col, Row, Label, Input } from 'reactstrap';
import StarSVG from '../svg/star-svg';
import Select from 'react-select';
import { commonCharatersLength } from 'components/common/commonJSON';
import { getValue } from 'core/lodash';
import AdvancedOptions from './AdvancedOptions';

interface IStartRatingProps {
  pageIndex: number;
  questionIndex: number;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  handleQuestionTypeDropdown: (
    e: object,
    pageIndex: number,
    questionIndex: number,
    maxLength: string
  ) => void;
  surveyType: string;
}

const StartRating: React.FunctionComponent<IStartRatingProps> = (props) => {
  const [ratingStart] = React.useState(commonCharatersLength.ratingStar);
  let startRating = ratingStart.filter(
    (item) =>
      item.value ===
      parseFloat(
        getValue(
          props.questionInfo[props.pageIndex],
          `questions[${props.questionIndex}].validations.scaleTo`,
          ''
        )
      )
  );

  return (
    <div className="star-rating-choice">
      <Row className="left-right-icons">
        <Col md="12">
          {/* <h4 className="d-inline-block mb-3">Answer</h4> */}
        </Col>
      </Row>
      {/* <Row>
        <Col md="2" className="d-flex align-items-center">
          <h6>Representation</h6>
        </Col>
        <Col md="6">
          <div className="form-group">
            <Input type="text" value="Stars" />
          </div>
        </Col>
      </Row> */}
      <Row className='mt-4'>
        <Col md="2" className="d-flex align-items-center">
          <h6>Rating Scale</h6>
        </Col>
        <Col md="3">
          <div className="form-group">
            <Select
              options={ratingStart}
              value={startRating}
              onChange={(e) =>
                props.handleQuestionTypeDropdown(
                  e,
                  props.pageIndex,
                  props.questionIndex,
                  'scaleTo'
                )
              }
            />
          </div>
        </Col>
        {/* <Col md="3">
          <div className="form-group">
            <Input type="select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </Input>
          </div>
        </Col> */}
      </Row>
      {/* <Row>
        <Col md="2" className="d-flex align-items-center">
          <h6>Labels</h6>
        </Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input type="text" placeholder="Enter text" />
            <span className="labels-left-text">1</span>
            <span className="smiley-left-text">
              <StarSVG />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="2"></Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input type="text" placeholder="Enter text" />
            <span className="labels-left-text">2</span>
            <span className="smiley-left-text">
              <StarSVG />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="2"></Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input type="text" placeholder="Enter text" />
            <span className="labels-left-text">3</span>
            <span className="smiley-left-text">
              <StarSVG />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="2"></Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input type="text" placeholder="Enter text" />
            <span className="labels-left-text">4</span>
            <span className="smiley-left-text">
              <StarSVG />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="2"></Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input type="text" placeholder="Enter text" />
            <span className="labels-left-text">5</span>
            <span className="smiley-left-text">
              <StarSVG />
            </span>
          </div>
        </Col>
      </Row>
      */}
      <h5 className="mb-2">Advance Options:</h5>
      <AdvancedOptions
            surveyType={props.surveyType}
        handleChangeQuestionAdvancedOptions={
          props.handleChangeQuestionAdvancedOptions
        }
        questionIndex={props.questionIndex}
        pageIndex={props.pageIndex}
        setQuestionsInfo={props.setQuestionsInfo}
        handleChange={props.handleChange}
        questionInfo={props.questionInfo}
      />
    </div>
  );
};

export default StartRating;
