import { NavLink } from 'react-router-dom';
import {
  Container,
  Button,
  Col,
  Row,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  Input,
} from 'reactstrap';

import * as React from 'react';
import BulbSVG from '../../components/svg/bulb-svg';
import BulkSVG from '../../components/svg/bulk-svg';
import { predefinedOptions } from '../../components/constants/predefinedOptions';

interface IDefaultChoiceProps {
  addPredefinedOption: (
    value: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  questionIndex: number;
  pageIndex: number;
  enableSideMenuBulkAnswer: (pageIndex: number, questionIndex: number) => void;
}

const DefaultSection: React.FunctionComponent<IDefaultChoiceProps> = (
  props
) => {
  return (
    <Row className="left-right-icons mt-3 mb-2">
      <Col md="6">
        <h5 className="d-inline-block">Answer</h5>
      </Col>
      <Col md="6" className="text-md-right">
        <Button
          color="nothing btn-fs-16"
          className="mr-3"
          onClick={() =>
            props.enableSideMenuBulkAnswer(props.pageIndex, props.questionIndex)
          }
        >
          <BulkSVG /> Bulk Answers
        </Button>
        <UncontrolledDropdown>
          <DropdownToggle color="nothing btn-fs-16" caret>
            <BulbSVG /> Predefined Choices
          </DropdownToggle>
          <DropdownMenu right className="custom-dropdown2">
            <h6 className="">Select Pre-Defined Choices</h6>
            <p>Demographics</p>
            <DropdownItem
              onClick={() =>
                props.addPredefinedOption(
                  predefinedOptions.GENDER,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            >
              Gender
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                props.addPredefinedOption(
                  predefinedOptions.MARITAL_STATUS,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            >
              Marital Status
            </DropdownItem>
            <p>Scaling</p>
            <DropdownItem
              onClick={() =>
                props.addPredefinedOption(
                  predefinedOptions.AGREE_DISAGREE,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            >
              Agree - Disagree
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                props.addPredefinedOption(
                  predefinedOptions.GOOD_POOR,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            >
              Good - Poor
            </DropdownItem>
            <p>Perspective View</p>
            <DropdownItem
              onClick={() =>
                props.addPredefinedOption(
                  predefinedOptions.YES_LIKE,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            >
              Like - Dislike
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                props.addPredefinedOption(
                  predefinedOptions.YES_NO,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            >
              Yes - No
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                props.addPredefinedOption(
                  predefinedOptions.TRUE_FALSE,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            >
              True - False
            </DropdownItem>
            <p>Date and time</p>
            <DropdownItem
              onClick={() =>
                props.addPredefinedOption(
                  predefinedOptions.DAYS,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            >
              Days of the week
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                props.addPredefinedOption(
                  predefinedOptions.MONTHS,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            >
              Months
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Col>
    </Row>
  );
};
export default DefaultSection;
