import LoginPage from '../pages/Auth/Login';
import ResetPasswordPage from '../pages/Auth/ResetPassword';
import SentEmail from '../pages/Auth/SentEmail';
import ForgotPasswordPage from '../pages/Auth/PasswordReset';

const PublicRoutes = [
  //auth routes
  { path: '/login', name: 'Login', component: LoginPage },
  {
    path: '/auth/forgot-password',
    name: 'ResetPassword',
    component: ForgotPasswordPage,
  },
  {
    path: '/auth/set-password',
    name: 'PasswordReset',
    component: ResetPasswordPage,
  },
  { path: '/sent-email', name: 'SentEmail', component: SentEmail },
];
export default PublicRoutes;
