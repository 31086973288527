import * as React from 'react';
import Dropdown from '../../components/takeSurvey/Dropdown';
import ShortQuestion from '../../components/takeSurvey/ShortAnswer';
import LongQuestion from '../../components/takeSurvey/LongAnswer';
import RatingScale from '../../components/takeSurvey/RatingScale';
import StarRating from '../../components/takeSurvey/StarRating';
import MultipleChoiceGrid from '../../components/takeSurvey/MultipleChoiceGrid';
import CheckboxGrid from '../../components/takeSurvey/CheckboxGrid';
import Date from '../../components/takeSurvey/Date';
import Time from '../../components/takeSurvey/Time';
import FullName from '../../components/takeSurvey/FullName';
import Email from '../../components/takeSurvey/Email';
import Number from '../../components/takeSurvey/Number';
import FileUpload from '../../components/takeSurvey/FileUpload';
import SingleChoice from '../../components/takeSurvey/MultipleChoice';
import CheckBoX from '../../components/takeSurvey/Checkbox';

interface IRenderTakeSurveyProps {
  param: string;
  options: [];
  questions: any;
  handleUserSurveyResponseDropdown: (value: any) => void;
  handleUserSurveyResponseDropdownArray: (
    questionId: string,
    questionType: string,
    response: [],
    comment: string,
    other: string
  ) => void;
  handleUserSurveyResponseDropdownGrid: (
    questionId: string,
    questionType: string,
    rowId: string,
    columnId: string
  ) => void;

  answer: any[];
  handleChangeTakeSurveyInput: (e: any, questionId: string) => void;
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string
  ) => void;
  handleChangeTakeSurveyNumber: (e: any, questionId: string) => void;
  simpleValidator: any;
  required: boolean;
  requiredErrors: any;
  handleChangeTakeSurveyFile: (e: any, questionId: string) => void;
  removeImageTakeSurveyFile: (questionId: string, url: string) => void;
  isLearning: boolean
}
const RenderTakeSurvey: React.FunctionComponent<IRenderTakeSurveyProps> = (
  props
) => {
  switch (props.param) {
    case 'SINGLE_CHOICE':
      return (
        <SingleChoice
          options={props.options}
          questions={props.questions}
          handleUserSurveyResponseDropdownArray={
            props.handleUserSurveyResponseDropdownArray
          }
          answer={props.answer}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
          requiredErrors={props.requiredErrors}
          isLearning={props.isLearning}
        />
      );
    case 'CHECK_BOX':
      return (
        <CheckBoX
          requiredErrors={props.requiredErrors}
          options={props.options}
          questions={props.questions}
          handleUserSurveyResponseDropdownArray={
            props.handleUserSurveyResponseDropdownArray
          }
          answer={props.answer}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
          isLearning={props.isLearning}
        />
      );
    case 'DROPDOWN':
      return (
        <Dropdown
          requiredErrors={props.requiredErrors}
          options={props.options}
          questions={props.questions}
          handleUserSurveyResponseDropdown={
            props.handleUserSurveyResponseDropdown
          }
          answer={props.answer}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
        />
      );
    case 'SHORT_ANSWER':
      return (
        <ShortQuestion
          requiredErrors={props.requiredErrors}
          options={props.options}
          questions={props.questions}
          answer={props.answer}
          handleChangeTakeSurveyInput={props.handleChangeTakeSurveyInput}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
        />
      );
    case 'LONG_ANSWER':
      return (
        <LongQuestion
          requiredErrors={props.requiredErrors}
          options={props.options}
          questions={props.questions}
          handleUserSurveyResponseDropdown={
            props.handleUserSurveyResponseDropdown
          }
          answer={props.answer}
          handleChangeTakeSurveyInput={props.handleChangeTakeSurveyInput}
          required={props.required}
        />
      );
    case 'RATING_SCALE':
      return (
        <RatingScale
          requiredErrors={props.requiredErrors}
          questions={props.questions}
          answer={props.answer}
          handleChangeTakeSurveyNumber={props.handleChangeTakeSurveyNumber}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
        />
      );
    case 'STAR_RATING':
      return (
        <StarRating
          requiredErrors={props.requiredErrors}
          questions={props.questions}
          answer={props.answer}
          handleChangeTakeSurveyNumber={props.handleChangeTakeSurveyNumber}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
        />
      );
    case 'MATRIX_RADIO_BOX':
      return (
        <MultipleChoiceGrid
          requiredErrors={props.requiredErrors}
          questions={props.questions}
          required={props.required}
          answer={props.answer}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
          handleUserSurveyResponseDropdownGrid={
            props.handleUserSurveyResponseDropdownGrid
          }
        />
      );
    case 'MATRIX_CHECK_BOX':
      return (
        <CheckboxGrid
          requiredErrors={props.requiredErrors}
          handleUserSurveyResponseDropdownGrid={
            props.handleUserSurveyResponseDropdownGrid
          }
          answer={props.answer}
          questions={props.questions}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
        />
      );
    case 'DATE':
      return (
        <Date
          requiredErrors={props.requiredErrors}
          questions={props.questions}
          handleUserSurveyResponseDropdown={
            props.handleUserSurveyResponseDropdown
          }
          answer={props.answer}
          handleChangeTakeSurveyInput={props.handleChangeTakeSurveyInput}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
        />
      );
    case 'TIME':
      return (
        <Time
          requiredErrors={props.requiredErrors}
          questions={props.questions}
          handleUserSurveyResponseDropdown={
            props.handleUserSurveyResponseDropdown
          }
          answer={props.answer}
          handleChangeTakeSurveyInput={props.handleChangeTakeSurveyInput}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
        />
      );
    case 'FULL_NAME':
      return (
        <FullName
          requiredErrors={props.requiredErrors}
          questions={props.questions}
          handleUserSurveyResponseDropdown={
            props.handleUserSurveyResponseDropdown
          }
          answer={props.answer}
          handleChangeTakeSurveyInput={props.handleChangeTakeSurveyInput}
          required={props.required}
        />
      );
    case 'EMAIL_TEXTBOX':
      return (
        <Email
          requiredErrors={props.requiredErrors}
          options={props.options}
          questions={props.questions}
          handleUserSurveyResponseDropdown={
            props.handleUserSurveyResponseDropdown
          }
          answer={props.answer}
          handleChangeTakeSurveyInput={props.handleChangeTakeSurveyInput}
          required={props.required}
        />
      );
    case 'NUMERIC_TEXTBOX':
      return (
        <Number
          requiredErrors={props.requiredErrors}
          options={props.options}
          questions={props.questions}
          handleUserSurveyResponseDropdown={
            props.handleUserSurveyResponseDropdown
          }
          answer={props.answer}
          handleChangeTakeSurveyInput={props.handleChangeTakeSurveyInput}
          required={props.required}
        />
      );
    case 'FILE_UPLOAD':
      return (
        <FileUpload
          requiredErrors={props.requiredErrors}
          options={props.options}
          handleChangeTakeSurveyInput={props.handleChangeTakeSurveyFile}
          questions={props.questions}
          answer={props.answer}
          required={props.required}
          removeImageTakeSurveyFile={props.removeImageTakeSurveyFile}
        />
      );
    default:
      return (
        <SingleChoice
          isLearning={props.isLearning}
          requiredErrors={props.requiredErrors}
          options={props.options}
          questions={props.questions}
          handleUserSurveyResponseDropdownArray={
            props.handleUserSurveyResponseDropdownArray
          }
          answer={props.answer}
          required={props.required}
          handleChangeTakeSurveyAdvancedOptionInput={
            props.handleChangeTakeSurveyAdvancedOptionInput
          }
        />
      );
  }
};

export default RenderTakeSurvey;
