import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { getValue } from '../../../core/lodash';

interface IChartPreviewProps {
  type: any;
  questions: any;
  option: any;
  qno: number;
}

const ChartPreview3: React.FunctionComponent<IChartPreviewProps> = (props) => {
  const { type, questions, option, qno } = props;
  // ===========================================================================
  // chart section
  // ===========================================================================

  const [seriesChart, setSeries] = React.useState([
    //     {
    //     name: 'Marine Sprite',
    //     data: [50, 100, 55, 65, 74, 23, 34]
    // }, {
    //     name: 'Striking Calf',
    //     data: [53, 32, 33, 52, 13, 43, 32]
    // }, {
    //     name: 'Tank Picture',
    //     data: [12, 17, 11, 9, 15, 11, 20]
    // }, {
    //     name: 'Bucket Slope',
    //     data: [9, 7, 5, 8, 6, 9, 4]
    // }, {
    //     name: 'Reborn Kid',
    //     data: [20, 20, 20, 20, 20, 20, 20]
    // }
  ]);

  const [optionsChart, setOptions] = React.useState<any>({
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    // title: {
    //     text: '100% Stacked Bar'
    // },
    // xaxis: {
    //     categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    // },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + '%';
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
  });
  React.useEffect(() => {
    if (!getValue(props, `questions.rows[${0}].text`, '')) {
      let series:any = [];
      let categories:any = [];
      let final:any = [];
      getValue(props, `questions.row[${0}].columns`, []).map((item) =>
        categories.push(item.text)
      );
      getValue(props, `questions.row`, []).map((item) =>
        series.push(item.text)
      );
      for (let i = 0; i < getValue(props, `questions.row.length`, 0) - 1; i++) {
        let a:any = [];
        getValue(props, `questions.row`, []).map((item2, index2) => {
          getValue(item2, `columns`, []).map((item3, index3) => {
            if (index3 === i) {
              a.push(item3.resPercentage ? item3.resPercentage : 0);
            }
          });
        });
        if (categories[i]) {
          final.push({ name: categories[i], data: a });
        }
      }
      setOptions({
        ...optionsChart,
        xaxis: {
          categories: series,
        },
      });
      setSeries(final);
    } else {
      let series:any = [];
      let categories:any = [];
      let final:any = [];
      getValue(props, `questions.rows[${0}].columns`, []).map((item) =>
        categories.push(item.text)
      );
      getValue(props, `questions.rows`, []).map((item) =>
        series.push(item.text)
      );
      for (let i = 0; i <= getValue(props, `questions.rows.length`, 0); i++) {
        let a:any = [];
        getValue(props, `questions.rows`, []).map((item2:any, index2) => {
          getValue(item2, `columns`, []).map((item3:any, index3) => {
            if (index3 === i) {
              a.push(item3.responsePercentage ? item3.responsePercentage : 0);
            }
          });
        });
        if (categories[i]) {
          final.push({ name: categories[i], data: a });
        }
      }
      setOptions({
        ...optionsChart,
        xaxis: {
          categories: series,
        },
      });
      setSeries(final);
    }
  }, [option]);
  //console.log(questions);
  return (
    <Card className="mb-3">
      <CardBody>
        <h5 className="mb-1">
          {/* Question {getValue(props, 'qno', 1)} */}
          {/* <span className="text-red">*</span> */}
        </h5>
        <h3 className="mb-1">{getValue(props, 'questions.text', '')}</h3>
        {!getValue(props, `questions.rows[${0}].text`, '') ? (
          <p>
            <span className="mr-3">
              Answered: {getValue(questions, 'resCount', 0)}
            </span>{' '}
            Skipped: {getValue(questions, 'skipped', 0)}
          </p>
        ) : (
          // <p>
          //     <span className="mr-3">Answered: {getValue(questions, 'resCount', 0)}</span> Skipped: {getValue(questions, 'skipped', 0)}
          // </p>
          ''
        )}
        <div id="chart">
          <ReactApexChart
            options={optionsChart}
            series={seriesChart}
            type="bar"
            height={350}
          />
        </div>

        {!getValue(props, `questions.rows[${0}].text`, '') ? (
          <>
            <h5>Response Data:</h5>
            <div className="table-responsive">
              <Table className="table-head-light">
                <tbody>
                  {getValue(props, `questions.row`, []).map((item, index) => {
                    return (
                      <>
                        {index === 0 ? (
                          <tr>
                            <th>Row</th>
                            {getValue(
                              props,
                              `questions.row[${0}].columns`,
                              []
                            ).map((vars:any) => {
                              if (index === 0) {
                                return <th>{getValue(vars, `text`, '')}</th>;
                              }else return
                            })}
                            <th>Response Count</th>
                          </tr>
                        ) : (
                          ''
                        )}
                        <tr>
                          <td>{item.text}</td>
                          {getValue(item, 'columns', []).map((qus) => {
                            return (
                              <td>
                                {getValue(qus, `resPercentage`, '') +
                                  `%` +
                                  ' ' +
                                  '(' +
                                  getValue(qus, `resCount`, 0) +
                                  ')'}
                              </td>
                            );
                          })}
                          <td>{getValue(item, 'resCount', '')}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            {getValue(props, `questions.totalScore`, 0) ? (
              <>
                <h5>
                  Response Score: ({getValue(props, `questions.resGetScore`, 0)}
                  /{getValue(props, `questions.totalScore`, 0)})
                </h5>
                <Table className="table-head-light">
                  <tbody>
                    {getValue(props, `questions.row`, []).map((item, index) => {
                      return (
                        <>
                          {index === 0 ? (
                            <tr>
                              <th>Row</th>
                              {getValue(
                                props,
                                `questions.row[${0}].columns`,
                                []
                              ).map((vars:any) => {
                                if (index === 0) {
                                  return <th>{getValue(vars, `text`, '')}</th>;
                                }else return
                              })}
                              <th>Total score</th>
                            </tr>
                          ) : (
                            ''
                          )}
                          <tr>
                            <td>{item.text}</td>
                            {getValue(item, 'columns', []).map((qus) => {
                              return (
                                <td>{getValue(qus, `resGetScore`, '')}</td>
                              );
                            })}
                            <td>{getValue(item, 'totalScore', '')}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            ) : (
              ''
            )}{' '}
          </>
        ) : (
          <>
            <div className="table-responsive">
              <Table className="table-head-light">
                <tbody>
                  {getValue(props, `questions.rows`, []).map((item, index) => {
                    return (
                      <>
                        {index === 0 ? (
                          <tr>
                            <th>Choices</th>
                            {getValue(
                              props,
                              `questions.rows[${0}].columns`,
                              []
                            ).map((vars:any) => {
                              if (index === 0) {
                                return <th>{getValue(vars, `text`, '')}</th>;
                              }else return
                            })}
                            <th>Response Count</th>
                          </tr>
                        ) : (
                          ''
                        )}
                        <tr>
                          <td>{item.text}</td>
                          {getValue(item, 'columns', []).map((qus) => {
                            return (
                              <td>
                                {getValue(qus, `responsePercentage`, '') +
                                  `%` +
                                  ' ' +
                                  '(' +
                                  getValue(qus, `responseCount`, 0) +
                                  ')'}
                              </td>
                            );
                          })}
                          <td>{getValue(item, 'totalReponseCount', '')}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <br></br>
            <div className="table-responsive">
              <Table className="table-head-light">
                <tbody>
                  {getValue(props, `questions.rows`, []).map((item, index) => {
                    return (
                      <>
                        {index === 0 ? (
                          <tr>
                            <th>Choices</th>
                            <th>Max Score</th>
                            <th>Self Evaluation Score</th>
                            <th>Manager Avg Score</th>
                            <th>Peers Avg Score</th>
                            <th>Subordinates Avg Score</th>
                            <th>External Avg Score</th>
                          </tr>
                        ) : (
                          ''
                        )}
                        <tr>
                          <td>{getValue(item, `text`, '')}</td>
                          <td>
                            {getValue(item, `maxResponseRating`, '') === 'NaN'
                              ? '-'
                              : getValue(item, `maxResponseRating`, '')}
                          </td>
                          <td>
                            {getValue(item, `groupResponseRating.self`, '') ===
                            'NaN'
                              ? '-'
                              : getValue(item, `groupResponseRating.self`, '')}
                          </td>
                          <td>
                            {getValue(
                              item,
                              `groupResponseRating.manager`,
                              ''
                            ) === 'NaN'
                              ? '-'
                              : getValue(
                                  item,
                                  `groupResponseRating.manager`,
                                  ''
                                )}
                          </td>
                          <td>
                            {getValue(item, `groupResponseRating.peer`, '') ===
                            'NaN'
                              ? '-'
                              : getValue(item, `groupResponseRating.peer`, '')}
                          </td>
                          <td>
                            {getValue(
                              item,
                              `groupResponseRating.subordinate`,
                              ''
                            ) === 'NaN'
                              ? '-'
                              : getValue(
                                  item,
                                  `groupResponseRating.subordinate`,
                                  ''
                                )}
                          </td>
                          <td>
                            {getValue(
                              item,
                              `groupResponseRating.public`,
                              ''
                            ) === 'NaN'
                              ? '-'
                              : getValue(
                                  item,
                                  `groupResponseRating.public`,
                                  ''
                                )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        )}
        <br></br>
      </CardBody>
    </Card>
  );
};

export default ChartPreview3;
