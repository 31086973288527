import React from 'react';

const Smiley2SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.998"
      height="35.998"
      viewBox="0 0 35.998 35.998"
    >
      <defs></defs>
      <g transform="translate(0.783 0.783)">
        <path
          style={{ fill: '#feca2e' }}
          d="M25.216,8A17.216,17.216,0,1,1,8,25.216,17.217,17.217,0,0,1,25.216,8Zm7.826,15.651a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,33.042,23.651Zm-12.521,0a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,20.521,23.651Z"
          transform="translate(-8 -8)"
        />
        <circle
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(22.217 14.216)"
        />
        <circle
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(9.217 14.216)"
        />
      </g>
      <path
        style={{ fill: '#6d4c41' }}
        d="M18,0A18,18,0,1,0,36,18,18.02,18.02,0,0,0,18,0Zm0,34.433A16.434,16.434,0,1,1,34.433,18,16.452,16.452,0,0,1,18,34.433Z"
      />
      <path
        style={{ fill: '#6d4c41' }}
        d="M100.7,146.348a2.348,2.348,0,1,0-2.348,2.348A2.351,2.351,0,0,0,100.7,146.348Zm-3.13,0a.783.783,0,1,1,.783.783A.784.784,0,0,1,97.565,146.348Z"
        transform="translate(-86.609 -129.914)"
      />
      <path
        style={{ fill: '#6d4c41' }}
        d="M226.348,144a2.348,2.348,0,1,0,2.348,2.348A2.351,2.351,0,0,0,226.348,144Zm0,3.13a.783.783,0,1,1,.783-.783A.783.783,0,0,1,226.348,147.13Z"
        transform="translate(-202.088 -129.914)"
      />
      <path
        style={{ fill: '#6d4c41' }}
        d="M208.777,83.128a.785.785,0,0,0,.349-.082l3.13-1.565a.783.783,0,0,0-.7-1.4l-3.13,1.565a.782.782,0,0,0,.351,1.482Z"
        transform="translate(-187.647 -72.172)"
      />
      <path
        d="M112.431,81.475l3.13,1.565a.783.783,0,0,0,.7-1.4l-3.13-1.565a.783.783,0,0,0-.7,1.4Z"
        transform="translate(-101.042 -72.166)"
      />
      <path
        style={{ fill: '#6d4c41' }}
        d="M111.822,224a10.239,10.239,0,0,0-7.627,3.391.783.783,0,0,0,1.168,1.044,8.705,8.705,0,0,1,12.92,0,.782.782,0,1,0,1.164-1.046A10.257,10.257,0,0,0,111.822,224Z"
        transform="translate(-93.823 -202.088)"
      />
    </svg>
  </>
);
export default Smiley2SVG;
