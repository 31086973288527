import { Col, Row, Label, Input } from 'reactstrap';

import * as React from 'react';
import { getValue } from '../../core/lodash';

interface IFullNameProps {
  questions: any;
  handleUserSurveyResponseDropdown: (value: object) => void;
  answer: any[];
  handleChangeTakeSurveyInput: (e: any, questionId: string) => void;
  // validation
  required: boolean;
  requiredErrors: any;
}

const FullName: React.FunctionComponent<IFullNameProps> = (props) => {
  let firstname = getValue(props, 'answer', []).filter(
    (item) =>
      item.questionId ===
      getValue(props.questions, `row[${0}].columns[${0}].questionId`, '')
  );
  let lastname = getValue(props, 'answer', []).filter(
    (item) =>
      item.questionId ===
      getValue(props.questions, `row[${0}].columns[${1}].questionId`, '')
  );
  return (
    <>
      <Row>
        <Col md="6">
          <div className="form-group">
            <Label>
              {getValue(props.questions, 'row[0].columns[0].question.text', '')}
            </Label>
            <Input
              type="text"
              placeholder="First Name"
              value={getValue(firstname[0], 'response', '')}
              onChange={(e) => {
                props.handleChangeTakeSurveyInput(
                  e,
                  getValue(firstname[0], 'questionId', '')
                );
              }}
            />
            <div className="form-error" style={{ color: 'red' }}>
              {props.requiredErrors.includes(
                getValue(props.questions, 'row[0].columns[0].question.id', '')
              )
                ? props.questions.mandatoryMsg
                : ''}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <Label>
              {getValue(props.questions, 'row[0].columns[1].question.text', '')}
            </Label>
            <Input
              type="text"
              placeholder="Last Name"
              value={getValue(lastname[0], 'response', '')}
              onChange={(e) => {
                props.handleChangeTakeSurveyInput(
                  e,
                  getValue(lastname[0], 'questionId', '')
                );
              }}
            />
            <div className="form-error" style={{ color: 'red' }}>
              {props.requiredErrors.includes(
                getValue(props.questions, 'row[0].columns[1].question.id', '')
              )
                ? props.questions.mandatoryMsg
                : ''}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FullName;
