import * as React from 'react';

interface IPageNotFoundProps {
}

const PageNotFound: React.FunctionComponent<IPageNotFoundProps> = (props) => {
  return (
      <div>
          Page Not Found 404
      </div>
  );
};

export default PageNotFound;
