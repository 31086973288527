import * as React from 'react';
import { Button, Col } from 'reactstrap';
import { getValue } from '../../core/lodash';
import ImageUploader from 'react-images-upload';
import DeleteSVG from '../../components/svg/delete-svg';

interface IFileUploadProps {
  options: any;
  questions: any;
  answer: any[];
  handleChangeTakeSurveyInput: (e: any, questionId: string) => void; // validation
  required: boolean;
  requiredErrors: any;
  removeImageTakeSurveyFile: (questionId: string,url:string) => void;
}

const FileUpload: React.FunctionComponent<IFileUploadProps> = (props) => {
  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === props.questions.id
  );
  return (
    <React.Fragment>
      <div className="image-upload-container">
        <ImageUploader
          // withPreview={true}
          withIcon={true}
          buttonText=""
          onChange={(e) =>
            props.handleChangeTakeSurveyInput(e, props.questions.id)
          }
          label=""
          singleImage={true}
          imgExtension={['.jpg', '.gif', '.png', '.gif','.jpeg']}
          // maxFileSize={ele.maxFileSize}
          // errorClass={imageConflict && "error"}
        />
        <div className="image-upload-txt pt-2">
          <p className="main-text">Upload Images here</p>
          <p className="small-text">
            Preferred image size:1024px x 768px @ 72 DPI Maximum size of 10MB
          </p>
        </div>
        {getValue(currentValue[0], 'response.length', '') !== 0 && (
          <div
            className="image-uploaded"
            style={{
              backgroundImage: `url(${getValue(
                currentValue[0],
                'response',
                ''
              )})`,
            }}
          >
            <Button
              color="nothing"
              onClick={() =>
                props.removeImageTakeSurveyFile(props.questions.id,getValue(
                  currentValue[0],
                  'response',
                  ''
                ))
              }
            >
              <DeleteSVG />
            </Button>
          </div>
        )}
      </div>
      <div className="form-error" style={{ color: 'red' }}>
        {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
          ? props.questions.mandatoryMsg
          : ''}
      </div>
    </React.Fragment>
  );
};

export default FileUpload;
