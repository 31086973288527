import React, { useState } from 'react';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import LeftMenu from '../components/menu/left-menu';

function HomeLayout({ children }: any) {
  const [isLeftMenu, manageMenu] = useState(false);
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  return (
    <div>
      <Header enableLeftMenu={() => enableLeftMenu()} hideMenu={false} />
      <LeftMenu
        isLeftMenu={isLeftMenu}
        enableLeftMenu={() => enableLeftMenu()}
      />
      {children}
      <Footer />
    </div>
  );
}

export default HomeLayout;
