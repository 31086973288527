import React from 'react';

const Smiley5SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.004"
      height="36.004"
      viewBox="0 0 36.004 36.004"
    >
      <defs>
        <style></style>
      </defs>
      <g transform="translate(0.783 0.783)">
        <path
          className="a"
          style={{ fill: '#feca2e' }}
          d="M25.219,8A17.219,17.219,0,1,1,8,25.219,17.22,17.22,0,0,1,25.219,8Zm7.827,14.088a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,33.046,22.088Zm-12.523,0a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,20.523,22.088Z"
          transform="translate(-8 -8)"
        />
        <ellipse
          className="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="2"
          rx="1.5"
          ry="2"
          transform="translate(22.219 12.219)"
        />
        <ellipse
          className="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="2"
          rx="1.5"
          ry="2"
          transform="translate(9.219 12.219)"
        />
      </g>
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M18,0A18,18,0,1,0,36,18,18.023,18.023,0,0,0,18,0Zm0,34.439A16.437,16.437,0,1,1,34.439,18,16.455,16.455,0,0,1,18,34.439Z"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M100.7,130.348a2.348,2.348,0,1,0-2.348,2.348A2.351,2.351,0,0,0,100.7,130.348Zm-3.131,0a.783.783,0,1,1,.783.783A.784.784,0,0,1,97.565,130.348Z"
        transform="translate(-86.608 -115.477)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M226.348,128a2.348,2.348,0,1,0,2.348,2.348A2.351,2.351,0,0,0,226.348,128Zm0,3.131a.783.783,0,1,1,.783-.783A.783.783,0,0,1,226.348,131.131Z"
        transform="translate(-202.085 -115.477)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M111.827,224a10.259,10.259,0,0,0-7.627,3.391.783.783,0,1,0,1.165,1.046,8.708,8.708,0,0,1,12.923,0,.782.782,0,1,0,1.166-1.043A10.235,10.235,0,0,0,111.827,224Z"
        transform="translate(-93.825 -202.085)"
      />
    </svg>
  </>
);
export default Smiley5SVG;
