import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface IDeleteSurveyProps {
  isOpen: boolean;
  removeValue: string;
  handleDeleteFun: (removeValue: string) => void;
  handleModal: () => void;
}

const DeleteSurveyModal: React.FunctionComponent<IDeleteSurveyProps> = (
  props
) => {
  return (
    <div>
      <Modal isOpen={props.isOpen} centered>
        <ModalBody>
          <h4>Delete</h4>
          <p>Are you sure you want to delete {props.removeValue}?</p>
          <div className="btn-group-margin text-center mt-4">
            <Button color="border" onClick={props.handleModal}>
              Cancel
            </Button>
            <Button
              color="blue"
              onClick={() => props.handleDeleteFun(props.removeValue)}
            >
              Delete
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteSurveyModal;
