import { config } from 'config';
import { post, get } from './helpers/http-handler';

export const createTenant = (payload: object) =>
  post(`${config.API_URL}/tenant`, payload);

export const updateTenant = (payload: object) =>
  post(`${config.API_URL}/tenant`, payload);

export const adminLogin = (payload: object) =>
  post(`${config.API_URL}auth/user/email/login`, payload);

export const adminForgotPassword = (payload: object) =>
  post(`${config.API_URL}auth/user/email/forgot-password`, payload);

export const adminSetPassword = (payload: object) =>
  post(`${config.API_URL}auth/user/email/set-password`, payload);

export const adminSuperUser = (payload: object) =>
  post(`${config.API_URL}auth/user/super-user`, payload);

// export const clientLogout = (payload: object) =>
//   DeleteWithPayload(`${config.API_URL}auth/user/logout`, payload);

export const clientUpdateToken = (payload: object) =>
  post(`${config.API_URL}auth/user/refresh`, payload);

//  export const getAllPermissions = () =>
// get(`${config.API_URL}users/my-permissions`);

export const getAllPermissions = () =>
  get(`${config.API_URL}users/my/permissions`);
