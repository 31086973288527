import { getValue } from 'core/lodash';
import * as React from 'react';
import CKEditor from 'react-ckeditor-component';
import { Input } from 'reactstrap';

interface IAppProps {
  surveyId: string;
  handleEditQuestion: (
    evt: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  questionIndex: any;
  pageIndex: any;
  item: object;
  questionsInfo: any;
}

const Question: React.FunctionComponent<IAppProps> = (props) => {
  return (
    <div className="form-group">
      <Input
        type="textarea"
        rows="7"
        placeholder="Question"
        value={getValue(
          props,
          `questionsInfo[${props.pageIndex}].questions[${props.questionIndex}].text`,
          ''
        )}
        onChange={(evt) =>
          props.handleEditQuestion(evt, props.pageIndex, props.questionIndex)
        }
        // content={getValue(props, 'item.text', '')}
        // events={{
        //   change: (evt) => props.handleEditQuestion(evt,props.pageIndex,props.questionIndex),
        // }}
      />
    </div>
  );
};

export default Question;
