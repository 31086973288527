import * as React from 'react';
import { Label, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';
import CommentOtherOption from './commentsOtherOption';
interface Props {
  options: any;
  questions: any;
}
const CheckBox: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      {getValue(props, 'options', []).map((opt, index) => {
        return (
          <>
            <div className="mb-2" key={index}>
              <Label className="checkbox-container d-inline-block">
                {getValue(opt, 'text', '')}
                <input
                  type="checkbox"
                  checked={
                    getValue(props, 'questions.response', []).includes(opt.id)
                      ? true
                      : false
                  }
                  disabled
                />
                <span className="checkmark"></span>
              </Label>{' '}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              {opt.correct && (
                <span style={{ color: 'green' }}>Correct Answer</span>
              )}
            </div>
          </>
        );
      })}
      <CommentOtherOption questions={props.questions} />
      {/* {getValue(props, 'questions.otherOptionEnabled', '') ? (
        <>
          <div className="mb-2">
            <Label className="radio-container d-inline-block">
              {getValue(props, 'questions.otherOptionMsg', '')}
              <input type="checkbox" />
              <span className="checkmark"></span>
            </Label>
          </div>
          <div className="pl-2 ml-4">
            <div className="form-group">
              <Input type="text" placeholder="Enter Text here" disabled />
            </div>
          </div>
        </>
      ) : (
        ''
      )} */}
      {/* {getValue(props, 'questions.commentEnabled', '') ? (
        <div className="form-group">
          <Label>Any Additonal Comments?</Label>
          <Input
            type="textarea"
            rows="3"
            placeholder="Enter Text here"
            disabled
          />
        </div>
      ) : (
        ''
      )} */}
    </>
  );
};
export default CheckBox;
