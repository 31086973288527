import * as React from 'react';
import { Label, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface INumberProps {
  questions: any;
}

const Number: React.FunctionComponent<INumberProps> = (props) => {
  return (
    <Input
      type="number"
      placeholder="Enter Number here"
      value={getValue(props, 'questions.response[0]', '')}
      disabled
    ></Input>
  );
};

export default Number;
