import * as React from 'react';
import { Label, Input, Row, Col, Button } from 'reactstrap';
import StarSVG from '../../components/svg/star-svg';
import { getValue } from '../../core/lodash';
import PreviewAdvancedOptions from './previewAdvancedOptions';

interface IStartRatingProps {
  questions: any;
  answer: any[];
  handleChangeTakeSurveyNumber: (e: any, questionId: string) => void;
  // validation
  required: boolean;
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string
  ) => void;
  requiredErrors: any;
}

const StartRating: React.FunctionComponent<IStartRatingProps> = (props) => {
  // number
  const [inputs] = React.useState(
    Array(
      parseFloat(getValue(props.questions, 'validations.scaleTo', ''))
    ).fill({ value: 'number' })
  );

  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === props.questions.id
  );
  return (
    <>
      {inputs &&
        inputs.map((_item, i) => {
          return (
            <Button
              color="nothing"
              className={`circle-rating ${
                i + 1 <= getValue(currentValue[0], 'response', 0)
                  ? 'active'
                  : ''
              }`}
              onClick={() => {
                props.handleChangeTakeSurveyNumber(i + 1, props.questions.id);
              }}
            >
              <p>
                {i === 0 ? (
                  <>
                    {getValue(props.questions, 'validations.startLabel', '')}
                    &nbsp;
                  </>
                ) : (
                  <>&nbsp;</>
                )}
                {i ===
                  parseFloat(
                    getValue(props.questions, 'validations.scaleTo', '')
                  ) -
                    1 && i !== 0 ? (
                  <>{getValue(props.questions, 'validations.endLabel', '')}</>
                ) : (
                  ''
                )}
              </p>
              <StarSVG />
            </Button>
          );
        })}
      <PreviewAdvancedOptions
        questions={props.questions}
        answer={props.answer}
        handleChangeTakeSurveyAdvancedOptionInput={
          props.handleChangeTakeSurveyAdvancedOptionInput
        }
      />
      <div className="form-error" style={{ color: 'red' }}>
        {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
          ? props.questions.mandatoryMsg
          : ''}
      </div>
    </>
  );
};

export default StartRating;
