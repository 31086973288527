import * as React from 'react';
import ChartPreview1 from './type1ChartPreview';
import ChartPreview2 from './type2ChartPreview';
import ChartPreview3 from './type3ChartPreview'

interface IChartDeciderProps {
    type: string,
    options: any;
    series: any;
    questions: any;
    option: any;
    qno: number
}


const ChartDecider: React.FunctionComponent<IChartDeciderProps> = (props) => {
    const {
        type,
        questions,
        option,
        qno
    } = props;
    switch (props.type) {
        case "SINGLE_CHOICE":
            return <ChartPreview1

                type={type}
                questions={questions}
                option={option}
                qno={qno}
            />
        case "DROPDOWN":
            return <ChartPreview1

                type={type}
                questions={questions}
                option={option}
                qno={qno}
            />
        case "CHECK_BOX":
            return <ChartPreview1

                type={type}
                questions={questions}
                option={option}
                qno={qno}
            />
        case "RATING_SCALE":
            return <ChartPreview1

                type={type}
                questions={questions}
                option={option}
                qno={qno}
            />
        case "STAR_RATING":
            return <ChartPreview1

                type={type}
                questions={questions}
                option={option}
                qno={qno}
            />
        case "MATRIX_CHECK_BOX":
            return <ChartPreview3
                type={type}
                questions={questions}
                option={option}
                qno={qno}
            />
        case "MATRIX_RADIO_BOX":
            return <ChartPreview3
                type={type}
                questions={questions}
                option={option}
                qno={qno}
            />
        case "SHORT_ANSWER":
            return <ChartPreview2
                questions={questions}
                qno={qno}
                type={type}
            />
        case "SHORT_ANSWER":
            return <ChartPreview2
                questions={questions}
                qno={qno}
                type={type}
            />
        default:
            return <ChartPreview2
                questions={questions}
                qno={qno}
                type={type}

            />
    }
};

export default ChartDecider;
