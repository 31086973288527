import * as React from 'react';
import { Label, Input, Row, Col, Button } from 'reactstrap';
import StarSVG from '../../components/svg/star-svg';
import { getValue } from '../../core/lodash';
import CommentOtherOption from './commentsOtherOption';

interface IStarRatingProps {
  questions: any;
}

const StarRating: React.FunctionComponent<IStarRatingProps> = (props) => {
  const [inputs] = React.useState(
    Array(
      parseFloat(getValue(props.questions, 'validations.scaleTo', 0))
    ).fill({ value: 'number' })
  );

  return (
    <>
      {inputs &&
        inputs.map((_item, i) => {
          return (
            <>
              <Button
                color="nothing"
                className={`circle-rating ${
                  i + 1 <= getValue(props, `questions.response[0]`, 0)
                    ? 'active'
                    : ''
                }`}
              >
                <p>
                  {i === 0 ? (
                    <>
                      {getValue(props.questions, 'validations.startLabel', '')}
                      &nbsp;
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                  {i ===
                    parseFloat(
                      getValue(props.questions, 'validations.scaleTo', '')
                    ) -
                      1 && i !== 0 ? (
                    <>{getValue(props.questions, 'validations.endLabel', '')}</>
                  ) : (
                    ''
                  )}
                </p>
                <StarSVG />
              </Button>
            </>
          );
        })}
           <CommentOtherOption 
        questions={props.questions}
      />
    </>
  );
};

export default StarRating;
