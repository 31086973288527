// import "./index.scss";
import React, { useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
// import {getValue} from '../../../utils/lodash'
import { adminLogin } from '../../../core/services/auth.service';
import LoginPageLyout from '../../../Layout/LoginLayout';
import { useHistory } from 'react-router';
import { getValue } from '../../../core/lodash';
import InputField from '../../../common/InputRuleFeild';
interface ILoginPageProps {}

const LoginPage: React.FunctionComponent<ILoginPageProps> = (props: any) => {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let resp = await adminLogin({ email, password });
        if (resp) {
          localStorage.setItem('view', 'client');
          setIsLoading(false);
          localStorage.setItem(
            'access_token',
            getValue(resp, `data.accessToken`, '')
          );
          localStorage.setItem(
            'refreshToken',
            getValue(resp, `data.refreshToken`, '')
          );
          localStorage.setItem(
            'user',
            JSON.stringify(getValue(resp, `data.user`, ''))
          );
          if (getValue(resp, `data.accessToken`, '')) {
            getData(getValue(resp, `data.accessToken`, ''));
          }
          window.location.href = `/`;
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const getData = async (token: string) => {
    // try {
    //   let resp = await getAllOrganisationByToken(token);
    //   if (resp) {
    //     if (
    //       getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
    //     ) {
    //       localStorage.setItem(
    //         "organisation_logo",
    //         getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
    //       );
    //     }
    //   }
    // } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const history = useHistory();

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && e.target.tagName.toLowerCase() !== 'button') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <>
      <LoginPageLyout>
        <div className="m-auto form-wrapper__form">
          <form autoComplete='on'>
            <h2>Sign in to Survey Berry</h2>
            <div className="form-group mt-4">
              <label htmlFor="email">Email</label>
              <InputField
                inputType="EMAIL"
                id="email"
                name="email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value.toLowerCase())}
                placeholder="Enter Email"
                className="form-control"
                label="Email"
                validator={simpleValidator}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <InputField
                inputType="PASSWORD"
                id="password"
                name="password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                placeholder="Enter Password"
                className="form-control"
                validator={simpleValidator}
                label={'Password'}
                onKeyDown={handleKeyDown}
                autoComplete='on'
              />
            </div>
            <div className="form-cta-button">
              <button
                className="form-cta-button__secondary-btn"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/auth/forgot-password`);
                }}
              >
                Forgot password?
              </button>
              {isLoading ? (
                <button className="form-cta-button__primary-btn">
                  Please wait...
                </button>
              ) : (
                <button
                  type={'submit'}
                  onClick={handleSubmit}
                  className="form-cta-button__primary-btn"
                >
                  Next
                </button>
              )}
            </div>
          </form>
        </div>
      </LoginPageLyout>
    </>
  );
};

export default LoginPage;
