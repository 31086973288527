import * as React from 'react';
import Cookies from 'js-cookie';
import { NavItem, Nav } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { getValue } from 'core/lodash';

interface IFormHeaderProps {
  surveyDetails: any;
  surveyId: string;
  // handleCheckCond: () => void;
  // condStatus: boolean;
  surveyTemplate: string;
  surveyQuestionType: string
}

const FormHeader: React.FunctionComponent<IFormHeaderProps> = (props) => {
  const { surveyDetails } = props;
  return (
    <Nav pills className="basic-tabs">
      {!getValue(props, 'surveyTemplate', '') ? (
         !Cookies.get('progressSurvey') ?
        <NavItem>
          <NavLink
            className="nav-link"
            to={`/create-new-survey-form/${getValue(props, 'surveyQuestionType', '')}/${getValue(props, `surveyId`, '')}`}
          >
            Write Questions
          </NavLink>
        </NavItem>:'')
        : <NavItem>
          <NavLink
            className="nav-link"
            to={`/create-new-survey-form/${getValue(props, 'surveyQuestionType', '')}/${getValue(props, `surveyId`, '')}/templates`}
          >
            Write Questions
          </NavLink>
        </NavItem>

      }
      {!getValue(props, 'surveyTemplate', '') ? (
        <NavItem>
          <NavLink
            className="nav-link"
            to={`/create-survey/settings/${getValue(props, 'surveyQuestionType', '')}/${getValue(props, `surveyId`, '')}`}
          >
            Settings
          </NavLink>
        </NavItem>
      ) : ''}
      {!getValue(props, 'surveyTemplate', '') ? (
       
          <NavItem>
            <NavLink
              className="nav-link"
              to={`/create-survey/pick-audience/${getValue(props, 'surveyQuestionType', '')}/${getValue(
                props,
                `surveyId`,
                ''
              )}`}
            >
              Pick Audience
            </NavLink>
          </NavItem>
      ) : ''}
      {!getValue(props, 'surveyTemplate', '') ? (
        <NavItem>
          <NavLink
            className="nav-link"
            to={`/create-survey/publish/${getValue(props, 'surveyQuestionType', '')}/${getValue(props, `surveyId`, '')}`}
          >
            Publish
          </NavLink>
        </NavItem>
      ) : ''}
    </Nav>
  );
};

export default FormHeader;
