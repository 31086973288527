import * as React from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import BackSVG from "../../components/svg/back-svg";
import RenderTakeSurvey from "../../components/common/RenderTakeSurvey";
import { getValue, setValue } from "../../core/lodash";
import {
  createTakeSurvey,
  getTakeSurvey,
  updateTakeSurvey,
  getTakeSurveyWithToken,
  uploadFile,
  getTakeSurveyToken,
  getSurveyBasedOnToken,
  createSurveySubmission,
  updateSurveySubmission,
  getSurveyPages,
  createFinalSubmission,
} from "../../core/services/surveyService";
import ArrowLeftSVG from "../../components/svg/arrow-left-svg";
import ArrowRightSVG from "../../components/svg/arrow-right-svg";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import RouteLeavingGuard from "./PromptPage";
import { UncontrolledTooltip } from "reactstrap";
import InfoSVG from "../../components/svg/info-svg";
import { useState } from "react";
import HRPolicy from "../HRPolicy";
import { config } from "../../config";
import axios from "axios";
import { Validation } from "./validation";

interface ITakeSurveyComponentProps {
  handleTakeSurveySubmit: () => void;
  answer: any[];
  // propmt
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

const TakeSurveyComponent: React.FunctionComponent<ITakeSurveyComponentProps> = (
  props
) => {
  const [pageStatus, setPageStatus] = useState("1");
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState(0);
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(1);
  const [questionsInfo, setQuestionsInfo] = React.useState([]);
  const [isOpen1, setIsOpen1] = React.useState(false);
  const [surveyId] = React.useState(
    getValue(props, "match.params.surveyId", "")
  );
  const [type] = React.useState(getValue(props, "match.params.type", ""));
  const [answer, setUserSurveyResponse] = React.useState<any>([]);
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  const [nav1, setNav1] = React.useState();
  const [nav2, setNav2] = React.useState();
  const slider1 = React.useRef<any>(null);
  const slider2 = React.useRef<any>(null);
  const [activeTabPage, setActiveTabPage] = React.useState("1");
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [surveyToken, setSurveyToken] = useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();
  const [surveyLoading, setSuveyLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [check, setChecked] = useState(true);

  React.useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
    sessionStorage.removeItem("maxPageNo");
    getToken();
    getSurvey("");
    let email;
    if (getValue(window, "location.pathname", "").split("/").length > 3) {
      email = getValue(window, "location.pathname", "").split("/")[3];
    }
    setEmail(email);
  }, []);
  React.useEffect(() => {
    if (!getValue(questionsInfo, "id", "")) {
      getSurvey("");
    }
  }, [surveyToken]);

  const [scrollCount, setScrollCount] = useState('');
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [scrollCount]);

  // ===========================================================================
  // Hr Policy component state and functions
  // ===========================================================================
  const getToken = async () => {
    setSuveyLoading(true);
    axios
      .get(
        `${config.API_URL}survey-collector/surveys/${getValue(
          props,
          "match.params.surveyUrl",
          ""
        )}/${getValue(
          props,
          "match.params.type",
          ""
        )}/token?tenant_id=${getValue(props, "match.params.tenant_id", "")}`,
        {
          headers: {
            Authorization: getValue(localStorage, `access_token`, "")
              ? `Bearer ${getValue(localStorage, `access_token`, "")}`
              : "",
            "x-tenant-origin": `${
              window.location.host === "localhost:3000"
                ? "id.surveyberry.ai"
                : window.location.host
            }`,
          },
        }
      )
      .then(async (resp) => {
        if (resp) {
          setSurveyToken(getValue(resp, "data.data.surveyToken", ""));
          if (getValue(resp, "data.data.surveyToken", "")) {
            let resp1 = await getSurveyBasedOnToken(
              getValue(props, "match.params.surveyUrl", ""),
              getValue(resp, "data.data.surveyToken", "")
            );
            if (resp1) {
              setQuestionsInfo(getValue(resp1, "data.data", ""));
              setSuveyLoading(false);
            }
          } else {
            window.location.href = "/home";
            setSuveyLoading(false);
          }
          setSuveyLoading(false);
        } else {
          setSuveyLoading(false);
        }
      })
      .catch((error) => {
        if (getValue(error, `response.data.statusCode`, "") === 401) {
          // window.location.href = `${
          //   config.API_URL
          // }auth/sso/stohrm/login?relay_state=${
          //   window.location.href
          // }&tenant_id=${localStorage.getItem("tenant_id")}`;
          window.location.href = `/login`;
        } else {
          setSuveyLoading(false);
          toast.error(
            getValue(error, `response.data.message`, "Unable to load survey, Please refresh the page"),
            { className: "red-circle" }
          );
          setErrorMessage(
            getValue(error, `response.data.message`, "Unable to load survey, Please refresh the page")
          );
        }
      });
  };

  const handleChangeStatus = () => {
    setScrollCount(scrollCount + 1);
    setPageStatus("2");
  };

  // ===========================================================================
  // Take a survey component state and functions
  // ===========================================================================

  // ============================
  // prompt
  // ============================

  const [isDirty, setIsDirty] = React.useState(true);

  const handleIsOpen1 = () => {
    setIsOpen1(!isOpen1);
  };

  // =====================================
  // Get survey/creating answer structure
  // =====================================

  const getSurvey = async (pageId) => {
    if (!pageId) {
      {
        /* getValue(props, 'match.params.type', '') */
      }
      // getValue(props, 'match.params.surveyUrl', '')

      if (surveyToken) {
        let resp = await getSurveyBasedOnToken(
          getValue(props, "match.params.surveyUrl", ""),
          surveyToken
        );
        if (resp) {
          setQuestionsInfo(getValue(resp, "data.data", ""));
          if (getValue(resp, `data.data.page.questions`, "")) {
            getValue(resp, `data.data.page.questions`, "").map((qus) => {
              if (
                qus.type !== "MATRIX_RADIO_BOX" &&
                qus.type !== "MATRIX_CHECK_BOX" &&
                qus.type !== "FULL_NAME"
              ) {
                if (qus.commentEnabled && qus.otherOptionEnabled) {
                  return answer.push({
                    questionId: getValue(qus, `id`, ""),
                    questionType: getValue(qus, "type", ""),
                    response: getValue(qus, "response", []),
                    otherOption: "",
                    commentOption: "",
                  });
                } else if (qus.commentEnabled && !qus.otherOptionEnabled) {
                  return answer.push({
                    questionId: getValue(qus, `id`, ""),
                    questionType: getValue(qus, "type", ""),
                    response: getValue(qus, "response", []),
                    commentOption: "",
                  });
                } else if (!qus.commentEnabled && qus.otherOptionEnabled) {
                  return answer.push({
                    questionId: getValue(qus, `id`, ""),
                    questionType: getValue(qus, "type", ""),
                    response: getValue(qus, "response", []),
                    otherOption: "",
                  });
                } else {
                  return answer.push({
                    questionId: getValue(qus, `id`, ""),
                    questionType: getValue(qus, "type", ""),
                    response: getValue(qus, "response", []),
                  });
                }
                // commentOption: "xZ"
                // otherOption: "xZ"
              } else if (
                qus.type === "MATRIX_RADIO_BOX" ||
                qus.type === "MATRIX_CHECK_BOX"
              ) {
                if (qus.type === "MATRIX_CHECK_BOX") {
                  let ids = getValue(qus, "row", []).map((row, i) => {
                    let columnIds = getValue(qus, `response`, []).filter(
                      (item) => item.rowId === row.id
                    );
                    let columnId =
                      columnIds.length !== 0
                        ? getValue(qus, `response`, []).filter(
                            (item) => item.rowId === row.id
                          )[0].columnId
                        : null;
                    if (columnIds.length !== 0) {
                      return {
                        rowId: row.id,
                        columnId: columnId ? columnId : null,
                      };
                    } else return;
                  });
                  let mainIds =
                    ids &&
                    ids.filter((item) => {
                      if (item && item.columnId) {
                        return item;
                      }
                    });
                  answer.push({
                    questionId: getValue(qus, `id`, ""),
                    questionType: getValue(qus, "type", ""),
                    response: mainIds,
                  });
                } else {
                  answer.push({
                    questionId: getValue(qus, `id`, ""),
                    questionType: getValue(qus, "type", ""),
                    response: getValue(qus, "row", []).map((row, i) => {
                      return {
                        rowId: row.id,
                        columnId:
                          getValue(qus, `response.length`, 0) !== 0
                            ? getValue(qus, `response`, []).filter(
                                (item) => item.rowId === row.id
                              )[0].columnId
                            : "",
                      };
                    }),
                  });
                }
              } else if (qus.type === "FULL_NAME") {
                getValue(qus, "row", []).map((row) => {
                  return getValue(row, "columns", []).map((col) => {
                    return answer.push({
                      questionId: getValue(col, `questionId`, ""),
                      questionType: getValue(col, "question.type", ""),
                      response: [],
                    });
                  });
                });
              } else {
                return answer.push({
                  questionId: getValue(qus, `id`, ""),
                  questionType: getValue(qus, "type", ""),
                  response: [],
                });
              }
            });
            setUserSurveyResponse([...answer]);
          } else {
            // toast.error("Survey page don't have any questions");
            // history.push('/');
          }
        }
      }
    } else {
      let resp = await getSurveyPages(
        getValue(props, "match.params.surveyUrl", ""),
        pageId,
        surveyToken
      );
      if (resp) {
        setQuestionsInfo(getValue(resp, "data.data", {}));
        if (getValue(resp, `data.data.page.questions.length`, 0) !== 0) {
          getValue(resp, `data.data.page.questions`, []).map((qus) => {
            var index = answer.findIndex((x) => x.questionId == qus.id);
            let data2: any[];
            data2 = getValue(resp, `data.data.page.questions`, []).filter(
              (item) => item.type === "FULL_NAME"
            );
            let data: any[];
            data = getValue(data2[0], `row[${0}].columns`, []).map(
              (item) => item.questionId
            );
            let fullNameIndex = answer.filter((item) =>
              data.includes(item.questionId)
            );

            if (index === -1) {
              if (
                qus.type !== "MATRIX_RADIO_BOX" &&
                qus.type !== "MATRIX_CHECK_BOX" &&
                qus.type !== "FULL_NAME"
              ) {
                return answer.push({
                  questionId: getValue(qus, `id`, ""),
                  questionType: getValue(qus, "type", ""),
                  response: getValue(qus, "response", []),
                });
              } else if (
                qus.type === "MATRIX_RADIO_BOX" ||
                qus.type === "MATRIX_CHECK_BOX"
              ) {
                if (qus.type === "MATRIX_CHECK_BOX") {
                  let ids = getValue(qus, "row", []).map((row, i) => {
                    let columnIds = getValue(qus, `response`, []).filter(
                      (item) => item.rowId === row.id
                    );
                    let columnId =
                      columnIds.length !== 0
                        ? getValue(qus, `response`, []).filter(
                            (item) => item.rowId === row.id
                          )[0].columnId
                        : null;
                    if (columnIds.length !== 0) {
                      return {
                        rowId: row.id,
                        columnId: columnId ? columnId : null,
                      };
                    } else return;
                  });
                  let mainIds =
                    ids &&
                    ids.filter((item) => {
                      if (item && item.columnId) {
                        return item;
                      }
                    });
                  answer.push({
                    questionId: getValue(qus, `id`, ""),
                    questionType: getValue(qus, "type", ""),
                    response: mainIds,
                  });
                } else {
                  answer.push({
                    questionId: getValue(qus, `id`, ""),
                    questionType: getValue(qus, "type", ""),
                    response: getValue(qus, "row", []).map((row, i) => {
                      return {
                        rowId: row.id,
                        columnId:
                          getValue(qus, `response.length`, 0) !== 0
                            ? getValue(qus, `response`, []).filter(
                                (item) => item.rowId === row.id
                              )[0].columnId
                            : "",
                      };
                    }),
                  });
                }
              } else if (
                qus.type === "FULL_NAME" &&
                fullNameIndex.length === 0
              ) {
                getValue(qus, "row", []).map((row) => {
                  return getValue(row, "columns", []).map((item) => {
                    return answer.push({
                      questionId: getValue(item, `questionId`, ""),
                      questionType: getValue(item, "question.type", ""),
                      response: [],
                    });
                  });
                });
              } else {
                if (qus.type !== "FULL_NAME") {
                  return answer.push({
                    questionId: getValue(qus, `id`, ""),
                    questionType: getValue(qus, "type", ""),
                    response: [],
                  });
                }
              }
            }
          });
          setUserSurveyResponse([...answer]);
        } else {
          // history.push('/');
          // toast.error("Survey page don't have any questions");
        }
      }
    }
  };

  // ==============================
  // handling answers/options
  // ==============================

  const handleUserSurveyResponseDropdown = (value) => {
    let newValue = [value];
    let alreadySelected =
      answer && answer.filter((ans) => ans.questionId === value.questionId);
    if (alreadySelected.length === 0) {
      answer.push(value);
      setUserSurveyResponse([...answer]);
    } else {
      var res = answer.map(
        (obj) =>
          newValue.find((ans) => ans.questionId === obj.questionId) || obj
      );
      setUserSurveyResponse(res);
    }
    // =========================================================================
    // Dropdown Error validations
    // =========================================================================

    let checkValidation = getValue(questionsInfo, "page.questions", []).filter(
      (item) => item.id === value.questionId && item.mandatoryEnabled === true
    );
    if (getValue(checkValidation, "length", 0) !== 0) {
      let errors = requiredErrors.filter((item) => item !== value.questionId);
      setRequiredErrors(errors);
    }
  };

  const handleUserSurveyResponseDropdownArray = (
    questionId,
    questionType,
    response,
    comments,
    others
  ) => {
    let other = others ? others : "";
    let comment = comments ? comments : "";
    if (questionType === "SINGLE_CHOICE") {
      let value = {
        questionId: questionId,
        questionType: questionType,
        response: [response],
        commentOption: comment,
        otherOption: other,
      };
      let newValue = [value];
      let alreadySelected =
        answer && answer.filter((ans) => ans.questionId === value.questionId);
      if (alreadySelected.length === 0) {
        answer.push(value);
        setUserSurveyResponse([...answer]);
      } else {
        var res = answer.map(
          (obj) =>
            newValue.find((ans) => ans.questionId === obj.questionId) || obj
        );
        setUserSurveyResponse(res);
      }
      // =========================================================================
      // Single choice validation
      // =========================================================================
      let checkValidation = getValue(
        questionsInfo,
        "page.questions",
        []
      ).filter(
        (item) => item.id === questionId && item.mandatoryEnabled === true
      );
      if (getValue(checkValidation, "length", 0) !== 0) {
        let errors = requiredErrors.filter((item) => item !== questionId);
        setRequiredErrors(errors);
      }
    } else if (questionType === "CHECK_BOX") {
      let mainArray: any = [];
      answer.map((item) => {
        if (item.questionId === questionId) {
          if (getValue(item, "response.length", 0) === 0) {
            mainArray.push({
              questionId: questionId,
              questionType: questionType,
              response: [response],
              commentOption: item.commentOption,
              otherOption: item.otherOption,
            });
          } else {
            var index = item.response.indexOf(response);
            if (index > -1) {
              item.response.splice(index, 1);
              mainArray.push({
                questionId: questionId,
                questionType: questionType,
                response: item.response,
                commentOption: item.commentOption,
                otherOption: item.otherOption,
              });
            } else {
              item.response.push(response);
              mainArray.push({
                questionId: questionId,
                questionType: questionType,
                response: item.response,
                commentOption: item.commentOption,
                otherOption: item.otherOption,
              });
            }
          }
        } else {
          mainArray.push({
            questionId: item.questionId,
            questionType: item.questionType,
            response: item.response,
            commentOption: item.commentOption,
            otherOption: item.otherOption,
          });
        }
      });
      setUserSurveyResponse(mainArray);
      // =========================================================================
      // Checkbox validation
      // =========================================================================
      let obj = mainArray.filter((item) => item.questionId === questionId);
      let checkValidation = getValue(
        questionsInfo,
        "page.questions",
        []
      ).filter(
        (item) => item.id === questionId && item.mandatoryEnabled === true
      );
      if (getValue(checkValidation, "length", 0) !== 0) {
        if (
          requiredErrors.length !== 0 ||
          getValue(obj[0], "response.length", 0) !== 0
        ) {
          let errorObj = mainArray.filter((item) => {
            if (
              requiredErrors.includes(item.questionId) &&
              item.questionId === questionId
            ) {
              return item;
            }
          });

          if (
            getValue(errorObj[0], "response.length", 0) !== 0 ||
            getValue(obj[0], "response.length", 0) !== 0
          ) {
            let errors = requiredErrors.filter((item) => item !== questionId);
            setRequiredErrors(errors);
          } else {
            if (
              !requiredErrors.includes(questionId) &&
              questionType === "CHECK_BOX"
            ) {
              requiredErrors.push(questionId);
            }
          }
        } else {
          requiredErrors.push(questionId);
        }
      }
    } else {
      let value = {
        questionId: questionId,
        questionType: questionType,
        response: [response],
      };
      let newValue = [value];

      var res = answer.map(
        (obj) =>
          newValue.find((ans) => ans.questionId === obj.questionId) || obj
      );
      setUserSurveyResponse(res);
    }
  };

  const handleUserSurveyResponseDropdownGrid = (
    questionId,
    questionType,
    rowId,
    colId
  ) => {
    if (questionType === "MATRIX_RADIO_BOX") {
      let alreadySelected =
        answer && answer.filter((item) => item.questionId === questionId);
      if (alreadySelected.length !== 0) {
        getValue(alreadySelected[0], "response", []).forEach((item) => {
          if (item.rowId === rowId) {
            if (!item.columnId) {
              item.columnId = colId;
            } else {
              if (item.columnId !== colId) {
                item.columnId = colId;
              } else {
                item.columnId = "";
              }
            }
          }
        });
        var res = answer.map(
          (obj) =>
            alreadySelected.find((ans) => ans.questionId === obj.questionId) ||
            obj
        );
        setUserSurveyResponse(res);
      }
      // =============================================================================
      // error validation radio
      // =============================================================================
      let validMultiChoicename = getValue(
        questionsInfo,
        "page.questions",
        []
      ).filter((item) => item.type === "MATRIX_RADIO_BOX");

      // finding current question array

      let actulQUestion = validMultiChoicename.filter(
        (item) => item.id === questionId && item.mandatoryEnabled === true
      );
      if (actulQUestion.length !== 0) {
        let validateRows = answer.filter(
          (item, i) =>
            item.questionId ===
            getValue(
              actulQUestion[0],
              "id",
              "" && item.mandatoryEnabled === true
            )
        );
        // pushing if any option have empty columnId && updating grid array
        validateRows.map((element: any) => {
          element.response.filter((res) => {
            if (!res.columnId) {
              let index = requiredErrorsGrid.indexOf(element.questionId);
              if (index === -1) {
                requiredErrorsGrid.push(element.questionId);
              }
            }
          });
        });
        // removing if any option have columnId && updating grid array
        validateRows.map((element) => {
          element.response.filter((res) => {
            if (res.columnId) {
              let index = requiredErrorsGrid.indexOf(element.questionId);
              if (index !== -1) {
                let filterGrid = requiredErrorsGrid.filter(
                  (item) => item !== element.questionId
                );
                setRequiredErrorsGrid(filterGrid);
              }
            }
            let fil = element.response.filter((item) => item.columnId !== "");
            if (fil.length === element.response.length) {
              let filerRequiredErrors = requiredErrors.filter(
                (item) => item !== element.questionId
              );
              setRequiredErrors(filerRequiredErrors);
            }
          });
        });
      }
    } else if (questionType === "MATRIX_CHECK_BOX") {
      let obj = {
        rowId: rowId,
        columnId: colId,
      };

      let alreadySelected =
        answer && answer.filter((item) => item.questionId === questionId);
      if (alreadySelected.length !== 0) {
        let item = getValue(alreadySelected[0], "response", []).filter(
          (item) => item.columnId === ""
        );
        if (getValue(item, "length", 0) !== 0) {
          setValue(alreadySelected[0], "response", [obj]);
        } else {
          let removeItem = getValue(alreadySelected[0], "response", []).filter(
            (item) => {
              return item.columnId === colId && item.rowId === rowId;
            }
          );
          if (removeItem.length === 0) {
            getValue(alreadySelected[0], "response", []).push(obj);
          } else {
            let item = getValue(alreadySelected[0], "response", []).filter(
              (a) => {
                return a.columnId !== colId || a.rowId !== rowId;
              }
            );
            setValue(alreadySelected[0], "response", item);
          }
        }
        var res = answer.map(
          (obj) =>
            alreadySelected.find((ans) => ans.questionId === obj.questionId) ||
            obj
        );
        setUserSurveyResponse(res);
      }
      // =============================================================================
      // error validation checkbox
      // =============================================================================

      let validMultiCheckboxname = getValue(
        questionsInfo,
        "page.questions",
        []
      ).filter((item) => item.type === "MATRIX_CHECK_BOX");
      let actulQUestion = validMultiCheckboxname.filter(
        (item) => item.id === questionId && item.mandatoryEnabled === true
      );
      // checking length of checkbox
      if (actulQUestion.length !== 0) {
        // finding rows that should validate
        let final: any = [];
        actulQUestion.filter((_item, i) => {
          let array = answer.filter(
            (item, _index) =>
              item.questionId ===
              getValue(
                actulQUestion[0],
                "id",
                "" && item.mandatoryEnabled === true
              )
          );
          final.push(array);
        });
        var validateRows = [].concat.apply([], final);
        // removing if all options pushed
        validateRows.map((element: any, i) => {
          element.response.filter((res) => {
            if (res.columnId) {
              let index = requiredErrorsGrid.indexOf(element.questionId);
              if (
                index !== -1 &&
                actulQUestion[0].row.length <= element.response.length
              ) {
                let filterGrid = requiredErrorsGrid.filter(
                  (item) => item !== element.questionId
                );
                setRequiredErrorsGrid(filterGrid);
              }
            }
            let fil = element.response.filter((item) => item.columnId !== "");
            if (
              fil.length !== 0 &&
              actulQUestion[0].row.length <= element.response.length
            ) {
              let filerRequiredErrors = requiredErrors.filter(
                (item) => item !== element.questionId
              );
              setRequiredErrors(filerRequiredErrors);
            } else {
              if (!requiredErrors.includes(questionId)) {
                requiredErrors.push(questionId);
              }
            }
          });
        });
      }
    } else {
    }
  };

  const handleChangeTakeSurveyInput = (e, questionId) => {
    const { name, value } = e.target;
    setUserSurveyResponse((pages) => {
      return pages.map((x, index) => {
        if (x.questionId !== questionId) return x;
        return {
          ...x,
          response: value,
        };
      });
    });

    // =========================================================================
    // Error handling/short answer/long answer/fullname/numeric box
    // =========================================================================

    let obj = answer.filter((item) => item.questionId === questionId);
    let findQuestions = getValue(questionsInfo, "page.questions", []);
    let findQuestionsInfo = findQuestions.filter(
      (item) => item.id === questionId
    );

    let findQuestionsInfoFullName = findQuestions.filter(
      (item) => item.type === "FULL_NAME"
    );
    // ==================================
    // short answer error validation
    // ==================================
    let checkValidation = getValue(questionsInfo, "page.questions", []).filter(
      (item) => item.id === questionId && item.mandatoryEnabled === true
    );

    if (
      getValue(findQuestionsInfo[0], "type", "") === "SHORT_ANSWER" &&
      getValue(findQuestionsInfo[0], "type", "") !== "FULL_NAME"
    ) {
      if (getValue(checkValidation, "length", 0) !== 0) {
        // if (
        //   value &&
        //   requiredErrors.length !== 0 &&
        //   getValue(obj[0], "response.length", 0) !== 0 &&
        //   value.length >= minLength &&
        //   value.length <= maxLength
        // ) {
        //   let errors = requiredErrors.filter((item) => item !== questionId);
        //   setRequiredErrors(errors);
        // } else {
        //   if (
        //     !requiredErrors.includes(questionId) &&
        //     getValue(findQuestionsInfo[0], "type", "") === "SHORT_ANSWER"
        //   ) {
        //     if (value.length <= minLength && value.length >= maxLength) {
        //       requiredErrors.push(questionId);
        //     }
        //   }
        // }
        if (
          value ||
          requiredErrors.length !== 0 ||
          getValue(obj[0], "response.length", 0) !== 0
        ) {
          if (
            value.length >=
              parseFloat(
                getValue(findQuestionsInfo[0], "validations.minLength", 0)
              ) &&
            parseFloat(
              getValue(findQuestionsInfo[0], "validations.maxLength", 0)
            ) >= value.length
          ) {
            let errors = requiredErrors.filter((item) => item !== questionId);
            setRequiredErrors(errors);
          } else {
            if (
              !requiredErrors.includes(questionId) &&
              getValue(findQuestionsInfo[0], "type", "") === "SHORT_ANSWER"
            ) {
              requiredErrors.push(questionId);
            }
          }
        }
      }
    }
    // ==================================
    // Long answer validation
    // ==================================
    else if (
      getValue(findQuestionsInfo[0], "type", "") === "LONG_ANSWER" &&
      getValue(findQuestionsInfo[0], "type", "") !== "FULL_NAME"
    ) {
      if (
        value ||
        requiredErrors.length !== 0 ||
        getValue(obj[0], "response.length", 0) !== 0
      ) {
        if (getValue(checkValidation, "length", 0) !== 0) {
          if (
            value.length >
              parseFloat(
                getValue(findQuestionsInfo[0], "validations.minLength", 5)
              ) &&
            parseFloat(
              getValue(findQuestionsInfo[0], "validations.maxLength", 10)
            ) > value.length
          ) {
            let errors = requiredErrors.filter((item) => item !== questionId);
            setRequiredErrors(errors);
          } else {
            if (
              !requiredErrors.includes(questionId) &&
              getValue(findQuestionsInfo[0], "type", "") === "LONG_ANSWER"
            ) {
              requiredErrors.push(questionId);
            }
          }
        }
      }
    }
    // ==================================
    // Number validation
    // ==================================
    else if (getValue(findQuestionsInfo[0], "type", "") === "NUMERIC_TEXTBOX") {
      if (getValue(checkValidation, "length", 0) !== 0) {
        if (
          value.length >
            parseFloat(
              getValue(checkValidation[0], "validations.minValue", "")
            ) &&
          parseFloat(getValue(checkValidation[0], "validations.maxValue", "")) >
            value.length
        ) {
          let errors = requiredErrors.filter((item) => item !== questionId);
          setRequiredErrors(errors);
        } else {
          requiredErrors.push(questionId);
        }
      }

      // ==================================
      // Email validation
      // ==================================
    } else if (getValue(findQuestionsInfo[0], "type", "") === "EMAIL_TEXTBOX") {
      if (
        value ||
        requiredErrors.length !== 0 ||
        getValue(obj[0], "response.length", 0) !== 0
      ) {
        if (getValue(checkValidation, "length", 0) !== 0) {
          // error check in answer array
          let errorObj = answer.filter((item) => {
            if (
              requiredErrors.includes(item.questionId) &&
              item.questionId === questionId
            ) {
              return item;
            }
          });
          // var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;
          const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (
            getValue(obj[0], "response.length", 0) !== 0 &&
            pattern.test(getValue(obj[0], "response", 0))
          ) {
            let errors = requiredErrors.filter((item) => item !== questionId);
            setRequiredErrors(errors);
          } else {
            if (
              !requiredErrors.includes(questionId) &&
              getValue(findQuestionsInfo[0], "type", "") === "EMAIL_TEXTBOX"
            ) {
              requiredErrors.push(questionId);
            }
          }
        }
      }
    }
    // ==================================
    // DATE/ TIME validation
    // ==================================
    else if (
      getValue(findQuestionsInfo[0], "type", "") === "DATE" ||
      getValue(findQuestionsInfo[0], "type", "") === "TIME"
    ) {
      if (
        value ||
        requiredErrors.length !== 0 ||
        getValue(obj[0], "response.length", 0) !== 0
      ) {
        if (getValue(checkValidation, "length", 0) !== 0) {
          // error check in answer array
          if (value) {
            let errors = requiredErrors.filter((item) => item !== questionId);
            setRequiredErrors(errors);
          } else {
            if (
              !requiredErrors.includes(questionId) &&
              (getValue(findQuestionsInfo[0], "type", "") === "DATE" ||
                getValue(findQuestionsInfo[0], "type", "") === "TIME")
            ) {
              requiredErrors.push(questionId);
            }
          }
        }
      }
    }
    // ==================================
    // Full name validation
    // ==================================
    else if (
      getValue(findQuestionsInfoFullName[0], "type", "") === "FULL_NAME" ||
      getValue(obj[0], "response.length", 0) !== 0
    ) {
      if (requiredErrors.includes(questionId)) {
        if (value) {
          let errors = requiredErrors.filter((item) => item !== questionId);
          setRequiredErrors(errors);
        } else {
          if (!requiredErrors.includes(questionId)) {
            requiredErrors.push(questionId);
          }
        }
      }
    } else {
      if (
        value ||
        requiredErrors.length !== 0 ||
        getValue(obj[0], "response.length", 0) !== 0
      ) {
        // error check in answer array
        let errorObj = answer.filter((item) => {
          if (
            requiredErrors.includes(item.questionId) &&
            item.questionId === questionId
          ) {
            return item;
          }
        });
        if (
          getValue(errorObj[0], "response.length", 0) !== 0 ||
          getValue(obj[0], "response.length", 0) !== 0
        ) {
          let errors = requiredErrors.filter((item) => item !== questionId);
          setRequiredErrors(errors);
        } else {
          if (
            !requiredErrors.includes(questionId) &&
            getValue(findQuestionsInfo[0], "type", "") === "EMAIL_TEXTBOX"
          ) {
            requiredErrors.push(questionId);
          }
        }
      }
    }
  };

  const handleChangeTakeSurveyAdvancedOptionInput = (e, questionId, param) => {
    const { name, value } = e.target;
    setUserSurveyResponse((pages) => {
      return pages.map((x, index) => {
        if (x.questionId !== questionId) return x;
        return {
          ...x,
          [param]: value,
        };
      });
    });
  };

  const handleChangeTakeSurveyFile = async (e, questionId) => {
    // let file = e.target.files[0];
    let file = e[0];
    let formData = new FormData();
    formData.append("image", file);
    let resp = await uploadFile(formData);
    if (resp) {
      setUserSurveyResponse((pages) => {
        return pages.map((x, index) => {
          if (x.questionId !== questionId) return x;
          return {
            ...x,
            response: getValue(resp, "data.url", ""),
          };
        });
      });
    }
    // ==================================
    // file upload error validation
    // ==================================
    let checkValidation = getValue(questionsInfo, "page.questions", []).filter(
      (item) => item.id === questionId && item.mandatoryEnabled === true
    );
    if (getValue(checkValidation, "length", 0) !== 0) {
      let errors = requiredErrors.filter((item) => item !== questionId);
      setRequiredErrors(errors);
    }
  };

  const removeImageTakeSurveyFile = async (questionId, _url) => {
    // let resp = await uploadFileRemove(url)
    setUserSurveyResponse((pages) => {
      return pages.map((x, index) => {
        if (x.questionId !== questionId) return x;
        return {
          ...x,
          response: [],
        };
      });
    });

    // ==================================
    // file upload error validation
    // ==================================
    let checkValidation = getValue(questionsInfo, "page.questions", []).filter(
      (item) => item.id === questionId && item.mandatoryEnabled === true
    );
    if (getValue(checkValidation, "length", 0) !== 0) {
      let errors = requiredErrors.filter((item) => item !== questionId);
      setRequiredErrors(errors);
    } else {
      if (!requiredErrors.includes(questionId)) {
        requiredErrors.push(questionId);
      }
    }
  };

  const handleChangeTakeSurveyNumber = (value, questionId) => {
    setUserSurveyResponse((pages) => {
      return pages.map((x, index) => {
        if (x.questionId !== questionId) return x;
        return {
          ...x,
          response: value,
        };
      });
    });

    // ==================================
    //  error validation
    // ==================================
    let checkValidation = getValue(questionsInfo, "page.questions", []).filter(
      (item) => item.id === questionId && item.mandatoryEnabled === true
    );

    if (getValue(checkValidation, "length", 0) !== 0) {
      let errors = requiredErrors.filter((item) => item !== questionId);
      setRequiredErrors(errors);
    }
  };

  const handleNavigate = (index) => {
    handleChangePage(index);
  };
  const handleChangePage = (index) => {
    togglePage(index);
    // slickGoTo(e.target.value);
  };

  const togglePage = (index) => {
    let no: any = sessionStorage.getItem("maxPageNo");
    if (index - 1 < parseFloat(no) || index === activeTab) {
      let pageID = getValue(questionsInfo, `surveyPageIds[${index - 1}]`, 0);
      getSurvey(pageID);
    } else if (index === activeTab) {
      return;
    } else {
      toast.error("Page cannot be skipped as questions are mandatory", {
        className: "red-circle",
      });
    }
  };

  const [requiredErrors, setRequiredErrors] = React.useState<any>([]);
  const [requiredErrorsGrid, setRequiredErrorsGrid] = React.useState<any>([]);

  // ===========================================================================
  // submit survey section
  // ===========================================================================

  const handleTakeSurveySubmit = async () => {
    answer.forEach((a1) => {
      if (a1.questionType === "MATRIX_RADIO_BOX") {
        a1.response.forEach((a2) => {
          if (!a2.columnId) {
            a2.columnId = null;
          }
        });
      }
    });
    answer.forEach((a1) => {
      if (a1.questionType === "MATRIX_CHECK_BOX") {
        a1.response.forEach((a2) => {
          if (!a2.columnId) {
            a2.columnId = null;
          }
        });
      }
    });
    let pageNumber = parseFloat(getValue(questionsInfo, "pageNo", "") + 1);
    let no: any = sessionStorage.getItem("maxPageNo");
    if (!sessionStorage.getItem("maxPageNo") || parseFloat(no) < pageNumber) {
      sessionStorage.setItem("maxPageNo", pageNumber.toString());
    }
    try {
      if (Validation(questionsInfo, answer).length === 0) {
        setErrorEnabled(false);
        if (!getValue(questionsInfo, "userSurveyId", "")) {
          let req = {
            responses: answer,
            // empId: getValue(window, 'location.pathname', '').split('/')[3],
          };
          let resp = await createSurveySubmission(
            getValue(props, "match.params.surveyUrl", ""),
            surveyToken,
            req
          );
          if (resp) {
            const formValid = simpleValidator.current.allValid();
            if (formValid) {
              simpleValidator.current.hideMessages();
              forceUpdate(0);
            }
            let nextPageId = getValue(questionsInfo, "nextPageId", "")
              ? getValue(questionsInfo, "nextPageId", "")
              : "";
            await getSurvey(nextPageId);
          }
        } else {
          let req = {
            responses: answer,
            // empId: getValue(window, 'location.pathname', '').split('/')[3],
          };
          let resp = await createSurveySubmission(
            getValue(props, "match.params.surveyUrl", ""),
            surveyToken,
            req
          );
          if (resp) {
            const formValid = simpleValidator.current.allValid();
            if (formValid) {
              simpleValidator.current.hideMessages();
              forceUpdate(0);
            }
            let nextPageId = getValue(questionsInfo, "nextPageId", "")
              ? getValue(questionsInfo, "nextPageId", "")
              : "";
            await getSurvey(nextPageId);
          }
        }
      } else {
        setErrorEnabled(true);
        setRequiredErrors(Validation(questionsInfo, answer));
      }
    } catch (error) {}

    setScrollCount(scrollCount + 1);
  };

  const handleFinalSubmit = async () => {
    answer.forEach((a1) => {
      if (a1.questionType === "MATRIX_RADIO_BOX") {
        a1.response.forEach((a2) => {
          if (!a2.columnId) {
            a2.columnId = null;
          }
        });
      }
    });
    answer.forEach((a1) => {
      if (a1.questionType === "MATRIX_CHECK_BOX") {
        a1.response.forEach((a2) => {
          if (!a2.columnId) {
            a2.columnId = null;
          }
        });
      }
    });
    let pageNumber = parseFloat(getValue(questionsInfo, "pageNo", "") + 1);
    let no: any = sessionStorage.getItem("maxPageNo");

    if (!sessionStorage.getItem("maxPageNo") || parseFloat(no) < pageNumber) {
      sessionStorage.setItem("maxPageNo", pageNumber.toString());
    }
    try {
      if (Validation(questionsInfo, answer).length === 0) {
        setErrorEnabled(false);
        if (!getValue(questionsInfo, "userSurveyId", "")) {
          let req = {
            responses: answer,
          };
          let resp = await createSurveySubmission(
            getValue(props, "match.params.surveyUrl", ""),
            surveyToken,
            req
          );
          if (resp) {
            let response = await createFinalSubmission(
              getValue(props, "match.params.surveyUrl", ""),
              surveyToken
            );
            if (response) {
              toast.success("Question submitted successfully", {
                className: "green-circle",
              });
              if (getValue(questionsInfo, "settings.isLearning", false)) {
                history.push(
                  `/thankyou-assessment/${type}/${getValue(
                    response,
                    "data.data.surveyId",
                    "surveyId"
                  )}/${getValue(
                    response,
                    "data.data.userSurveyId",
                    "userSurveyId"
                  )}`
                );
              } else {
                if (getValue(questionsInfo, "type", "") === "ASSESSMENT") {
                  history.push(
                    `/thankyou-assessment/${type}/${getValue(
                      response,
                      "data.data.surveyId",
                      "surveyId"
                    )}/${getValue(
                      response,
                      "data.data.userSurveyId",
                      "userSurveyId"
                    )}`
                  );
                } else {
                  history.push(`/thankyou/${type}`);
                }
              }
            }
          }
        } else {
          let req = {
            responses: answer,
          };

          let resp = await createSurveySubmission(
            getValue(props, "match.params.surveyUrl", ""),
            surveyToken,
            req
          );
          if (resp) {
            sessionStorage.removeItem("maxPageNo");
            let response = await createFinalSubmission(
              getValue(props, "match.params.surveyUrl", ""),
              surveyToken
            );
            if (response) {
              toast.success("Question submitted successfully", {
                className: "green-circle",
              });
              if (getValue(questionsInfo, "settings.isLearning", false)) {
                history.push(
                  `/thankyou-assessment/${type}/${getValue(
                    response,
                    "data.data.surveyId",
                    "surveyId"
                  )}/${getValue(
                    response,
                    "data.data.userSurveyId",
                    "userSurveyId"
                  )}`
                );
              } else {
                if (getValue(questionsInfo, "type", "") === "ASSESSMENT") {
                  history.push(
                    `/thankyou-assessment/${type}/${getValue(
                      response,
                      "data.data.surveyId",
                      "surveyId"
                    )}/${getValue(
                      response,
                      "data.data.userSurveyId",
                      "userSurveyId"
                    )}`
                  );
                } else {
                  history.push(`/thankyou/${type}`);
                }
              }
            }
          }
        }
      } else {
        setErrorEnabled(true);
        handleBack1();
        setRequiredErrors(Validation(questionsInfo, answer));
      }
    } catch (error) {}
  };

  const handleBack = () => {
    setIsOpen(!isOpen);
  };
  const handleBack1 = () => {
    setIsOpen1(!isOpen1);
  };
  const handleBackSubmit = () => {
    if (type !== "public") {
      history.push("/home");
    } else {
      history.push(
        `/take-survey/${type}/${getValue(props, "match.params.surveyUrl", "")}`
      );
    }
  };
  const handleSaveAndContinue = async () => {
    try {
      // if (Validation(questionsInfo, answer).length === 0) {
      let req = {
        responses: answer,
      };
      answer.forEach((a1) => {
        if (a1.questionType === "MATRIX_RADIO_BOX") {
          a1.response.forEach((a2) => {
            if (!a2.columnId) {
              a2.columnId = null;
            }
          });
        }
      });
      answer.forEach((a1) => {
        if (a1.questionType === "MATRIX_CHECK_BOX") {
          a1.response.forEach((a2) => {
            if (!a2.columnId) {
              a2.columnId = null;
            }
          });
        }
      });
      let resp = await createSurveySubmission(
        getValue(props, "match.params.surveyUrl", ""),
        surveyToken,
        req
      );
      if (resp) {
        history.push("/home");
      }
      // } else {
      //   setRequiredErrors(Validation(questionsInfo, answer));
      // }
    } catch (error) {}
  };

  const [errorEnabled, setErrorEnabled] = useState(false);
  return (
    <React.Fragment>
      {pageStatus === "1" ? (
        <HRPolicy
          type={getValue(props, "match.params.type", "")}
          surveyUrl={getValue(props, "match.params.surveyUrl", "")}
          surveyLoading={surveyLoading}
          errorMessage={errorMessage}
          questionsInfo={questionsInfo}
          check={check}
          setChecked={setChecked}
          handleChangeStatus={handleChangeStatus}
        />
      ) : (
        <div className="page-layout page-layout-full">
          <div className="create-survey-tabs border-bottom-0">
            <div className="basic-tabs text-left pt-3 pb-5">
              <Row>
                <Col lg={{ size: 4, offset: 1 }}>
                  <h4 className="mb-0">
                    {getValue(questionsInfo, "survey360Name", "")
                      ? getValue(questionsInfo, "name", "") +
                        " " +
                        "for" +
                        " " +
                        getValue(questionsInfo, "survey360Name", "")
                      : getValue(questionsInfo, "name", "")}
                  </h4>
                  <hr className="my-2" />
                  <h6>
                    {/* 5 Sections  */}
                    <span className="dot-grey mx-1"></span>{" "}
                    {getValue(questionsInfo, `totalPages`, 0)} Page(s)
                    <span className="dot-grey mx-1"></span>{" "}
                    {getValue(questionsInfo, `totalQuestions`, 0)} Question(s)
                    {/* ({activeTab} page) */}
                  </h6>
                </Col>
                {type !== "public" && (
                  <Col lg="5" className="text-right">
                    {/* <h4 className="mb-0" style={{ opacity: '0.5' }}>
                  No time limit defined
                </h4> */}
                    <p>
                      <Button
                        color="primary"
                        className="btn"
                        onClick={handleBack}
                      >
                        Save and Continue later
                      </Button>
                    </p>
                  </Col>
                )}
              </Row>
            </div>
            {type !== "public" && (
              <div className="back-btn">
                {/* <NavLink to="/home"> */}
                <Button color="nothing" onClick={handleBack}>
                  <BackSVG />
                </Button>
                {/* </NavLink> */}
              </div>
            )}
          </div>
          <div className="page-height">
            <div className="add-survey-line">
              <div className="page-padding py-0">
                <div className="line-relative">
                  <ul className="line-relative-slick">
                    {Array.from(
                      { length: getValue(questionsInfo, "totalPages", []) },
                      (v, k) => k
                    ).map((k, i) => (
                      <li key={i}>
                        <Button
                          className={
                            i === getValue(questionsInfo, "pageNo", 0)
                              ? "active"
                              : ""
                          }
                          color="nothing"
                          onClick={() => handleChangePage(i + 1)}
                        >
                          {i + 1}
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <Button
                    color="nothing"
                    className="left-prev"
                    disabled={
                      !getValue(questionsInfo, "previousPageId", "")
                        ? true
                        : false
                    }
                    onClick={() =>
                      getSurvey(getValue(questionsInfo, "previousPageId", ""))
                    }
                  >
                    <img src="/images/slick-fonts/left.svg" alt="" />
                  </Button>
                  {getValue(questionsInfo, "nextPageId", "") ? (
                    <Button
                      color="nothing"
                      className="right-next"
                      disabled={
                        activeTab === questionsInfo.length ? true : false
                      }
                      onClick={handleTakeSurveySubmit}
                    >
                      <img src="/images/slick-fonts/right.svg" alt="" />
                    </Button>
                  ) : (
                    <Button
                      color="nothing"
                      className="right-next"
                      onClick={handleIsOpen1}
                    >
                      <img src="/images/slick-fonts/right.svg" alt="" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="page-padding pt-5">
              <Row>
                <Col lg={{ size: 10, offset: 1 }}>
                  <h5 className="mb-1 text-green-light text-center">
                    {getValue(questionsInfo, "page.title", "") === "Untitled"
                      ? ""
                      : getValue(questionsInfo, "page.title", "")}
                  </h5>
                  <p className="text-center">
                    {getValue(questionsInfo, "page.description", "") ===
                    "Untitled"
                      ? ""
                      : getValue(questionsInfo, "page.description", "")}
                  </p>
                  {
                    getValue(questionsInfo, `page.questions`, []).map(
                      (qus, index) => {
                        return (
                          <>
                            <h3 style={{ color: "grey",textAlign:'left',fontSize:'17px' }}>
                              {/* Question: */}
                              Question
                              {getValue(qus, "mandatoryEnabled", "") ? (
                                <span className="text-red">*</span>
                              ) : (
                                ""
                              )}
                              {getValue(qus, "hintEnabled", "") ? (
                                <>
                                  <span
                                    className="tooltip-icon"
                                    id={"UncontrolledTooltipExample" + qus.id}
                                  >
                                    &nbsp;
                                    <InfoSVG />
                                  </span>
                                  <UncontrolledTooltip
                                    placement="right"
                                    target={
                                      "UncontrolledTooltipExample" + qus.id
                                    }
                                  >
                                    {getValue(qus, "hintMsg", "")}
                                  </UncontrolledTooltip>
                                </>
                              ) : (
                                ""
                              )}
                            </h3>
                            <h3 className="mb-1">
                              {getValue(qus, "text", "")}
                              {/* <p
                              // className="abtcnt"
                              style={{ whiteSpace: 'pre-line' }}
                              dangerouslySetInnerHTML={{
                                __html: getValue(qus, 'text', ''),
                              }}
                            /> */}
                            </h3>
                            <div className="ml-3">
                              <RenderTakeSurvey
                                param={qus.type}
                                simpleValidator={simpleValidator}
                                options={getValue(qus, "options", [])}
                                questions={qus}
                                removeImageTakeSurveyFile={
                                  removeImageTakeSurveyFile
                                }
                                isLearning={getValue(
                                  questionsInfo,
                                  `settings.isLearning`,
                                  false
                                )}
                                handleUserSurveyResponseDropdown={
                                  handleUserSurveyResponseDropdown
                                }
                                handleUserSurveyResponseDropdownArray={
                                  handleUserSurveyResponseDropdownArray
                                }
                                handleUserSurveyResponseDropdownGrid={
                                  handleUserSurveyResponseDropdownGrid
                                }
                                handleChangeTakeSurveyInput={
                                  handleChangeTakeSurveyInput
                                }
                                handleChangeTakeSurveyFile={
                                  handleChangeTakeSurveyFile
                                }
                                answer={answer}
                                handleChangeTakeSurveyNumber={
                                  handleChangeTakeSurveyNumber
                                }
                                required={getValue(qus, "mandatoryEnabled", "")}
                                handleChangeTakeSurveyAdvancedOptionInput={
                                  handleChangeTakeSurveyAdvancedOptionInput
                                }
                                requiredErrors={requiredErrors}
                              />
                            </div>
                            <hr className="my-4" />
                          </>
                        );
                      }
                    )
                    //  : 'Please Add Questions'
                  }
                  <div className="my-4 btn-group-margin">
                    <Button
                      color="border"
                      disabled={
                        !getValue(questionsInfo, "previousPageId", "")
                          ? true
                          : false
                      }
                      className="pl-1 py-1"
                      onClick={() =>
                        getSurvey(getValue(questionsInfo, "previousPageId", ""))
                      }
                    >
                      <ArrowLeftSVG /> <span className="pl-2">Previous</span>
                    </Button>
                    {getValue(questionsInfo, "nextPageId", "") ? (
                      <Button
                        color="border"
                        className="pl-1 py-1"
                        disabled={
                          activeTab === questionsInfo.length ? true : false
                        }
                        onClick={handleTakeSurveySubmit}
                      >
                        <ArrowRightSVG /> <span className="pl-2">Next</span>
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        className="pl-1 py-1"
                        onClick={handleIsOpen1}
                        disabled={
                          (Validation(questionsInfo, answer).length !== 0 &&
                            errorEnabled) ||
                          Validation(questionsInfo, answer).length !== 0
                            ? true
                            : false
                        }
                      >
                        <span className="pl-2">Submit</span>
                      </Button>
                    )}
                    {(Validation(questionsInfo, answer).length !== 0 ||
                      errorEnabled) && (
                      <p className="form-error">
                        Certain details provided above are required; kindly
                        verify.
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
      {/* <Footer /> */}
      <Modal isOpen={isOpen} centered>
        <ModalBody>
          <h3 style={{ color: "#D7242E", textAlign: "center" }}>
            Are you sure?
          </h3>
          <h5 style={{ color: "#3C4043", textAlign: "center" }}>
            This action will take you back to the home page
          </h5>
          <br></br>
          <div className="btn-group-margin text-md-right">
            {type !== "public" ? (
              <>
                <Button color="blue" block onClick={handleSaveAndContinue}>
                  Save and Continue later
                </Button>

                <Button color="border" block onClick={handleBackSubmit}>
                  Go to Homepage
                </Button>
                <Button color="border" block onClick={() => handleBack()}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button color="border" block onClick={handleBackSubmit}>
                  Yes
                </Button>
                <Button color="border" block onClick={() => handleBack()}>
                  No
                </Button>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isOpen1} centered>
        <ModalBody>
          <h3 style={{ color: "#D7242E", textAlign: "center" }}>
            Are you sure?
          </h3>
          <h5 style={{ color: "#3C4043", textAlign: "center" }}>
            This action will end your Survey
          </h5>
          <div className="btn-group-margin text-center mt-4">
            <Button color="border" onClick={() => handleBack1()}>
              No
            </Button>
            <Button color="blue" onClick={handleFinalSubmit}>
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <RouteLeavingGuard
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        when={isDirty}
        // Navigate function
        navigate={(path) => history.push(path)}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={(location) => {
          // This case it blocks the navigation when:
          // 1. the login form is dirty, and
          // 2. the user is going to 'sign-up' scene.
          //    (Just an example, in real case you might
          //     need to block all location regarding this case)
          if (isDirty) {
            if (location.pathname === "signup") {
              return true;
            }
          }
          return false;
        }}
      />
    </React.Fragment>
  );
};

export default TakeSurveyComponent;
