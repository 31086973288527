import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Label,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import LeftNav from '../../components/menu/left-nav';
import MoreSVG from '../../components/svg/more-svg';
import {
  getDraftSurveys,
  deleteSurvey,
} from '../../core/services/surveyService';
import { getValue } from '../../core/lodash';
import Moment from 'react-moment';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import DeleteSurveyModal from '../../components/common/modal/deleteModal';
import { NavLink } from 'react-router-dom';
import SearchSVG from '../../components/svg/search-svg';
import CloseSVG from '../../components/svg/close-svg';
import Cookies from 'js-cookie';
import LoaderCommon from '../../components/common/loader-common';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';

interface ITemplateMainProps {}

const OpenSurveyMain: React.FunctionComponent<ITemplateMainProps> = (props) => {
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [search, openSearch] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [openSurvey, setOPenSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);

  // delete
  const [isOpen, setIsOPen] = useState(false);
  const [removeValue, setRemoveValue] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [sort, setSort] = useState('newest');

  const handleModal = () => {
    setIsOPen(!isOpen);
  };

  const handleDeleteSurvey = async (id, value) => {
    setDeleteId(id);
    setRemoveValue(value);
    handleModal();
  };
  // =============================================================================
  // API section
  // =============================================================================
  useEffect(() => {
    getOpenSurveyFun(skip);
    Cookies.remove('progressSurvey');
    // Cookies.remove('template');
    // Cookies.remove('templateId');
    // Cookies.remove('templateEdit');
  }, []);

  const getOpenSurveyFun = async (skip) => {
    try {
      setIsLoading(true);
      let resp = await getDraftSurveys(skip, limit, '', sort);
      if (resp) {
        setIsLoading(false);
        setOPenSurveys(getValue(resp, 'data.surveys', []));
        setTotalCount(getValue(resp, 'data.count', []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [loading, setLoading] = useState(false);
  const handleDeleteFun = async () => {
    try {
      setLoading(true);
      let resp = await deleteSurvey(deleteId);
      if (resp) {
        setLoading(false);
        toast.success('Deleted successfully', { className: 'green-circle' });
        handleModal();
        getOpenSurveyFun(skip);
      } else {
        setLoading(false);
        getOpenSurveyFun(skip);
        handleModal();
      }
    } catch (error) {
      handleModal();
      setLoading(false);
    }
  };

  // ===========================================================================
  // handle change section
  // ===========================================================================
  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  const onProductPageChangeHandler = (pageNumber) => {
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    getOpenSurveyFun(skip);
  };

  // ===========================================================================
  // search section
  // ===========================================================================

  const [searchInput, setSearchInput] = useState('');

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    globalSearch(value);
  };

  const toggleSearch = () => {
    openSearch(!search);
    setSearchInput('');
    globalSearch('');
  };

  const globalSearch = async (value) => {
    try {
      let response = await getDraftSurveys(skip, limit, value, sort);
      if (response) {
        setOPenSurveys(getValue(response, 'data.surveys', []));
        setTotalCount(getValue(response, 'data.count', []));
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const history = useHistory();
  // const handleRedirectCreateSurvey = () => {
  //   history.push('/create-new-survey')
  // }
  return (
    <div>
      <div className={'page-layout ' + (isLeftNav ? 'active' : '')}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="create-survey-tabs create-survey-tabs-search">
            <Nav pills className="basic-tabs pl-0">
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Drafts
                </NavLink1>
              </NavItem>
              {/* <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Scheduled Surveys
                </NavLink1>
              </NavItem> */}
            </Nav>
            <div className="hover-circle">
              <Button color="nothing" onClick={toggleSearch}>
                <SearchSVG />
              </Button>
              <div className={'table-search ' + (search ? 'show' : '')}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={searchInput}
                  onChange={handleChangeSearch}
                />
                <Button
                  color="nothing"
                  onClick={toggleSearch}
                  className="table-search-close"
                >
                  <CloseSVG />
                </Button>
              </div>
            </div>
          </div>
          <div className="page-padding pt-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {!isLoading ? (
                  openSurvey.length !== 0 ? (
                    <Table className="table-custom">
                      <thead>
                        <tr>
                          <th>Survey Name</th>
                          <th>Survey Type</th>
                          <th>Status</th>
                          {/* <th>Questions</th> */}
                          {/* <th>Audience</th> */}
                          <th
                            style={{ width: '50' }}
                            className="text-right"
                          ></th>
                        </tr>
                      </thead>
                      {openSurvey.map((survey:any, key) => {
                        let date = moment(survey.createdAt)
                          // .tz('Asia/Kolkata')
                          .format('DD MMM YYYY hh:mm a');
                        return (
                          <tbody key={key}>
                            <tr>
                              <td>
                                <NavLink
                                  to={`/create-new-survey-form/${survey.type}/${survey.id}`}
                                >
                                  <h5 style={{ color: '#007bff' }}>
                                    {survey.name}
                                  </h5>
                                  <p>Created date: {date}</p>
                                </NavLink>
                              </td>
                              <td>
                                {survey.type
                                  ? survey.type
                                      .split(' ')
                                      .map(
                                        (w) =>
                                          w[0].toUpperCase() +
                                          w.substr(1).toLowerCase()
                                      )
                                      .join(' ')
                                  : ''}
                              </td>
                              <td>
                                <h5 className="text-red">{survey.status}</h5>
                                <p>
                                  Modified date:{' '}
                                  <Moment format="DD MMM YYYY">
                                    {survey.updatedAt}
                                  </Moment>
                                </p>
                              </td>
                              {/* <td>
                                <h5>{survey.totalQuestions}</h5>
                              </td> */}
                              {/* <td>
                                <h5>None Assigned</h5>
                              </td> */}
                              <td className="table-more">
                                <UncontrolledDropdown>
                                  <DropdownToggle color="nothing">
                                    <MoreSVG />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="custom-dropdown3"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        handleDeleteSurvey(
                                          survey.id,
                                          survey.name
                                        )
                                      }
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </Table>
                  ) : (
                    <div className="text-center mt-5">
                      <div className="mt-4 mb-2">
                        <img src="/images/empty.PNG" alt="" />
                      </div>
                      <h4 className="mb-2">No Survey Found</h4>
                      {/* <Button color="blue" onClick={handleRedirectCreateSurvey}>New Survey</Button> */}
                    </div>
                  )
                ) : (
                  <div className="text-center my-3">
                    <LoaderCommon />
                  </div>
                )}
                {totalCount > 10 && (
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={onProductPageChangeHandler}
                    prevPageText="Prev"
                    nextPageText="Next"
                    hideFirstLastPages={true}
                  />
                )}
              </TabPane>
              <TabPane tabId="2">Lorem ipsum</TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <DeleteSurveyModal
        isOpen={isOpen}
        handleDeleteFun={handleDeleteFun}
        removeValue={removeValue}
        handleModal={handleModal}
      />
    </div>
  );
};

export default OpenSurveyMain;
