import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getValue } from '../../core/lodash';
import { getPermission } from '../../core/services/surveyService';
import AddGradientSVG from '../svg/add-gradient-svg';
import CompletedSVG from '../svg/completed-svg';
import ImportantSVG from '../svg/important-svg';
import InProgressSurveySVG from '../svg/inprogress-survey-svg';
import OpenSurveySVG from '../svg/open-survey-svg';
import UserSvgComponent from '../svg/user-svg';
import TemplateSVG from '../svg/template-svg';
import LocationSvgComponent from '../svg/location-svg';
import { useGlobalContext } from '../../context/global-context';
import DepartmentSvgComponent from '../svg/department-svg';
const LeftNavLinks = () => {
  // const [permissions, setPermissions] = useState<any>([]);

  useEffect(() => {
    // if(!sessionStorage.getItem('permissions')){
    // getPermissions()
    // }
  }, []);

  // const getPermissions = async () => {
  //   if (getValue(localStorage, 'access_token', '')) {
  //     let resp = await getPermission();
  //     if (resp) {
  //       sessionStorage.setItem('permissions', getValue(resp, 'data.permissions', []));
  //       setPermissions(getValue(resp, 'data.permissions', []));
  //     }
  //   }
  // }

  const { permissions } = useGlobalContext();
  return (
    <>
      {/* {sessionStorage.getItem('permissions') !==null && sessionStorage.getItem('permissions').split(',').includes("create-new-survey") && */}
      {permissions.includes('create-new-survey') && (
        <div className="left-menu-top">
          <NavLink to="/create-new-survey">
            <Button color="border">
              <AddGradientSVG /> New Survey
            </Button>
          </NavLink>
        </div>
      )}
      {/* } */}
      <ul className="menu-ul">
        {/* {sessionStorage.getItem('permissions') !==null && sessionStorage.getItem('permissions').split(',').includes("home") && */}
        {permissions.includes('home') && (
          <li>
            <NavLink to="/home" title="Take a survey">
              <span className="menu-icon">
                <ImportantSVG />
              </span>
              <span className="menu-text">Take a survey</span>
            </NavLink>
          </li>
        )}
        {permissions.includes('templates') && (
          <li>
            {/* {sessionStorage.getItem('permissions')  !==null && sessionStorage.getItem('permissions').split(',').includes("templates") && */}
            <NavLink to="/templates" title="Templates">
              <span className="menu-icon">
                <TemplateSVG />
              </span>
              <span className="menu-text">Templates</span>
            </NavLink>
          </li>
        )}
        {permissions.includes('open-surveys') && (
          <li>
            {/* {sessionStorage.getItem('permissions') !==null && sessionStorage.getItem('permissions').split(',').includes("open-surveys") && */}
            <NavLink to="/open-surveys" title="Open Surveys">
              <span className="menu-icon">
                <OpenSurveySVG />
              </span>
              <span className="menu-text">Draft Surveys</span>
            </NavLink>
          </li>
        )}
        {permissions.includes('inprogress-surveys') && (
          <li>
            {/* {sessionStorage.getItem('permissions')  !==null && sessionStorage.getItem('permissions').split(',').includes("inprogress-surveys") && */}
            <NavLink to="/inprogress-surveys" title="In Progress Surveys">
              <span className="menu-icon">
                <InProgressSurveySVG />
              </span>
              <span className="menu-text">In Progress Surveys</span>
            </NavLink>
          </li>
        )}
        {/* {sessionStorage.getItem('permissions')  !==null && sessionStorage.getItem('permissions').split(',').includes("completed-surveys") && */}
        {permissions.includes('completed-surveys') && (
          <li>
            <NavLink to="/completed-surveys" title="Completed Surveys">
              <span className="menu-icon">
                <CompletedSVG />
              </span>
              <span className="menu-text">Completed Surveys</span>
            </NavLink>
          </li>
        )}
        {permissions.includes('users') && (
          <li>
            <NavLink to="/users" title="Completed Surveys">
              <span className="menu-icon">
                <UserSvgComponent />
              </span>
              <span className="menu-text">Users</span>
            </NavLink>
          </li>
        )}
        {permissions.includes('departments') && (
          <li>
            <NavLink to="/departments" title="Completed Surveys">
              <span className="menu-icon">
                <DepartmentSvgComponent />
              </span>
              <span className="menu-text">Departments</span>
            </NavLink>
          </li>
        )}

        {permissions.includes('locations') && (
          <li>
            <NavLink to="/locations" title="Completed Surveys">
              <span className="menu-icon">
                <LocationSvgComponent />
              </span>
              <span className="menu-text">Locations</span>
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );
};
export default LeftNavLinks;
