import * as React from 'react';
import { Label, Input, Row, Col } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface ILongAnswerProps {
  options: any;
  questions: any;
  handleUserSurveyResponseDropdown: (value: object) => void;
  answer: any[];
  handleChangeTakeSurveyInput: (e: any, questionId: string) => void;
  // validation
  required: boolean;
  requiredErrors: any;
}

const LongAnswer: React.FunctionComponent<ILongAnswerProps> = (props) => {
  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === props.questions.id
  );
  return (
    <div className="form-group">
      <Input
        type="textarea"
        rows={6}
        placeholder="Enter Text"
        value={getValue(currentValue[0], 'response', '')}
        onChange={(e) => {
          props.handleChangeTakeSurveyInput(e, props.questions.id);
        }}
      ></Input>
      <div className="form-error" style={{ color: 'red' }}>
        {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
          ?getValue(currentValue[0], 'response.length', '') === 0? props.questions.mandatoryMsg:`characters range should be minimum ${getValue(props.questions,'validations.minLength','')} and maximum ${getValue(props.questions,'validations.maxLength','')}`
          : ''}
      </div>
    </div>
  );
};

export default LongAnswer;
