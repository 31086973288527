import * as React from 'react';
import { Label, Input, Col, Row } from 'reactstrap';
import { getValue } from '../../core/lodash';
import PreviewAdvancedOptions from './previewAdvancedOptions';

interface IDateProps {
  questions: any;
  handleUserSurveyResponseDropdown: (value: object) => void;
  answer: any[];
  handleChangeTakeSurveyInput: (e: any, questionId: string) => void;
  // validation
  required: boolean;
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string
  ) => void;
  requiredErrors: any;
}

const Date: React.FunctionComponent<IDateProps> = (props) => {
  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === props.questions.id
  );
  return (
    <>
      <Row>
        <Col md="6">
          <div className="form-group">
            <Input
              type="date"
              onChange={(e) => {
                props.handleChangeTakeSurveyInput(e, props.questions.id);
              }}
              value={getValue(currentValue[0], 'response', '')}
            ></Input>
          </div>
        </Col>
      </Row>
      <PreviewAdvancedOptions
        questions={props.questions}
        answer={props.answer}
        handleChangeTakeSurveyAdvancedOptionInput={
          props.handleChangeTakeSurveyAdvancedOptionInput
        }
      />
      <div className="form-error" style={{ color: 'red' }}>
        {getValue(props, 'requiredErrors', []).includes(
          getValue(props, 'questions.id', '')
        )
          ? props.questions.mandatoryMsg
          : ''}
      </div>
    </>
  );
};

export default Date;
