import * as React from 'react';
import { Label, Input, Row, Col, Button } from 'reactstrap';
import SmileySVG from '../../components/svg/smiley-svg';
import { getValue } from '../../core/lodash';
interface IRatingScaleProps {
  questions: any;
}

const RatingScale: React.FunctionComponent<IRatingScaleProps> = (props) => {
  // number
  const [inputs] = React.useState(
    Array(
      parseFloat(getValue(props.questions, 'validations.scaleTo', 0))
    ).fill({ value: 'number' })
  );
  // smiley
  const [simley] = React.useState(
    Array(
      parseFloat(getValue(props.questions, 'validations.scaleTo', 0))
    ).fill({ value: 'number' })
  );
  return (
    <>
      {getValue(props.questions, 'validations.type', '') === 'number' ? (
        <>
          {inputs &&
            inputs.map((_item, i) => {
              return (
                <Button
                  color="nothing"
                  className={`circle-rating ${
                    i + 1 <= getValue(props, 'questions.response[0]', 0)
                      ? 'active'
                      : ''
                  }`}
                  // onClick={() => {
                  //   props.handleChangeTakeSurveyNumber(
                  //     i + 1,
                  //     props.questions.id
                  //   );
                  // }}
                >
                  <p>
                    {i === 0 ? (
                      <>
                        {getValue(
                          props.questions,
                          'validations.startLabel',
                          ''
                        )}
                      </>
                    ) : (
                      <>&nbsp;</>
                    )}
                    {i ===
                      parseFloat(
                        getValue(props.questions, 'validations.scaleTo', '')
                      ) -
                        1 && i !== 0 ? (
                      <>
                        {getValue(props.questions, 'validations.endLabel', '')}
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                  <span>{i + 1}</span>
                </Button>
              );
            })}
          {/* <Button color="nothing" className="circle-rating">
          <p>Not Applicable</p>
          <span>&nbsp;</span>
        </Button> */}
        </>
      ) : (
        <>
          {simley &&
            simley.map((_item, i) => {
              return (
                <Button
                  color="nothing"
                  className={`circle-rating ${
                    i + 1 <= getValue(props, 'questions.response[0]', 0)
                      ? 'active'
                      : ''
                  }`}
                  // onClick={() => {
                  //   props.handleChangeTakeSurveyNumber(
                  //     i + 1,
                  //     props.questions.id
                  //   );
                  // }}
                >
                  <p>
                    {i === 0 ? (
                      <>
                        {getValue(
                          props.questions,
                          'validations.startLabel',
                          ''
                        )}
                      </>
                    ) : (
                      <>&nbsp;</>
                    )}
                    {i ===
                      parseFloat(
                        getValue(props.questions, 'validations.scaleTo', '')
                      ) -
                        1 && i !== 0 ? (
                      <>
                        {getValue(props.questions, 'validations.endLabel', '')}
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                  <SmileySVG />
                </Button>
              );
            })}
        </>
      )}
    </>
  );
};

export default RatingScale;
