import * as React from 'react';
import { Input, Label } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface IPreviewAdvancedOptionsProps {
  questions: object;
  answer: any;
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string,
    comment: string,
    other: string
  ) => void;
}

const PreviewAdvancedOptions: React.FunctionComponent<IPreviewAdvancedOptionsProps> = (
  props
) => {
  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === getValue(props.questions, 'id', '')
  );
  return (
    <div>
      {getValue(props, 'questions.commentEnabled', '') ? (
        <div className="form-group">
          <Label>{getValue(props, 'questions.commentMsg', '')}</Label>
          <Input
            type="textarea"
            rows="3"
            placeholder="Enter Text here"
            value={getValue(currentValue[0], 'commentOption', '')}
            name="commentOption"
            onChange={(e) =>
              props.handleChangeTakeSurveyAdvancedOptionInput(
                e,
                getValue(props.questions, 'id', ''),
                'commentOption',
                getValue(currentValue[0], 'commentOption', ''),
                getValue(currentValue[0], 'otherOption', '')
              )
            }
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PreviewAdvancedOptions;
