import React from 'react';

const Smiley10SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.996"
      height="35.996"
      viewBox="0 0 35.996 35.996"
    >
      <defs>
        <style></style>
      </defs>
      <g transform="translate(0.783 0.783)">
        <path
          className="a"
          style={{ fill: '#feca2e' }}
          d="M25.216,8A17.216,17.216,0,1,1,8,25.216,17.216,17.216,0,0,1,25.216,8Zm7.825,20.346H17.39a7.825,7.825,0,1,0,15.65,0Zm0-7.825a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,33.041,20.52Zm-12.52,0a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,20.52,20.52Z"
          transform="translate(-8 -8)"
        />
        <circle
          className="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(22.216 11.215)"
        />
        <circle
          className="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(9.216 11.215)"
        />
      </g>
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M226.348,116.7A2.348,2.348,0,1,0,224,114.348,2.35,2.35,0,0,0,226.348,116.7Zm0-3.13a.783.783,0,1,1-.783.783A.783.783,0,0,1,226.348,113.565Z"
        transform="translate(-202.089 -101.045)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M98.348,116.7A2.348,2.348,0,1,0,96,114.348,2.35,2.35,0,0,0,98.348,116.7Zm0-3.13a.783.783,0,1,1-.783.783A.783.783,0,0,1,98.348,113.565Z"
        transform="translate(-86.61 -101.045)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M18,36A18,18,0,1,0,0,18,18.018,18.018,0,0,0,18,36ZM18,1.565A16.433,16.433,0,1,1,1.565,18,16.452,16.452,0,0,1,18,1.565Z"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M104.608,217.39a8.618,8.618,0,0,0,8.608-8.608.782.782,0,0,0-.783-.783H96.783a.782.782,0,0,0-.783.783A8.618,8.618,0,0,0,104.608,217.39Zm7-7.825a7.042,7.042,0,0,1-14,0Z"
        transform="translate(-86.61 -187.654)"
      />
    </svg>
  </>
);
export default Smiley10SVG;
