import * as React from 'react';
import { Input } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface ILongAnswerProps {
  questions: any;
}

const LongAnswer: React.FunctionComponent<ILongAnswerProps> = (props) => {
  return (
    <div className="form-group">
      <Input
        type="text"
        placeholder="Enter Text"
        value={getValue(props, 'questions.response[0]', '')}
        disabled
      ></Input>
    </div>
  );
};

export default LongAnswer;
