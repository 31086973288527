import React, { FunctionComponent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import BackSVG from '../components/svg/back-svg';
import ArrowLeftSVG from '../components/svg/arrow-left-svg';
import ArrowRightSVG from '../components/svg/arrow-right-svg';
import { getValue } from '../core/lodash';
import { getSurveyQuestions } from '../core/services/surveyService';
import RenderAnswerPreview from '../components/common/RenderAnswerPreview';

interface Props {}

const Survey: FunctionComponent<Props> = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [surveyId] = useState(getValue(props, 'match.params.surveyId', ''));
  const [type] = useState(getValue(props, 'match.params.type', ''));

  const [questionsInfo, setQuestionsInfo] = useState([]);
  const [infoData, setInfoData] = useState([]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getSurveyQuestion();
  }, []);
  const getSurveyQuestion = async () => {
    let resp = await getSurveyQuestions(surveyId);
    if (resp) {
      setInfoData(getValue(resp, 'data', ''));
      setQuestionsInfo(getValue(resp, 'data.pages', ''));
    }
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
    draggable: false,
  };
  const handleNavigate = (index) => {
    handleChangePage(index);
  };
  const handleChangePage = (index) => {
    togglePage(index);
    // slickGoTo(e.target.value);
  };

  const togglePage = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <div className="page-layout page-layout-full">
        <div className="create-survey-tabs border-bottom-0">
          <div className="basic-tabs text-left pt-3 pb-5">
            <Row>
              <Col lg={{ size: 4, offset: 1 }}>
                <h4 className="mb-0">{getValue(infoData, 'name', '')}</h4>
                <hr className="my-2" />
                <h6>
                  {getValue(infoData, 'pages.length', '')} Sections{' '}
                  <span className="dot-grey mx-1"></span>
                  {/* 130 Questions */}
                </h6>
              </Col>
              {/* <Col lg="5" className="text-right">
                <h4 className="mb-0" style={{ opacity: '0.5' }}>
                  No time limit defined
                </h4>
                <p>
                  <Button color="nothing" className="text-blue">
                    Save and Continue later
                  </Button>
                </p>
              </Col> */}
            </Row>
          </div>
          <div className="back-btn">
            <NavLink to={`/create-new-survey-form/${type}/${surveyId}`}>
              <Button color="nothing">
                <BackSVG />
              </Button>
            </NavLink>
          </div>
        </div>
        <div className="page-height">
          <div className="add-survey-line">
            <div className="page-padding py-0">
              <div className="line-relative">
                <ul className="line-relative-slick">
                  {questionsInfo.map((_item, index) => (
                    <li key={index}>
                      <Button
                        className={index === activeTab - 1 ? 'active' : ''}
                        color="nothing"
                        onClick={() => handleChangePage(index + 1)}
                      >
                        {index + 1}
                      </Button>
                    </li>
                  ))}
                </ul>
                <Button
                  color="nothing"
                  className="left-prev"
                  disabled={activeTab - 1 === 0 ? true : false}
                  onClick={() => {
                    handleNavigate(activeTab - 1);
                  }}
                >
                  <img src="/images/slick-fonts/left.svg" alt="" />
                </Button>
                <Button
                  color="nothing"
                  className="right-next"
                  disabled={
                    activeTab - 1 === questionsInfo.length - 1 ? true : false
                  }
                  onClick={() => {
                    handleNavigate(activeTab + 1);
                  }}
                >
                  <img src="/images/slick-fonts/right.svg" alt="" />
                </Button>
              </div>
            </div>
          </div>
          <div className="page-padding pt-5">
            <Row>
              <Col lg={{ size: 10, offset: 1 }}>
                <h5 className="mb-1 text-green-light text-center">
                  {getValue(questionsInfo[activeTab], 'title', '') ===
                  'Untitled'
                    ? ''
                    : getValue(questionsInfo[activeTab], 'title', '')}
                </h5>
                <p className="text-center">
                  {getValue(questionsInfo[activeTab], 'description', '') ===
                  'Untitled'
                    ? ''
                    : getValue(questionsInfo[activeTab], 'description', '')}
                </p>
                {getValue(questionsInfo[0], 'questions.length', 0) !== 0
                  ? getValue(questionsInfo[activeTab - 1], 'questions', []).map(
                      (qus, index) => {
                        return (
                          <>
                            <h4 style={{color:"gray"}}>
                              {/* {index + 1} . */}
                              Question {" "}
                               {/* {index + 1} */}
                               
                              <span className="text-red">*</span>
                            </h4>
                            <h3 className="mb-1">
                              {/* Which color of visible light travels the fastest in the crown
                           glass? */}
                           { getValue(qus, 'text', '')}
                              {/* <p
                                className="abtcnt"
                                // style={{ whiteSpace: 'pre-line' }}
                                dangerouslySetInnerHTML={{
                                  __html: getValue(qus, 'text', ''),
                                }}
                              /> */}
                            </h3>
                            <div className="ml-3">
                              <RenderAnswerPreview
                                param={qus.type}
                                options={getValue(qus, 'options', [])}
                                questions={qus}
                              />
                            </div>
                          </>
                        );
                      }
                    )
                  : 'Please Add Questions'}
                <div className="my-4 btn-group-margin">
                  <Button
                    color="border"
                    disabled={activeTab === 1 ? true : false}
                    className="pl-1 py-1"
                    onClick={() => toggle(activeTab - 1)}
                  >
                    <ArrowLeftSVG /> <span className="pl-2">Previous</span>
                  </Button>
                  <Button
                    color="border"
                    className="pl-1 py-1"
                    disabled={activeTab === questionsInfo.length ? true : false}
                    onClick={() => toggle(activeTab + 1)}
                  >
                    <ArrowRightSVG /> <span className="pl-2">Next</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default Survey;
