import * as React from 'react';
import {
  questionBankSearch,
  getSpecificQuestion,
} from '../../core/services/surveyService';
import QuestionBankHome from './question-bank-home';
import { getValue } from '../../core/lodash';

interface IQuestionBankMainProps {}

const QuestionBankMain: React.FunctionComponent<IQuestionBankMainProps> = (
  props
) => {
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [questionsList, setQuestionsList] = React.useState<any>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [skip, setSkip] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  // =============================================================================
  // API section
  // =============================================================================
  React.useEffect(() => {
    getQuestionBank(skip);
  }, []);
  const getQuestionBank = async (skip) => {
    let array:any = [];
    try {
      setIsLoading(true);
      let resp = await questionBankSearch(skip, limit, '');
      if (resp) {
        getValue(resp, 'data.questions', []).map(async (surveyQus) => {
          let specificQus = await getSpecificQuestion(
            surveyQus.surveyId,
            surveyQus.surveyPageId,
            surveyQus.id
          );
          array.push(getValue(specificQus, 'data.questionData', {}));
          setQuestionsList([...array]);
        });
        setTotalCount(getValue(resp, 'data.count', []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // ===========================================================================
  // Header and left sidebar section
  // ===========================================================================

  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  // ===========================================================================
  // Pagination
  // ===========================================================================
  const onProductPageChangeHandler = async (pageNumber) => {
    let array = [];
    setQuestionsList([...array]);
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    getQuestionBank(skip);
  };
  //console.log(questionsList);
  return (
    <React.Fragment>
      <QuestionBankHome
        onProductPageChangeHandler={onProductPageChangeHandler}
        questionsList={questionsList}
        totalCount={totalCount}
        pageNumber={pageNumber}
        limit={limit}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default QuestionBankMain;
