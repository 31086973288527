import * as React from 'react';
import { Input, Label } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface ICommentOtherOptionProps {
  questions: any;
}

const CommentOtherOption: React.FunctionComponent<ICommentOtherOptionProps> = (
  props
) => {
  return (
    <div>
      <br></br>
      {getValue(props, 'questions.otherOptionEnabled', '') ? (
        <>
          <div className="mb-2">
            <Label className="radio-container d-inline-block">
              {/* Other (Please specify) */}
              {getValue(props, 'questions.otherOptionMsg', '')}
              <input
                type={'radio'}
                checked={
                  getValue(props, 'questions.response', []).includes('-1')
                    ? true
                    : false
                }
              />
              <span className="checkmark"></span>
            </Label>
          </div>
          {getValue(props, `questions.response[${0}]`, '') === '-1' ? (
            <div className="pl-2 ml-4">
              <div className="form-group">
                <Input
                  type="text"
                  placeholder="Enter Text here"
                  value={getValue(props, 'questions.otherOption', '')}
                  name="otherOption"
                  disabled
                />
              </div>
            </div>
          ) : (
            <div className="pl-2 ml-4">
              <div className="form-group">
                <Input
                  type="text"
                  placeholder="Enter Text here"
                  value={getValue(
                    props,
                    'questions.otherOption.response[0]',
                    ''
                  )}
                  name="otherOption"
                  disabled
                />
              </div>
            </div>
          )}
        </>
      ) : (
        ''
      )}
      {getValue(props, 'questions.commentEnabled', '') ? (
        <div className="form-group">
          <Label>{getValue(props, 'questions.commentMsg', '')}</Label>
          <Input
            type="textarea"
            rows="3"
            placeholder="Enter Text here"
            value={getValue(props, 'questions.commentOption', '')}
            name="commentOption"
            disabled
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CommentOtherOption;
