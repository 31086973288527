import React from "react";

export const LoginSlider = () => {
  return (
    <div className="login-wrapper flex-1">
      <div>
        <img src="/images/login-home1.png" className="img-fluid" />
        <h4 className="header_text_login">Survey now is as easy as creating a document</h4>
        <p>
          Select from multiple question types, drag-and-drop to reorder
          questions and customise values as easily as pasting a list.
        </p>
      </div>
    </div>
  );
};
