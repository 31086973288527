import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { saveAs } from 'file-saver';
import MoreSVG from '../../components/svg/more-svg';
import LeftNav from '../../components/menu/left-nav';
import {
  deleteSurvey,
  downloadDetailReport,
  downloadFeedbackReport,
  downloadSurveyReport,
  get360SummaryEmployees,
  get360SummarySurvey,
  getCompletedSurveys,
  getSummarySurvey,
  getSummarySurveyPagination,
} from '../../core/services/surveyService';
import { getValue } from '../../core/lodash';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import DeleteSurveyModal from '../../components/common/modal/deleteModal';
import { NavLink } from 'react-router-dom';
import BackSVG from '../../components/svg/back-svg';
import SummaryPreview from './summaryPreview';
import Pagination from 'react-js-pagination';
import { ToastHandler } from '../../common/toast';

interface ITemplateMainProps {}

const Summary360: React.FunctionComponent<ITemplateMainProps> = (props) => {
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [search, openSearch] = useState(false);
  const [activeTab, setActiveTab] = useState<any>('2');
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [surveyInfo, setSurveyInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // summary
  const [summaryInfo, setSummaryInfo] = useState([]);
  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState('newest');

  // delete
  const [isOpen, setIsOPen] = useState(false);
  const [removeValue, setRemoveValue] = useState('');
  const [deleteId, setDeleteId] = useState('');

  const [
    summaryQuestionsWithoutAnswers,
    setSummaryQuestionsWithoutAnswers,
  ] = useState([]);
  const [
    summaryQuestionsWithoutAnswersQuestions,
    setSummaryQuestionsWithoutAnswersQuestions,
  ] = useState([]);

  const handleModal = () => {
    setIsOPen(!isOpen);
  };

  const handleDeleteSurvey = async (id, value) => {
    setDeleteId(id);
    setRemoveValue(value);
    handleModal();
  };

  const handleDownloadFeedbackReport = async (
    surveyId,
    participantsId,
    name
  ) => {
    let resp = await downloadFeedbackReport(surveyId, participantsId);
    // console.log(resp)
    if (getValue(resp, `data.downloadUrl.xlsxUrl`, '')) {
      saveAs(resp.data.downloadUrl.xlsxUrl, name);
    } else {
      ToastHandler('Software Update', 'red-circle');
    }
  };

  const handleDownloadFeedbackReportPDF = async (
    surveyId,
    participantsId,
    name
  ) => {
    let resp = await downloadFeedbackReport(surveyId, participantsId);
    // console.log(resp)
    if (getValue(resp, `data.downloadUrl.pdfUrl`, '')) {
      saveAs(resp.data.downloadUrl.pdfUrl, name);
    } else {
      ToastHandler('Software Update', 'red-circle');
    }
  };

  const handleDownloadSurveyReportPDF = async (
    surveyId,
    participantsId,
    name
  ) => {
    let resp = await downloadSurveyReport(surveyId, participantsId);
    // console.log(resp)
    if (getValue(resp, `data.downloadUrl`, '')) {
      saveAs(resp.data.downloadUrl, name);
    } else {
      ToastHandler('Software Update', 'red-circle');
    }
  };

  const handleDownloadDetailReport = async (surveyId, participantsId, name) => {
    let resp = await downloadDetailReport(surveyId, participantsId);
    //console.log(resp)
    if (getValue(resp, `data.downloadUrl`, '')) {
      saveAs(resp.data.downloadUrl, name);
    } else {
      ToastHandler('Software Update', 'red-circle');
    }
  };


  // ===========================================================================
  // handle change section
  // ===========================================================================

  const handleDeleteFun = async () => {
    try {
      let resp = await deleteSurvey(deleteId);
      if (resp) {
        toast.success('Deleted successfully', { className: 'green-circle' });
        handleModal();
        // getOpenSurveyFun();
      }
    } catch (error) {
      handleModal();
    }
  };

  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  // const onProductPageChangeHandler = (pageNumber) => {
  //   setPageNumber(pageNumber);
  //   let skip = (pageNumber - 1) * 10;
  //   setSkip(skip);
  //   getOpenSurveyFun();
  // };

  // ===========================================================================
  // api section
  // ===========================================================================

  // useEffect(() => {
  //   getSurvey360Employees();
  //   getSummerySurveyQuestion();
  //   get360SurveySummaryInfo();
  // }, []);

  useEffect(() => {
    if (activeTab === '1') {
      get360SurveySummaryInfo();
    } else if (activeTab === '2') {
      getSurvey360Employees(skip);
    } else {
      getSummerySurveyQuestion();
    }
  }, [activeTab]);

  const get360SurveySummaryInfo = async () => {
    let resp = await get360SummarySurvey(
      getValue(props, 'match.params.surveyId', '')
    );
    if (resp) {
      setSummaryInfo(getValue(resp, 'data', {}));
    }
  };

  const getSurvey360Employees = async (skip) => {
    try {
      setIsLoading(true);
      let resp = await get360SummaryEmployees(
        getValue(props, 'match.params.surveyId', ''),
        skip,
        limit
      );
      if (resp) {
        //console.log(resp);
        setIsLoading(false);
        setSurveyInfo(getValue(resp, 'data.survey360Participants', []));
        setTotalCount(getValue(resp, 'data.count', 0));
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  //console.log(totalCount)

  const getSummerySurveyQuestion = async () => {
    let resp = await getSummarySurvey(
      getValue(props, 'match.params.surveyId', '')
    );
    if (resp) {
      setSummaryQuestionsWithoutAnswers(getValue(resp, 'data', {}));
      setSummaryQuestionsWithoutAnswersQuestions(
        getValue(resp, 'data.page', [])
      );
    }
  };

  const getSummerySurveyQuestionspage = async (pageId) => {
    let resp = await getSummarySurveyPagination(
      getValue(props, 'match.params.surveyId', ''),
      pageId
    );
    if (resp) {
      setSummaryQuestionsWithoutAnswers(getValue(resp, 'data', {}));
      setSummaryQuestionsWithoutAnswersQuestions(
        getValue(resp, 'data.page', [])
      );
    }
  };

  // ===========================================================================
  // search section
  // ===========================================================================

  const [searchInput, setSearchInput] = useState('');

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    globalSearch(value);
  };

  const toggleSearch = () => {
    openSearch(!search);
    setSearchInput('');
    globalSearch('');
  };

  const globalSearch = async (value) => {
    try {
      let response = await getCompletedSurveys(skip, limit, value, sort);
      if (response) {
        setSurveyInfo(getValue(response, 'data.surveys', []));
        setTotalCount(getValue(response, 'data.count', []));
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleNavigate = (id, empId) => {
    getValue(props, 'history', '').push(
      `/360/survey-detail/${id}/${empId}/${getValue(
        props,
        `match.params.routeUrl`,
        ''
      )}`
    );
  };
  const onProductPageChangeHandler = (pageNumber) => {
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    getSurvey360Employees(skip);
  };
  return (
    <div>
      <div className={'page-layout ' + (isLeftNav ? 'active' : '')}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="create-survey-tabs">
            <Row>
              <Col md="6">
                <div className="back-btn">
                  <NavLink
                    to={`/${getValue(props, `match.params.routeUrl`, '')}`}
                  >
                    <Button color="nothing">
                      <BackSVG />
                    </Button>
                  </NavLink>
                </div>
                <div className="pl-5 py-2 mb-0">
                  <h4 className="mb-0 py-1 text-left">
                    {getValue(props, `match.params.routeUrl`, '') ===
                    'inprogress-surveys'
                      ? 'In Progress Surveys'
                      : 'Completed Surveys'}
                  </h4>
                </div>
              </Col>
              {/* <Col md="6">
                <ul className="table-header-actions mt-1">
                  <li className="hover-circle">
                    <Button color="nothing">
                      <DeleteSVG />
                    </Button>
                  </li>
                  <li className="hover-circle">
                    <Button color="nothing">
                      <EditSVG />
                    </Button>
                  </li>
                  <li className="hover-circle">
                    <Button color="nothing">
                      <CopySVG />
                    </Button>
                  </li>
                  <li className="hover-circle">
                    <Button color="nothing">
                      <ShareSVG />
                    </Button>
                  </li>
                </ul>
              </Col> */}
            </Row>
          </div>
          <div className="create-survey-tabs">
            <Nav pills className="basic-tabs pl-0">
              {/* <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Summary
                </NavLink1>
              </NavItem> */}
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Employees
                </NavLink1>
              </NavItem>
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggle('3');
                  }}
                >
                  Survey
                </NavLink1>
              </NavItem>
            </Nav>
          </div>
          <div className="page-padding pt-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col md="6">
                    <h5 className="mt-3 mb-1">
                      {getValue(summaryInfo, `name`, 0)}
                    </h5>
                    <h5 className="text-green-light">
                      Status : {getValue(summaryInfo, 'status', '-')}
                    </h5>
                  </Col>
                  {/* <Col md="6">
                    <ul className="table-header-actions mt-1">
                      <li className="hover-circle">
                        <Button color="nothing">
                          <PageSVG />
                        </Button>
                      </li>
                      <li className="hover-circle">
                        <Button color="nothing">
                          <CopySVG />
                        </Button>
                      </li>
                    </ul>
                  </Col> */}
                </Row>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="2">
                        <p className="mb-0">Respondents</p>
                        <Row>
                          <Col md="6">
                            <h3 className="mb-0">
                              {getValue(summaryInfo, `respondCollected`, 0)}
                            </h3>
                            <p className="text-green-light mb-0">
                              <small>Collected</small>
                            </p>
                          </Col>
                          <Col md="6">
                            <h3 className="mb-0">
                              {getValue(summaryInfo, `respondTarget`, 0)}
                            </h3>
                            <p className="mb-0">
                              <small>Targeted</small>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="2">
                        <p className="mb-0">Start Date</p>
                        <h3 className="mb-0">
                          <Moment format="DD MMM YYYY">
                            {getValue(summaryInfo, `launchDate`, 0)}
                          </Moment>
                        </h3>
                        <p className="mb-0">
                          <small>
                            <Moment format="hh:mm a">
                              {getValue(summaryInfo, `launchDate`, 0)}
                            </Moment>
                          </small>
                        </p>
                      </Col>
                      <Col md="2">
                        <p className="mb-0">End Date</p>
                        <h3 className="mb-0">
                          <Moment format="DD MMM YYYY">
                            {getValue(summaryInfo, `endDate`, 0)}
                          </Moment>
                        </h3>
                        <p className="mb-0">
                          <small>
                            <Moment format="hh:mm a">
                              {getValue(summaryInfo, `endDate`, 0)}
                            </Moment>
                          </small>
                        </p>
                      </Col>
                      <Col md="4">
                        <Row>
                          <Col md="6">
                            <p className="mb-0">Type</p>
                            <h3 className="mb-0">
                              {getValue(summaryInfo, `type`, '')
                                ? getValue(summaryInfo, `type`, '')
                                    .split(' ')
                                    .map(
                                      (w) =>
                                        w[0].toUpperCase() +
                                        w.substr(1).toLowerCase()
                                    )
                                    .join(' ')
                                : ''}
                            </h3>
                            {/* <p className="mb-0">
                              <small>Private</small>
                            </p> */}
                          </Col>
                          <Col md="6">
                            <p className="mb-0">Questions</p>
                            <h3 className="mb-0">
                              {getValue(summaryInfo, `totalQuestions`, 0)}
                            </h3>
                            <p className="mb-0">
                              <small>
                                Pages : {getValue(summaryInfo, `totalPages`, 0)}
                              </small>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                {!isLoading ? (
                  <Table className="table-custom">
                    <thead>
                      <tr>
                        <th>Employee Name</th>
                        <th>Employee Id</th>
                        <th>Self Evaluation</th>
                        <th></th>
                        {/* <th>Feedback Participants Evaluation</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {getValue(surveyInfo, 'length', 0) !== 0 ? (
                        surveyInfo.map((survey) => {
                          //console.log(survey)
                          return (
                            <tr>
                              <td
                                onClick={() => {
                                  handleNavigate(
                                    getValue(
                                      props,
                                      'match.params.surveyId',
                                      ''
                                    ),
                                    getValue(survey, `id`, '')
                                  );
                                }}
                                style={{
                                  color: '#d7242e',
                                  cursor: 'pointer',
                                }}
                              >
                                {getValue(survey, 'user.first_name', '')}{' '}
                                {getValue(survey, 'user.last_name', '')}
                              </td>
                              <td
                                onClick={() => {
                                  handleNavigate(
                                    getValue(
                                      props,
                                      'match.params.surveyId',
                                      ''
                                    ),
                                    getValue(survey, `id`, '')
                                  );
                                }}
                                style={{
                                  color: '#d7242e',
                                  cursor: 'pointer',
                                }}
                              >
                                {getValue(survey, 'empId', '-')}
                              </td>{' '}
                              <td>
                                {getValue(survey, 'selfCompleted', '')
                                  ? 'Completed'
                                  : 'Pending'}
                              </td>
                              {/* <td>
                                {getValue(survey, 'feebackCompletedPercentage', '0')}{' '}%
                              </td> */}
                              <td className="table-more">
                                {/* {survey.type === 'SURVEY360'&&  */}
                                <UncontrolledDropdown>
                                  <DropdownToggle color="nothing">
                                    <MoreSVG />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="custom-dropdown3"
                                    right
                                  >
                                    <>
                                      {/* <DropdownItem
                                        onClick={() =>
                                          handleDownloadFeedbackReport(
                                            getValue(
                                              props,
                                              'match.params.surveyId',
                                              ''
                                            ),
                                            getValue(survey, `id`, ''),
                                            getValue(survey, 'name', 'Excel')
                                          )
                                        }
                                      >
                                        Download Feedback Excel Report
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          handleDownloadFeedbackReportPDF(
                                            getValue(
                                              props,
                                              'match.params.surveyId',
                                              ''
                                            ),
                                            getValue(survey, `id`, ''),
                                            getValue(survey, 'name', 'PDF')
                                          )
                                        }
                                      >
                                        Download Feedback PDF Report
                                      </DropdownItem> */}
                                      <DropdownItem
                                        onClick={() =>
                                          handleDownloadSurveyReportPDF(
                                            getValue(
                                              props,
                                              'match.params.surveyId',
                                              ''
                                            ),
                                            getValue(survey, `id`, ''),
                                            getValue(survey, 'name', 'PDF')
                                          )
                                        }
                                      >
                                        Download Survey Report
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          handleDownloadDetailReport(
                                            getValue(
                                              props,
                                              'match.params.surveyId',
                                              ''
                                            ),
                                            getValue(survey, `id`, ''),
                                            getValue(survey, 'name', 'Excel')
                                          )
                                        }
                                      >
                                        Download Detailed Report
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          handleDownloadSurveyReportPDF(
                                            getValue(
                                              props,
                                              'match.params.surveyId',
                                              ''
                                            ),
                                            getValue(survey, `id`, ''),
                                            getValue(survey, 'name', 'PDF')
                                          )
                                        }
                                      >
                                        Download Survey Report
                                      </DropdownItem>
                                    </>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                {/* } */}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  'Please wait...'
                )}
              </TabPane>
              {activeTab == 2 && totalCount > 10 && (
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={limit}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={onProductPageChangeHandler}
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
              )}
              <TabPane tabId="3">
                <SummaryPreview
                  questionsInfo={summaryQuestionsWithoutAnswersQuestions}
                  infoData={summaryQuestionsWithoutAnswers}
                  handleSurveySubmit={getSummerySurveyQuestionspage}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <DeleteSurveyModal
        isOpen={isOpen}
        handleDeleteFun={handleDeleteFun}
        removeValue={removeValue}
        handleModal={handleModal}
      />
    </div>
  );
};

export default Summary360;
