import * as actions from '../actionTypes/surveyActionTypes';

export function setSurvey(survey: object): actions.SetSurveyAction {
  return {
    type: actions.SET_SURVEY,
    // productDetail:survey
    survey,
  };
}

export function getSurvey(): actions.GetSurveyAction {
  return {
    type: actions.GET_SURVEY,
  };
}

export function getLyricsRequest(): actions.GetLyricsRequestAction {
  return {
    type: actions.GET_LYRICS_REQUEST,
  };
}

export function getLyricsSuccess(
  lyrics: string
): actions.GetLyricsSuccessAction {
  return {
    type: actions.GET_LYRICS_SUCCESS,
    lyrics,
  };
}

export function getLyricsFailure(
  error: Error | string
): actions.GetLyricsFailureAction {
  return {
    type: actions.GET_LYRICS_FAILURE,
    error,
  };
}
