import { Col, Row, Label, Input } from 'reactstrap';

import * as React from 'react';
import { getValue } from '../../core/lodash';

interface IFullNamProps {
  questions: any;
}

const FullNam: React.FunctionComponent<IFullNamProps> = (props) => {
  return (
    <>
      <Row>
        <Col md="6">
          <div className="form-group">
            <Label>
              {getValue(props.questions, 'row[0].columns[0].question.text', '')}
            </Label>
            <Input
              type="text"
              placeholder="First Name"
              disabled
              value={getValue(
                props.questions,
                'row[0].columns[0].question.response[0]',
                ''
              )}
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <Label>
              {getValue(props.questions, 'row[0].columns[1].question.text', '')}
            </Label>
            <Input
              type="text"
              placeholder="Last Name"
              value={getValue(
                props.questions,
                'row[0].columns[1].question.response[0]',
                ''
              )}
              disabled
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FullNam;
