import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
interface ISSOAuthProps {}

const SSOAuth: React.FunctionComponent<ISSOAuthProps> = (props) => {
  function UseQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query:any = UseQuery();

  useEffect(() => {
    let redirectingUrl = query.get('relay_state');
    if (query.get('access_token')) {
      localStorage.setItem('access_token', query.get('access_token'));
    }
    if (query.get('tenant_id')) {
      localStorage.setItem('tenant_id', query.get('tenant_id'));
    }
    window.location.href = redirectingUrl;
  }, []);
  return <div>Please wait...</div>;
};

export default SSOAuth;
