// short answer
const shortAnswerMaxLength = [
  { value: '30 Characters', label: '30 Characters' },
  { value: '40 Characters', label: '40 Characters' },
  { value: '50 Characters', label: '50 Characters' },
];
const shortAnswerMinLength = [
  { value: '5 Characters', label: '5 Characters' },
  { value: '10 Characters', label: '10 Characters' },
  { value: '15 Characters', label: '15 Characters' },
  { value: '20 Characters', label: '20 Characters' },
  { value: '25 Characters', label: '25 Characters' },
];

// long answer
const longAnswerMaxLength = [
  { value: '100 Characters', label: '100 Characters' },
  { value: '200 Characters', label: '200 Characters' },
  { value: '300 Characters', label: '300 Characters' },
  { value: '400 Characters', label: '400 Characters' },
  { value: '500 Characters', label: '500 Characters' },
];
const longAnswerMinLength = [
  { value: '5 Characters', label: '5 Characters' },
  { value: '10 Characters', label: '10 Characters' },
  { value: '15 Characters', label: '15 Characters' },
  { value: '20 Characters', label: '20 Characters' },
  { value: '25 Characters', label: '25 Characters' },
];

// rating scale
const ratingScaleMin = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
];
const ratingScaleMax = [
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 }
];

const ratingScaleType = [{ value: 'Likert scale', label: 'Likert scale' }];
const ratingScaleRepresentation = [
  { value: 'number', label: 'number' },
  { value: 'smiley', label: 'smiley' },
];

// start
const ratingStar = [
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 }
];

export const commonCharatersLength = {
  shortAnswerMaxLength: shortAnswerMaxLength,
  shortAnswerMinLength: shortAnswerMinLength,
  longAnswerMaxLength: longAnswerMaxLength,
  longAnswerMinLength: longAnswerMinLength,
  ratingScaleMin: ratingScaleMin,
  ratingScaleMax: ratingScaleMax,
  ratingScaleType: ratingScaleType,
  ratingScaleRepresentation: ratingScaleRepresentation,
  ratingStar:ratingStar
};
