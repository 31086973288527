import React, { useEffect, useState } from 'react';
import {
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap';
import classnames from 'classnames';
import SearchSVG from '../../components/svg/search-svg';
import { getValue, setValue } from '../../core/lodash';
import SimpleReactValidator from 'simple-react-validator';
import Cookies from 'js-cookie';
import {
  createSurvey,
  createSurveyFromTemplate,
  createSurveyPage,
} from '../../core/services/surveyService';
import { NavLink, useHistory } from 'react-router-dom';
import { getSurveyTemplate } from '../../core/services/surveyTemplateService';

interface IChooseTemplateLayoutProps {
  templateInfo: any;
  getTemplateInfo: (id: string) => void;
}

const ChooseTemplateLayout: React.FunctionComponent<IChooseTemplateLayoutProps> = (
  props
) => {
  // validation
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState(0);
  const history = useHistory();

  const [activeTab2, setActiveTab2] = useState('1');
  const [modal, setModal] = useState(false);
  const [name, setName] = useState('');
  const [templateList, setTemplateList] = React.useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setName(getValue(props, 'templateInfo.name', ''));
    getTemplateList();
  }, [getValue(props, 'templateInfo.name', '')]);

  // ===========================================================================
  // handle change section
  // ===========================================================================
  const toggleModal = () => setModal(!modal);
  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };
  const handlenavigate = () => {
    toggleModal();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setName(value);
  };

  // ===========================================================================
  // api section
  // ===========================================================================
  const getTemplateList = async () => {
    try {
      let response = await getSurveyTemplate(skip, limit, '');
      if (response) {
        setTemplateList(getValue(response, 'data.templates', []));
      }
    } catch (error) {
      //console.log(error)
    }
  };
  const [loading, setLoading] = useState(false);
  const handleCreate = async () => {
    try {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
      } else {
        // let data = {
        //   name: name,
        //   surveyId: getValue(props, 'templateInfo.surveyId', ''),
        //   createType: 'from_template',
        // };
        setLoading(true);
        let resp = await createSurveyFromTemplate(
          getValue(props, 'templateInfo.id', '')
        );
        if (resp) {
          setLoading(false);
          Cookies.set('surveyId', getValue(resp, 'data.id', ''));
          history.push(
            `/create-new-survey-form/${getValue(
              resp,
              'data.type',
              ''
            )}/${getValue(resp, 'data.id', '')}`
          );
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const handleNavigateTemplate = (id) => {
    if (window.location.pathname.split('/').length === 2) {
      history.push(`templates-list/${id}`);
      props.getTemplateInfo(id);
    } else {
      props.getTemplateInfo(id);
      history.push(`${id}`);
    }
    // window.location.replace(`${window.location.origin}/templates-list/${id}`);
  };

  // ===========================================================================
  // search section
  // ===========================================================================

  const [searchInput, setSearchInput] = useState('');

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    globalSearch(value);
  };

  const globalSearch = async (value) => {
    try {
      let response = await getSurveyTemplate(skip, limit, value);
      if (response) {
        setTemplateList(getValue(response, 'data.templates', []));
        setTotalCount(getValue(response, 'data.count', []));
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleEdit = (type, id) => {
    // Cookies.remove('template')
    // Cookies.set('templateEdit', true);
    // Cookies.set('templateId', id);
    history.push(
      `/create-new-survey-form/${type}/${getValue(
        props,
        'templateInfo.id',
        ''
      )}/templates`
    );
  };
  const onProductPageChangeHandler = (pageNumber) => {
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    getTemplateList();
  };
  return (
    <div className="choose-template-layout">
      <div className="choose-template-list">
        <div className="choose-template-list-top">
          <SearchSVG />
          <Input
            type="text"
            placeholder="Search"
            value={searchInput}
            onChange={handleChangeSearch}
          />
        </div>
        <Nav pills className="menu-ul">
          {/* <li>
            <h6>
              {getValue(props, 'templateInfo.survey.category', '')}-dynamic
            </h6>
          </li> */}
          {templateList && templateList.length !== 0
            ? templateList.map((value, key) => {
                return (
                  <div key={key}>
                    <NavItem>
                      <NavLink1
                        className={classnames({
                          active: window.location.pathname.split('/')[2]
                            ? window.location.pathname.split('/')[2] ===
                              getValue(value, 'id', '')
                            : key === 0,
                        })}
                        onClick={() => {
                          handleNavigateTemplate(getValue(value, 'id', ''));
                        }}
                      >
                        {getValue(value, 'name', '')}
                      </NavLink1>
                    </NavItem>
                  </div>
                );
              })
            : 'No Data found'}
          {/* <NavItem>
            <NavLink1
              className={classnames({ active: activeTab2 === '2' })}
              onClick={() => {
                toggle2('2');
              }}
            >
              Customer feedback survey - Product
            </NavLink1>
          </NavItem>
          <NavItem>
            <NavLink1
              className={classnames({ active: activeTab2 === '3' })}
              onClick={() => {
                toggle2('3');
              }}
            >
              Customer feedback survey - Service
            </NavLink1>
          </NavItem> */}
        </Nav>
      </div>
      <div className="choose-template-height">
        <TabContent activeTab={activeTab2}>
          <TabPane tabId="1">
            <div className="page-padding">
              <Row>
                <Col lg={{ size: 10, offset: 1 }}>
                  {templateList.length !== 0 ? (
                    <Card>
                      <CardBody>
                        <div className="left-img-name">
                          <img
                            src={'/images/icon.svg'}
                            alt=""
                            width="36"
                            height="36"
                          />
                          <h4 className="detail-heading">
                            {getValue(props, 'templateInfo.name', '')}
                          </h4>
                          <p>
                            {getValue(props, 'templateInfo.totalPages', '')}{' '}
                            Pages <span className="dot-grey mx-1"></span>
                            {getValue(
                              props,
                              'templateInfo.totalQuestions',
                              ''
                            )}{' '}
                            Questions
                          </p>
                          <div className="btn-group-margin mt-3">
                            <Button
                              color="border"
                              onClick={() =>
                                handleEdit(
                                  getValue(props, 'templateInfo.type', ''),
                                  getValue(props, 'templateInfo.id', '')
                                )
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              color="blue"
                              // onClick={toggleModal}
                              onClick={handleCreate}
                            >
                              Use this
                            </Button>
                          </div>
                        </div>
                        <hr className="mt-2" />
                        <h3 className="text-center">Sample Questions</h3>
                        <h5 className="text-red mb-0">
                          Category :{' '}
                          {getValue(props, 'templateInfo.category', '')}
                        </h5>
                        <hr />
                        {/* <p>{getValue(props, 'templateInfo.description', '-')}</p> */}

                        {getValue(props, 'templateInfo.pages', []).map(
                          (page, key) => {
                            return (
                              <div key={key}>
                                <p className="mb-0">Page {key + 1}:</p>
                                {getValue(page, 'questions', []).map(
                                  (question, _qIndex) => {
                                    return (
                                      <>
                                        <p className="mb-0">Question</p>
                                        <h5>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: question.text,
                                            }}
                                          ></p>
                                        </h5>
                                      </>
                                    );
                                  }
                                )}

                                {/* <Row>
                              <Col md="6">
                                <div className="form-group">
                                  <Label className="radio-container">
                                    Strongly Disagree
                                    <input type="radio" />
                                    <span className="checkmark"></span>
                                  </Label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <Label className="radio-container">
                                    Agree
                                    <input type="radio" />
                                    <span className="checkmark"></span>
                                  </Label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <Label className="radio-container">
                                    Disagree
                                    <input type="radio" />
                                    <span className="checkmark"></span>
                                  </Label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <Label className="radio-container">
                                    Strongly Agree
                                    <input type="radio" />
                                    <span className="checkmark"></span>
                                  </Label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <Label className="radio-container">
                                    Neutral/Neither agree nor disagree
                                    <input type="radio" />
                                    <span className="checkmark"></span>
                                  </Label>
                                </div>
                              </Col>
                            </Row> */}
                              </div>
                            );
                          }
                        )}
                        {/* 
                      <div>
                        <p className="mb-0">Question 2:</p>
                        <h5>
                          Iam pleased with the career opportunities available to
                          me.
                        </h5>
                        <Row>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Neutral/Neither agree nor disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                   
                    */}
                      </CardBody>
                    </Card>
                  ) : (
                    'No Templates Found'
                  )}
                </Col>
              </Row>
            </div>
          </TabPane>
          {/* <TabPane tabId="2">
            <div className="page-padding">
              <Row>
                <Col lg={{ size: 10, offset: 1 }}>
                  <Card>
                    <CardBody>
                      <div className="left-img-name">
                        <img
                          src={'/images/icon.svg'}
                          alt=""
                          width="36"
                          height="36"
                        />
                        <h4 className="detail-heading">
                          Customer feedback survey - Product
                        </h4>
                        <p>
                          5 Sections <span className="dot-grey mx-1"></span> 130
                          Questions
                        </p>
                        <div className="btn-group-margin">
                          <Button color="border">Preview</Button>
                          <Button color="blue">Select</Button>
                        </div>
                      </div>
                      <hr className="mt-2" />
                      <h3 className="text-center">Sample Questions</h3>
                      <h5 className="text-red mb-0">Customer Development</h5>
                      <p>
                        This section contains questions about your personal
                        development
                      </p>
                      <div>
                        <p className="mb-0">Question 1:</p>
                        <h5>
                          Iam pleased with the career opportunities available to
                          me.
                        </h5>
                        <Row>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Neutral/Neither agree nor disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <p className="mb-0">Question 2:</p>
                        <h5>
                          Iam pleased with the career opportunities available to
                          me.
                        </h5>
                        <Row>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Neutral/Neither agree nor disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className="page-padding">
              <Row>
                <Col lg={{ size: 10, offset: 1 }}>
                  <Card>
                    <CardBody>
                      <div className="left-img-name">
                        <img
                          src={'/images/icon.svg'}
                          alt=""
                          width="36"
                          height="36"
                        />
                        <h4 className="detail-heading">
                          Customer feedback survey - Service
                        </h4>
                        <p>
                          5 Sections <span className="dot-grey mx-1"></span> 130
                          Questions
                        </p>
                        <div className="btn-group-margin">
                          <Button color="border">Preview</Button>
                          <Button color="blue">Select</Button>
                        </div>
                      </div>
                      <hr className="mt-2" />
                      <h3 className="text-center">Sample Questions</h3>
                      <h5 className="text-red mb-0">Customer Development</h5>
                      <p>
                        This section contains questions about your personal
                        development
                      </p>
                      <div>
                        <p className="mb-0">Question 1:</p>
                        <h5>
                          Iam pleased with the career opportunities available to
                          me.
                        </h5>
                        <Row>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Neutral/Neither agree nor disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <p className="mb-0">Question 2:</p>
                        <h5>
                          Iam pleased with the career opportunities available to
                          me.
                        </h5>
                        <Row>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Strongly Agree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <Label className="radio-container">
                                Neutral/Neither agree nor disagree
                                <input type="radio" />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </TabPane>
         */}
        </TabContent>
      </div>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalBody>
          <h3 className="text-center">Name your Survey</h3>
          <div className="form-group">
            <Label>
              Survey Name<span className="imp">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter Survey Name"
              value={name}
              name="name"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <div className="form-error">
              {simpleValidator.current.message('name', name, 'required')}
            </div>
          </div>
          {/* <div className="form-group">
            <Label>Category Name</Label>
            <Input type="select">
              <option>Select Survey Category</option>
              <option>Human Resources</option>
            </Input>
          </div> */}
          <div className="btn-group-margin text-md-right">
            {!loading ? (
              <Button color="blue" block onClick={handleCreate}>
                Create Survey
              </Button>
            ) : (
              <Button color="blue" block>
                Please wait...
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChooseTemplateLayout;
