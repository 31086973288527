import * as React from 'react';
import { Button } from 'reactstrap';
import ImageUploader from 'react-images-upload';
import { getValue } from '../../core/lodash';

interface IFileUploadProps {
  questions: any;
}

const FileUpload: React.FunctionComponent<IFileUploadProps> = (props) => {
  return (
    <div className="image-upload-container">
      <ImageUploader
        // withPreview={true}
        withIcon={true}
        buttonText=""
        label=""
        singleImage={true}
        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
        // maxFileSize={ele.maxFileSize}
        // errorClass={imageConflict && "error"}
      />
      {/* <div className="image-upload-txt pt-2">
          <p className="main-text">Upload Images here</p>
          <p className="small-text">
            Preferred image size:1024px x 768px @ 72 DPI Maximum size of 10MB
          </p>
        </div> */}
      {getValue(props, 'questions.response.length', '') !== 0 && (
        <div
          className="image-uploaded"
          style={{
            backgroundImage: `url(${getValue(
              props,
              'questions.response[0]',
              ''
            )})`,
          }}
        ></div>
      )}
    </div>
  );
};

export default FileUpload;
