import { FunctionComponent, useState } from "react";
import {
  Container,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Alert,
  Input,
} from "reactstrap";
import classnames from "classnames";
import RadioEmptySVG from "../../components/svg/radio-empty-svg";
import RadioSVG from "../../components/svg/radio-svg";
import AddSVG from "../../components/svg/add-svg";
import CKEditor from "react-ckeditor-component";
import ViewSVG from "../../components/svg/view-svg";
import { getValue } from "../../core/lodash";
import ImageUploader from "react-images-upload";
import DeleteSVG from "../../components/svg/delete-svg";

interface Props {
  handleIntroduction(): void;
  handleTermsAndConditions(): void;
  handleEmailTemplate(): void;
  handleChangeBooleanFourParams: (
    name: string,
    value: boolean,
    path: any,
    state: any
  ) => void;
  introductionPage: any;
  termsConditions: any;
  handleEditorData: (
    evt: any,
    path: string,
    state: string,
    value: string
  ) => void;
  handleStateData: (
    evt: any,
    path: string,
    state: string,
    value: string
  ) => void;
  setIntroductionPage: any;
  emailTemplate: any;
  setTermsConditions: any;
  handleChangeFile: (e: any) => void;
  headerSettings: any;
  visibility: object;
  setEmailTemplate: any;
  handleChangeVisibility: (status: boolean, path: string) => void;
  handleSubmitVisibility: () => void;
  handleUpdateEmailTemplate: (id: string) => void;
  emailTemplateId: string;
  // email template
  emailTemplateText: any;
  emailTemplateSubject: any;
  setEmailTemplateText: any;
  setEmailTemplateSubject: any;
  handleChange: (evt: any, path: any) => void;
  surveyType: string;
  fileUploadingStatus: boolean;
  handleRemoveFile: () => void;
  emailStatus: any;
  surveyDetails: any;
  introLoading: boolean;
  visibleLoading: boolean;
  termsLoading: boolean;
  surveyQuestionType: boolean;
  handleAssessmentLearningStatus: (e: boolean) => void;
  assessmentLearningStatus: boolean;
  assessmentScore: any;
  handleAssessmentScore: (e: any) => void;
  handleSubmitAssessmentScore: () => void;
  assessmentLoading: boolean;
  setVisibility: any;
}

const SettingsComponent: FunctionComponent<Props> = (props) => {
  const [activeTab2, setActiveTab2] = useState("1");
  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };
  // assessment radio status
  const [assessmentRadioStatus, setAssessmentRadioStatus] = useState("");
  const handleAssessmentStatus = (value) => {
    setAssessmentRadioStatus(value);
  };
  return (
    <>
      <Container fluid={true} className=" border-bottom">
        <h6 className="text-center my-3">
          Design your survey, its appearance and navigation, and customize
          Access Preferences
        </h6>
      </Container>
      <div className="choose-template-layout">
        <div className="choose-template-list">
          <Nav pills className="menu-ul">
            <li>
              <h6 className="mt-2">Type</h6>
            </li>
            <NavItem>
              <NavLink1
                className={classnames({ active: activeTab2 === "1" })}
                onClick={() => {
                  toggle2("1");
                }}
              >
                {activeTab2 === "1" ? <RadioSVG /> : <RadioEmptySVG />}Header
              </NavLink1>
            </NavItem>
            <NavItem>
              <NavLink1
                className={classnames({ active: activeTab2 === "2" })}
                onClick={() => {
                  toggle2("2");
                }}
              >
                {activeTab2 === "2" ? <RadioSVG /> : <RadioEmptySVG />}
                Introduction Page
              </NavLink1>
            </NavItem>
            {getValue(props, "surveyQuestionType", "") !== "SURVEY360" && (
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab2 === "3" })}
                  onClick={() => {
                    toggle2("3");
                  }}
                >
                  {activeTab2 === "3" ? <RadioSVG /> : <RadioEmptySVG />}Access
                  Preferences
                </NavLink1>
              </NavItem>
            )}
            <NavItem>
              <NavLink1
                className={classnames({ active: activeTab2 === "4" })}
                onClick={() => {
                  toggle2("4");
                }}
              >
                {activeTab2 === "4" ? <RadioSVG /> : <RadioEmptySVG />}Terms
                &amp; Conditions
              </NavLink1>
            </NavItem>
            <NavItem>
              <NavLink1
                className={classnames({ active: activeTab2 === "5" })}
                onClick={() => {
                  toggle2("5");
                }}
              >
                {activeTab2 === "5" ? <RadioSVG /> : <RadioEmptySVG />}Email
                Template
              </NavLink1>
            </NavItem>
            {getValue(props, "surveyType", "") === "ASSESSMENT" && (
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab2 === "6" })}
                  onClick={() => {
                    toggle2("6");
                  }}
                >
                  {activeTab2 === "6" ? <RadioSVG /> : <RadioEmptySVG />}
                  Assessment Preference
                </NavLink1>
              </NavItem>
            )}
          </Nav>
        </div>
        <div className="choose-template-height">
          <TabContent activeTab={activeTab2}>
            <TabPane tabId="1">
              <div className="page-padding">
                <Row>
                  <Col lg={{ size: 10, offset: 1 }}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md="">
                            <div className="left-img-name">
                              <img
                                src={"/images/icon2.PNG"}
                                alt=""
                                width="36"
                                height="36"
                              />
                              <h4 className="detail-heading">Header</h4>
                              <p className="detail-para text-center">
                                Upload survey logo here and the same will be
                                shown to participants
                              </p>
                            </div>
                          </Col>
                          {/* <Col md="4" className="text-right">
                            <Label className="switch">
                              <input type="radio" />
                              <span className="slider"></span>
                            </Label>
                          </Col> */}
                        </Row>
                        <hr />
                        <h6>Survey Logo</h6>
                        <div className="survey-logo">
                          <label htmlFor="file">
                            <Row>
                              <Col md="3">
                                <div className="image-upload-container">
                                  <ImageUploader
                                    // withPreview={true}
                                    withIcon={true}
                                    buttonText=""
                                    onChange={(e) => props.handleChangeFile(e)}
                                    label=""
                                    singleImage={true}
                                    imgExtension={[
                                      ".jpg",
                                      ".gif",
                                      ".png",
                                      ".gif",
                                      ".jpeg",
                                    ]}
                                    // maxFileSize={ele.maxFileSize}
                                    // errorClass={imageConflict && "error"}
                                  />
                                  {/* <div className="image-upload-txt pt-2">
          <p className="main-text">Upload Images here</p>
          <p className="small-text">
            Preferred image size:1024px x 768px @ 72 DPI Maximum size of 10MB
          </p>
        </div> */}
                                  {getValue(
                                    props.headerSettings,
                                    `headerLogoUrl`,
                                    ""
                                  ) && (
                                    <div
                                      className="image-uploaded"
                                      style={{
                                        backgroundImage: `url(${props.headerSettings.headerLogoUrl})`,
                                      }}
                                    >
                                      <Button
                                        color="nothing"
                                        onClick={() => props.handleRemoveFile()}
                                      >
                                        <DeleteSVG />
                                      </Button>
                                    </div>
                                  )}
                                </div>

                                {/* {!getValue(props,'fileUploadingStatus',false)?
                                <div
                                  className="logo-placeholder"
                                  style={{
                                    // backgroundImage: 'url(/images/logo.png)',
                                    backgroundImage: `url(${props.headerSettings.headerLogoUrl})`,
                                  }}
                                >
                                  
                                </div>:"Please wait..."} */}
                              </Col>
                              <Col md="4">
                                <div className="logo-text">
                                  <h6 className="mt-2 pt-1 mb-0">
                                    <Button
                                      color="nothing"
                                      className="blue-text-svg"
                                    >
                                      <AddSVG /> Upload
                                    </Button>
                                    {/* <input
                                      type="file"
                                      id="file"
                                      onChange={(e) =>
                                        props.handleChangeFile(e)
                                      }
                                    /> */}
                                  </h6>
                                  <p className="mb-0">
                                    JPG or PNG (250px x 85px)
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </label>
                        </div>
                      </CardBody>
                    </Card>
                    {/* <div className="text-right mt-4">
                      <Button color="blue">Save</Button>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="page-padding">
                <Row>
                  <Col lg={{ size: 10, offset: 1 }}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md="">
                            <div className="left-img-name">
                              <img
                                src={"/images/icon3.PNG"}
                                alt=""
                                width="36"
                                height="36"
                              />
                              <h4 className="detail-heading">
                                Introduction Page
                              </h4>
                              <p className="detail-para text-center">
                                Add an Introduction page to say something about
                                the topic of your survey
                              </p>
                            </div>
                          </Col>
                          {/* <Col md="4" className="text-right">
                            <Label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  props.introductionPage.introductionPageEnabled
                                }
                                onChange={() => {
                                  props.handleChangeBooleanFourParams(
                                    'introductionPageEnabled',
                                    !props.introductionPage
                                      .introductionPageEnabled,
                                    props.setIntroductionPage,
                                    props.introductionPage
                                  );
                                }}
                              />
                              <span className="slider"></span>
                            </Label>
                          </Col> */}
                        </Row>
                        <hr />
                        <h6>Description</h6>
                        <CKEditor
                          content={
                            props.introductionPage.introductionPageDescription
                              ? props.introductionPage
                                  .introductionPageDescription
                              : ""
                          }
                          events={{
                            change: (evt) => {
                              props.handleEditorData(
                                evt,
                                props.setIntroductionPage,
                                props.introductionPage,
                                "introductionPageDescription"
                              );
                            },
                          }}
                        />
                      </CardBody>
                    </Card>
                    <div className="text-right mt-4">
                      {!props.introLoading ? (
                        <Button
                          color="blue"
                          onClick={props.handleIntroduction}
                          disabled={
                            getValue(
                              props,
                              `surveyDetails.pages[${0}].questions.length`,
                              0
                            ) === 0
                              ? true
                              : false
                          }
                        >
                          Save
                        </Button>
                      ) : (
                        <Button color="blue">Please wait...</Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>
            {getValue(props, "surveyQuestionType", "") !== "SURVEY360" && (
              <TabPane tabId="3">
                <div className="page-padding">
                  <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col md="">
                              <div className="left-img-name">
                                <img
                                  src={"/images/icon.svg"}
                                  alt=""
                                  width="36"
                                  height="36"
                                />
                                <h4 className="detail-heading">
                                  Access Preferences
                                </h4>
                                <p className="detail-para text-center">
                                  Customise your preferences for access
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <hr />
                          <div className="mt-3 form-group">
                            <Label className="checkbox-container">
                              Private (Requires login to the account)
                              <input
                                type="checkbox"
                                checked={getValue(
                                  props.visibility,
                                  "privateEnabled",
                                  false
                                )}
                                onChange={() =>
                                  props.handleChangeVisibility(
                                    !getValue(
                                      props.visibility,
                                      "privateEnabled",
                                      ""
                                    ),
                                    "privateEnabled"
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </Label>
                          </div>
                          <div className="form-group">
                            <Label className="checkbox-container">
                              Public (Doesn’t require login)
                              <input
                                type="checkbox"
                                checked={
                                  getValue(
                                    props.visibility,
                                    "publicEnabled",
                                    false
                                  ) ||
                                  getValue(
                                    props.visibility,
                                    "publicUniqueEnabled",
                                    false
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  props.setVisibility({
                                    ...props.visibility,
                                    publicEnabled: !getValue(
                                      props.visibility,
                                      "publicEnabled",
                                      false
                                    ),
                                    publicUniqueEnabled: false,
                                  });
                                }}
                              />
                              <span className="checkmark"></span>
                            </Label>
                          </div>

                          <div className="form-group d-flex mx-5">
                            <Label className="radio-container ">
                              Generate common link
                              <input
                                type="radio"
                                checked={getValue(
                                  props.visibility,
                                  "publicEnabled",
                                  false
                                )}
                                // onChange={() =>
                                //   props.handleChangeVisibility(
                                //     !getValue(
                                //       props.visibility,
                                //       "publicEnabled",
                                //       ""
                                //     ),
                                //     "publicEnabled"
                                //   )
                                // }

                                onChange={() => {
                                  props.setVisibility({
                                    ...props.visibility,
                                    publicEnabled: !getValue(
                                      props.visibility,
                                      "publicEnabled",
                                      false
                                    ),
                                    publicUniqueEnabled: false,
                                  });
                                }}
                              />
                              <span className="checkmark"></span>
                            </Label>
                            <Label className="radio-container mx-2">
                              Generate unique link per user
                              <input
                                type="radio"
                                checked={getValue(
                                  props.visibility,
                                  "publicUniqueEnabled",
                                  false
                                )}
                                // onChange={() => {
                                //   props.handleChangeVisibility(
                                //     !getValue(
                                //       props.visibility,
                                //       "publicUniqueEnabled",
                                //       false
                                //     ),
                                //     "publicUniqueEnabled"
                                //   );
                                // }}
                                onChange={() => {
                                  props.setVisibility({
                                    ...props.visibility,
                                    publicUniqueEnabled: !getValue(
                                      props.visibility,
                                      "publicUniqueEnabled",
                                      false
                                    ),
                                    publicEnabled: false,
                                  });
                                }}
                              />
                              <span className="checkmark"></span>
                            </Label>
                          </div>

                          {/* <div className="ml-3 pl-3">
                          <Row>
                            <Col md="2">
                              <UncontrolledDropdown>
                                <DropdownToggle color="border" caret block>
                                  09
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem>Duplicate</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </Col>
                            <Col md="10">
                              <p className="mt-2 pt-1">Questions per page</p>
                            </Col>
                          </Row>
                        </div> */}
                        </CardBody>
                      </Card>
                      <div className="text-right mt-4">
                        {!props.visibleLoading ? (
                          <Button
                            color="blue"
                            onClick={props.handleSubmitVisibility}
                            disabled={
                              getValue(
                                props,
                                `surveyDetails.pages[${0}].questions.length`,
                                0
                              ) === 0
                                ? true
                                : false
                            }
                          >
                            Save
                          </Button>
                        ) : (
                          <Button color="blue">Please wait...</Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}
            <TabPane tabId="4">
              <div className="page-padding">
                <Row>
                  <Col lg={{ size: 10, offset: 1 }}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md="">
                            <div className="left-img-name">
                              <img
                                src={"/images/icon4.PNG"}
                                alt=""
                                width="36"
                                height="36"
                              />
                              <h4 className="detail-heading">
                                Terms and Conditions
                              </h4>
                              <p className="detail-para text-center">
                                Ask your respondents to agree to a set of terms
                                and conditions before taking up your survey
                              </p>
                            </div>
                          </Col>
                          {/* <Col md="4" className="text-right">
                            <Label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  props.termsConditions.termsConditionsEnabled
                                }
                                onChange={() => {
                                  props.handleChangeBooleanFourParams(
                                    'termsConditionsEnabled',
                                    !props.termsConditions
                                      .termsConditionsEnabled,
                                    props.setTermsConditions,
                                    props.termsConditions
                                  );
                                }}
                              />
                              <span className="slider"></span>
                            </Label>
                          </Col> */}
                        </Row>
                        <hr />
                        <h6>Description</h6>
                        <CKEditor
                          content={
                            props.termsConditions.termsConditionsDescription
                              ? props.termsConditions.termsConditionsDescription
                              : ""
                          }
                          events={{
                            change: (evt) => {
                              props.handleEditorData(
                                evt,
                                props.setTermsConditions,
                                props.termsConditions,
                                "termsConditionsDescription"
                              );
                            },
                          }}
                        />
                      </CardBody>
                    </Card>
                    <div className="text-right my-4">
                      {!props.termsLoading ? (
                        <Button
                          color="blue"
                          onClick={props.handleTermsAndConditions}
                          disabled={
                            getValue(
                              props,
                              `surveyDetails.pages[${0}].questions.length`,
                              0
                            ) === 0
                              ? true
                              : false
                          }
                        >
                          Save
                        </Button>
                      ) : (
                        <Button color="blue">Please wait...</Button>
                      )}
                    </div>
                    <Alert color="primary">
                      <span className="left-icon">
                        <ViewSVG />
                      </span>
                      Note: If the survey has an Introduction page, the terms
                      and conditions will be displayed at the end of the
                      Introduction page. Else, it will be displayed at the end
                      of the first page of the survey.
                    </Alert>
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tabId="5">
              <div className="page-padding">
                <Row>
                  <Col lg={{ size: 10, offset: 1 }}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md="">
                            <div className="left-img-name">
                              <img
                                src={"/images/icon4.PNG"}
                                alt=""
                                width="36"
                                height="36"
                              />
                              <h4 className="detail-heading">Email Template</h4>
                              <p className="detail-para text-center">
                                {/* Ask your respondents to agree to a set of terms
                                and conditions before taking up your survey */}
                              </p>
                            </div>
                          </Col>
                          {/* <Col md="4" className="text-right">
                            <Label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  props.termsConditions.termsConditionsEnabled
                                }
                                onChange={() => {
                                  props.handleChangeBooleanFourParams(
                                    'termsConditionsEnabled',
                                    !props.termsConditions
                                      .termsConditionsEnabled,
                                    props.setTermsConditions,
                                    props.termsConditions
                                  );
                                }}
                              />
                              <span className="slider"></span>
                            </Label>
                          </Col> */}
                        </Row>
                        <hr />
                        <h6>Subject</h6>
                        <Row>
                          <Col md="12">
                            <Input
                              type="text"
                              value={
                                props.emailTemplateSubject
                                  ? props.emailTemplateSubject
                                  : ""
                              }
                              rows={2}
                              onChange={(evt) => {
                                props.handleChange(
                                  evt,
                                  props.setEmailTemplateSubject
                                );
                              }}
                              name="subject"
                            />
                          </Col>
                        </Row>
                        <br></br>
                        <h6>Body</h6>
                        <CKEditor
                          content={
                            props.emailTemplate.text
                              ? props.emailTemplate.text
                              : ""
                          }
                          events={{
                            change: (evt) => {
                              props.handleEditorData(
                                evt,
                                props.setEmailTemplate,
                                props.emailTemplate,
                                "text"
                              );
                            },
                          }}
                        />
                      </CardBody>
                    </Card>
                    {getValue(props, "emailStatus.length", 0) === 0 ? (
                      <div className="text-right my-4">
                        <Button
                          color="blue"
                          onClick={props.handleEmailTemplate}
                          disabled={
                            getValue(
                              props,
                              `surveyDetails.pages[${0}].questions.length`,
                              0
                            ) === 0
                              ? true
                              : false
                          }
                        >
                          Save
                        </Button>
                      </div>
                    ) : (
                      <div className="text-right my-4">
                        <Button
                          color="blue"
                          onClick={() =>
                            props.handleUpdateEmailTemplate(
                              props.emailTemplateId
                            )
                          }
                        >
                          Update
                        </Button>
                      </div>
                    )}

                    <Alert color="primary">
                      <span className="left-icon">
                        <ViewSVG />
                      </span>
                      Note: If the survey has an Introduction page, the terms
                      and conditions will be displayed at the end of the
                      Introduction page. Else, it will be displayed at the end
                      of the first page of the survey.
                    </Alert>
                  </Col>
                </Row>
              </div>
            </TabPane>

            {getValue(props, "surveyType", "") === "ASSESSMENT" && (
              <TabPane tabId="6">
                <div className="page-padding">
                  <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col md="8">
                              <div className="left-img-name">
                                <img
                                  src={"/images/icon.svg"}
                                  alt=""
                                  width="36"
                                  height="36"
                                />
                                <h4 className="detail-heading">
                                  Respondent can see
                                </h4>
                                <p className="detail-para">
                                  Customise your preferences for assessment
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <hr />
                          {/* <h6>Survey Format</h6> */}
                          <div className="mt-3 form-group">
                            <Label className="radio-container">
                              Enable Learning Mode (Show correct answers at each
                              question)
                              <input
                                type="radio"
                                checked={getValue(
                                  props,
                                  "assessmentLearningStatus",
                                  ""
                                )}
                                onChange={() =>
                                  props.handleAssessmentLearningStatus(
                                    !getValue(
                                      props,
                                      "assessmentLearningStatus",
                                      ""
                                    )
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </Label>
                          </div>

                          <div className="mt-3 form-group">
                            <Label className="radio-container">
                              Quiz Mode ( User will be shown correct answers
                              after submission)
                              <input
                                type="radio"
                                onChange={() =>
                                  props.handleAssessmentLearningStatus(
                                    !getValue(
                                      props,
                                      "assessmentLearningStatus",
                                      ""
                                    )
                                  )
                                }
                                checked={
                                  !getValue(
                                    props,
                                    "assessmentLearningStatus",
                                    ""
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </Label>
                            {!getValue(
                              props,
                              "assessmentLearningStatus",
                              ""
                            ) && (
                              <>
                                Total Score :
                                <Col md="4">
                                  <Input
                                    type="text"
                                    value={getValue(
                                      props,
                                      "assessmentScore.totalScore",
                                      0
                                    )}
                                    disabled
                                  />
                                </Col>
                                Passing Score :
                                <Col md="4">
                                  <Input
                                    type="text"
                                    value={getValue(
                                      props,
                                      "assessmentScore.passingScore",
                                      0
                                    )}
                                    onChange={props.handleAssessmentScore}
                                    name="passingScore"
                                  />
                                </Col>
                                <br></br>
                                {props.assessmentLoading ? (
                                  <Button>Please wait...</Button>
                                ) : (
                                  <Button
                                    onClick={props.handleSubmitAssessmentScore}
                                  >
                                    {getValue(
                                      props,
                                      "assessmentScore.passingScore",
                                      ""
                                    )
                                      ? "Update"
                                      : "Save"}
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}
          </TabContent>
        </div>
      </div>
    </>
  );
};
export default SettingsComponent;
