import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from '../../core/reducers/rootReducer';
import * as actionTypes from '../../core/actionTypes/surveyActionTypes';
import SurveyFormComponent from './SurveyFormComponent';
import { getValue } from '../../core/lodash';

const mapStateToProps = (state: AppState) => {
  return {
    productDetails: getValue(state, 'surveyModel.survey.productDetail', ''),
    isLoading: state.isLoading[actionTypes.GET_SURVEY],
    error: state.error[actionTypes.GET_SURVEY],
  };
};
export default connect(mapStateToProps, null)(SurveyFormComponent);
