import React from 'react';

const Smiley1SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <defs></defs>
      <g transform="translate(0.166 0.166)">
        <circle
          style={{ fill: '#feca2e' }}
          cx="18"
          cy="18"
          r="18"
          transform="translate(-0.166 -0.166)"
        />
        <path
          style={{ fill: '#6d4c41' }}
          d="M111.754,224a10.163,10.163,0,0,0-7.555,3.359.775.775,0,1,0,1.154,1.036,8.626,8.626,0,0,1,12.8,0,.775.775,0,0,0,1.156-1.034A10.14,10.14,0,0,0,111.754,224Z"
          transform="translate(-93.92 -202.289)"
        />
        <path
          style={{ fill: '#6d4c41' }}
          d="M17.834,0A17.834,17.834,0,1,0,35.668,17.834,17.854,17.854,0,0,0,17.834,0Zm0,34.117A16.283,16.283,0,1,1,34.117,17.834,16.3,16.3,0,0,1,17.834,34.117Z"
        />
        <path
          style={{ fill: '#6d4c41' }}
          d="M87.748,115.872a.776.776,0,0,0-.429-.694l-6.2-3.1a.776.776,0,0,0-.694,1.387l4.817,2.408-4.816,2.408a.776.776,0,0,0,.693,1.388l6.2-3.1A.776.776,0,0,0,87.748,115.872Z"
          transform="translate(-72.24 -101.14)"
        />
        <path
          style={{ fill: '#6d4c41' }}
          d="M215.673,112.43a.775.775,0,0,0-1.041-.347l-6.2,3.1a.775.775,0,0,0,0,1.387l6.2,3.1a.776.776,0,0,0,.694-1.388l-4.817-2.408,4.816-2.408A.776.776,0,0,0,215.673,112.43Z"
          transform="translate(-187.84 -101.146)"
        />
      </g>
    </svg>
  </>
);
export default Smiley1SVG;
