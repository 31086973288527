import * as React from "react";
import { Label, Input, Row, Col } from "reactstrap";
import { getValue } from "../../core/lodash";

interface IEmailProps {
  options: any;
  questions: any;
  handleUserSurveyResponseDropdown: (value: object) => void;
  answer: any[];
  handleChangeTakeSurveyInput: (e: any, questionId: string) => void;
  // validation
  required: boolean;
  requiredErrors: any;
}

const Email: React.FunctionComponent<IEmailProps> = (props) => {
  let currentValue = getValue(props, "answer", []).filter(
    (item) => item.questionId === props.questions.id
  );
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return (
    <div className="form-group">
      <Input
        type="text"
        placeholder="Enter Email"
        value={getValue(currentValue[0], "response", "")}
        onChange={(e) => {
          props.handleChangeTakeSurveyInput(e, props.questions.id);
        }}
        onPaste={(e) => {
          props.handleChangeTakeSurveyInput(e, props.questions.id);
        }}
      ></Input>
      <div className="form-error" style={{ color: "red" }}>
        {!pattern.test(getValue(currentValue[0], "response", "")) &&
          (props.requiredErrors.includes(getValue(props, "questions.id", ""))
            ? getValue(currentValue[0], "response.length", "") === 0
              ? props.questions.mandatoryMsg
              : `Please provide a valid email address`
            : "")}
      </div>
    </div>
  );
};

export default Email;
