import { getValue } from 'core/lodash';
import * as React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import AdvancedOptions from './AdvancedOptions';

interface INumberProps {
  pageIndex: number;
  questionIndex: number;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  handleQuestionTypeInputBox: (
    e: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  surveyType: string;
}

const Number: React.FunctionComponent<INumberProps> = (props) => {
  return (
    <>
      <Row>
        {/* <Col md="4">
          <div className="form-group">
            <Label>Textbox Size (Characters) :</Label>
            <Input
              type="number"
              name="answerWidth"
              value={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].validations.answerWidth`,
                ''
              )}
              onChange={(e) =>
                props.handleQuestionTypeInputBox(
                  e,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            />
          </div>
        </Col> */}
        <Col md="4">
          <div className="form-group">
            <Label>Minimum Value :</Label>
            <Input
              type="number"
              name="minValue"
              value={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].validations.minValue`,
                ''
              )?getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].validations.minValue`,
                ''
              ):""}
              onChange={(e) =>
                props.handleQuestionTypeInputBox(
                  e,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            />
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <Label>Maximum Value :</Label>
            <Input
              type="number"
              name="maxValue"
              value={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].validations.maxValue`,
                ''
              )?getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].validations.maxValue`,
                ''
              ):""}
              onChange={(e) =>
                props.handleQuestionTypeInputBox(
                  e,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            />
          </div>
        </Col>
      </Row>
      <AdvancedOptions
            surveyType={props.surveyType}
        handleChangeQuestionAdvancedOptions={
          props.handleChangeQuestionAdvancedOptions
        }
        questionIndex={props.questionIndex}
        pageIndex={props.pageIndex}
        setQuestionsInfo={props.setQuestionsInfo}
        handleChange={props.handleChange}
        questionInfo={props.questionInfo}
      />
    </>
  );
};

export default Number;
