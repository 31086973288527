import { authHeader } from "./auth-header";
import { authHeaderOnlyToken } from "./auth-header-collector";
import { toast } from "react-toastify";

let access_token = localStorage.getItem("access_token");

export const post = (url, payload) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const postFileUpload = (url, payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + access_token,
      "x-tenant-origin": `${
        window.location.host === "localhost:3000"
          ? "id.surveyberry.ai"
          : window.location.host
      }`,
    },

    body: payload,
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const patchFileUpload = (url, payload) => {
  const requestOptions = {
    method: "PATCH",
    headers: { Authorization: "Bearer " + access_token },
    "x-tenant-origin": `${
      window.location.host === "localhost:3000"
        ? "id.surveyberry.ai"
        : window.location.host
    }`,
    body: payload,
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const patch = (url, payload) => {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const put = (url, payload) => {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const Delete = (url) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(url),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const get = (url) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: authHeader(url),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {
    //console.log(error)
  }
};

export const getPayload = (url, payload) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

function logout() {
  localStorage.clear();
}

export const getOnlyToken = (url) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: authHeaderOnlyToken(url),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {
    //console.log(error)
  }
};

export const postOnlyToken = (url, payload) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: authHeaderOnlyToken(url),
      body: JSON.stringify(payload),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {
    //console.log(error)
  }
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        localStorage.clear();
        // window.location.reload()
        // window.location.href = `${
        //   config.API_URL
        // }auth/sso/stohrm/login?relay_state=${
        //   window.location.href
        // }&tenant_id=${localStorage.getItem('tenant_id')}`;
        window.location.href = `/login`;
      }
      const error = (data && data.message) || response.statusText;
      toast.error(error, { className: "red-circle" });
      return Promise.reject(error);
    }

    return data;
  });
}
