import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { useHistory } from 'react-router-dom';
import { adminForgotPassword } from '../../../core/services/auth.service';
import { getValue } from '../../../core/lodash';
import LoginPageLyout from '../../../Layout/LoginLayout';
import InputField from '../../../common/InputRuleFeild';

interface ILoginPageProps {}

const ForgotPasswordPage: React.FunctionComponent<ILoginPageProps> = (
  props: any
) => {
  let history = useHistory();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isLoading, setIsLoading] = useState(false);
  const [, forceUpdate] = useState(0);
  const [email, setEmail] = useState('');
  const [user_type] = useState('VENDOR');

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let payload = {
          email: email,
          user_type: user_type,
        };
        let resp = await adminForgotPassword(payload);
        if (resp) {
          // console.log(resp);
          setIsLoading(false);
          toast.success(getValue(resp, 'message', ''));
          history.push(`/sent-email`);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && e.target.tagName.toLowerCase() !== 'button') {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  return (
    <LoginPageLyout>
      <div className="form-wrapper flex-1 d-flex position-relative">
        {/* <a href="#0" className="logo position-absolute">
          <img
            src="/images/tripx_logo.svg"
            // src="/images/demo.svg"
            className="img-fluid"
          />
        </a> */}
        <div className="m-auto form-wrapper__form">
          <NavLink to="/login">
            <a href="#0" className="back-btn">
              <img
                src="/images/back-arrow.svg"
                className="img-fluid  back-arrow"
              />
              Back
            </a>
          </NavLink>
          <h2>Password Reset</h2>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <InputField
              inputType="EMAIL"
              id="email"
              name="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value.toLowerCase())}
              placeholder="Enter Email"
              className="form-control"
              label="Email"
              validator={simpleValidator}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="form-cta-button">
            {isLoading ? (
              <button className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn">
                Please wait...
              </button>
            ) : (
              <button
                className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn"
                onClick={handleSubmit}
              >
                Get Reset Link
              </button>
            )}
          </div>
        </div>
      </div>
    </LoginPageLyout>
  );
};

export default ForgotPasswordPage;
