export const QueryRequestHelper = (props: any) => {
  let payload = { ...props };
  Object.keys(payload).forEach((key) => {
    if (
      (!payload[key] ||
        payload[key] === undefined ||
        (Array.isArray(payload[key]) && payload[key].length === 0)) &&
      typeof payload[key] !== 'boolean'
    ) {
      delete payload[key];
    }
  });
  let queryRequest = objectToQueryString(payload);
  return queryRequest;
};

function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
}

export const removeNullInObject = (props: any) => {
  let payload = { ...props };
  Object.keys(payload).forEach((key) => {
    if (
      (!payload[key] ||
        payload[key] === undefined ||
        (Array.isArray(payload[key]) && payload[key].length === 0)) &&
      typeof payload[key] !== 'boolean'
    ) {
      delete payload[key];
    }
  });
  return payload;
};

export const removeNullValues = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.filter(
      (item) =>
        item === true ||
        item === false ||
        (Array.isArray(item) && item.length > 0) ||
        item !== ''
    );
  }
  const cleanedObj: any = {};
  for (const key in obj) {
    const value = obj[key];
    if (
      typeof value === 'boolean' ||
      (Array.isArray(value) && value.length > 0) ||
      (typeof value !== 'boolean' &&
        value !== '' &&
        value !== null &&
        value !== undefined &&
        !isNaN(value))
    ) {
      cleanedObj[key] = value;
    }
  }

  return cleanedObj;
};
