import * as React from 'react';
import {
  Container,
  NavLink as NavLink1,
  Button,
  Input,
  Modal,
  ModalBody,
} from 'reactstrap';
import { getValue } from '../../core/lodash';
interface IAlreadyThereParticipantsProps {
  modal: boolean;
  toggleModal: () => void;
  alreadyThereParticipants: any;
}

const AlreadyThereParticipants: React.FunctionComponent<IAlreadyThereParticipantsProps> = (
  props
) => {
  //console.log(props.alreadyThereParticipants)
  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <ModalBody>
        <h5 className="mb-1">Participants Emails :</h5>
        <hr className="my-2" />
        Participant Email:{' '}
        {getValue(props, 'alreadyThereParticipants.email', '')}
        <div className="ol-overflow-scroll mb-3">
          <ol className="mt-3 answer-ol pl-5">
            {getValue(
              props,
              'alreadyThereParticipants.feedbackParticipants.manager.length',
              0
            ) !== 0 ? (
              <>
                <p style={{ color: 'blue' }}>
                  Manager/Skip Level Emails (Max Limit -{' '}
                  {getValue(props, 'alreadyThereParticipants.managerLimit', '')
                    ? getValue(
                        props,
                        'alreadyThereParticipants.managerLimit',
                        0
                      )
                    : 0}
                  ) :{' '}
                </p>
                {getValue(
                  props,
                  'alreadyThereParticipants.feedbackParticipants.manager',
                  []
                ).map((item) => {
                  return <li>{item}</li>;
                })}
              </>
            ) : (
              ''
            )}
          </ol>
        </div>
        <div className="ol-overflow-scroll mb-3">
          <ol className="mt-3 answer-ol pl-5">
            {getValue(
              props,
              'alreadyThereParticipants.feedbackParticipants.peer.length',
              0
            ) !== 0 ? (
              <>
                <p style={{ color: 'blue' }}>
                  Peer Emails (Max Limit -{' '}
                  {getValue(props, 'alreadyThereParticipants.peerLimit', '')
                    ? getValue(props, 'alreadyThereParticipants.peerLimit', 0)
                    : 0}
                  ) :{' '}
                </p>
                {getValue(
                  props,
                  'alreadyThereParticipants.feedbackParticipants.peer',
                  []
                ).map((item) => {
                  return <li>{item}</li>;
                })}
              </>
            ) : (
              ''
            )}
          </ol>
        </div>
        <div className="ol-overflow-scroll mb-3">
          <ol className="mt-3 answer-ol pl-5">
            {getValue(
              props,
              'alreadyThereParticipants.feedbackParticipants.public.length',
              0
            ) !== 0 ? (
              <>
                <p style={{ color: 'blue' }}>
                  Public Emails (Max Limit -{' '}
                  {getValue(props, 'alreadyThereParticipants.publicLimit', '')
                    ? getValue(props, 'alreadyThereParticipants.publicLimit', 0)
                    : 0}
                  ) :{' '}
                </p>
                {getValue(
                  props,
                  'alreadyThereParticipants.feedbackParticipants.public',
                  []
                ).map((item) => {
                  return <li>{item}</li>;
                })}
              </>
            ) : (
              ''
            )}
          </ol>
        </div>
        <div className="ol-overflow-scroll mb-3">
          <ol className="mt-3 answer-ol pl-5">
            {getValue(
              props,
              'alreadyThereParticipants.feedbackParticipants.subordinate.length',
              0
            ) !== 0 ? (
              <>
                <p style={{ color: 'blue' }}>
                  Subordinate Emails (Max Limit -{' '}
                  {getValue(
                    props,
                    'alreadyThereParticipants.subordinateLimit',
                    ''
                  )
                    ? getValue(
                        props,
                        'alreadyThereParticipants.subordinateLimit',
                        0
                      )
                    : 0}
                  ) :{' '}
                </p>
                {getValue(
                  props,
                  'alreadyThereParticipants.feedbackParticipants.subordinate',
                  []
                ).map((item) => {
                  return <li>{item}</li>;
                })}
              </>
            ) : (
              ''
            )}
          </ol>
        </div>
        <div className="text-right">
          <Button onClick={props.toggleModal}>Close</Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AlreadyThereParticipants;
