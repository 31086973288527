import { commonCharatersLength } from 'components/common/commonJSON';
import * as React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import AdvancedOptions from './AdvancedOptions';
import { getValue } from 'core/lodash';
interface IShortAnswerProps {
  // handleQuestionTypeDropdown: (
  //   e: object,
  //   pageIndex: number,
  //   questionIndex: number,
  //   maxLength: string
  // ) => void;
  pageIndex: number;
  questionIndex: number;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  handleQuestionTypeInputBox: (
    e: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  surveyType: string;
}
const ShortAnswer: React.FunctionComponent<IShortAnswerProps> = (props) => {
  const [maxCharactersLength] = React.useState(
    commonCharatersLength.shortAnswerMaxLength
  );
  const [minCharactersLength] = React.useState(
    commonCharatersLength.shortAnswerMinLength
  );

  let minValue = minCharactersLength.filter(
    (item) =>
      parseFloat(item.value.split(' ')[0]) ===
      getValue(
        props.questionInfo[props.pageIndex],
        `questions[${props.questionIndex}].validations.minLength`,
        ''
      )
  );

  // let maxValue = maxCharactersLength.filter(
  //   (item) =>
  //     parseFloat(item.value.split(' ')[0]) ===
  //     getValue(
  //       props.questionInfo[props.pageIndex],
  //       `questions[${props.questionIndex}].validations.maxLength`,
  //       ''
  //     )
  // );

  return (
    <>
      <Row>
        <Col md="4">
          <div className="form-group form-react-select">
            <Label>Minimum Length :</Label>
            <Input
              type="number"
              value={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].validations.minLength`,
                ''
              )}
              name="minLength"
              onChange={(e) =>
                props.handleQuestionTypeInputBox(
                  e,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            ></Input>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group form-react-select">
            <Label>Maximum Length :</Label>
            <Input
              type="number"
              value={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].validations.maxLength`,
                ''
              )}
              name="maxLength"
              onChange={(e) =>
                props.handleQuestionTypeInputBox(
                  e,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            ></Input>
            {/* <Select
              options={maxCharactersLength}
              value={maxValue}
              onChange={(e) =>
                props.handleQuestionTypeDropdown(
                  e,
                  props.pageIndex,
                  props.questionIndex,
                  'maxLength'
                )
              }
            /> */}
          </div>
        </Col>
      </Row>
      <h5 className="mb-2">Advance Options:</h5>

      <AdvancedOptions
            surveyType={props.surveyType}
        handleChangeQuestionAdvancedOptions={
          props.handleChangeQuestionAdvancedOptions
        }
        questionIndex={props.questionIndex}
        pageIndex={props.pageIndex}
        setQuestionsInfo={props.setQuestionsInfo}
        handleChange={props.handleChange}
        questionInfo={props.questionInfo}
      />
    </>
  );
};
export default ShortAnswer;
