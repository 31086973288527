// https://stackoverflow.com/questions/70585815/react-this-props-match-is-undefined
// https://reactrouter.com/docs/en/v6/getting-started/tutorial#reading-url-params
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import _ from 'lodash';
import React from 'react';
import PageNotFound from '../pages/PageNotFound';
import HomeLayout from '../Layout/HomeLayout';
import PublicRoutes from './public.routes';
import PrivateRoutes from './private.routes';
const IndexRoutes = () => {
  return (
    <Router>
      <Switch>
        {PublicRoutes.map((item, key) => {
          return (
            <Route
              key={key}
              path={item.path}
              exact
              component={item.component}
            />
          );
        })}
        <HomeLayout>
          {PrivateRoutes.map((item, key) => {
            return (
              <Route
                key={key}
                path={item.path}
                exact
                component={item.component}
              />
            );
          })}
        </HomeLayout>
        <Route path="/404" component={PageNotFound} />{' '}
      </Switch>
    </Router>
  );
};

export default IndexRoutes;
