import * as React from 'react';
import { Label, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface IDateProps {
  questions: any;
}

const Date: React.FunctionComponent<IDateProps> = (props) => {
  return (
    <Input
      type="date"
      value={getValue(props, 'questions.response[0]', '')}
      disabled
    ></Input>
  );
};

export default Date;
