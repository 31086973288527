import * as React from 'react';
import { Label, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';
import PreviewAdvancedOptions from './previewAdvancedOptions';
interface Props {
  options: any;
  questions: any;
  handleUserSurveyResponseDropdownArray: (
    questionId: string,
    questionType: string,
    response: any,
    comment: string,
    other: string
  ) => void;
  answer: any[];
  // validation
  required: boolean;
  // advanced option
  requiredErrors: any;
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string
  ) => void;
  isLearning: boolean;
}
const CheckBox: React.FunctionComponent<Props> = (props) => {
  const [options, setOptions] = React.useState([]);
  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === getValue(props.questions, 'id', '')
  );
  React.useEffect(() => {
    if (
      getValue(props.questions, 'randomEnabled', '') &&
      getValue(currentValue[0], 'response.length', 0) === 0
    ) {
      // generating random array indexs
      for (
        var a: any = [], i = 0;
        i < getValue(props, 'options.length', 0);
        ++i
      )
        a[i] = i;
      a = shuffle(a);
      function shuffle(array) {
        var tmp,
          current,
          top = array.length;
        if (top)
          while (--top) {
            current = Math.floor(Math.random() * (top + 1));
            tmp = array[current];
            array[current] = array[top];
            array[top] = tmp;
          }
        return array;
      }
      // using random array index to changing the position of object index
      function moveElement(array, initialIndex, finalIndex) {
        array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
        return array;
      }
      setOptions(moveElement(getValue(props, 'options', []), a[0], a[1]));
    } else {
      setOptions(getValue(props, 'options', []));
    }
  }, [getValue(props, 'options', [])]);
  return (
    <>
      {options &&
        options.map((opt: any) => {
          let answers = getValue(props, 'answer', '').filter(
            (item, i) => item.questionId === opt.questionId
          );
          return (
            <>
              <div className="mb-2">
                <Label className="checkbox-container d-inline-block">
                  {getValue(opt, 'text', '')}
                  {/* assessment condition starts */}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {props.isLearning &&
                    (getValue(opt, 'correct', false)
                      ? getValue(currentValue[0], 'response.length', 0) > 0 && (
                          <span style={{ color: 'green' }}>Correct answer</span>
                        )
                      : getValue(answers[0], `response`, []).includes(
                          opt.id ? opt.id : ''
                        ) && (
                          <span style={{ color: 'red' }}>Wrong answer</span>
                        ))}
                  {/* assessment condition ends */}
                  <input
                    type="checkbox"
                    checked={getValue(answers[0], `response`, []).includes(
                      opt.id ? opt.id : ''
                    )}
                    onChange={() => {
                      props.handleUserSurveyResponseDropdownArray(
                        opt.questionId,
                        getValue(props.questions, 'type', ''),
                        opt.id,
                        getValue(currentValue[0], 'commentOption', ''),
                        getValue(currentValue[0], 'otherOption', '')
                      );
                    }}
                  />
                  <span className="checkmark"></span>
                </Label>
              </div>
            </>
          );
        })}
      {getValue(props, 'questions.otherOptionEnabled', '') ? (
        <>
          <div className="mb-2">
            <Label className="checkbox-container d-inline-block">
              {getValue(props, 'questions.otherOptionMsg', '')}
              <input
                type="checkbox"
                checked={
                  getValue(props, 'answer', '').filter(
                    (item) =>
                      item.questionId === getValue(props.questions, 'id', '') &&
                      getValue(item, 'response', []).includes(-1)
                  ).length !== 0
                    ? true
                    : false
                }
                onChange={() => {
                  props.handleUserSurveyResponseDropdownArray(
                    getValue(props.questions, 'id', ''),
                    getValue(props.questions, 'type', ''),
                    -1,
                    getValue(currentValue[0], 'commentOption', ''),
                    getValue(currentValue[0], 'otherOption', '')
                  );
                }}
              />
              <span className="checkmark"></span>
            </Label>
          </div>
          {getValue(currentValue[0], `response`, '').includes(-1) ? (
            <div className="pl-2 ml-4">
              <div className="form-group">
                <Input
                  type="text"
                  placeholder="Enter Text here"
                  value={getValue(currentValue[0], 'otherOption', '')}
                  name="otherOption"
                  onChange={(e) =>
                    props.handleChangeTakeSurveyAdvancedOptionInput(
                      e,
                      getValue(props.questions, 'id', ''),
                      'otherOption'
                    )
                  }
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <PreviewAdvancedOptions
        questions={props.questions}
        answer={props.answer}
        handleChangeTakeSurveyAdvancedOptionInput={
          props.handleChangeTakeSurveyAdvancedOptionInput
        }
      />
      <div className="form-error" style={{ color: 'red' }}>
        {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
          ? props.questions.mandatoryMsg
          : ''}
      </div>
    </>
  );
};
export default CheckBox;
