import * as React from 'react';
import { Label, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';
import CommentOtherOption from './commentsOtherOption';
import PreviewAdvancedOptions from './previewAdvancedOptions';

interface Props {
  options: any;
  questions: any;
}

const MultipleChoice: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      {getValue(props, 'options', []).map((opt) => {
        return (
          <>
            <div className="mb-2">
              <Label className="radio-container d-inline-block">
                {getValue(opt, 'text', '')}
                <input
                  type="radio"
                  checked={
                    getValue(props, 'questions.response', []).includes(opt.id)
                      ? true
                      : false
                  }
                  disabled
                />
                <span className="checkmark"></span>
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              {opt.correct && (
                <span style={{ color: 'green' }}>Correct Answer</span>
              )}
            </div>
          </>
        );
      })}
      <CommentOtherOption questions={props.questions} />
    </>
  );
};
export default MultipleChoice;
