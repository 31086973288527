import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
} from 'reactstrap';
import LeftNav from '../../components/menu/left-nav';
import MoreSVG from '../../components/svg/more-svg';
import {
  deleteSurvey,
  downloadAdvancedReport,
  downloadAdvancedReportRemainder,
  downloadAdvancedReportStandard,
  downloadSimpleReport,
  endSurvey,
  getRunningSurveys,
  downloadAdvancedReport360,
  downloadSimpleIndividualReport,
  downloadCombinedReport,
  downloadCombinedDetailedReport,
  download360DetailedReport,
} from '../../core/services/surveyService';
import { getValue } from '../../core/lodash';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import DeleteSurveyModal from '../../components/common/modal/deleteModal';
import Pagination from 'react-js-pagination';
import { NavLink } from 'react-router-dom';
import SearchSVG from '../../components/svg/search-svg';
import CloseSVG from '../../components/svg/close-svg';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import LoaderCommon from '../../components/common/loader-common';
import moment from 'moment';
import 'moment-timezone';
import { saveAs } from 'file-saver';
import { ToastHandler } from '../../common/toast';

interface ITemplateMainProps {}

const ComplatedSurveyMain: React.FunctionComponent<ITemplateMainProps> = (
  props
) => {
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [search, openSearch] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [inProgressSurvey, setInProgressSurvey] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState('newest');

  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const history = useHistory();

  // delete
  const [isOpen, setIsOPen] = useState(false);
  const [isOpen1, setIsOPen1] = useState(false);
  const [isOpen2, setIsOPen2] = useState(false);
  const [removeValue, setRemoveValue] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [endSurveyId, setEndSurveyId] = useState('');

  const [reminderId, setReminderId] = useState('');

  const handleModal = () => {
    setIsOPen(!isOpen);
  };

  const handleModal1 = () => {
    setIsOPen1(!isOpen1);
  };
  const handleModal2 = (id) => {
    if (id) {
      setReminderId(id);
      setIsOPen2(!isOpen2);
    } else {
      setIsOPen2(!isOpen2);
    }
  };

  const handleDeleteSurvey = async (id, value) => {
    setDeleteId(id);
    setRemoveValue(value);
    handleModal();
  };
  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  // ===========================================================================
  // API section
  // ===========================================================================

  useEffect(() => {
    getOpenSurveyFun(skip);
  }, []);

  const getOpenSurveyFun = async (skip) => {
    try {
      setIsLoading(true);
      let resp = await getRunningSurveys(skip, limit, '', sort);
      if (resp) {
        setIsLoading(false);
        setInProgressSurvey(getValue(resp, 'data.surveys', []));
        setTotalCount(getValue(resp, 'data.count', []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleDeleteFun = async () => {
    try {
      let resp = await deleteSurvey(deleteId);
      if (resp) {
        toast.success('Deleted successfully', { className: 'green-circle' });
        handleModal();
        getOpenSurveyFun(skip);
      }
    } catch (error) {
      handleModal();
    }
  };

  const endSurveys = async (id) => {
    try {
      let resp = await endSurvey(id);
      if (resp) {
        toast.success('Survey ended successfully', {
          className: 'green-circle',
        });
        setTotalCount(0);
        getOpenSurveyFun(skip);
        handleModal1();
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleEndSurveyModal = (id) => {
    setEndSurveyId(id);
    handleModal1();
  };

  // ===========================================================================
  // handle change section
  // ===========================================================================

  const onProductPageChangeHandler = (pageNumber) => {
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    getOpenSurveyFun(skip);
  };

  const handleNavigate = (type, id) => {
    Cookies.set('progressSurvey', 'true');
    history.push(`/create-survey/settings/${type}/${id}`);
  };

  // ===========================================================================
  // search section
  // ===========================================================================

  const [searchInput, setSearchInput] = useState('');

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    globalSearch(value);
  };

  const toggleSearch = () => {
    openSearch(!search);
    setSearchInput('');
    globalSearch('');
  };

  const globalSearch = async (value) => {
    try {
      let response = await getRunningSurveys(skip, limit, value, sort);
      if (response) {
        setInProgressSurvey(getValue(response, 'data.surveys', []));
        setTotalCount(getValue(response, 'data.count', []));
      }
    } catch (error) {
      //console.log(error);
    }
  };
  // const handleRedirectCreateSurvey = () => {
  //   history.push('/create-new-survey')
  // }

  const handleDownloadAdvanced = async (surveyId) => {
    let resp = await downloadAdvancedReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, 'Excel');
    }
  };

  const handleDownloadSimple = async (surveyId) => {
    let resp = await downloadSimpleReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, 'Excel');
    }
  };

  const handleIndividualDownload = async (surveyId) => {
    let resp = await downloadSimpleIndividualReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, 'Excel');
    }
  };

  const handleDownloadAdvancedStandard = async (surveyId) => {
    let resp = await downloadAdvancedReportStandard(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, 'Excel');
    }
  };
  const handleDownloadAdvanced360 = async (surveyId) => {
    let resp = await downloadAdvancedReport360(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, 'Excel');
    }
  };
  const [reminderLoading, setReminderLoading] = useState(false);
  const handleDownloadRemainder = async (surveyId) => {
    try {
      setReminderLoading(true);
      let resp = await downloadAdvancedReportRemainder(surveyId);
      if (resp) {
        setReminderLoading(false);
        ToastHandler(
          getValue(resp, 'message', 'Sent successfully'),
          'green-circle'
        );
        handleModal2('');
      } else {
        setReminderLoading(false);
      }
    } catch (error) {
      setReminderLoading(false);
    }
  };

  const handleDownloadCombinedReport = async (surveyId) => {
    let resp = await downloadCombinedReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, 'Excel');
    }
  };

  const handleDownloadCombinedDetailedReport = async (surveyId) => {
    let resp = await downloadCombinedDetailedReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, 'Excel');
    }
  };
  const handleDownload360ResponseReport = async (surveyId) => {
    let resp = await download360DetailedReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, 'Excel');
    }
  };
  return (
    <div>
      <div className={'page-layout ' + (isLeftNav ? 'active' : '')}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="create-survey-tabs create-survey-tabs-search">
            <Nav pills className="basic-tabs pl-0">
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  In Progress Surveys
                </NavLink1>
              </NavItem>
              {/* <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  In Progress Surveys
                </NavLink1>
              </NavItem> */}
            </Nav>
            <div className="hover-circle">
              <Button color="nothing" onClick={toggleSearch}>
                <SearchSVG />
              </Button>

              <div className={'table-search ' + (search ? 'show' : '')}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  onChange={handleChangeSearch}
                  value={searchInput}
                />
                <Button
                  color="nothing"
                  onClick={toggleSearch}
                  className="table-search-close"
                >
                  <CloseSVG />
                </Button>
              </div>
            </div>
          </div>
          <div className="page-padding pt-0">
            <TabContent activeTab={activeTab}>
              {!isLoading ? (
                inProgressSurvey.length !== 0 ? (
                  <TabPane tabId="1">
                    <Table className="table-custom">
                      <thead>
                        <tr>
                          <th>Survey Name</th>
                          <th>Survey Type</th>
                          <th>Launch Date & Time</th>
                          <th>End Date</th>
                          {/* <th>Responses</th> */}
                          <th
                            style={{ width: '50' }}
                            className="text-right"
                          ></th>
                        </tr>
                      </thead>
                      {inProgressSurvey.map((survey: any, key) => {
                        let date = moment(survey.launchDate)
                          .tz('Asia/Kolkata')
                          .format('DD MMM YYYY');
                        let time = moment(survey.launchDate)
                          .tz('Asia/Kolkata')
                          .format('hh:mm a');
                        return (
                          <tbody key={key}>
                            <tr>
                              <td>
                                <NavLink
                                  to={
                                    survey.type === 'SURVEY360'
                                      ? `/360-summary/${survey.id}/inprogress-surveys`
                                      : `/summary/${survey.id}/inprogress-surveys`
                                  }
                                >
                                  <h5 style={{ color: '#276ef1' }}>
                                    {survey.name}
                                  </h5>
                                </NavLink>
                              </td>
                              <td>
                                {survey.type
                                  ? survey.type
                                      .split(' ')
                                      .map(
                                        (w) =>
                                          w[0].toUpperCase() +
                                          w.substr(1).toLowerCase()
                                      )
                                      .join(' ')
                                  : ''}
                              </td>
                              <td>
                                <h5>{date}</h5>
                                <p>{time}</p>
                              </td>
                              <td>
                                <h5>
                                  <Moment format="DD MMM YYYY">
                                    {survey.settings.endDate}
                                  </Moment>
                                </h5>
                                <p>
                                  <Moment format="hh:mm a">
                                    {survey.settings.endDate}
                                  </Moment>
                                </p>
                              </td>
                              {/* <td>
                                <h5>
                                  {getValue(survey, 'totalResponse', '-')}
                                </h5>
                                <p>
                                  Out of{' '}
                                  {getValue(survey, 'responseLimit', '-')}
                                </p>
                              </td> */}
                              <td className="table-more">
                                <UncontrolledDropdown>
                                  <DropdownToggle color="nothing">
                                    <MoreSVG />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="custom-dropdown3"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        handleNavigate(survey.type, survey.id)
                                      }
                                    >
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleEndSurveyModal(survey.id)
                                      }
                                    >
                                      End Survey
                                    </DropdownItem>

                                    {survey.type === 'SURVEY360' ? (
                                      <>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadSimple(survey.id)
                                          }
                                        >
                                          Download Simple Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadAdvanced(survey.id)
                                          }
                                        >
                                          Download Advanced Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleModal2(survey.id)
                                          }
                                        >
                                          Send Reminder
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadAdvanced360(survey.id)
                                          }
                                        >
                                          Download Summary Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleIndividualDownload(survey.id)
                                          }
                                        >
                                          Download Individual Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadCombinedReport(
                                              survey.id
                                            )
                                          }
                                        >
                                          Download Combined Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadCombinedDetailedReport(
                                              survey.id
                                            )
                                          }
                                        >
                                          Download Combined Detailed Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownload360ResponseReport(
                                              survey.id
                                            )
                                          }
                                        >
                                          360 Response Report
                                        </DropdownItem>
                                      </>
                                    ) : (
                                      <DropdownItem
                                        onClick={() =>
                                          handleDownloadAdvancedStandard(
                                            survey.id
                                          )
                                        }
                                      >
                                        Download Summary Report
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </Table>
                  </TabPane>
                ) : (
                  <div className="text-center mt-5">
                    <div className="mt-4 mb-2">
                      <img src="/images/empty.PNG" alt="" />
                    </div>
                    <h4 className="mb-2">No Survey Found</h4>
                    {/* <Button color="blue" onClick={handleRedirectCreateSurvey}>New Survey</Button> */}
                  </div>
                )
              ) : (
                <div className="text-center my-3">
                  <LoaderCommon />
                </div>
              )}
              {totalCount > 10 && (
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={limit}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={onProductPageChangeHandler}
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
              )}
              <TabPane tabId="2">Lorem ipsum</TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <DeleteSurveyModal
        isOpen={isOpen}
        handleDeleteFun={handleDeleteFun}
        removeValue={removeValue}
        handleModal={handleModal}
      />

      <Modal isOpen={isOpen1} centered>
        <ModalBody className="text-center">
          <h4>End Survey</h4>
          <p>Are you sure you want to End Survey?</p>
          <div className="btn-group-margin text-center mt-4">
            <Button color="border" onClick={handleModal1}>
              Cancel
            </Button>
            <Button color="blue" onClick={() => endSurveys(endSurveyId)}>
              End Survey
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isOpen2} centered>
        <ModalBody className="text-center">
          <h4>Send Reminder</h4>
          <p>Are you sure you want to send reminder?</p>
          <div className="btn-group-margin text-center mt-4">
            {reminderLoading ? (
              <Button color="blue">Please wait...</Button>
            ) : (
              <Button
                color="blue"
                onClick={() => handleDownloadRemainder(reminderId)}
              >
                Yes
              </Button>
            )}

            <Button color="border" onClick={() => handleModal2('')}>
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ComplatedSurveyMain;
