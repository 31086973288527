import React from 'react';

const Smiley4SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <defs>
        <style></style>
      </defs>
      <g transform="translate(0.109 0.109)">
        <circle
          className="a"
          style={{ fill: '#feca2e' }}
          cx="18"
          cy="18"
          r="18"
          transform="translate(-0.109 -0.109)"
        />
        <path
          className="b"
          style={{ fill: '#6d4c41' }}
          d="M111.779,224a10.2,10.2,0,0,0-7.579,3.37.778.778,0,1,0,1.157,1.039,8.654,8.654,0,0,1,12.843,0,.778.778,0,1,0,1.159-1.037A10.172,10.172,0,0,0,111.779,224Z"
          transform="translate(-93.888 -202.22)"
        />
        <path
          className="b"
          style={{ fill: '#6d4c41' }}
          d="M17.891,0A17.891,17.891,0,1,0,35.781,17.891,17.911,17.911,0,0,0,17.891,0Zm0,34.226A16.335,16.335,0,1,1,34.226,17.891,16.353,16.353,0,0,1,17.891,34.226Z"
        />
        <path
          className="b"
          style={{ fill: '#6d4c41' }}
          d="M215,128a.778.778,0,0,0-.778.778,2.334,2.334,0,1,1-4.667,0,.778.778,0,0,0-1.556,0,3.889,3.889,0,0,0,7.779,0A.778.778,0,0,0,215,128Z"
          transform="translate(-187.776 -115.554)"
        />
        <path
          className="b"
          style={{ fill: '#6d4c41' }}
          d="M87.779,128.778a.778.778,0,0,0-1.556,0,2.334,2.334,0,1,1-4.667,0,.778.778,0,0,0-1.556,0,3.889,3.889,0,0,0,7.779,0Z"
          transform="translate(-72.221 -115.554)"
        />
      </g>
    </svg>
  </>
);
export default Smiley4SVG;
