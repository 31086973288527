import { Label, Input } from 'reactstrap';

import * as React from 'react';
import AdvancedOptions from '../answerTypes/AdvancedOptions';

interface IEmailProps {
  pageIndex: number;
  questionIndex: number;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  surveyType: string;
}

const Email: React.FunctionComponent<IEmailProps> = (props) => {
  return (
    <div>
      <AdvancedOptions
            surveyType={props.surveyType}
        handleChangeQuestionAdvancedOptions={
          props.handleChangeQuestionAdvancedOptions
        }
        questionIndex={props.questionIndex}
        pageIndex={props.pageIndex}
        setQuestionsInfo={props.setQuestionsInfo}
        handleChange={props.handleChange}
        questionInfo={props.questionInfo}
      />
    </div>
  );
};

export default Email;
