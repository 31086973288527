import React, { useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { adminSetPassword } from '../../../core/services/auth.service';
import { getValue } from '../../../core/lodash';
import InputField from '../../../common/InputRuleFeild';
import LoginPageLyout from '../../../Layout/LoginLayout';
interface IResetPasswordPageProps {}

const ResetPasswordPage: React.FunctionComponent<IResetPasswordPageProps> = (
  props: any
) => {
  let history = useHistory();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isLoading, setIsLoading] = useState(false);
  const [, forceUpdate] = useState(0);
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [token, setToken] = useState(
    window.location.search.split('?token=')[1]
  );

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (password === cPassword) {
        try {
          setIsLoading(true);
          let payload = {
            token: token,
            password: password,
          };
          let resp = await adminSetPassword(payload);
          if (resp) {
            // console.log(resp);
            setIsLoading(false);
            toast.success(getValue(resp, 'message', ''));
            history.push(`/login`);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      } else {
        toast.error('Password and Confirm Password should be equal');
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && e.target.tagName.toLowerCase() !== 'button') {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  return (
    <LoginPageLyout>
      <div className="m-auto form-wrapper__form">
        <h2 className="form-wrapper__form-common-title">Password Reset</h2>
        {/* <div className="form-group">
					<label htmlFor="u-id">User ID</label>
					<input
						type="text"
						id="u-id"
						placeholder="Auditteam@Ascent-online.com"
						className="form-control form-control--user-id"
					/>
				</div> */}
        <div className="form-group">
          <label htmlFor="pwd">Password</label>
          <InputField
            inputType="PASSWORD"
            id="password"
            name="password"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            placeholder="Enter Password"
            className="form-control"
            validator={simpleValidator}
            label={'Password'}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirm-pwd">Confirm Password</label>
          <InputField
            inputType="PASSWORD"
            id="cPassword"
            name="cPassword"
            value={password}
            onChange={(e: any) => setCPassword(e.target.value)}
            placeholder="Confirm Password"
            className="form-control"
            validator={simpleValidator}
            label={'Confirm Password'}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="form-cta-button">
          {isLoading ? (
            <button className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn">
              Please wait...
            </button>
          ) : (
            <button
              className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn"
              onClick={handleSubmit}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </LoginPageLyout>
  );
};

export default ResetPasswordPage;
