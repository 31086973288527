import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import { getValue } from '../lodash';
import { get, patch, post, Delete, put } from './helpers/http-handler';

interface surveyResponses {
  survey: object;
}

// *********** survey *********** //

export const surveyResponse = (
  surveyId: string,
  skip: number,
  limit: number
) => get(
  `${config.API_URL}survey-response/${surveyId}?skip=${skip}&limit=${limit}`
)



// export async function surveyResponse(
//   surveyId: string,
//   skip: number,
//   limit: number
// ): Promise<surveyResponse> {
//   try {
//     return await axios.get(
//       `${config.API_URL}survey-response/${surveyId}?skip=${skip}&limit=${limit}`
//     );
//   } catch (error) {
//     if (getValue(
//       error,
//       `response.data.statusCode`,
//       ''
//     ) === 401) {
//       window.location.href = `${config.API_URL}auth/sso/stohrm/login?relay_state=${window.location.href}`
//     } else {
//       toast.error(
//         getValue(
//           error,
//           `response.data.message`,
//           'Software update in progress'
//         )
//       );
//     }
//   }
// }
