export const QuestionType = [
  { name: 'SINGLE_CHOICE', value: 'Single Choice' },
  { name: 'CHECK_BOX', value: 'Checkboxes' },
  { name: 'DROPDOWN', value: 'Dropdown' },
  { name: 'SHORT_ANSWER', value: 'Short Answer Question' },
  { name: 'LONG_ANSWER', value: 'Long Answer Question' },
  { name: 'RATING_SCALE', value: 'Rating Scale' },
  { name: 'STAR_RATING', value: ' Star Rating' },
  { name: 'MATRIX_RADIO_BOX', value: 'Single Choice Grid' },
  { name: 'DATE', value: 'Date' },
  { name: 'TIME', value: 'Time' },
  { name: 'MATRIX_CHECK_BOX', value: 'Checkbox Grid' },
  { name: 'FULL_NAME', value: 'Full Name' },
  { name: 'EMAIL_TEXTBOX', value: 'Email' },
  { name: 'NUMERIC_TEXTBOX', value: 'Number' },
  { name: 'FILE_UPLOAD', value: 'File Upload' },
];
