import { createContext, useContext, useEffect, useState } from 'react';
import { getPermission } from '../core/services/surveyService';
import { getValue } from '../core/lodash';

const StateContext = createContext();
export function GlobalStateProvider({ children }) {
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    let user =
      getValue(localStorage, `user`, '') &&
      JSON.parse(getValue(localStorage, `user`, {}));
    setUserInfo(user);
    getPermissions();
  }, []);
  const [permissions, setPermissions] = useState([]);
  const getPermissions = async () => {
    if (getValue(localStorage, 'access_token', '')) {
      let resp = await getPermission();
      if (resp) {
        sessionStorage.setItem(
          'permissions',
          getValue(resp, 'data.permissions', [])
        );
        setPermissions(getValue(resp, 'data.permissions', []));
      }
    }
  };

  return (
    <StateContext.Provider
      value={{
        permissions,
        userInfo,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(StateContext);
}
