import React, { useState } from 'react';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { LoginSlider } from './login-swiper';

function LoginPageLyout({ children }: any) {
  const [isLeftMenu, manageMenu] = useState(false);
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  return (
    <section className="main-wrapper-login">
      <LoginSlider />
      <div className="form-wrapper flex-1 d-flex position-relative">
        <a href="#0" className="logo position-absolute">
          <img
            src="/images/logo.png"
            // src="/images/demo.svg"
            className="img-fluid"
          />
        </a>
        {children}
      </div>
    </section>
  );
}

export default LoginPageLyout;
