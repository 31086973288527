import React, { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Col } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface Props {}
const ThankYou: FunctionComponent<Props> = (props) => {
  const [isLeftMenu, manageMenu] = useState(false);
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  return (
    <>
      <div className="page-layout page-layout-full">
        <div className="page-height page-padding">
          <Col lg={{ size: 10, offset: 1 }}>
            <div className="mb-5">
              <img
                src="/images/banner2.svg"
                alt=""
                className="img-fluid w-100"
              />
            </div>
            <h1 className="text-center">
              Survey submitted successfully. Thanks for
              <br />
              taking the survey.
            </h1>
            {getValue(props,'match.params.type','') !== "public"&&
            <div className="btn-group-margin text-center mt-4">
              <NavLink to="/home" className="btn btn-broder">
                Close
              </NavLink>
              <NavLink className="btn btn-blue" to="/home">
                Go to Homepage
              </NavLink>
            </div>}
          </Col>
        </div>
      </div>
    </>
  );
};
export default ThankYou;
