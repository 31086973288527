import * as React from 'react';
import CheckBoX from 'components/answerTypes/Checkbox';
import SingleChoice from 'components/answerTypes/SingleChoice';
import Dropdown from 'components/answerTypes/Dropdown';
import ShortQuestion from 'components/answerTypes/ShortAnswer';
import LongQuestion from 'components/answerTypes/LongAnswer';
import RatingScale from 'components/answerTypes/RatingScale';
import StarRating from 'components/answerTypes/StarRating';
import MultipleChoiceGrid from 'components/answerTypes/MultipleChoiceGrid';
import CheckboxGrid from 'components/answerTypes/CheckboxGrid';
import Date from 'components/answerTypes/Date';
import Time from 'components/answerTypes/Time';
import FullName from 'components/answerTypes/FullName';
import Email from 'components/answerTypes/Email';
import Number from 'components/answerTypes/Number';
import FileUpload from 'components/answerTypes/FileUpload';
interface IRenderQuestionTypeProps {
  param: string;
  pageIndex: number;
  questionIndex: number;
  questionInfo: any;
  setQuestionsInfo: any;
  surveyId: string;
  surveyType: string;
  addMoreOption: (value: any, pageIndex: number, questionIndex: number) => void;
  removeMoreOption: (
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  handleScore: (
    index: number,
    name: string,
    value: number,
    updateState: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  addPredefinedOption: (
    value: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleChangeQuestion: (
    evt: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleQuestionTypeValues: (
    e: any,
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleDateQuestionType: (e: any, pageIndex: any, questionIndex: any) => void;
  handleQuestionTypeDropdown: (
    e: object,
    pageIndex: number,
    questionIndex: number,
    maxLength: string
  ) => void;
  handleQuestionTypeInputBox: (
    e: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleFullName: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number
  ) => void;
  handleGridData: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;

  handleAddGridDataColumn: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;

  handleAddGridDataRow: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleGridDataColumn: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleGridDataColumnScore: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleDeleteGridRow: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number
  ) => void;
  handleDeleteGridColumn: (
    pageIndex: number,
    questionIndex: number,
    colIndex: number
  ) => void;

  handleRatingScaleDropdownString: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    param: any
  ) => void;
  handleScoreInputBoxEdit: (
    index: number,
    e: any,
    updateState: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  enableSideMenuBulkAnswer: (pageIndex: number, questionIndex: number) => void;
  handleFullNameCheckbox: (
    status: boolean,
    pageIndex: number,
    questionIndex: number,
    optioIndex: number
  ) => void;
  handleAssessmentCorrectAnswer: (
    optionIndex: number,
    status: boolean,
    updateState: any,
    pageIndex: number,
    questionIndex: number,
    type:string
  ) => void;
}

const RenderQuestionType: React.FunctionComponent<IRenderQuestionTypeProps> = (
  props
) => {
  switch (props.param) {
    case 'SINGLE_CHOICE':
      return (
        <SingleChoice
          surveyType={props.surveyType}
          handleAssessmentCorrectAnswer={props.handleAssessmentCorrectAnswer}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          questionInfo={props.questionInfo}
          setQuestionsInfo={props.setQuestionsInfo}
          surveyId={props.surveyId}
          handleQuestionTypeValues={props.handleQuestionTypeValues}
          addMoreOption={props.addMoreOption}
          removeMoreOption={props.removeMoreOption}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          handleScore={props.handleScore}
          addPredefinedOption={props.addPredefinedOption}
          handleChange={props.handleChangeQuestion}
          enableSideMenuBulkAnswer={props.enableSideMenuBulkAnswer}
          handleScoreInputBoxEdit={props.handleScoreInputBoxEdit}
        />
      );
    case 'CHECK_BOX':
      return (
        <CheckBoX
          handleAssessmentCorrectAnswer={props.handleAssessmentCorrectAnswer}
          surveyType={props.surveyType}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          questionInfo={props.questionInfo}
          setQuestionsInfo={props.setQuestionsInfo}
          surveyId={props.surveyId}
          handleQuestionTypeValues={props.handleQuestionTypeValues}
          addMoreOption={props.addMoreOption}
          removeMoreOption={props.removeMoreOption}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          handleScore={props.handleScore}
          addPredefinedOption={props.addPredefinedOption}
          handleChange={props.handleChangeQuestion}
          enableSideMenuBulkAnswer={props.enableSideMenuBulkAnswer}
          handleScoreInputBoxEdit={props.handleScoreInputBoxEdit}
        />
      );
    case 'DROPDOWN':
      return (
        <Dropdown
          handleAssessmentCorrectAnswer={props.handleAssessmentCorrectAnswer}
          surveyType={props.surveyType}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          questionInfo={props.questionInfo}
          setQuestionsInfo={props.setQuestionsInfo}
          surveyId={props.surveyId}
          handleQuestionTypeValues={props.handleQuestionTypeValues}
          addMoreOption={props.addMoreOption}
          removeMoreOption={props.removeMoreOption}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          handleScore={props.handleScore}
          addPredefinedOption={props.addPredefinedOption}
          handleChange={props.handleChangeQuestion}
          enableSideMenuBulkAnswer={props.enableSideMenuBulkAnswer}
          handleScoreInputBoxEdit={props.handleScoreInputBoxEdit}
        />
      );
    case 'SHORT_ANSWER':
      return (
        <ShortQuestion
          surveyType={props.surveyType}
          handleQuestionTypeInputBox={props.handleQuestionTypeInputBox}
          // handleQuestionTypeDropdown={props.handleQuestionTypeDropdown}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
        />
      );
    case 'LONG_ANSWER':
      return (
        <LongQuestion
          surveyType={props.surveyType}
          handleQuestionTypeInputBox={props.handleQuestionTypeInputBox}
          // handleQuestionTypeDropdown={props.handleQuestionTypeDropdown}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
        />
      );
    case 'RATING_SCALE':
      return (
        <RatingScale
          surveyType={props.surveyType}
          handleRatingScaleDropdownString={
            props.handleRatingScaleDropdownString
          }
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
          handleQuestionTypeInputBox={props.handleQuestionTypeInputBox}
          handleQuestionTypeDropdown={props.handleQuestionTypeDropdown}
        />
      );
    case 'STAR_RATING':
      return (
        <StarRating
          surveyType={props.surveyType}
          handleQuestionTypeDropdown={props.handleQuestionTypeDropdown}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
        />
      );
    case 'MATRIX_RADIO_BOX':
      return (
        <MultipleChoiceGrid
          surveyType={props.surveyType}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
          handleGridData={props.handleGridData}
          handleAddGridDataRow={props.handleAddGridDataRow}
          handleAddGridDataColumn={props.handleAddGridDataColumn}
          handleGridDataColumn={props.handleGridDataColumn}
          handleGridDataColumnScore={props.handleGridDataColumnScore}
          handleDeleteGridRow={props.handleDeleteGridRow}
          handleDeleteGridColumn={props.handleDeleteGridColumn}
        />
      );
    case 'MATRIX_CHECK_BOX':
      return (
        <CheckboxGrid
          surveyType={props.surveyType}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          handleGridDataColumnScore={props.handleGridDataColumnScore}
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
          handleGridData={props.handleGridData}
          handleAddGridDataRow={props.handleAddGridDataRow}
          handleAddGridDataColumn={props.handleAddGridDataColumn}
          handleGridDataColumn={props.handleGridDataColumn}
          handleDeleteGridRow={props.handleDeleteGridRow}
          handleDeleteGridColumn={props.handleDeleteGridColumn}
        />
      );
    case 'DATE':
      return (
        <Date
          surveyType={props.surveyType}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          questionInfo={props.questionInfo}
          setQuestionsInfo={props.setQuestionsInfo}
          surveyId={props.surveyId}
          handleDateQuestionType={props.handleDateQuestionType}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          handleChange={props.handleChangeQuestion}
        />
      );
    case 'TIME':
      return (
        <Time
          surveyType={props.surveyType}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
        />
      );
    case 'FULL_NAME':
      return (
        <FullName
          surveyType={props.surveyType}
          handleFullNameCheckbox={props.handleFullNameCheckbox}
          handleFullName={props.handleFullName}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
        />
      );
    case 'EMAIL_TEXTBOX':
      return (
        <Email
          surveyType={props.surveyType}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
        />
      );
    case 'NUMERIC_TEXTBOX':
      return (
        <Number
          surveyType={props.surveyType}
          handleQuestionTypeInputBox={props.handleQuestionTypeInputBox}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
        />
      );
    case 'FILE_UPLOAD':
      return (
        <FileUpload
          surveyType={props.surveyType}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChangeQuestion}
          questionInfo={props.questionInfo}
        />
      );
    default:
      return (
        <SingleChoice
          handleAssessmentCorrectAnswer={props.handleAssessmentCorrectAnswer}
          surveyType={props.surveyType}
          handleScoreInputBoxEdit={props.handleScoreInputBoxEdit}
          pageIndex={props.pageIndex}
          questionIndex={props.questionIndex}
          questionInfo={props.questionInfo}
          setQuestionsInfo={props.setQuestionsInfo}
          surveyId={props.surveyId}
          handleQuestionTypeValues={props.handleQuestionTypeValues}
          addMoreOption={props.addMoreOption}
          removeMoreOption={props.removeMoreOption}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          handleScore={props.handleScore}
          addPredefinedOption={props.addPredefinedOption}
          handleChange={props.handleChangeQuestion}
          enableSideMenuBulkAnswer={props.enableSideMenuBulkAnswer}
        />
      );
  }
};
export default RenderQuestionType;
