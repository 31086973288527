import React, { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {Col } from 'reactstrap';

interface Props {}
const SurveyPublishSuccess: FunctionComponent<Props> = (props) => {
  const [isLeftMenu, manageMenu] = useState(false);
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  
  return (
    <>
      <div className="page-layout page-layout-full">
        <div className="page-height page-padding">
          <Col lg={{ size: 10, offset: 1 }}>
            <div className="mb-5">
              <img
                src="/images/banner.svg"
                alt=""
                className="img-fluid w-100"
              />
            </div>
            <h1 className="text-center">Survey Creation successful.</h1>
            <div className="btn-group-margin text-center mt-4">
              {/* <p>
                Survey will become active according to the date and time
                configured
              </p> */}
            </div>
            <div className="btn-group-margin text-center mt-4">
              {/* <Button 
               className="btn btn-broder"
               onClick={()=>copyToClipboard(window.location.origin+"/take-surveys/"+getValue(props,'match.params.id',''))}
               >
                Copy Invite link
              </Button> */}
              <NavLink className="btn btn-blue" to="/home">
                Go to Homepage
              </NavLink>
            </div>
          </Col>
        </div>
      </div>
    </>
  );
};
export default SurveyPublishSuccess;
