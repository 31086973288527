import * as React from 'react';
import { Label, Input, Col, Row } from 'reactstrap';
import { getValue } from '../../core/lodash';
import PreviewAdvancedOptions from './previewAdvancedOptions';

interface Props {
  options: any;
  questions: any;
  handleUserSurveyResponseDropdown: (value: object) => void;
  answer: any[];
  // validation
  required: boolean;
  // advanced option
  requiredErrors: any;
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string,
    comments: string,
    other: string
  ) => void;
}
const Dropdown: React.FunctionComponent<Props> = (props) => {
  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === getValue(props.questions, 'id', '')
  );
  React.useEffect(() => {
    if (getValue(props.questions, 'otherOptionEnabled', '')) {
      getValue(props, 'options', []).push({
        text: 'Others (Please specify)',
        id: [-1],
      });
    } else {
    }
  }, []);
  return (
    <>
      <Row>
        <Col md="6">
          <Input
            type="select"
            onChange={(e) => {
              props.handleUserSurveyResponseDropdown({
                questionId: getValue(props.questions, `id`, ''),
                questionType: getValue(props.questions, 'type', ''),
                response: e.target.value,
                commentOption: getValue(currentValue[0], 'commentOption', ''),
                otherOption: getValue(currentValue[0], 'otherOption', ''),
              });
            }}
          >
            <option>Select</option>
            {getValue(props, 'options', []).map((opt) => {
              return (
                <>
                  <option
                    value={opt.id}
                    selected={
                      opt.id === getValue(currentValue[0], 'response', '')
                    }
                  >
                    {getValue(opt, 'text', '')}
                  </option>
                </>
              );
            })}
          </Input>
          <br></br>
          {getValue(currentValue[0], `response`, '') === '-1' ? (
            <>
              <div className="mb-2">
                <div className="pl-2 ml-4">
                  <div className="form-group">
                    <Input
                      type="text"
                      placeholder="Enter Text here"
                      value={getValue(currentValue[0], 'otherOption', '')}
                      name="otherOption"
                      onChange={(e) =>
                        props.handleChangeTakeSurveyAdvancedOptionInput(
                          e,
                          getValue(props.questions, 'id', ''),
                          'otherOption',
                          getValue(currentValue[0], 'commentOption', ''),
                          getValue(currentValue[0], 'otherOption', '')
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          <PreviewAdvancedOptions
            questions={props.questions}
            answer={props.answer}
            handleChangeTakeSurveyAdvancedOptionInput={
              props.handleChangeTakeSurveyAdvancedOptionInput
            }
          />
          <div className="form-error" style={{ color: 'red' }}>
            {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
              ? props.questions.mandatoryMsg
              : ''}
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Dropdown;
