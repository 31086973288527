import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
class LoaderCommon extends Component {
  render() {
    return (
      <>
        <div className="loader-common section-loader">
          <Container fluid={true}>
            <Row>
              <Col lg={{ size: 5, offset: 1 }}>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
              </Col>
              <Col lg={{ size: 5 }}>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
              </Col>
              <Col lg={{ size: 10, offset: 1 }}>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
                <div className="shimmerCommonHeading animateLoader"></div>
                <div className="shimmerCommonText animateLoader"></div>
                <div className="shimmerCommonText animateLoader mr-5"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export default LoaderCommon;
