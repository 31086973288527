import LeftNav from "../../components/menu/left-nav";
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import {
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SearchSVG from "components/svg/search-svg";
import CloseSVG from "components/svg/close-svg";
import {
  createUser,
  exportUsers,
  getAllUsers,
  importUsers,
  resendEmailsNotVerified,
  updateUser,
} from "core/services/users.service";
import { QueryRequestHelper } from "common/query-request-helper";
import { getValue } from "core/lodash";
import LoaderCommon from "components/common/loader-common";
import { useHistory } from "react-router";
import NoData from "../../components/Nodata";
import Pagination from "react-js-pagination";
import AddSVG from "components/svg/add-svg";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import InputField from "common/InputRuleFeild";
import { getAllDepartments } from "core/services/departments.service";
import { getAllLocations } from "core/services/locations.service";
import ResendSvgComponent from "../../assets/svg/resend";
import { inviteUser } from "core/services/users.service";
import { updateUserStatusBulk } from "core/services/users.service";
import UploadSVG from "components/svg/upload-svg";
import DownloadSVG from "components/svg/download-svg";
import { ToastHandler } from "common/toast";
import { saveAs } from "file-saver";
import MoreSVG from "components/svg/more-svg";

function SurveyUsers() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const history = useHistory();
  /* -------------------------------------------------------------------------- */
  /*                                tab/Nav Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(urlParams).length === 0) {
      getData();
    }
    getDepartments();
    getLocations();
  }, []);
  useEffect(() => {
    if (Object.keys(urlParams).length > 0) {
      getData();
      if (getValue(urlParams, `text`, "")) {
        setSearchInput(getValue(urlParams, `text`, ""));
        openSearch(true);
      }
      if (getValue(urlParams, `skip`, "")) {
        setSkip(parseInt(getValue(urlParams, `skip`, "")));
      }
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setUsersList] = React.useState<[]>([]);

  const getData = async () => {
    let payload: any = {
      skip: getValue(urlParams, `skip`, ""),
      take: limit,
      sort: sort,
      text: getValue(urlParams, `text`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllUsers(queryRequest);
      if (resp) {
        setUsersList(getValue(resp, `data.users`, []));
        setTotalCount(getValue(resp, `data.count`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                             Pagination section                             */
  /* -------------------------------------------------------------------------- */
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState<any>(0);
  const [sort, setSort] = useState("newest");

  const onProductPageChangeHandler = (pageNumber) => {
    if (pageNumber == 1) {
      setPageNumber(pageNumber);
      let skip = "0";
      setSkip(skip);
      let payload = {
        ...urlParams,
        skip: skip,
      };
      let queryRequest = QueryRequestHelper(payload);
      history.push(`${window.location.pathname}?${queryRequest}`);
    } else {
      setPageNumber(pageNumber);
      let skip = (pageNumber - 1) * 10;
      setSkip(skip);
      let payload = {
        ...urlParams,
        skip: skip,
      };
      let queryRequest = QueryRequestHelper(payload);
      history.push(`${window.location.pathname}?${queryRequest}`);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Search section                             */
  /* -------------------------------------------------------------------------- */

  const [search, openSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    let payload = {
      ...urlParams,
      text: value,
    };
    let queryRequest = QueryRequestHelper(payload);
    history.push(`${window.location.pathname}?${queryRequest}`);
  };
  const toggleSearch = () => {
    openSearch(!search);
    setSearchInput("");
    delete urlParams.text;
    let payload = {
      ...urlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    history.push(`${window.location.pathname}?${queryRequest}`);
    if (searchInput) {
      getData();
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Add section                              */
  /* -------------------------------------------------------------------------- */
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [roles] = useState([
    { value: "participator", label: "Participator" },
    { value: "creator", label: "Creator" },
    { value: "reporter", label: "Reporter" },
    { value: "admin", label: "Admin" },
  ]);

  const getDepartments = async () => {
    let payload: any = {
      skip: getValue(urlParams, `skip`, ""),
      take: 100,
      sort: sort,
      text: getValue(urlParams, `text`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllDepartments(queryRequest);
      if (resp) {
        setDepartments(
          getValue(resp, `data.departments`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
        // setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getLocations = async () => {
    let payload: any = {
      skip: getValue(urlParams, `skip`, ""),
      take: 100,
      sort: sort,
      text: getValue(urlParams, `text`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllLocations(queryRequest);
      if (resp) {
        setLocations(
          getValue(resp, `data.locations`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
        // setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const intialState = {
    first_name: "",
    last_name: "",
    email: "",
    empId: "",
    mobile_number: "",
    send_invite: true,
    // gender: "",
    roles: [],
    location_id: "",
    department_id: "",
  };
  const [request, setRequest] = useState<any>(intialState);
  const reset = () => {
    setRequest({ ...request, ...intialState });
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp;
        let payload = JSON.parse(JSON.stringify(request));
        payload["roles"] = getValue(request, `roles`, []).map((item: object) =>
          getValue(item, `value`, "")
        );

        Object.keys(payload).forEach((key) => {
          if (
            (!payload[key] ||
              payload[key] === undefined ||
              (Array.isArray(payload[key]) && payload[key].length === 0)) &&
            typeof payload[key] !== "boolean"
          ) {
            delete payload[key];
          }
        });

        if (editId) {
          resp = await updateUser(editId, payload);
        } else {
          resp = await createUser(payload);
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""), {
            className: "green-circle",
          });
          setSubmitLoading(false);
          getData();
          reset();
          toggleModal();
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const [editId, setEditId] = useState("");
  const handleEdit = (obj) => {
    setEditId(getValue(obj, `id`, ""));
    const filteredRoles = roles.filter(
      (role) => role.value === "participator" || role.value === "creator"
    );

    setRequest({
      ...request,
      first_name: getValue(obj, `first_name`, ""),
      last_name: getValue(obj, `last_name`, ""),
      email: getValue(obj, `email`, ""),
      empId: getValue(obj, `empId`, ""),
      mobile_number: getValue(obj, `mobile_number`, ""),
      // send_invite: true,
      // gender: getValue(obj, `name`, ""),
      roles: roles.filter((role) => obj.roles.includes(role.value)),
      location_id: getValue(obj, `location.id`, ""),
      department_id: getValue(obj, `department.id`, ""),
    });
    toggleModal();
  };

  const handleSendInv = async (id: string) => {
    try {
      let resp = await inviteUser(id);
      if (resp) {
        toast.success(getValue(resp, `message`, ""), {
          className: "green-circle",
        });
      }
    } catch (error) {}
  };

  const handleChangeStaus = async (id: string, status: boolean) => {
    let payload = {
      ids: [id],
      is_active: status,
    };
    try {
      let resp = await updateUserStatusBulk(payload);
      if (resp) {
        toast.success(getValue(resp, "message", ""), {
          className: "green-circle",
        });
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Upload section                             */
  /* -------------------------------------------------------------------------- */
  const handleToggleUpload = () => {
    setIsUpload(!isUpload);
  };
  const [isUpload, setIsUpload] = useState(false);
  const [uploadData, setUploadData] = useState("");

  const importUser = async (e) => {
    setUploadData(e.target.files[0]);
  };
  const [uploadLoading, setUploadLoading] = useState(false);
  const UploadImportUser = async () => {
    try {
      setUploadLoading(true);
      let formData = new FormData();
      formData.append("file", uploadData);
      let resp = await importUsers(formData);
      if (resp) {
        handleToggleUpload();
        setUploadLoading(false);
        getData();
      } else {
        handleToggleUpload();
        setUploadLoading(false);
      }
    } catch (error) {
      setUploadLoading(false);
    }
  };

  const handleDownloadUserReport = async () => {
    let resp = await exportUsers();
    //console.log(resp)
    if (getValue(resp, `data`, "")) {
      saveAs(resp.data, "User");
    } else {
      ToastHandler("Software Update", "red-circle");
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                            Resend Invite section                           */
  /* -------------------------------------------------------------------------- */

  const [resendLoading, setResendLoading] = useState(false);
  const handleResendInvite = async () => {
    try {
      setResendLoading(true);
      let resp = await resendEmailsNotVerified();
      if (resp) {
        setResendLoading(false);
      } else {
        setResendLoading(false);
      }
    } catch (error) {
      setResendLoading(false);
    }
  };

  return (
    <div>
      <div className={"page-layout " + (isLeftNav ? "active" : "")}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="create-survey-tabs create-survey-tabs-search d-flex justify-content-between align-items-center">
            <div>
              <ul
                className="table-header-actions"
                onClick={() => {
                  reset();
                  toggleModal();
                }}
              >
                <li className="orange-circle">
                  <Button color="primary">
                    <AddSVG />
                  </Button>
                </li>
              </ul>
            </div>
            <div>
              <Nav pills className="basic-tabs pl-0">
                <NavItem>
                  <NavLink1
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Users
                  </NavLink1>
                </NavItem>
              </Nav>
            </div>
            <div className="d-flex align-items-center" style={{ width: "15%" }}>
              <Button
                color="nothing"
                onClick={handleDownloadUserReport}
                style={{ marginRight: "-30px" }}
                className="mx-2"
              >
                <DownloadSVG />
              </Button>
              <div>
                <Col lg="11" className="text-right">
                  <div className="btn-group-margin">
                    <>
                      <Button
                        color="icon"
                        className="icon-upload-drop mb-0 mr-2"
                      >
                        <div onClick={handleToggleUpload}>
                          <UploadSVG />
                        </div>
                        {isUpload && (
                          <>
                            <div className="upload-drop">
                              <input
                                type="file"
                                name="xlsFile"
                                id="xlsFile"
                                accept=".xls, .xlsx"
                                onChange={importUser}
                              />
                              <Button
                                color="nothing"
                                className="m-0"
                                onClick={handleToggleUpload}
                              >
                                <CloseSVG />
                              </Button>
                              <div className="text-right">
                                <Button
                                  color="border"
                                  className="px-2 m-0 text-blue py-0"
                                  onClick={UploadImportUser}
                                  disabled={uploadLoading}
                                >
                                  {uploadLoading ? "Uploading..." : "Upload"}
                                </Button>
                              </div>
                            </div>
                          </>
                        )}
                      </Button>
                    </>
                  </div>
                </Col>
                <div className="hover-circle">
                  <Button color="nothing" onClick={toggleSearch}>
                    <SearchSVG />
                  </Button>
                  <div className={"table-search " + (search ? "show" : "")}>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      onChange={handleChangeSearch}
                      value={searchInput}
                    />
                    <Button
                      color="nothing"
                      onClick={toggleSearch}
                      className="table-search-close"
                    >
                      <CloseSVG />
                    </Button>
                  </div>
                </div>
              </div>
              <UncontrolledDropdown>
                <DropdownToggle color="nothing">
                  <MoreSVG />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => !resendLoading && handleResendInvite()}
                  >
                    {resendLoading ? "Loading..." : "Resend Invite"}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <div className="page-padding pt-0">
            {isLoading ? (
              <LoaderCommon />
            ) : getValue(list, `length`, 0) > 0 ? (
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Table className="table-custom">
                    <thead>
                      <tr>
                        <th>Emp ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>Department</th>
                        <th>Location</th>
                        <th>Roles</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item: object) => {
                        return (
                          <tr
                            onClick={() => handleEdit(item)}
                            className="cursor-pointer"
                          >
                            <td>{getValue(item, `empId`, "")}</td>
                            <td>
                              {getValue(item, `first_name`, "")}{" "}
                              {getValue(item, `last_name`, "")}
                            </td>
                            <td>{getValue(item, `email`, "")}</td>
                            <td>{getValue(item, `mobile_number`, "")}</td>
                            <td>{getValue(item, `department.name`, "")}</td>
                            <td>{getValue(item, `location.name`, "")}</td>
                            <td>
                              {getValue(item, `roles`, []).map(
                                (role: object) => {
                                  return <p className="ms-2">{role}</p>;
                                }
                              )}
                            </td>
                            <td className="d-flex align-items-center ">
                              <div
                                // onClick={(e: any) => {
                                //   e.stopPropagation();
                                //   handleChangeStaus(
                                //     getValue(item, `id`, ""),
                                //     getValue(item, `is_active`, "")
                                //       ? false
                                //       : true
                                //   );
                                // }}
                                className="ms-3"
                              >
                                <div className="choicelist-wrapper custom-switch-wrapper ">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={getValue(
                                        item,
                                        `is_active`,
                                        false
                                      )}
                                      onChange={(e: any) => {
                                        e.stopPropagation();
                                        handleChangeStaus(
                                          getValue(item, `id`, ""),
                                          getValue(item, `is_active`, "")
                                            ? false
                                            : true
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>{" "}
                              <div
                                className="mx-3"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  handleSendInv(getValue(item, `id`, ""));
                                }}
                              >
                                {!getValue(item, `is_verified`, false) && (
                                  <ResendSvgComponent />
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
            ) : (
              <NoData />
            )}
          </div>
          {totalCount > 10 && (
            <div className="pagination-container">
              <div></div>
              <div className="d-flex align-items-center">
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={limit}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={onProductPageChangeHandler}
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
                <p className="mx-2">|</p>
                <h6>Total count : {totalCount}</h6>
              </div>
            </div>
          )}
        </div>
        <Modal
          isOpen={isOpen}
          toggle={toggleModal}
          centered
          className="modal-lg"
        >
          <ModalHeader>
            <h6 className="header_text">User</h6>
          </ModalHeader>
          <ModalBody className="">
            <div className="p-1 grid-container">
              <div className="">
                <label htmlFor="email">Employee ID *</label>
                <InputField
                  inputType="TEXT"
                  id="empId"
                  name="empId"
                  value={getValue(request, `empId`, "")}
                  onChange={(e: any) =>
                    setRequest({ ...request, empId: e.target.value })
                  }
                  placeholder="Enter Employee ID"
                  className="form-control"
                  label="Employee ID"
                  validator={simpleValidator}
                />
              </div>
              <div className="">
                <label htmlFor="email">First Name *</label>
                <InputField
                  inputType="TEXT"
                  id="first_name"
                  name="first_name"
                  value={getValue(request, `first_name`, "")}
                  onChange={(e: any) =>
                    setRequest({ ...request, first_name: e.target.value })
                  }
                  placeholder="Enter First Name"
                  className="form-control"
                  label="First Namee"
                  validator={simpleValidator}
                />
              </div>
              <div className="">
                <label htmlFor="email">Last Name *</label>
                <InputField
                  inputType="TEXT"
                  id="last_name"
                  name="last_name"
                  value={getValue(request, `last_name`, "")}
                  onChange={(e: any) =>
                    setRequest({ ...request, last_name: e.target.value })
                  }
                  placeholder="Enter Last Name"
                  className="form-control"
                  label="Last Name"
                  validator={simpleValidator}
                />
              </div>
              <div className="">
                <label htmlFor="email">Email *</label>
                <InputField
                  inputType="EMAIL"
                  id="email"
                  name="email"
                  value={getValue(request, `email`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      email: e.target.value.toLowerCase(),
                    })
                  }
                  placeholder="Enter Email"
                  className="form-control"
                  label="Email"
                  validator={simpleValidator}
                />
              </div>
              <div className="">
                <label htmlFor="email">Mobile Number</label>
                <InputField
                  inputType="TEXT"
                  id="mobile_number"
                  name="mobile_number"
                  value={getValue(request, `mobile_number`, "")}
                  onChange={(e: any) =>
                    setRequest({ ...request, mobile_number: e.target.value })
                  }
                  placeholder="Enter Mobile Number"
                  className="form-control"
                  label="Mobile Number"
                  // validator={simpleValidator}
                />
              </div>
              <div className="">
                <label htmlFor="email">Location</label>
                <InputField
                  inputType="INPUT_REACT_SELECT"
                  id="location_id"
                  name="location_id"
                  options={locations}
                  value={locations.filter(
                    (item: object) =>
                      getValue(item, `id`, "") ==
                      getValue(request, `location_id`, "")
                  )}
                  onChange={(e: any) =>
                    setRequest({ ...request, location_id: e.value })
                  }
                  placeholder="Choose Location"
                  className="form-control"
                  label="Mobile Number"
                  // validator={simpleValidator}
                />
              </div>
              <div className="">
                <label htmlFor="email">Department</label>
                <InputField
                  inputType="INPUT_REACT_SELECT"
                  id="department_id"
                  name="department_id"
                  options={departments}
                  value={departments.filter(
                    (item: object) =>
                      getValue(item, `id`, "") ==
                      getValue(request, `department_id`, "")
                  )}
                  onChange={(e: any) =>
                    setRequest({ ...request, department_id: e.value })
                  }
                  placeholder="Choose Department"
                  className="form-control"
                  label="Department"
                  // validator={simpleValidator}
                />
              </div>
              <div className="">
                <label htmlFor="email">Roles *</label>
                <InputField
                  isMulti
                  inputType="INPUT_REACT_SELECT"
                  id="roles"
                  name="roles"
                  options={roles}
                  value={getValue(request, `roles`, [])}
                  onChange={(e: any) => setRequest({ ...request, roles: e })}
                  placeholder="Choose Roles"
                  className="form-control"
                  label="Roles"
                  validator={simpleValidator}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="" color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
            <Button className="" color="blue" onClick={handleSubmit}>
              {submitLoading ? "Please wait..." : "Submit"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default SurveyUsers;
