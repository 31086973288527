import * as React from 'react';
import { Label, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';
import PreviewAdvancedOptions from './previewAdvancedOptions';
import _ from 'lodash';
interface Props {
  options: any;
  questions: any;
  handleUserSurveyResponseDropdownArray: (
    questionId: string,
    questionType: string,
    response: any,
    comment: string,
    other: string
  ) => void;
  answer: any[];
  // validation
  required: boolean;
  // advanced option
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string
  ) => void;
  requiredErrors: any;
  isLearning: boolean;
}

const MultipleChoice: React.FunctionComponent<Props> = (props) => {
  const [options, setOptions] = React.useState([]);
  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === getValue(props.questions, 'id', '')
  );

  React.useEffect(() => {
    if (
      getValue(props.questions, 'randomEnabled', '') &&
      getValue(currentValue[0], 'response.length', 0) === 0
    ) {
      // generating random array indexs
      for (var a:any = [], i = 0; i < getValue(props, 'options.length', 0); ++i)
        a[i] = i;
      a = shuffle(a);
      function shuffle(array) {
        var tmp,
          current,
          top = array.length;
        if (top)
          while (--top) {
            current = Math.floor(Math.random() * (top + 1));
            tmp = array[current];
            array[current] = array[top];
            array[top] = tmp;
          }
        return array;
      }
      // using random array index to changing the position of object index
      function moveElement(array, initialIndex, finalIndex) {
        array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
        return array;
      }
      setOptions(moveElement(getValue(props, 'options', []), a[0], a[1]));
    } else {
      setOptions(getValue(props, 'options', []));
    }
  }, [getValue(props, 'options', [])]);
  return (
    <>
      {options &&
        options.map((opt:any, i) => {
          return (
            <>
              <div className="mb-2">
                <Label className="radio-container d-inline-block">
                  {getValue(opt, 'text', '')}
                  {/* assessment condition starts */}

                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {props.isLearning && (getValue(opt, 'correct', false) ?
                    (getValue(currentValue[0], 'response.length', 0) > 0)
                    && <span style={{ color: 'green' }}>Correct answer</span> :

                    (getValue(props, 'answer', []).filter(
                      (item:any) => item.response[0] === opt.id
                    ).length !== 0)
                    && <span style={{ color: 'red' }}>Wrong answer</span>)}

                  {/* assessment condition ends */}
                  <input
                    type="radio"
                    checked={
                      getValue(props, 'answer', []).filter(
                        (item) => item.response[0] === opt.id
                      ).length !== 0
                        ? true
                        : false
                    }
                    onChange={() => {
                      props.handleUserSurveyResponseDropdownArray(
                        opt.questionId,
                        getValue(props.questions, 'type', ''),
                        opt.id,
                        getValue(currentValue[0], 'commentOption', ''),
                        getValue(currentValue[0], 'otherOption', '')
                      );
                    }}
                  />
                  <span className="checkmark"></span>
                </Label>
              </div>
            </>
          );
        })}
      {getValue(props, 'questions.otherOptionEnabled', '') ? (
        <>
          <div className="mb-2">
            <Label className="radio-container d-inline-block">
              {/* Other (Please specify) */}
              {getValue(props, 'questions.otherOptionMsg', '')}
              <input
                type={'radio'}
                checked={
                  getValue(props, 'answer', []).filter(
                    (item) =>
                      item.response[0] === -1 &&
                      item.questionId === getValue(props.questions, 'id', '')
                  ).length !== 0
                    ? true
                    : false
                }
                onChange={() => {
                  props.handleUserSurveyResponseDropdownArray(
                    getValue(props.questions, 'id', ''),
                    getValue(props.questions, 'type', ''),
                    -1,
                    getValue(currentValue[0], 'commentOption', ''),
                    getValue(currentValue[0], 'otherOption', '')
                  );
                }}
              />
              <span className="checkmark"></span>
            </Label>
          </div>
          {getValue(currentValue[0], `response[${0}]`, '') === -1 ? (
            <div className="pl-2 ml-4">
              <div className="form-group">
                <Input
                  type="text"
                  placeholder="Enter Text here"
                  value={getValue(currentValue[0], 'otherOption', '')}
                  name="otherOption"
                  onChange={(e) =>
                    props.handleChangeTakeSurveyAdvancedOptionInput(
                      e,
                      getValue(props.questions, 'id', ''),
                      'otherOption'
                    )
                  }
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}

      <PreviewAdvancedOptions
        questions={props.questions}
        answer={props.answer}
        handleChangeTakeSurveyAdvancedOptionInput={
          props.handleChangeTakeSurveyAdvancedOptionInput
        }
      />
      <div className="form-error" style={{ color: 'red' }}>
        {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
          ? props.questions.mandatoryMsg
          : ''}
      </div>
    </>
  );
};
export default MultipleChoice;
