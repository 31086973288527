export const SET_SURVEY = "surveyActionTypes/SET_SURVEY";
export interface SetSurveyAction {
  type: typeof SET_SURVEY;
  survey: object;
}

export const GET_SURVEY = "surveyActionTypes/GET_SURVEY";
export interface GetSurveyAction {
  type: typeof GET_SURVEY; 
}

export const GET_LYRICS_REQUEST = "surveyActionTypes/GET_LYRICS_REQUEST";
export interface GetLyricsRequestAction {
  type: typeof GET_LYRICS_REQUEST;
}

export const GET_LYRICS_SUCCESS = "surveyActionTypes/GET_LYRICS_SUCCESS";
export interface GetLyricsSuccessAction {
  type: typeof GET_LYRICS_SUCCESS;
  lyrics: string;
}

export const GET_LYRICS_FAILURE = "surveyActionTypes/GET_LYRICS_FAILURE";
export interface GetLyricsFailureAction {
  type: typeof GET_LYRICS_FAILURE;
  error: Error | string;
}

export type SurveyAction =
  | SetSurveyAction
  | GetSurveyAction
  | GetLyricsRequestAction
  | GetLyricsSuccessAction
  | GetLyricsFailureAction;
