import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  Modal,
  ModalBody,
} from 'reactstrap';
import LeftNav from '../../components/menu/left-nav';
import {
  deleteSurvey,
  getCompletedSurveys,
  getSummarySurvey,
  getSummarySurveyGraphPagination,
  getSummarySurveyPagination,
  getSurveySummaryResponse,
} from '../../core/services/surveyService';
import { getValue } from '../../core/lodash';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import DeleteSurveyModal from '../../components/common/modal/deleteModal';
import { NavLink } from 'react-router-dom';
import BackSVG from '../../components/svg/back-svg';
import { surveyResponse } from '../../core/services/completedSurveyService';
import SummaryPreview from './summaryPreview';
import ReactApexChart from 'react-apexcharts'
import SummarySurveyQuestion from './summary/summarySurveyQuestions360';

interface ITemplateMainProps { }

const Summary: React.FunctionComponent<ITemplateMainProps> = (props) => {
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [search, openSearch] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [surveyInfo, setSurveyInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState('newest')

  // summary
  const [summaryInfo, setSummaryInfo] = useState([]);
  const [
    summaryQuestionsWithoutAnswers,
    setSummaryQuestionsWithoutAnswers,
  ] = useState([]);
  const [
    summaryQuestionsWithoutAnswersQuestions,
    setSummaryQuestionsWithoutAnswersQuestions,
  ] = useState([]);

  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);

  // delete
  const [isOpen, setIsOPen] = useState(false);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [removeValue, setRemoveValue] = useState('');
  const [deleteId, setDeleteId] = useState('');

  const handleModal = () => {
    setIsOPen(!isOpen);
  };

  const handleDeleteSurvey = async (id, value) => {
    setDeleteId(id);
    setRemoveValue(value);
    handleModal();
  };




  // ===========================================================================
  // handle change section
  // ===========================================================================

  const handleDeleteFun = async () => {
    try {
      let resp = await deleteSurvey(deleteId);
      if (resp) {
        toast.success('Deleted successfully'
          , { className: 'green-circle' }
        );
        handleModal();
        // getOpenSurveyFun();
      }
    } catch (error) {
      handleModal();
    }
  };

  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  // const onProductPageChangeHandler = (pageNumber) => {
  //   setPageNumber(pageNumber);
  //   let skip = (pageNumber - 1) * 10;
  //   setSkip(skip);
  //   getOpenSurveyFun();
  // };

  // ===========================================================================
  // api section
  // ===========================================================================



  useEffect(() => {
    if (activeTab === "1") {
      getSurveySummary();
      getSummaryOptions('')
    }
    else if (activeTab === "2") {
      getSurveyInfo();

    } else {
      getSummerySurveyQuestions();

    }
  }, [activeTab])

  const getSurveyInfo = async () => {
    try {
      setIsLoading(true);
      let resp = await surveyResponse(
        getValue(props, 'match.params.surveyId', ''),
        skip,
        limit
      );
      if (resp) {
        setIsLoading(false);
        setSurveyInfo(getValue(resp, 'data', []));
        // setTotalCount(getValue(resp, 'data.count', []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getSurveySummary = async () => {
    let resp = await getSurveySummaryResponse(
      getValue(props, 'match.params.surveyId', '')
    );
    if (resp) {
      setSummaryInfo(getValue(resp, 'data', {}));
    }
  };

  const getSummerySurveyQuestions = async () => {
    let resp = await getSummarySurvey(
      getValue(props, 'match.params.surveyId', '')
    );
    if (resp) {
      getSummaryOptions(getValue(resp, 'data.surveyPageIds[0]', ''))
      setSummaryQuestionsWithoutAnswers(getValue(resp, 'data', {}));
      setSummaryQuestionsWithoutAnswersQuestions(
        getValue(resp, 'data.page', [])
      );
    }
  };

  const handleChangePage = (index) => {
    togglePage(index);
  }
  
  const togglePage = async (index) => {
    let pageID = getValue(summaryQuestionsWithoutAnswers, `surveyPageIds[${index - 1}]`, 0);
    getSummaryOptions(pageID)
  };

  // ===========================================================================
  // chart section
  // ===========================================================================

  const [series, setSeries] = useState([{
    data: [400, 540, 1200, 1380]
  }])

  const [options, setOptions] = useState<any>({
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: ['Red', 'Blue', 'Green', 'Yellow', "Violet"],
    }
  })

  // 2nd chart

  const [option1, setOption1] = useState<any>({
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%'
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    title: {
      text: '100% Stacked Bar'
    },
    xaxis: {
      categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "K"
        }
      }
    },
    fill: {
      opacity: 1

    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    }
  })

  const [series1, setSeries1] = useState([{
    name: 'Marine Sprite',
    data: [44, 55, 41, 37, 22, 43, 21]
  }, {
    name: 'Striking Calf',
    data: [53, 32, 33, 52, 13, 43, 32]
  }, {
    name: 'Tank Picture',
    data: [12, 17, 11, 9, 15, 11, 20]
  }, {
    name: 'Bucket Slope',
    data: [9, 7, 5, 8, 6, 9, 4]
  }, {
    name: 'Reborn Kid',
    data: [25, 12, 19, 32, 25, 24, 10]
  }])



  const [chartSummary, setChartSummary] = useState([])
  // ===========================================================================
  // summary/graph section
  // ===========================================================================
  const getSummaryOptions = async (pageId) => {
    let resp = await getSummarySurveyGraphPagination(
      getValue(props, 'match.params.surveyId', ''),
      pageId
    );
    if (resp) {
      setChartSummary(getValue(resp, 'data.page', {}))
      setSummaryQuestionsWithoutAnswers(getValue(resp, 'data', {}));
      setSummaryQuestionsWithoutAnswersQuestions(
        getValue(resp, 'data.page', [])
      );
    }
  };


  return (
    <div>
      <div className={'page-layout ' + (isLeftNav ? 'active' : '')}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="create-survey-tabs">
            <Row>
              <Col md="6">
                <div className="back-btn">
                  <NavLink
                    to={`/${getValue(props, `match.params.routeUrl`, '')}`}
                  >
                    <Button color="nothing">
                      <BackSVG />
                    </Button>
                  </NavLink>
                </div>
                <div className=" py-2 mb-0">
                  <h4 className="mb-0 py-1 text-left">
                    {getValue(props, `match.params.routeUrl`, '') ===
                      'inprogress-surveys'
                      ? 'In Progress Surveys'
                      : 'Completed Surveys'}
                  </h4>
                </div>
              </Col>
              {/* <Col md="6">
                <ul className="table-header-actions mt-1">
                  <li className="hover-circle">
                    <Button color="nothing">
                      <DeleteSVG />
                    </Button>
                  </li>
                  <li className="hover-circle">
                    <Button color="nothing">
                      <EditSVG />
                    </Button>
                  </li>
                  <li className="hover-circle">
                    <Button color="nothing">
                      <CopySVG />
                    </Button>
                  </li>
                  <li className="hover-circle">
                    <Button color="nothing">
                      <ShareSVG />
                    </Button>
                  </li>
                </ul>
              </Col> */}
            </Row>
          </div>
          <div className="create-survey-tabs">
            <Nav pills className="basic-tabs pl-0">
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Summary
                </NavLink1>
              </NavItem>
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Individual Responses
                </NavLink1>
              </NavItem>
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggle('3');
                  }}
                >
                  Survey
                </NavLink1>
              </NavItem>
            </Nav>
          </div>
          <div className="page-padding pt-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <SummarySurveyQuestion
                  summaryInfo={summaryInfo}
                  options={options}
                  series={series}
                  toggleModal={toggleModal}
                  option1={option1}
                  series1={series1}
                  questionsInfo={chartSummary}
                  infoData={summaryQuestionsWithoutAnswers}
                  handleSurveySubmit={getSummaryOptions}
                  handleChangePage={handleChangePage}
                  from={"survey"}
                />
              </TabPane>
              <TabPane tabId="2">
                {!isLoading ? (
                  <Table className="table-custom">
                    <thead>
                      <tr>
                        <th>Responder ID</th>
                        <th>Employee ID</th>
                        <th>Completion Date &amp; Time</th>
                        <th>Answered</th>
                        <th>Skipped</th>
                        <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getValue(surveyInfo, 'length', 0) !== 0 ? (
                        surveyInfo.map((survey) => {
                          return (
                            <tr>
                              <td>
                                <NavLink
                                  to={`/survey-preview/${getValue(
                                    survey,
                                    `surveyId`,
                                    ''
                                  )}/${getValue(survey, `id`, '')}/${getValue(
                                    props,
                                    `match.params.routeUrl`,
                                    ''
                                  )}`}
                                  style={{ color: '#d7242e' }}
                                >
                                  {getValue(survey, 'id', '')}
                                </NavLink>
                              </td>
                              <td>{getValue(survey, 'empId', '-')}</td>
                              <td>
                                <Moment format="DD MMM YYYY hh:mm a">
                                  {getValue(survey, 'updatedAt', '')}
                                </Moment>
                              </td>
                              <td>
                                {getValue(survey, 'answered', '')} Questions
                              </td>
                              <td>
                                {getValue(survey, 'skipped', '')} Questions
                              </td>
                              <td>{getValue(survey, 'score.score', '')}/{getValue(survey, 'score.total', '')}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  'Please wait...'
                )}
              </TabPane>
              <TabPane tabId="3">
                <SummaryPreview
                  questionsInfo={summaryQuestionsWithoutAnswersQuestions}
                  infoData={summaryQuestionsWithoutAnswers}
                  handleSurveySubmit={getSummaryOptions}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <DeleteSurveyModal
        isOpen={isOpen}
        handleDeleteFun={handleDeleteFun}
        removeValue={removeValue}
        handleModal={handleModal}
      />
      <Modal isOpen={modal} toggle={toggleModal} centered className="modal-lg">
        <ModalBody>
          <h5 className="mb-1">Question 4:</h5>
          <h3 className="mb-1">What is the capital city of India</h3>
          <hr className="my-2" />
          <ol className="mt-3 answer-ol">
            <li>Delhi</li>
            <li>Bangalore</li>
            <li>Pune</li>
          </ol>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Summary;
