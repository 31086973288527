import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Card, CardBody, Table, Modal, ModalBody } from 'reactstrap';
import { getValue } from '../../../core/lodash';

interface IChartPreviewProps {
  type: any;
  questions: any;
  option: any;
  qno: number;
}

const ChartPreview1: React.FunctionComponent<IChartPreviewProps> = (props) => {
  const { type, questions, option, qno } = props;
  // ===========================================================================
  // chart section
  // ===========================================================================

  const [seriesChart, setSeries] = React.useState<any>([
    {
      data: [400, 540, 1200, 1380],
    },
  ]);

  const [optionsChart, setOptions] = React.useState<any>({
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Red', 'Blue', 'Green', 'Yellow', 'Violet'],
    },
  });
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  React.useEffect(() => {
    if (
      getValue(props, 'type', '') !== 'RATING_SCALE' &&
      getValue(props, 'type', '') !== 'STAR_RATING'
    ) {
      let categories:any = [];
      getValue(props, 'questions.options', []).map((item) =>
        categories.push(item.text)
      );
      let series:any = [];
      getValue(props, 'questions.options', []).map((item) =>
        series.push(item.resPercentage)
      );
      setOptions({
        ...optionsChart,
        xaxis: {
          categories: categories,
        },
      });
      setSeries((pages) => {
        return pages.map((x, index) => {
          if (index !== 0) return x;
          return {
            ...x,
            data: series,
          };
        });
      });
    } else {
      let categories:any = [];
      getValue(props, 'questions.options', []).map((item) =>
        categories.push(item.id)
      );
      let series:any = [];
      getValue(props, 'questions.options', []).map((item) =>
        series.push(item.resPercentage)
      );
      setOptions({
        ...optionsChart,
        xaxis: {
          categories: categories,
        },
      });
      setSeries((pages) => {
        return pages.map((x, index) => {
          if (index !== 0) return x;
          return {
            ...x,
            data: series,
          };
        });
      });
    }
  }, [option]);
  //console.log(getValue(props, 'questions', ''));
  return (
    <Card className="mb-3">
      <CardBody>
        <h5 className="mb-1">
          {/* Question {getValue(props, 'qno', 1)} */}
          {/* <span className="text-red">*</span> */}
        </h5>
        <h3 className="mb-1">{getValue(props, 'questions.text', '')}</h3>
        <p>
          <span className="mr-3">
            Answered: {getValue(questions, 'resCount', 0)}
          </span>{' '}
          Skipped: {getValue(questions, 'skipped', 0)}
        </p>
        <ReactApexChart
          options={optionsChart}
          series={seriesChart}
          type="bar"
          height={350}
        />
        <h5>Response Data:</h5>
        <div className="table-responsive">
          <Table className="table-head-light">
            <thead>
              <tr>
                <th style={{ width: '40%' }}>
                  {getValue(props, 'type', '') === 'RATING_SCALE' ||
                  getValue(props, 'type', '') === 'STAR_RATING'
                    ? 'Rating'
                    : 'Choices'}
                </th>
                <th style={{ width: '20%' }}>Response percent</th>
                <th style={{ width: '20%' }}>Response count</th>
              </tr>
            </thead>
            <tbody>
              {getValue(props, 'option', []).map((item) => {
                return (
                  <tr>
                    <td>
                      {getValue(props, 'type', '') === 'RATING_SCALE' ||
                      getValue(props, 'type', '') === 'STAR_RATING'
                        ? getValue(item, 'id', '')
                        : getValue(item, 'text', '')}
                    </td>
                    <td>
                      {getValue(item, 'resPercentage', 0) === 'NaN'
                        ? '-'
                        : getValue(item, 'resPercentage', 0) + '%'}
                    </td>
                    <td className="text-green-light">
                      {getValue(item, 'resCount', 0)}
                    </td>
                  </tr>
                );
              })}
              {/* <thead> */}
              {getValue(props, 'questions.otherOptionEnabled', '') ? (
                <>
                  {/* <tr>
                            <th>Other Choice</th>
                            <th>Response percent</th>
                            <th>Response count</th>
                            </tr> */}
                  <tr>
                    <td>
                      {getValue(props, 'questions.otherOption.text', '')}
                      &nbsp;&nbsp;&nbsp;{' '}
                      <span
                        onClick={toggleModal}
                        style={{ color: 'green', cursor: 'pointer' }}
                      >
                        Show{' '}
                        {getValue(
                          props,
                          'questions.otherOption.response.length',
                          0
                        )}{' '}
                        responses
                      </span>
                    </td>
                    <td>
                      {getValue(
                        props,
                        'questions.otherOption.resPercentage',
                        ''
                      )}
                    </td>
                    <td className="text-green-light">
                      {getValue(props, 'questions.otherOption.resCount', '')}
                    </td>
                  </tr>
                </>
              ) : (
                ''
              )}
            </tbody>
            {/* </thead> */}
          </Table>
        </div>
        <br></br>
        {getValue(props, 'questions.totalScore', '') ? (
          <>
            <h5>
              Response Scores:({getValue(props, 'questions.resGetScore', '')}/
              {getValue(props, 'questions.totalScore', '')})
            </h5>
            <div className="table-responsive">
              <Table className="table-head-light">
                <thead>
                  <tr>
                    <th style={{ width: '40%' }}>
                      {getValue(props, 'type', '') === 'RATING_SCALE' ||
                      getValue(props, 'type', '') === 'STAR_RATING'
                        ? 'Rating'
                        : 'Choices'}
                    </th>
                    {/* <th style={{ width: '20%' }}>Survey Data</th> */}
                    <th style={{ width: '20%' }}>Score</th>
                    <th style={{ width: '20%' }}>Total score</th>
                  </tr>
                </thead>
                {getValue(props, 'option', []).map((item) => {
                  return (
                    <tbody>
                      <tr>
                        <td>
                          {getValue(props, 'type', '') === 'RATING_SCALE' ||
                          getValue(props, 'type', '') === 'STAR_RATING'
                            ? getValue(item, 'id', '')
                            : getValue(item, 'text', '')}
                        </td>
                        {/* <td className="text-green-light">140/400</td> */}
                        <td>
                          {getValue(item, 'resGetScore', 0) === 'NaN'
                            ? '-'
                            : getValue(item, 'resGetScore', 0)}
                        </td>
                        <td className="text-green-light">
                          {getValue(item, 'score', 0)}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </Table>
            </div>
          </>
        ) : (
          ''
        )}
      </CardBody>
      <Modal isOpen={modal} toggle={toggleModal} centered className="modal-lg">
        <ModalBody>
          {/* <h5 className="mb-1">Question 4:</h5> */}
          <h3 className="mb-1">{getValue(questions, 'text', '')}</h3>
          <hr className="my-2" />
          <ol className="mt-3 answer-ol">
            {getValue(props, 'questions.otherOption.response', []).map(
              (item, index) => {
                return <li key={index}>{item}</li>;
              }
            )}
          </ol>
        </ModalBody>
      </Modal>
    </Card>
  );
};

export default ChartPreview1;
