import React from 'react';

const Smiley8SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.999"
      height="35.999"
      viewBox="0 0 35.999 35.999"
    >
      <defs>
        <style></style>
      </defs>
      <circle
        className="a"
        style={{ fill: '#feca2e' }}
        cx="17"
        cy="17"
        r="17"
        transform="translate(1 0.999)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M118.286,224.258a8.706,8.706,0,0,1-12.921,0A.782.782,0,0,0,104.2,225.3a10.272,10.272,0,0,0,15.253,0,.783.783,0,0,0-1.168-1.044Z"
        transform="translate(-93.827 -202.085)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M18,0A18,18,0,1,0,36,18,18.02,18.02,0,0,0,18,0Zm0,34.434A16.434,16.434,0,1,1,34.434,18,16.452,16.452,0,0,1,18,34.434Z"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M215.043,144a.783.783,0,0,0-.783.783,2.348,2.348,0,1,1-4.7,0,.783.783,0,1,0-1.565,0,3.913,3.913,0,0,0,7.826,0A.783.783,0,0,0,215.043,144Z"
        transform="translate(-187.653 -129.913)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M87.826,144.783a.783.783,0,1,0-1.565,0,2.348,2.348,0,1,1-4.7,0,.783.783,0,1,0-1.565,0,3.913,3.913,0,0,0,7.826,0Z"
        transform="translate(-72.174 -129.913)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M208.431,81.475l3.13,1.565a.783.783,0,0,0,.7-1.4l-3.13-1.565a.783.783,0,0,0-.7,1.4Z"
        transform="translate(-187.651 -72.166)"
      />
      <path
        className="b"
        style={{ fill: '#6d4c41' }}
        d="M112.777,83.128a.785.785,0,0,0,.349-.082l3.13-1.565a.783.783,0,0,0-.7-1.4l-3.13,1.565a.782.782,0,0,0,.351,1.482Z"
        transform="translate(-101.038 -72.172)"
      />
    </svg>
  </>
);
export default Smiley8SVG;
