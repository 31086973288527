import { config } from 'config';
import { Delete, get, patch, post } from './helpers/http-handler';

export const getAllLocations = (queryRequest: string) =>
  get(`${config.API_URL}location?${queryRequest}`);

export const getSpecificLocation = (id: string) =>
  get(`${config.API_URL}location/${id}`);

export const createLocation = (payload: object) =>
  post(`${config.API_URL}location`, payload);

export const updateLocation = (id: string, payload: object) =>
  patch(`${config.API_URL}location/${id}`, payload);

export const deleteLocation = (id: string) =>
  Delete(`${config.API_URL}location/${id}`);

export const updateLocationsStatusBulk = (payload: object) =>
  patch(`${config.API_URL}location/bulk/active-inactive`, payload);
