import * as actions from "../actionTypes/surveyActionTypes";

export interface SurveyState {
    survey: object;
}

const initialState: SurveyState = {
    survey: {}
};

export default function surveyReducer(
    state: SurveyState = initialState,
    action: actions.SurveyAction
): SurveyState {
    switch (action.type) {
        case actions.SET_SURVEY:
        case actions.GET_SURVEY:
            return {
                survey: action
            };
        default:
            return state;
    }
}
