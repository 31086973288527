import { put, call, takeEvery, all, fork } from 'redux-saga/effects';

import { fetchLyrics } from '../services/surveyService';
import * as actionCreators from '../actionCreators/surveyActionCreators';
import * as actionTypes from '../actionTypes/surveyActionTypes';

function* onLoadLyrics(survey: actionTypes.GetSurveyAction) {
  try {
    yield put(actionCreators.getLyricsRequest());
    const { data } = yield call(fetchLyrics, survey);
    yield put(actionCreators.getLyricsSuccess(data.lyrics));
  } catch (error) {
    // yield put(actionCreators.getLyricsFailure(error.response.data.error));
  }
}

function* watchOnLoadLyrics() {
  yield takeEvery(actionTypes.GET_LYRICS_REQUEST, onLoadLyrics);
}

export default function* SurveySaga() {
  yield all([fork(watchOnLoadLyrics)]);
}
