import * as React from 'react';
import { Label, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface ITimeProps {
  questions: any;
}

const Time: React.FunctionComponent<ITimeProps> = (props) => {
  return (
    <div className="form-group">
      <Input
        type="time"
        value={getValue(props, 'questions.response[0]', '')}
        disabled
      ></Input>
    </div>
  );
};

export default Time;
