import * as React from 'react';
import Dropdown from '../../components/questionTypesPreview/Dropdown';
import ShortQuestion from '../../components/questionTypesPreview/ShortAnswer';
import LongQuestion from '../../components/questionTypesPreview/LongAnswer';
import RatingScale from '../../components/questionTypesPreview/RatingScale';
import StarRating from '../../components/questionTypesPreview/StarRating';
import MultipleChoiceGrid from '../../components/questionTypesPreview/MultipleChoiceGrid';
import CheckboxGrid from '../../components/questionTypesPreview/CheckboxGrid';
import Date from '../../components/questionTypesPreview/Date';
import Time from '../../components/questionTypesPreview/Time';
import FullName from '../../components/questionTypesPreview/FullName';
import Email from '../../components/questionTypesPreview/Email';
import Number from '../../components/questionTypesPreview/Number';
import FileUpload from '../../components/questionTypesPreview/FileUpload';
import SingleChoice from '../../components/questionTypesPreview/MultipleChoice';
import CheckBoX from '../../components/questionTypesPreview/Checkbox';

interface IRenderAnswerPreviewProps {
  param: string;
  options: [];
  questions: any;
}

const RenderAnswerPreview: React.FunctionComponent<IRenderAnswerPreviewProps> = (
  props
) => {
  switch (props.param) {
    case 'SINGLE_CHOICE':
      return (
        <SingleChoice options={props.options} questions={props.questions} />
      );
    case 'CHECK_BOX':
      return <CheckBoX options={props.options} questions={props.questions} />;
    case 'DROPDOWN':
      return <Dropdown options={props.options} questions={props.questions} />;
    case 'SHORT_ANSWER':
      return <ShortQuestion questions={props.questions} />;
    case 'LONG_ANSWER':
      return <LongQuestion questions={props.questions} />;
    case 'RATING_SCALE':
      return <RatingScale questions={props.questions} />;
    case 'STAR_RATING':
      return <StarRating questions={props.questions} />;
    case 'MATRIX_RADIO_BOX':
      return <MultipleChoiceGrid questions={props.questions} />;
    case 'MATRIX_CHECK_BOX':
      return <CheckboxGrid questions={props.questions} />;
    case 'DATE':
      return <Date questions={props.questions} />;
    case 'TIME':
      return <Time questions={props.questions} />;
    case 'FULL_NAME':
      return <FullName questions={props.questions} />;
    case 'EMAIL_TEXTBOX':
      return <Email questions={props.questions} />;
    case 'NUMERIC_TEXTBOX':
      return <Number questions={props.questions} />;
    case 'FILE_UPLOAD':
      return <FileUpload questions={props.questions} />;
    default:
      return (
        <SingleChoice options={props.options} questions={props.questions} />
      );
  }
};

export default RenderAnswerPreview;
