const GENDER = [
  {
    text: 'Male',
    score: 0,
    seqNo: 1,
    uniqueOrder: 'A',
  },
  {
    text: 'Female',
    score: 0,
    seqNo: 2,
    uniqueOrder: 'B',
  },
];
const MARITAL_STATUS = [
  {
    text: 'Single',
    score: 0,

    // seqNo: 1,
    // uniqueOrder: 'A',
  },
  {
    text: 'Married',
    score: 0,

    // seqNo: 2,
    // uniqueOrder: 'B',
  },
  {
    text: 'Divorced',
    score: 0,

    // seqNo: 3,
    // uniqueOrder: 'C',
  },
];
const AGREE_DISAGREE = [
  {
    text: 'Strongly disagree',
    score: 0,

    // seqNo: 1,
    // uniqueOrder: 'A',
  },
  {
    text: 'Disagree',
    score: 0,

    // seqNo: 2,
    // uniqueOrder: 'B',
  },
  {
    text: 'Neutral',
    score: 0,

    // seqNo: 3,
    // uniqueOrder: 'C',
  },
  {
    text: 'Agree',
    score: 0,

    // seqNo: 4,
    // uniqueOrder: 'D',
  },
  {
    text: 'Strongly agree',
    score: 0,

    // seqNo: 5,
    // uniqueOrder: 'E',
  },
];
const GOOD_POOR = [
  {
    text: 'Very poor',
    score: 0,

    // seqNo: 1,
    // uniqueOrder: 'A',
  },
  {
    text: 'Poor',
    score: 0,

    // seqNo: 2,
    // uniqueOrder: 'B',
  },
  {
    text: 'Neutral',
    score: 0,

    // seqNo: 3,
    // uniqueOrder: 'C',
  },
  {
    text: 'Good',
    score: 0,

    // seqNo: 4,
    // uniqueOrder: 'D',
  },
  {
    text: 'Very good',
    score: 0,

    // seqNo: 5,
    // uniqueOrder: 'E',
  },
];

const YES_LIKE = [
  {
    text: 'Yes, I like this',
    score: 0,

    // seqNo: 1,
    // uniqueOrder: 'A',
  },
  {
    text: 'No, I don`t like this',
    score: 0,

    // seqNo: 2,
    // uniqueOrder: 'B',
  },
];

const YES_NO = [
  {
    text: 'Yes',
    score: 0,

    // seqNo: 1,
    // uniqueOrder: 'A',
  },
  {
    text: 'No',
    score: 0,

    // seqNo: 2,
    // uniqueOrder: 'B',
  },
];

const TRUE_FALSE = [
  {
    text: 'True',
    score: 0,

    // seqNo: 1,
    // uniqueOrder: 'A',
  },
  {
    text: 'False',
    score: 0,

    // seqNo: 2,
    // uniqueOrder: 'B',
  },
];
const DAYS = [
  {
    text: 'Sunday',
    score: 0,

    // seqNo: 1,
    // uniqueOrder: 'A',
  },
  {
    text: 'Monday',
    score: 0,

    // seqNo: 2,
    // uniqueOrder: 'B',
  },
  {
    text: 'Tuesday',
    score: 0,

    // seqNo: 3,
    // uniqueOrder: 'C',
  },
  {
    text: 'Wednesday',
    score: 0,

    // seqNo: 4,
    // uniqueOrder: 'D',
  },
  {
    text: 'Thursday',
    score: 0,

    // seqNo: 5,
    // uniqueOrder: 'E',
  },
  {
    text: 'Friday',
    score: 0,

    // seqNo: 6,
    // uniqueOrder: 'F',
  },
  {
    text: 'Saturday',
    score: 0,

    // seqNo: 7,
    // uniqueOrder: 'G',
  },
];

const MONTHS = [
  {
    text: 'January',
    score: 0,

    // seqNo: 1,
    // uniqueOrder: 'A',
  },
  {
    text: 'February',
    score: 0,

    // seqNo: 2,
    // uniqueOrder: 'B',
  },
  {
    text: 'March',
    score: 0,

    // seqNo: 3,
    // uniqueOrder: 'C',
  },
  {
    text: 'April',
    score: 0,

    // seqNo: 4,
    // uniqueOrder: 'D',
  },
  {
    text: 'May',
    score: 0,

    // seqNo: 5,
    // uniqueOrder: 'E',
  },
  {
    text: 'June',
    score: 0,

    // seqNo: 6,
    // uniqueOrder: 'F',
  },
  {
    text: 'July',
    score: 0,

    // seqNo: 7,
    // uniqueOrder: 'G',
  },
  {
    text: 'August',
    score: 0,

    // seqNo: 8,
    // uniqueOrder: 'H',
  },
  {
    text: 'September',
    score: 0,

    // seqNo: 9,
    // uniqueOrder: 'I',
  },
  {
    text: 'October',
    score: 0,

    // seqNo: 10,
    // uniqueOrder: 'J',
  },
  {
    text: 'November',
    score: 0,

    // seqNo: 11,
    // uniqueOrder: 'K',
  },
  {
    text: 'December',
    score: 0,

    // seqNo: 12,
    // uniqueOrder: 'L',
  },
];

export const predefinedOptions = {
  GENDER: GENDER,
  MARITAL_STATUS: MARITAL_STATUS,
  AGREE_DISAGREE: AGREE_DISAGREE,
  GOOD_POOR: GOOD_POOR,
  YES_NO: YES_NO,
  YES_LIKE: YES_LIKE,
  TRUE_FALSE: TRUE_FALSE,
  DAYS: DAYS,
  MONTHS: MONTHS,
};
