import * as React from "react";
import {
  Container,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import AddSVG from "../../components/svg/add-svg";
import { getValue } from "../../core/lodash";
import { ReactMultiEmail } from "react-multi-email";
import DownloadSVG from "../../components/svg/download-svg";
import UploadSVG from "../../components/svg/upload-svg";
import CloseSVG from "../../components/svg/close-svg";

interface InviteParticipantSurveyProps {
  isOpen: boolean;
  //   removeValue: string;
  //   handleDeleteFun: (removeValue: string) => void;
  handleModal: () => void;
  emailTemplateOnchange(e: any, index: number): void;
  simpleValidator: any;
  removeParticipants: (index: number) => void;
  handleEmailTemplateEmail: (
    p: object,
    index: number,
    mainIndex: number
  ) => void;
  add360EmailTemplate(): void;
  loading360: boolean;
  addParticipants(): void;
  participant: any;
  modal: boolean;
  toggleModal: () => void;
  toggleModal1: () => void;
  handleDownloadEmails: () => void;
  errorEmails: any;
  handleToggleUpload: () => void;
  toggleUpload: any;
  import360Emails: (e: any) => void;
  uploadData: any;
  upload360Emails: () => void;
  userName: string;
  getParticipantsEmails: (surveyId: string, empId: string) => void;
  empId: string;
  surveyId: string;
  showClient: boolean;
}

const InviteParticipantSurveyModal: React.FunctionComponent<InviteParticipantSurveyProps> = (
  props
) => {
  return (
    <div>
      <Modal isOpen={props.isOpen} centered className="modal-lg">
        <ModalBody>
          <h4>Invite Participants</h4>
          <p>
            360 survey has been assigned for you and please send the 360 to
            necessary feedback provider if they not part of the below list
          </p>
          {/* <Col lg="11" className="text-right">
                        <div className="btn-group-margin mt-3">
                            <>
                                <Button color="icon" className="icon-upload-drop mb-0 mr-2">
                                    <div onClick={props.handleToggleUpload}>
                                        <UploadSVG />
                                    </div>
                                    {props.toggleUpload && (
                                        <>
                                            <div className="upload-drop">
                                                <input
                                                    type="file"
                                                    name="xlsFile"
                                                    id="xlsFile"
                                                    accept=".xls, .xlsx"
                                                    onChange={props.import360Emails}
                                                />
                                                <Button
                                                    color="nothing"
                                                    className="m-0"
                                                    onClick={props.handleToggleUpload}
                                                >
                                                    <CloseSVG />
                                                </Button>
                                                <div className="text-right">
                                                    <Button
                                                        color="border"
                                                        className="px-2 m-0 text-blue py-0"
                                                        onClick={props.upload360Emails}
                                                    >
                                                        Upload
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Button>
                            </>
                        </div>
                    </Col> */}

          <div className="page-padding">
            <Row>
              <Col lg={{ size: 10, offset: 1 }}>
                <Card>
                  <CardBody>
                    <div className="left-img-name">
                      <img
                        src={"/images/icon.svg"}
                        alt=""
                        width="36"
                        height="36"
                      />
                      <h4 className="detail-heading">360 Feedback</h4>
                      <p className="detail-para">
                        Please enter email addresses of the participants you
                        want to take this survey
                      </p>
                    </div>
                    <hr />
                    {/* repeat row */}
                    <h6>
                      360 Survey for{" "}
                      <span style={{ color: "#276ef1" }}>
                        {getValue(props, "userName", "")}
                      </span>{" "}
                      {" - "}
                      <span
                        className="cursor-pointer"
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => props.toggleModal1()}
                      >
                        Participants Already sent
                      </span>
                    </h6>
                    {getValue(props, `participant`, []).map((_item, index) => {
                      return (
                        <div key={index}>
                          <hr />
                          <Row>
                            {/* <Col md="6">
                                                                <div className="form-group">
                                                                    <Label>Name</Label>
                                                                    <Input
                                                                    type="text"
                                                                    placeholder="Enter Name"
                                                                    value={
                                                                        getValue(
                                                                        props,
                                                                        `participant[${index}].name`,
                                                                        ''
                                                                        )
                                                                        ? getValue(
                                                                            props,
                                                                            `participant[${index}].name`,
                                                                            ''
                                                                            )
                                                                        : ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        props.emailTemplateOnchange(
                                                                        e,
                                                                        index
                                                                        )
                                                                    }
                                                                    name="name"
                                                                    />
                                                                </div>
                                                                </Col> */}
                            {/* <Col md="6">
                                                                <div className="form-group">
                                                                    <Label>Self Participant Email Address</Label>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Enter email address"
                                                                        value={
                                                                            getValue(
                                                                                props,
                                                                                `participant[${index}].email`,
                                                                                ''
                                                                            )
                                                                                ? getValue(
                                                                                    props,
                                                                                    `participant[${index}].email`,
                                                                                    ''
                                                                                )
                                                                                : ''
                                                                        }
                                                                        onChange={(e) =>
                                                                            props.emailTemplateOnchange(
                                                                                e,
                                                                                index
                                                                            )
                                                                        }
                                                                        name="email"
                                                                    />
                                                                    <div className="form-error">
                                                                        {props.simpleValidator.current.message(
                                                                            'Participant Email Address',
                                                                            getValue(
                                                                                props,
                                                                                `participant[${index}].email`,
                                                                                ''
                                                                            ),
                                                                            'email|required'
                                                                        )}
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                       */}
                          </Row>
                          <h6>Feedback Participants</h6>
                          {/* <div className="text-right">
                                                            <Button
                                                                color="nothing"
                                                                className="text-blue"
                                                                onClick={() =>
                                                                    props.removeParticipants(index)
                                                                }
                                                                disabled={index === 0 ? true : false}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div> */}
                          <Row>
                            <Col md="6">
                              <div className="form-group">
                                <Label>
                                  Manager/Skip Level Email Addresses
                                </Label>
                                <ReactMultiEmail
                                  emails={getValue(
                                    props,
                                    `participant[${index}].feedbackParticipants[${0}].emails`,
                                    ""
                                  )}
                                  onChange={(e) =>
                                    props.handleEmailTemplateEmail(e, 0, index)
                                  }
                                  placeholder="One or more email here"
                                  getLabel={(
                                    email: string,
                                    index: number,
                                    removeEmail: (index: number) => void
                                  ) => {
                                    return (
                                      <div data-tag key={index}>
                                        {email}
                                        <span
                                          data-tag-handle
                                          onClick={() => removeEmail(index)}
                                        >
                                          ×
                                        </span>
                                      </div>
                                    );
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <Label>Peers Email Addresses</Label>
                                <ReactMultiEmail
                                  emails={getValue(
                                    props,
                                    `participant[${index}].feedbackParticipants[${1}].emails`,
                                    ""
                                  )}
                                  onChange={(e) =>
                                    props.handleEmailTemplateEmail(e, 1, index)
                                  }
                                  placeholder="One or more email here"
                                  getLabel={(
                                    email: string,
                                    index: number,
                                    removeEmail: (index: number) => void
                                  ) => {
                                    return (
                                      <div data-tag key={index}>
                                        {email}
                                        <span
                                          data-tag-handle
                                          onClick={() => removeEmail(index)}
                                        >
                                          ×
                                        </span>
                                      </div>
                                    );
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <Label>Subordinates Email Addresses</Label>
                                <ReactMultiEmail
                                  emails={getValue(
                                    props,
                                    `participant[${index}].feedbackParticipants[${2}].emails`,
                                    ""
                                  )}
                                  onChange={(e) =>
                                    props.handleEmailTemplateEmail(e, 2, index)
                                  }
                                  placeholder="One or more email here"
                                  getLabel={(
                                    email: string,
                                    index: number,
                                    removeEmail: (index: number) => void
                                  ) => {
                                    return (
                                      <div data-tag key={index}>
                                        {email}
                                        <span
                                          data-tag-handle
                                          onClick={() => removeEmail(index)}
                                        >
                                          ×
                                        </span>
                                      </div>
                                    );
                                  }}
                                />
                              </div>
                            </Col>
                            {getValue(props, `showClient`, false) && (
                              <Col md="6">
                                <div className="form-group">
                                  <Label>Client Email Addresses</Label>
                                  <ReactMultiEmail
                                    emails={getValue(
                                      props,
                                      `participant[${index}].feedbackParticipants[${3}].emails`,
                                      ""
                                    )}
                                    onChange={(e) =>
                                      props.handleEmailTemplateEmail(
                                        e,
                                        3,
                                        index
                                      )
                                    }
                                    placeholder="One or more email here"
                                    getLabel={(
                                      email: string,
                                      index: number,
                                      removeEmail: (index: number) => void
                                    ) => {
                                      return (
                                        <div data-tag key={index}>
                                          {email}
                                          <span
                                            data-tag-handle
                                            onClick={() => removeEmail(index)}
                                          >
                                            ×
                                          </span>
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                              </Col>
                            )}
                          </Row>
                        </div>
                      );
                    })}
                    <hr />
                    {/* {getValue(props.surveyDetails,'status','') !== "published" && */}
                    {/* <Button
                                            color="nothing"
                                            className="blue-text-svg"
                                            onClick={getValue(props, `addParticipants`, '')}
                                        // disabled={
                                        //     getValue(
                                        //         props,
                                        //         `surveyDetails.pages[${0}].questions.length`,
                                        //         0
                                        //     ) === 0
                                        //         ? true
                                        //         : false
                                        // }
                                        >
                                            <AddSVG /> Add more
                                        </Button> */}
                    {/* } */}
                  </CardBody>
                </Card>
                {/* {getValue(props.surveyDetails,'status','') !== "published" && */}
                {/* <div className="text-right mt-4">
                                    {!props.loading360 ? (
                                        <Button
                                            color="blue"
                                            onClick={props.add360EmailTemplate}
                                        >
                                            Save
                                        </Button>
                                    ) : (
                                        <Button color="blue">Please wait...</Button>
                                    )}
                                </div> */}
                {/* } */}
              </Col>
            </Row>
          </div>
          <div className="btn-group-margin text-center mt-4">
            <Button color="border" onClick={props.handleModal}>
              Close
            </Button>
            <Button color="blue" onClick={props.add360EmailTemplate}>
              Save &amp; Send
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={props.modal}
        toggle={props.toggleModal}
        centered
        className="modal-lg"
      >
        <ModalBody>
          <div
            className="text-right"
            onClick={props.handleDownloadEmails}
            style={{ cursor: "pointer" }}
          >
            <DownloadSVG />
          </div>
          <h5 className="mb-1">Invalid Emails :</h5>
          <hr className="my-2" />
          <div className="ol-overflow-scroll mb-3">
            <ol className="mt-3 answer-ol pl-5">
              {props.errorEmails &&
                props.errorEmails.map((item) => {
                  return <li>{item}</li>;
                })}
            </ol>
          </div>
          <div className="text-right">
            <Button onClick={props.toggleModal}>Close</Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InviteParticipantSurveyModal;
