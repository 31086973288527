import LeftNav from "../../components/menu/left-nav";
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import {
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import SearchSVG from "components/svg/search-svg";
import CloseSVG from "components/svg/close-svg";
import { QueryRequestHelper } from "common/query-request-helper";
import { getValue } from "core/lodash";
import LoaderCommon from "components/common/loader-common";
import { useHistory } from "react-router";
import NoData from "../../components/Nodata";
import Pagination from "react-js-pagination";
import {
  getAllDepartments,
  updateDepartment,
} from "core/services/departments.service";
import {
  createLocation,
  getAllLocations,
  updateLocation,
  updateLocationsStatusBulk,
} from "core/services/locations.service";
import AddSVG from "components/svg/add-svg";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import InputField from "common/InputRuleFeild";
import moment from "moment";

function SurveyLocations() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const history = useHistory();
  /* -------------------------------------------------------------------------- */
  /*                                tab/Nav Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(urlParams).length === 0) {
      getData();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(urlParams).length > 0) {
      getData();
      if (getValue(urlParams, `text`, "")) {
        setSearchInput(getValue(urlParams, `text`, ""));
        openSearch(true);
      }
      if (getValue(urlParams, `skip`, "")) {
        setSkip(parseInt(getValue(urlParams, `skip`, "")));
      }
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setUsersList] = React.useState<[]>([]);

  const getData = async () => {
    let payload: any = {
      skip: getValue(urlParams, `skip`, ""),
      take: limit,
      sort: sort,
      text: getValue(urlParams, `text`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllLocations(queryRequest);
      if (resp) {
        console.log(resp);
        setUsersList(getValue(resp, `data.locations`, []));
        setTotalCount(getValue(resp, `data.count`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                             Pagination section                             */
  /* -------------------------------------------------------------------------- */
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState<any>(0);
  const [sort, setSort] = useState("newest");

  const onProductPageChangeHandler = (pageNumber) => {
    if (pageNumber == 1) {
      setPageNumber(pageNumber);
      let skip = "0";
      setSkip(skip);
      let payload = {
        ...urlParams,
        skip: skip,
      };
      let queryRequest = QueryRequestHelper(payload);
      history.push(`${window.location.pathname}?${queryRequest}`);
    } else {
      setPageNumber(pageNumber);
      let skip = (pageNumber - 1) * 10;
      setSkip(skip);
      let payload = {
        ...urlParams,
        skip: skip,
      };
      let queryRequest = QueryRequestHelper(payload);
      history.push(`${window.location.pathname}?${queryRequest}`);
    }
  };


  /* -------------------------------------------------------------------------- */
  /*                                 Search section                             */
  /* -------------------------------------------------------------------------- */

  const [search, openSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    let payload = {
      ...urlParams,
      text: value,
    };
    let queryRequest = QueryRequestHelper(payload);
    history.push(`${window.location.pathname}?${queryRequest}`);
  };
  const toggleSearch = () => {
    openSearch(!search);
    setSearchInput("");
    delete urlParams.text;
    let payload = {
      ...urlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    history.push(`${window.location.pathname}?${queryRequest}`);
    if (searchInput) {
      getData();
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Add section                              */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const intialState = {
    name: "",
    code: "",
    description: "",
    is_active: true,
  };
  const [request, setRequest] = useState(intialState);
  const reset = () => {
    setRequest({ ...request, ...intialState });
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp;
        if (editId) {
          resp = await updateLocation(editId, request);
        } else {
          resp = await createLocation(request);
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading(false);
          getData();
          reset();
          toggleModal();
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const [editId, setEditId] = useState("");
  const handleEdit = (obj) => {
    setEditId(getValue(obj, `id`, ""));
    setRequest({
      ...request,
      name: getValue(obj, `name`, ""),
      code: getValue(obj, `code`, ""),
      description: getValue(obj, `description`, ""),
    });
    toggleModal();
  };

  const handleChangeStaus = async (id: string, status: boolean) => {
    let payload = {
      ids: [id],
      is_active: status,
    };
    try {
      let resp = await updateLocationsStatusBulk(payload);
      if (resp) {
        toast.success(getValue(resp, "message", ""), {
          className: "green-circle",
        });
        getData();
      }
    } catch (error) {}
  };

  return (
    <div>
      <div className={"page-layout " + (isLeftNav ? "active" : "")}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="create-survey-tabs create-survey-tabs-search d-flex justify-content-between align-items-center">
            <div>
              <ul
                className="table-header-actions"
                onClick={() => {
                  reset();
                  toggleModal();
                }}
              >
                <li className="orange-circle">
                  <Button color="primary">
                    <AddSVG />
                  </Button>
                </li>
              </ul>
            </div>
            <div>
              <Nav pills className="basic-tabs pl-0">
                <NavItem>
                  <NavLink1
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Locations
                  </NavLink1>
                </NavItem>
              </Nav>
            </div>
            <div>
              <div className="hover-circle">
                <Button color="nothing" onClick={toggleSearch}>
                  <SearchSVG />
                </Button>
                <div className={"table-search " + (search ? "show" : "")}>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search"
                    onChange={handleChangeSearch}
                    value={searchInput}
                  />
                  <Button
                    color="nothing"
                    onClick={toggleSearch}
                    className="table-search-close"
                  >
                    <CloseSVG />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="page-padding pt-0">
            {isLoading ? (
              <LoaderCommon />
            ) : getValue(list, `length`, 0) > 0 ? (
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Table className="table-custom">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item: object) => {
                        return (
                          <tr
                            onClick={() => handleEdit(item)}
                            className="cursor-pointer"
                          >
                            <td>{getValue(item, `name`, "")}</td>
                            <td>{getValue(item, `code`, "")}</td>
                            <td>{getValue(item, `description`, "")}</td>
                            <td>
                              {getValue(item, `is_active`, "")
                                ? "Active"
                                : "Inactive"}
                            </td>

                            <td>
                              {moment(getValue(item, `created_at`, "")).format(
                                "DD MMM YYYY"
                              )}
                            </td>
                            <td>
                              <div className="choicelist-wrapper custom-switch-wrapper ">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={getValue(item, `is_active`, false)}
                                    onChange={(e: any) => {
                                      e.stopPropagation();
                                      handleChangeStaus(
                                        getValue(item, `id`, ""),
                                        getValue(item, `is_active`, "")
                                          ? false
                                          : true
                                      );
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
            ) : (
              <NoData />
            )}
          </div>
          {totalCount > 10 && (
            <div className="pagination-container">
              <div></div>
              <div className="d-flex align-items-center">
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={limit}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={onProductPageChangeHandler}
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
                <p className="mx-2">|</p>
                <h6>Total count : {totalCount}</h6>
              </div>
            </div>
          )}
        </div>
        <Modal
          isOpen={isOpen}
          toggle={toggleModal}
          centered
          // className="modal-lg"
        >
          <ModalHeader>Location</ModalHeader>
          <ModalBody className="">
            <div className=" p-1">
              <div className="form-group">
                <label htmlFor="email">Location Name</label>
                <InputField
                  inputType="TEXT"
                  id="name"
                  name="name"
                  value={getValue(request, `name`, "")}
                  onChange={(e: any) =>
                    setRequest({ ...request, name: e.target.value })
                  }
                  placeholder="Enter Location Name"
                  className="form-control"
                  label="Location Name"
                  validator={simpleValidator}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Location Code</label>
                <InputField
                  inputType="TEXT"
                  id="code"
                  name="code"
                  value={getValue(request, `code`, "")}
                  onChange={(e: any) =>
                    setRequest({ ...request, code: e.target.value })
                  }
                  placeholder="Enter Location Code"
                  className="form-control"
                  label="Location Code"
                  validator={simpleValidator}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Description</label>
                <InputField
                  inputType="TEXTAREA"
                  id="description"
                  name="description"
                  value={getValue(request, `description`, "")}
                  onChange={(e: any) =>
                    setRequest({ ...request, description: e.target.value })
                  }
                  placeholder="Enter Description"
                  className="form-control"
                  label="Location Description"
                  validator={simpleValidator}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="" color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
            <Button className="" color="blue" onClick={handleSubmit}>
              {submitLoading ? "Please wait..." : "Submit"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default SurveyLocations;
