import React, { useState } from 'react';
import {
  getSpecificSurveyTemplate,
  getSurveyTemplate,
} from '../../core/services/surveyTemplateService';
import ChooseTemplateLayout from './chooseTemplateLayout';
import { getValue } from '../../core/lodash';

interface ITemplateFormComponantProps {}

const TemplateSurveyCreationComponant: React.FunctionComponent<ITemplateFormComponantProps> = (
  _props
) => {
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [templateInfo, setTemplateInfo] = React.useState({});
  const [templateList, setTemplateList] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  // const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };

  React.useEffect(() => {
    getTemplateList();
  }, []);

  // ===========================================================================
  // API section
  // ===========================================================================
  const getTemplateList = async () => {
    try {
      let response = await getSurveyTemplate(skip, limit, '');
      if (response) {
        // setTemplateList(getValue(response, 'data.templates', []));
        getTemplateInfo(getValue(response, 'data.templates[0].id', []));
      }
    } catch (error) {}
  };
  const getTemplateInfo = async (id) => {
    try {
      let resp = await getSpecificSurveyTemplate(id);
      if (resp) {
        setTemplateInfo(getValue(resp, 'data', ''));
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <>
      <ChooseTemplateLayout
        templateInfo={templateInfo}
        getTemplateInfo={getTemplateInfo}
      />
    </>
  );
};

export default TemplateSurveyCreationComponant;
